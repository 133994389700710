const img = {
  pcttLogoImg: require("./img/pctt-logo.png"),
  wallpaperImg: require("./img/wallpaper.jpg"),
  mapLayerImg: require("./img/map-layers.png"),
  searchImg: require("./img/search.png"),
  legendImg: require("./img/map-legend.png"),
  directionImg: require("./img/direction.png"),
  changeStyleImg: require("./img/change-layer.png"),
  openImg: require("./img/open.png"),
  closeImg: require("./img/close.png"),
  tableImg: require("./img/table.png"),
  statisticImg: require("./img/statistic.png"),
  chartLineImg: require("./img/chart-line.png"),
  logoutImg: require("./img/logout.png"),
  mapslayerImg: require("./img/maps-layer.png"),
  exportImg: require("./img/export.png"),
  wordImg: require("./img/word.png"),
  excelImg: require("./img/excel.png"),
  chartColumnImg: require("./img/chart-column.png"),
  openMenuImg: require("./img/menu-icon.png"),
  notFoundImg: require("./img/not-found.png"),
  successImg: require("./img/success.png"),
  infoImg: require("./img/info.png"),
  errorImg: require("./img/error.png"),
  pdfImg: require("./img/pdf.png"),
  createImg: require("./img/create.png"),
  downloadImg: require("./img/download.png"),
  uploadImg: require("./img/upload.png"),
  refreshImg: require("./img/refresh.png"),
  viewExcelImg: require("./img/view-excel.png"),
};
const icon = {
  triangleIcon: require("./icon/triangle.png"),
  sewerIcon: require("./icon/sewer.png"),
  circleIcon: require("./icon/circle.png"),
  warningMarkIcon: require("./icon/warning-mark.png"),
  warningSignIcon: require("./icon/warning-sign.png"),
  waterLevelIcon: require("./icon/water-level.png"),
  lakeIcon: require("./icon/lake.png"),
  sunIcon: require("./icon/sun.png"),
  rainIcon: require("./icon/rain.png"),
  startIcon: require("./icon/start.png"),
  endIcon: require("./icon/end.png"),
  fireIcon: require("./icon/fire.png"),
  anchorIcon: require("./icon/anchor.png"),
  safeIcon: require("./icon/safe.png"),
  weakIcon: require("./icon/weak.png"),
  tornadoIcon: require("./icon/tornado.png"),
  stormIcon: require("./icon/storm.png"),
  tropicalIcon: require("./icon/tropical.png"),
  arrowIcon: require("./icon/arrow.png"),
};
const line = {
  districtLine: require("./line/district.png"),
  trafficLine: require("./line/traffic.png"),
  waterSystemLine: require("./line/water-system.png"),
  embankmentLine: require("./line/embankment.png"),
  dikeLine: require("./line/dike.png"),
  failureLine: require("./line/failure.png"),
  stormLine: require("./line/storm.png"),
  depressionLine: require("./line/depression.png"),
  directionLine: require("./line/direction.png"),
};

export { img, icon, line };
