import { currentTime } from "src/tools/currentTime";

export const reports_BM006 = ({ type, data }) => {
  const subtitle = data.subtitle.text;

  let endMonth = "";
  let endYear = "";
  let startMonth = "";
  let reportMonth = "";

  if (subtitle) {
    const subtitleArray = subtitle.split(' ');
    const startDateString = subtitleArray[1];
    const endDateString = subtitleArray[3];

    // Extracting date components
    const [startDay, startMonthTemp, startYear] = startDateString.split('/');
    const [endDay, endMonthTemp, endYearTemp] = endDateString.split('/');

    // Assigning the extracted values
    startMonth = startMonthTemp;
    endMonth = endMonthTemp;
    endYear = endYearTemp;

    // Determining the reportMonth
    if (parseInt(startMonth) === parseInt(endMonth)) {
      reportMonth = endMonth;
    } else {
      reportMonth = '';
    }
  }

  const title = data.title.text;
  if (!data.report?.length) return;
  const source = data.report;
  if (!source) return;
  const report = source.map((dt) => {
    const data = { ...dt };
    const fields = Object.keys(data).reduce((acc, val) => {
      data[val] = data[val] ?? "";
      return { ...acc, [val]: data[val] };
    }, {});
    return fields;
  });

  let disasterStats = new Map();

  function initializeStats() {
    return {
      ng_chet: 0,
      ng_mattich: 0,
      ng_bthuong: 0,
      nu_chet: 0,
      nu_mattich: 0,
      nu_bthuong: 0,
      nha_sap: 0,
      nha_thai: 0,
      lua: 0,
      hoamau: 0,
      giasuc: 0,
      giacam: 0,
      thiethai: 0,
      uniqueDisasters: new Set(),
    };
  }

  let disasterTypes = {
    'Bão': 'Bão, áp thấp nhiệt đới, gió mạnh trên biển',
    'Mưa lớn, lũ, ngập lụt, sạt sở, lũ quét': 'Mưa lớn, lũ, ngập lụt, sạt sở, lũ quét',
    'Giông, lốc': 'Mưa lốc, giông, sét, mưa đá',
    'Sương, rét': 'Sương muối, sương mù, rét hại',
    'Hạn hán, nắng nóng, xâm nhập mặn': 'Hạn hán, nắng nóng; xâm nhập mặn',
    'Sạt lở': 'Sạt lở, sụt lún bờ biển, bờ sông',
    'Triều cường': 'Thiên tai khác (triều cường)'
  };

  Object.keys(disasterTypes).forEach(type => {
    disasterStats.set(type, initializeStats());
  });

  let statsByDistrict = new Map();
  let uniqueDisastersByDistrict = new Map();

  let districtNames = {
    '760': 'Quận 1', '761': 'Quận 12', '764': 'Quận Gò Vấp', '765': 'Quận Bình Thạnh',
    '766': 'Quận Tân Bình', '767': 'Quận Tân Phú', '768': 'Quận Phú Nhuận', '769': 'Thành phố Thủ Đức',
    '770': 'Quận 3', '771': 'Quận 10', '772': 'Quận 11', '773': 'Quận 4',
    '774': 'Quận 5', '775': 'Quận 6', '776': 'Quận 8', '777': 'Quận Bình Tân',
    '778': 'Quận 7', '783': 'Huyện Củ Chi', '784': 'Huyện Hóc Môn', '785': 'Huyện Bình Chánh',
    '786': 'Huyện Nhà Bè', '787': 'Huyện Cần Giờ'
  };

  report.forEach(item => {
    let disasterType = item.loaithientai;
    if (!disasterStats.has(disasterType)) return;

    let stats = disasterStats.get(disasterType);
    let disasterKey = item.date + "-" + item.diadiem;
    stats.uniqueDisasters.add(disasterKey);
   
    stats.thiethai += parseInt(item.giatrithiethai);

    if (item.doituongthiethai === "Người (chết)") {
      stats.ng_chet += parseInt(item.soluong);
      stats.nu_chet += parseInt(item.gioitinhnu);
    } else if (item.doituongthiethai === "Người (mất tích)") {
      stats.ng_mattich += parseInt(item.soluong);
      stats.nu_mattich += parseInt(item.gioitinhnu);
    } else if (item.doituongthiethai === "Người (bị thương)") {
      stats.ng_bthuong += parseInt(item.soluong);
      stats.nu_bthuong += parseInt(item.gioitinhnu);
    } else if (item.doituongthiethai === "Nhà, trụ sở") {
      if (item.motathiethai.toLowerCase().includes("sập")) {
        stats.nha_sap += parseInt(item.soluong);
      } else {
        stats.nha_thai += parseInt(item.soluong);
      }
    } else if (item.doituongthiethai === "Nông nghiệp (ha)") {
      if (item.motathiethai.toLowerCase().includes("hoa màu") || item.motathiethai.toLowerCase().includes("vườn")) {
        stats.hoamau += parseInt(item.soluong);
      }
    } else if (item.doituongthiethai === "Lúa (ha)") {
      stats.lua += parseInt(item.soluong);
    } else if (item.doituongthiethai === "Gia súc") {
      stats.giasuc += parseInt(item.soluong);
    } else if (item.doituongthiethai === "Gia cầm") {
      stats.giacam += parseInt(item.soluong);
    }


    disasterStats.set(disasterType, stats);

    const districtCode = item.mahuyen;
    let districtName = districtNames[districtCode] || item.diadiem;

    if (!statsByDistrict.has(districtName)) {
      statsByDistrict.set(districtName, initializeStats());
      uniqueDisastersByDistrict.set(districtName, new Set());
    }

    let districtStats = statsByDistrict.get(districtName);
    districtStats.thiethai += parseInt(item.giatrithiethai);

    if (item.doituongthiethai === "Người (chết)") {
      districtStats.ng_chet += parseInt(item.soluong);
      districtStats.nu_chet += parseInt(item.gioitinhnu);
    } else if (item.doituongthiethai === "Người (mất tích)") {
      districtStats.ng_mattich += parseInt(item.soluong);
      districtStats.nu_mattich += parseInt(item.gioitinhnu);
    } else if (item.doituongthiethai === "Người (bị thương)") {
      districtStats.ng_bthuong += parseInt(item.soluong);
      districtStats.nu_bthuong += parseInt(item.gioitinhnu);
    } else if (item.doituongthiethai === "Nhà, trụ sở") {
      if (item.motathiethai.toLowerCase().includes("sập")) {
        districtStats.nha_sap += parseInt(item.soluong);
      } else {
        districtStats.nha_thai += parseInt(item.soluong);
      }
    } else if (item.doituongthiethai === "Nông nghiệp (ha)") {
      if (item.motathiethai.toLowerCase().includes("hoa màu") || item.motathiethai.toLowerCase().includes("vườn")) {
        districtStats.hoamau += parseInt(item.soluong);
      }
    } else if (item.doituongthiethai === "Lúa (ha)") {
      districtStats.lua += parseInt(item.soluong);
    } else if (item.doituongthiethai === "Gia súc") {
      districtStats.giasuc += parseInt(item.soluong);
    } else if (item.doituongthiethai === "Gia cầm") {
      districtStats.giacam += parseInt(item.soluong);
    }

    statsByDistrict.set(districtName, districtStats);
    uniqueDisastersByDistrict.get(districtName).add(disasterKey);
  });

  function replaceZerosWithEmptyString(obj) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value === 0 ? '' : value])
    );
  }

  let exportDataByDisasterType = Array.from(disasterStats.entries()).map(([type, stats], index) => ({
    id: `0${index + 2}`,
    type: disasterTypes[type] || type,
    uniqueDisastersCount: stats.uniqueDisasters.size,
    ...stats
  }));

  let totalStats = initializeStats();
  let totalUniqueDisasters = 0;

  exportDataByDisasterType.forEach(stats => {
    totalStats.ng_chet += stats.ng_chet;
    totalStats.ng_mattich += stats.ng_mattich;
    totalStats.ng_bthuong += stats.ng_bthuong;
    totalStats.nu_chet += stats.nu_chet;
    totalStats.nu_mattich += stats.nu_mattich;
    totalStats.nu_bthuong += stats.nu_bthuong;
    totalStats.nha_sap += stats.nha_sap;
    totalStats.nha_thai += stats.nha_thai;
    totalStats.lua += stats.lua;
    totalStats.hoamau += stats.hoamau;
    totalStats.giasuc += stats.giasuc;
    totalStats.giacam += stats.giacam;
    totalStats.thiethai += stats.thiethai;
    totalUniqueDisasters += stats.uniqueDisastersCount;
  });

  // Replace zero values with empty strings for each disaster type stats
  exportDataByDisasterType = exportDataByDisasterType.map(stats => ({
    /* ...stats, */
    ...replaceZerosWithEmptyString(stats)
  }));
  let CustomexportDataByDisasterType = ({
    id: '01',
    type: 'Tổng số',
    uniqueDisastersCount: totalUniqueDisasters,
    ...replaceZerosWithEmptyString(totalStats),
  });

  const districtOrder = (district) => {
    if (district.includes("Thành phố")) return 1;
    if (district.match(/Quận \d+/)) return 2;
    if (district.match(/Quận [A-Z]/i)) return 3;
    if (district.includes("Huyện")) return 4;
    return 5;
  };

  const sortDistricts = (a, b) => {
    const orderA = districtOrder(a.district);
    const orderB = districtOrder(b.district);

    if (orderA === orderB) {
      return a.district.localeCompare(b.district);
    }

    return orderA - orderB;
  };

  let exportDataByDistrict = Array.from(statsByDistrict.entries()).map(([district, stats], index) => ({
    id: `${index + 10}`,
    district: district,
    uniqueDisastersCount: uniqueDisastersByDistrict.get(district).size,
    ...stats
  })).sort(sortDistricts);

  let totalStatsByDistrict = initializeStats();
  let totalUniqueDisastersByDistrict = 0;

  exportDataByDistrict.forEach(stats => {
    totalStatsByDistrict.ng_chet += stats.ng_chet;
    totalStatsByDistrict.ng_mattich += stats.ng_mattich;
    totalStatsByDistrict.ng_bthuong += stats.ng_bthuong;
    totalStatsByDistrict.nu_chet += stats.nu_chet;
    totalStatsByDistrict.nu_mattich += stats.nu_mattich;
    totalStatsByDistrict.nu_bthuong += stats.nu_bthuong;
    totalStatsByDistrict.nha_sap += stats.nha_sap;
    totalStatsByDistrict.nha_thai += stats.nha_thai;
    totalStatsByDistrict.lua += stats.lua;
    totalStatsByDistrict.hoamau += stats.hoamau;
    totalStatsByDistrict.giasuc += stats.giasuc;
    totalStatsByDistrict.giacam += stats.giacam;
    totalStatsByDistrict.thiethai += stats.thiethai;
    totalUniqueDisastersByDistrict += stats.uniqueDisastersCount;
  });

  exportDataByDistrict = exportDataByDistrict.map(stats => ({
    /* ...stats, */
    ...replaceZerosWithEmptyString(stats)
  }));
  let CustomexportDataByDistrict = ({
    id: '09',
    district: '2. Chia theo huyện/quận/thị xã/thành phố',
    uniqueDisastersCount: totalUniqueDisastersByDistrict,
    ...replaceZerosWithEmptyString(totalStatsByDistrict)
  });

  const exportData = {
    isDetail: type === "detail",
    isTotal: type === "total",
    endYear: endYear,
    reportMonth: reportMonth.toString(),
    time: currentTime(),
    day: currentTime().split(' ')[0].split('/')[0],
    month: currentTime().split(' ')[0].split('/')[1],
    year: currentTime().split(' ')[0].split('/')[2],
    totalStats: CustomexportDataByDisasterType,
    disasterStats: exportDataByDisasterType,
    totalDistricts: CustomexportDataByDistrict,
    statsByDistrict: exportDataByDistrict
  };
  return exportData;
};
