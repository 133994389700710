import "./EdittingUser.css";
import { Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import useAxiosJWT from "src/hooks/useAxiosJWT";
import useToast from "src/hooks/useToast";
import config from "src/config";
import { Button, ButtonGroup } from "src/components/interfaces/Button";
import Modal from "src/components/interfaces/Modal";
import { formValidation } from "src/utils/formValidation";
import { edittingUserObj } from "./edittingUserObj";
import { apiDeleteMember, apiUpdateMemberPassword } from "src/services/admin";
import {
  toggleAssignRole,
  userRoleLists,
  userAccount,
  setUserAccount,
  isAssignRole,
  initState,
} from "src/stores/admin";
import Form from "src/components/interfaces/Form";

const EdittingUser = ({ pageType }) => {
  const edittingPage = edittingUserObj[pageType];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosJWT = useAxiosJWT();
  const toast = useToast();
  const { id } = useParams();
  const { userAccount: userAccountValidate } = initState;
  const isPermission = useSelector(isAssignRole);
  const userInfo = useSelector(userAccount);
  const userRoleList = useSelector(userRoleLists);
  const randomUserID = uuidv4().replaceAll("-", "");
  const [formValidate, setFormValidate] = useState(userAccountValidate);
  const configForms = config.forms;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleDeleteUser = useCallback(() => {
    closeDeleteModal();
    apiDeleteMember({ id, axiosJWT, navigate, toast });
  }, [id, axiosJWT, navigate, toast, closeDeleteModal]);

  const handleEnterForm = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch(setUserAccount({ ...userInfo, [name]: value }));
    },
    [userInfo, dispatch]
  );

  const handleTogglePassword = useCallback(() => {
    typeof userInfo.password === "string"
      ? dispatch(setUserAccount({ ...userInfo, password: null }))
      : dispatch(setUserAccount({ ...userInfo, password: "" }));
  }, [userInfo, dispatch]);

  const handleResetPassword = useCallback(() => {
    if (!userInfo.password) return;
    const formNotValid = formValidation(userInfo);
    const isNotValid = Object.keys(formNotValid).length !== 0;
    if (isNotValid) return setFormValidate(formNotValid);
    setFormValidate({});
    const hash = CryptoJS.SHA512(userInfo.password);
    const hashedPwd = hash.toString(CryptoJS.enc.Base64);
    const updatePassWord = {
      memberid: userInfo.memberid,
      pwd: hashedPwd,
    };
    apiUpdateMemberPassword({ updatePassWord, axiosJWT, toast });
    dispatch(setUserAccount({ ...userInfo, password: null }));
  }, [userInfo, axiosJWT, dispatch, toast]);

  const [isRoleAssigned, setIsRoleAssigned] = useState(false);

  const handleEditUser = useCallback(() => {
    const onEditData = edittingPage.data.onEdit;
    const dataValidation = onEditData({ userInfo });
    setFormValidate(dataValidation);
    const isNotValid = Object.keys(dataValidation).length !== 0;
    if (isNotValid) return;
    dispatch(toggleAssignRole(true));
    setIsRoleAssigned(true);
    if (userInfo.memberid) return;
    dispatch(setUserAccount({ ...userInfo, memberid: randomUserID }));
  }, [edittingPage, randomUserID, userInfo, dispatch]);

  const handleSubmit = useCallback(() => {
    if (Object.keys(edittingPage.data.onEdit({ userInfo })).length !== 0) {
      setFormValidate(edittingPage.data.onEdit({ userInfo }));
      return
    }
    const onSubmit = edittingPage.submit.onSubmit;

    const hash = CryptoJS.SHA512(userInfo.password);
    const hashedPwd = hash.toString(CryptoJS.enc.Base64);
    const userAccount = { ...userInfo, password: hashedPwd };
    if (pageType === "create" && !isRoleAssigned) {
      toast.error({ title: "Cảnh báo", message: "Chưa phân quyền" });
      return;
    }
    onSubmit({ axiosJWT, userAccount, userRoleList, navigate, toast });

  }, [edittingPage, userInfo, userRoleList, axiosJWT, navigate, toast, isRoleAssigned, pageType]);



  const handleCloseAssignRole = () => {
    dispatch(toggleAssignRole());
  };

  return (
    <div className="edit-data-form">
      <div className="edit-data-title">
        <span>{edittingPage.title}</span>
        {pageType === "update" && (
          <Button onClick={openDeleteModal}>
            Xóa người dùng
          </Button>
        )}
      </div>
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <div className="modal-header">
          <h2 className="modal-title">Xác nhận xóa người dùng</h2>
        </div>
        <div className="modal-body">
          <p className="modal-content">Bạn có chắc chắn muốn xóa người dùng này không?</p></div>
        <div className="modal-footer">
          <Button onClick={handleDeleteUser}>Xác nhận</Button>
          <Button onClick={closeDeleteModal}>Hủy bỏ</Button>
        </div>
      </Modal>
      <form className="edit-data-content">
        <div className="edit-data-argument">
          <Form>
            {(pageType === "profile" ? configForms.userProfileForms : configForms.userInfoForms).map((form) => (
              <Form.Group key={form.id} controlID={form.id}>
                <Form.Label>{form.label}</Form.Label>
                <Form.Control
                  type={form.type}
                  id={form.id}
                  name={form.id}
                  disabled={
                    typeof userInfo[form.id] !== "string"
                  }
                  value={userInfo[form.id] ?? ""}
                  onChange={handleEnterForm}
                />
                {(pageType === "update" || pageType === "profile") && form.id === "password" && (
                  <ResetPassword
                    userInfo={userInfo}
                    onToggle={handleTogglePassword}
                    onReset={handleResetPassword}
                  />
                )}
                {Object.keys(formValidate).length !== 0 &&
                  formValidate[form.id] && <span>{formValidate[form.id]}</span>}
              </Form.Group>
            ))}
          </Form>
        </div>
      </form>
      <div className="edit-data-action">
        {pageType === "profile" ? (
          <Button outline onClick={() => window.history.back()}>
            Quay lại
          </Button>
        ) : (
          <Button outline onClick={() => navigate("/admin")}>
            Thoát
          </Button>)}
        <Button outline onClick={handleSubmit} style={{ display: pageType === "profile" ? "none" : "inline-block" }}
          disabled={isPermission && pageType === "create"}>
          {edittingPage.submit.text}
        </Button>
        {pageType === "create" && !isPermission ? (
          <Button outline onClick={handleEditUser}>
            Phân quyền
          </Button>
        ) : pageType === "create" && isPermission ? (
          <Button outline onClick={handleCloseAssignRole}>
            Tắt phân quyền
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const ResetPassword = ({ userInfo, onToggle, onReset }) => {
  return (
    <Fragment>
      {typeof userInfo["password"] !== "string" ? (
        <Button
          outline
          className="edit-data-password"
          onClick={onToggle}
        >
          Đặt lại mật khẩu
        </Button>
      ) : (
        <ButtonGroup>
          <Button outline className="edit-data-password" onClick={onReset}>
            Lưu
          </Button>
          <Button outline className="edit-data-password" onClick={onToggle}>
            Hủy
          </Button>
        </ButtonGroup>
      )}
    </Fragment>
  );
};

export default EdittingUser;
