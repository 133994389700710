const table = {
  idColumn: [
    "objectid",
    "idapthap",
    "idbao",
    "idbcbsl",
    "idchay",
    "idcongdap",
    "idkenhmuong",
    "idantoan",
    "idxungyeu",
    "idtramman",
    "idhochua",
    "idke",
    "idknd",
    "idlocxoay",
    "idmoccbtc",
    "idtrammua",
    "idtrammucnuoc",
    "idtramkt",
    "idsatlol",
    "idsatlop",
    "idthiethai",
    "idxalu",
    "iddichuyen",
    "idkhlucluong",
    "idkhphuogtien",
    "idkhsotan",
    "iddanhba",
    "idhinhanh",
    "idvideo",
    "idtulieu",
    process.env.REACT_APP_NOTECOLUMN,
    process.env.REACT_APP_LINECOLUMN,
    process.env.REACT_APP_SHAPECOLUMN,
  ],
  dateColumn: [
    "ngaybatdau",
    "ngayketthuc",
    "ngayhinhanh",
    "ngaytulieu",
    "ngayvideo",
    "ngayvb",
    process.env.REACT_APP_DATECOLUMN,
  ],
};

export default table;
