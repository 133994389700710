import "./Data.css";
import { ShowData } from "./pages";

const Data = () => {
  return (
    <div className="data">
      <ShowData />
    </div>
  );
};

export default Data;
