import {
  apiTrafficData,
  apiWaterSystemData,
  apiDepressionData,
  apiStormData,
  apiTornadoData,
  apiFailureLineData,
  apiFailurePointData,
  apiSalinityData,
  apiHotData,
  apiRainData,
  apiWaterLevelData,
  apiLakeData,
  apiForestFireData,
  apiEmbankmentData,
  apiDikeData,
  apiSewerData,
  apiWarningMarkData,
  apiWarningSignData,
  apiParkZoneData,
  apiDamageData,
  apiWeakPointData,
  apiSafePointData,
  apiStormStatistic,
  apiSalinityStatistic,
  apiHotStatistic,
  apiRainStatistic,
  apiWaterLevelStatistic,
  apiLakeStatistic,
  apiDamageStatistic,
  apiDirectionData,
  apiDepressionStatistic,
  apiTornadoStatistic,
  apiFailureLineStatistic,
  apiFailurePointStatistic,
  apiDikeStatistic,
  apiEmbankmentStatistic,
  apiSewerStatistic,
  apiWarningMarkStatistic,
  apiWarningSignStatistic,
  apiParkZoneStatistic,
  apiWeakPointStatistic,
  apiSafePointStatistic,
} from "src/services/home";
import {
  apiPhotoMaterial,
  apiVideoMaterial,
  apiOrtherMaterial,
} from "src/services/material";
import {
  apiWeakPoint,
  apiSafePoint,
  apiDirection,
  apiMigration,
  apiForce,
  apiTransport,
  apiContact,
  apiMigrationStatistic,
  apiForceStatistic,
  apiTransportStatistic,
} from "src/services/solution";

const services = {
  basemaps: {
    "Bản đồ giao thông": {
      dataApi: apiTrafficData,
    },
    "Bản đồ thủy hệ": {
      dataApi: apiWaterSystemData,
    },
  },
  home: {
    "Áp thấp nhiệt đới": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiDepressionData,
      statisticApi: apiDepressionStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_DEPRESSIONDATA,
      file: {},
    },
    "Bão": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiStormData,
      statisticApi: apiStormStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_STORMDATA,
      file: {},
    },
    "Lốc": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiTornadoData,
      statisticApi: apiTornadoStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_TORNADODATA,
      file: {},
    },
    "Tuyến sạt lở": {
      table: { type: "LineString", form: "application/json", element: "only" },
      dataApi: apiFailureLineData,
      statisticApi: apiFailureLineStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_FAILURELINEDATA,
      file: {},
    },
    "Điểm sạt lở": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiFailurePointData,
      statisticApi: apiFailurePointStatistic,
      editAccept: false,
      editApi: process.env.REACT_APP_API_GET_FAILUREPOINTDATA,
      file: {},
    },
    "Xâm nhập mặn (Độ mặn)": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiSalinityData,
      statisticApi: apiSalinityStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_SALINITYDATA,
      file: {},
    },
    "Nắng nóng": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiHotData,
      statisticApi: apiHotStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_HOTDATA,
      file: {},
    },
    "Mưa": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiRainData,
      statisticApi: apiRainStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_RAINDATA,
      file: {},
    },
    "Mực nước": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiWaterLevelData,
      statisticApi: apiWaterLevelStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_WATERLEVELDATA,
      file: {},
    },
    "Hồ chứa": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiLakeData,
      statisticApi: apiLakeStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_LAKEDATA,
      file: {},
    },
    "Cháy rừng tự nhiên": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiForestFireData,
      statisticApi: null,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_FORESTFIREDATA,
      file: {},
    },
    "Kè": {
      table: { type: "MultiLineString", form: "application/json", element: "only" },
      dataApi: apiEmbankmentData,
      statisticApi: apiEmbankmentStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_EMBANKMENTDATA,
      file: {},
    },
    "Đê bao, bờ bao": {
      table: { type: "MultiLineString", form: "application/json", element: "only" },
      dataApi: apiDikeData,
      statisticApi: apiDikeStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_DIKEDATA,
      file: {},
    },
    "Cống, đập": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiSewerData,
      statisticApi: apiSewerStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_SEWERDATA,
      file: {},
    },
    "Mốc cảnh báo triều cường": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiWarningMarkData,
      statisticApi: apiWarningMarkStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_WARNINGMARKDATA,
      file: {},
    },
    "Biển cảnh báo sạt lở": {
      table: { type: "Point", form: "multipart/form-data", element: "only" },
      dataApi: apiWarningSignData,
      statisticApi: apiWarningSignStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_WARNINGSIGNDATA,
      file: { type: "image", path: "photo", name: "hinhanh" },
    },
    "Khu neo đậu tàu thuyền": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiParkZoneData,
      statisticApi: apiParkZoneStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_PARKZONEDATA,
      file: {},
    },
    "Thiệt hại do thiên tai": {
      table: { type: null, form: "application/json", element: "multi" },
      dataApi: apiDamageData,
      statisticApi: apiDamageStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_DAMAGEDATA,
      file: {},
    },
    "Vị trí xung yếu": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiWeakPointData,
      statisticApi: apiWeakPointStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_WEAKPOINTDATA,
      file: {},
    },
    "Vị trí an toàn": {
      table: { type: "Point", form: "application/json", element: "multi" },
      dataApi: apiSafePointData,
      statisticApi: apiSafePointStatistic,
      editAccept: true,
      editApi: process.env.REACT_APP_API_GET_SAFEPOINTDATA,
      file: {},
    },
    "Hướng di chuyển sơ tán dân": {
      table: { type: null, form: null, element: null },
      dataApi: apiDirectionData,
      statisticApi: null,
      editAccept: false,
      editApi: null,
      file: {},
    },
  },
  material: {
    "Thông tin lưu trữ tư liệu hình ảnh": {
      dataApi: apiPhotoMaterial,
      editApi: process.env.REACT_APP_API_GET_PHOTODATAMATERIAL,
      file: { type: "image", path: "photo", name: "tenhinhanh" },
    },
    "Thông tin lưu trữ tư liệu video": {
      dataApi: apiVideoMaterial,
      editApi: process.env.REACT_APP_API_GET_VIDEODATAMATERIAL,
      file: { type: "video", path: "media", name: "tenvideo" },
    },
    "Thông tin lưu trữ tư liệu khác": {
      dataApi: apiOrtherMaterial,
      editApi: process.env.REACT_APP_API_GET_ORTHERDATAMATERIAL,
      file: { type: "pdf", path: "pdf", name: "tentulieu" },
    },
  },
  solution: {
    "Vị trí xung yếu": {
      dataApi: apiWeakPoint,
      statisticApi: null,
      editApi: process.env.REACT_APP_API_GET_WEAKPOINTDATA,
    },
    "Vị trí an toàn": {
      dataApi: apiSafePoint,
      statisticApi: null,
      editApi: process.env.REACT_APP_API_GET_SAFEPOINTDATA,
    },
    "Hướng di chuyển sơ tán dân": {
      dataApi: apiDirection,
      statisticApi: null,
      editApi: process.env.REACT_APP_API_GET_DIRECTIONDATA,
    },
    "Kế hoạch dự kiến di dời, sơ tán dân": {
      dataApi: apiMigration,
      statisticApi: apiMigrationStatistic,
      editApi: process.env.REACT_APP_API_GET_MIGRATIONDATA,
    },
    "Kế hoạch lực lượng dự kiến huy động": {
      dataApi: apiForce,
      statisticApi: apiForceStatistic,
      editApi: process.env.REACT_APP_API_GET_FORCEDATA,
    },
    "Kế hoạch phương tiện, trang thiết bị dự kiến huy động": {
      dataApi: apiTransport,
      statisticApi: apiTransportStatistic,
      editApi: process.env.REACT_APP_API_GET_TRANSPORTDATA,
    },
    "Danh bạ điện thoại": {
      dataApi: apiContact,
      statisticApi: null,
      editApi: process.env.REACT_APP_API_GET_CONTACTDATA,
    },
  },
};

export default services;
