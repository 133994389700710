const times = {
  months: [
    { id: "january", value: "1" },
    { id: "febuary", value: "2" },
    { id: "march", value: "3" },
    { id: "april", value: "4" },
    { id: "may", value: "5" },
    { id: "jun", value: "6" },
    { id: "july", value: "7" },
    { id: "august", value: "8" },
    { id: "september", value: "9" },
    { id: "october", value: "10" },
    { id: "november", value: "11" },
    { id: "december", value: "12" },
  ],
};

export default times;
