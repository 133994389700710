//Trang thay đổi mật khẩu người dùng

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAxiosJWT from "src/hooks/useAxiosJWT";
import { EdittingUser } from "src/pages/Admin/components";
//import { isAssignRole, toggleAssignRole } from "src/stores/admin";
import { apiShowMemberAccount } from "src/services/admin";

const EditProfile = () => {
  //const isPermission = useSelector(isAssignRole);
  const axiosJWT = useAxiosJWT();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    //dispatch(toggleAssignRole(true));
    apiShowMemberAccount({ id, axiosJWT, dispatch });
    //apiShowMemberRoles({ id, axiosJWT, dispatch });
  }, [id, axiosJWT, dispatch]);

  return (
    <div className="edit-data">
      <div className="edit-data-container">
        <EdittingUser pageType="profile" />
        {/* {isPermission && <AssignRole />} */}
      </div>
    </div>
  );
};

export default EditProfile;

