import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Protected, Allowed, Required } from "./middleware/Router";
import { protectedRoutes, allowedRoutes, errorRoutes } from "./routes";
import RootLayout from "./layouts/RootLayout";
import { ToastProvider } from "./contexts/Toast";
import ToastContainer from "./components/interfaces/Toast";
import Browser from "./middleware/Browser";
import axios from "axios";
import useToast from "src/hooks/useToast";

function App() {
  const { toast } = useToast();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      try {
        // Show the toast notification
        toast.info({
          title: "Thông báo",
          message: "Đang đăng xuất ..."
        });
      } catch (error) {
        // Bỏ qua lỗi không xác định
        // Không hiển thị thông báo lỗi cho người dùng
      }

      // Send the logout request to the API
      axios.post(`${process.env.REACT_APP_DOMAIN}`+"/logout").then(() => {
        // Close the application after the logout request is completed
        window.close();
      }).catch((error) => {
        console.error("Logout failed", error);
      }); 
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [toast]);

  return (
    <div className="App">
      <Router>
        <ToastProvider>
          <ToastContainer />
          <Browser>
            <Routes>
              <Route element={<Protected />}>
                {protectedRoutes.map((route) => (
                  <Route
                    key={route.path}
                    element={<Required role={route.role} />}
                  >
                    <Route
                      path={route.path}
                      element={<RootLayout>{route.component}</RootLayout>}
                    />
                  </Route>
                ))}
              </Route>
              <Route element={<Allowed />}>
                {allowedRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<RootLayout>{route.component}</RootLayout>}
                  />
                ))}
              </Route>
              <Route path={errorRoutes.path} element={errorRoutes.component} />
            </Routes>
          </Browser>
        </ToastProvider>
      </Router>
    </div>
  );
}

export default App;