const data = {
  fullZoneZoom: [
    [90, 3], // Southwest coordinates
    [130, 30], // Northeast coordinates
  ],
  cityZoom: [
    [106.987979, 10.349607], // Southwest coordinates
    [106.319888, 11.170031], // Northeast coordinates
  ],
  cityBoundaries: [
    {
      lon: 106.459709681,
      lat: 11.158200101,
    },
    {
      lon: 106.456230259,
      lat: 11.160228063,
    },
    {
      lon: 106.451823765,
      lat: 11.151874532,
    },
    {
      lon: 106.458212155,
      lat: 11.151309243,
    },
    {
      lon: 106.455924816,
      lat: 11.140615148,
    },
    {
      lon: 106.450986039,
      lat: 11.136267546,
    },
    {
      lon: 106.444854715,
      lat: 11.125421359,
    },
    {
      lon: 106.442613473,
      lat: 11.116102718,
    },
    {
      lon: 106.437336361,
      lat: 11.111441764,
    },
    {
      lon: 106.430829792,
      lat: 11.092061235,
    },
    {
      lon: 106.42595803,
      lat: 11.091618411,
    },
    {
      lon: 106.427340578,
      lat: 11.074014021,
    },
    {
      lon: 106.420176338,
      lat: 11.063363406,
    },
    {
      lon: 106.420307038,
      lat: 11.052001554,
    },
    {
      lon: 106.424525366,
      lat: 11.047161592,
    },
    {
      lon: 106.420065373,
      lat: 11.036163623,
    },
    {
      lon: 106.41197842,
      lat: 11.030253964,
    },
    {
      lon: 106.408936957,
      lat: 11.016623261,
    },
    {
      lon: 106.412811258,
      lat: 11.008890127,
    },
    {
      lon: 106.403356497,
      lat: 11.003564125,
    },
    {
      lon: 106.392024985,
      lat: 10.999789961,
    },
    {
      lon: 106.380726499,
      lat: 10.998403601,
    },
    {
      lon: 106.378205991,
      lat: 11,
    },
    {
      lon: 106.362530593,
      lat: 10.997300374,
    },
    {
      lon: 106.356638598,
      lat: 10.99160869,
    },
    {
      lon: 106.373334075,
      lat: 10.969614679,
    },
    {
      lon: 106.411887118,
      lat: 10.97358904,
    },
    {
      lon: 106.453529059,
      lat: 10.94190192,
    },
    {
      lon: 106.503766211,
      lat: 10.916887375,
    },
    {
      lon: 106.531783953,
      lat: 10.90245442,
    },
    {
      lon: 106.532878759,
      lat: 10.89998135,
    },
    {
      lon: 106.52004945,
      lat: 10.852247204,
    },
    {
      lon: 106.504529188,
      lat: 10.788907967,
    },
    {
      lon: 106.489174935,
      lat: 10.775996424,
    },
    {
      lon: 106.463674223,
      lat: 10.756515452,
    },
    {
      lon: 106.472279407,
      lat: 10.745492663,
    },
    {
      lon: 106.474492261,
      lat: 10.745443201,
    },
    {
      lon: 106.487131802,
      lat: 10.729831798,
    },
    {
      lon: 106.499662284,
      lat: 10.724990339,
    },
    {
      lon: 106.509552784,
      lat: 10.717122163,
    },
    {
      lon: 106.520203006,
      lat: 10.72367636,
    },
    {
      lon: 106.530229097,
      lat: 10.717264546,
    },
    {
      lon: 106.527226308,
      lat: 10.709257911,
    },
    {
      lon: 106.534032027,
      lat: 10.687893354972,
    },
    {
      lon: 106.550538954,
      lat: 10.685418315,
    },
    {
      lon: 106.55354292,
      lat: 10.668409599,
    },
    {
      lon: 106.55045444,
      lat: 10.659027062,
    },
    {
      lon: 106.555924142,
      lat: 10.656947773,
    },
    {
      lon: 106.555581744,
      lat: 10.650886463,
    },
    {
      lon: 106.580652954,
      lat: 10.655493886,
    },
    {
      lon: 106.590441549,
      lat: 10.653071538,
    },
    {
      lon: 106.597518357,
      lat: 10.653385228,
    },
    {
      lon: 106.609145828,
      lat: 10.657486984,
    },
    {
      lon: 106.61553813,
      lat: 10.654098464,
    },
    {
      lon: 106.618954235,
      lat: 10.649009349,
    },
    {
      lon: 106.624673025,
      lat: 10.647478221,
    },
    {
      lon: 106.622160417,
      lat: 10.641405776,
    },
    {
      lon: 106.630580845,
      lat: 10.63784934,
    },
    {
      lon: 106.629625241,
      lat: 10.63164369,
    },
    {
      lon: 106.634453583,
      lat: 10.625779604,
    },
    {
      lon: 106.640912258,
      lat: 10.626633147,
    },
    {
      lon: 106.64614082,
      lat: 10.634181119,
    },
    {
      lon: 106.652941309,
      lat: 10.637987873,
    },
    {
      lon: 106.657449388,
      lat: 10.64360116,
    },
    {
      lon: 106.664824756,
      lat: 10.638981873,
    },
    {
      lon: 106.669744525,
      lat: 10.639332941,
    },
    {
      lon: 106.685260612,
      lat: 10.651073469,
    },
    {
      lon: 106.691686403,
      lat: 10.648457585,
    },
    {
      lon: 106.700744731,
      lat: 10.653955117,
    },
    {
      lon: 106.712730275,
      lat: 10.650063081,
    },
    {
      lon: 106.723248269,
      lat: 10.644671307,
    },
    {
      lon: 106.730101173,
      lat: 10.636249524,
    },
    {
      lon: 106.730037674,
      lat: 10.620562254982,
    },
    {
      lon: 106.728460589,
      lat: 10.608757532,
    },
    {
      lon: 106.723853561,
      lat: 10.611898988,
    },
    {
      lon: 106.718689986,
      lat: 10.606090919,
    },
    {
      lon: 106.722274289,
      lat: 10.599535829,
    },
    {
      lon: 106.723271883,
      lat: 10.588068522,
    },
    {
      lon: 106.728857555,
      lat: 10.579884794,
    },
    {
      lon: 106.738908921,
      lat: 10.581407662,
    },
    {
      lon: 106.74569042,
      lat: 10.575601138,
    },
    {
      lon: 106.7418,
      lat: 10.568451248,
    },
    {
      lon: 106.742146386,
      lat: 10.561481412967,
    },
    {
      lon: 106.747776003,
      lat: 10.532825873,
    },
    {
      lon: 106.747383571,
      lat: 10.515636084,
    },
    {
      lon: 106.743902578,
      lat: 10.503629935,
    },
    {
      lon: 106.741369462,
      lat: 10.487551733965,
    },
    {
      lon: 106.761350088,
      lat: 10.453192397,
    },
    {
      lon: 106.776759542,
      lat: 10.434183026,
    },
    {
      lon: 106.793297259,
      lat: 10.416105369,
    },
    {
      lon: 106.80131515,
      lat: 10.405752421,
    },
    {
      lon: 106.818241061,
      lat: 10.374541064,
    },
    {
      lon: 106.828252993,
      lat: 10.352531952,
    },
    {
      lon: 106.852751548,
      lat: 10.358330608,
    },
    {
      lon: 106.875859186,
      lat: 10.360718622,
    },
    {
      lon: 106.894540079,
      lat: 10.365085022,
    },
    {
      lon: 106.91844992,
      lat: 10.37398,
    },
    {
      lon: 106.942633046,
      lat: 10.385448764,
    },
    {
      lon: 106.970533198,
      lat: 10.394813028977,
    },
    {
      lon: 106.98094993,
      lat: 10.405229641,
    },
    {
      lon: 106.985599716,
      lat: 10.413195011,
    },
    {
      lon: 106.982274049,
      lat: 10.42474478,
    },
    {
      lon: 106.972584281,
      lat: 10.43316005,
    },
    {
      lon: 106.962671609,
      lat: 10.438493284,
    },
    {
      lon: 106.966086457,
      lat: 10.446488984,
    },
    {
      lon: 106.980841164,
      lat: 10.447343313,
    },
    {
      lon: 106.991457964,
      lat: 10.450945694,
    },
    {
      lon: 106.996566885,
      lat: 10.460489999,
    },
    {
      lon: 106.998193881,
      lat: 10.478635182,
    },
    {
      lon: 106.997733392,
      lat: 10.498979766,
    },
    {
      lon: 106.999243451,
      lat: 10.506381672,
    },
    {
      lon: 107.005729992,
      lat: 10.509367394,
    },
    {
      lon: 107.012936016,
      lat: 10.521548709,
    },
    {
      lon: 107.027084888,
      lat: 10.537796658,
    },
    {
      lon: 107.024315933,
      lat: 10.546233359,
    },
    {
      lon: 107.013189098,
      lat: 10.549178628,
    },
    {
      lon: 107.010491828,
      lat: 10.555763944,
    },
    {
      lon: 107.014177979,
      lat: 10.564420846,
    },
    {
      lon: 107.023085193,
      lat: 10.576633078,
    },
    {
      lon: 107.026856478,
      lat: 10.584390339,
    },
    {
      lon: 107.017436181,
      lat: 10.606519101,
    },
    {
      lon: 107.006833759,
      lat: 10.610277879,
    },
    {
      lon: 106.99124373,
      lat: 10.605418741,
    },
    {
      lon: 106.985988675,
      lat: 10.5955,
    },
    {
      lon: 106.987124869,
      lat: 10.58898278,
    },
    {
      lon: 106.98385057,
      lat: 10.57943343,
    },
    {
      lon: 106.973825644,
      lat: 10.58306438,
    },
    {
      lon: 106.969275272,
      lat: 10.588735975,
    },
    {
      lon: 106.959689457,
      lat: 10.593813516,
    },
    {
      lon: 106.937911445,
      lat: 10.59948892,
    },
    {
      lon: 106.926452168,
      lat: 10.60416919,
    },
    {
      lon: 106.919510124,
      lat: 10.61001887498,
    },
    {
      lon: 106.911580614,
      lat: 10.607935249,
    },
    {
      lon: 106.901937537,
      lat: 10.626036002,
    },
    {
      lon: 106.892504475,
      lat: 10.632601599,
    },
    {
      lon: 106.885640342,
      lat: 10.639718157,
    },
    {
      lon: 106.882730332,
      lat: 10.647684881965,
    },
    {
      lon: 106.872417202,
      lat: 10.64775951,
    },
    {
      lon: 106.858718024,
      lat: 10.636829086967,
    },
    {
      lon: 106.850349292,
      lat: 10.63977907,
    },
    {
      lon: 106.842579365,
      lat: 10.637807858,
    },
    {
      lon: 106.838790326,
      lat: 10.629423821,
    },
    {
      lon: 106.834810043,
      lat: 10.62691843,
    },
    {
      lon: 106.822785258,
      lat: 10.629988765,
    },
    {
      lon: 106.812235205,
      lat: 10.643834753,
    },
    {
      lon: 106.808203052,
      lat: 10.65574782,
    },
    {
      lon: 106.784079796,
      lat: 10.672527234,
    },
    {
      lon: 106.776467682,
      lat: 10.674493085,
    },
    {
      lon: 106.759507552,
      lat: 10.690599787986,
    },
    {
      lon: 106.755427864,
      lat: 10.696018937,
    },
    {
      lon: 106.751157235,
      lat: 10.711722236,
    },
    {
      lon: 106.75707637698,
      lat: 10.728147798,
    },
    {
      lon: 106.769578058,
      lat: 10.742819678,
    },
    {
      lon: 106.777962173,
      lat: 10.749668536,
    },
    {
      lon: 106.800590911,
      lat: 10.760062201,
    },
    {
      lon: 106.805825526,
      lat: 10.770267764,
    },
    {
      lon: 106.81286286,
      lat: 10.777249682,
    },
    {
      lon: 106.821563323,
      lat: 10.780700971977,
    },
    {
      lon: 106.826210182,
      lat: 10.773299653,
    },
    {
      lon: 106.822993568,
      lat: 10.761674595,
    },
    {
      lon: 106.827928405,
      lat: 10.76,
    },
    {
      lon: 106.834472648,
      lat: 10.768027843,
    },
    {
      lon: 106.848015772,
      lat: 10.775045318,
    },
    {
      lon: 106.854489045,
      lat: 10.773771667,
    },
    {
      lon: 106.862189786,
      lat: 10.765778226,
    },
    {
      lon: 106.873270213,
      lat: 10.76686945297,
    },
    {
      lon: 106.873787226,
      lat: 10.774346448,
    },
    {
      lon: 106.866369129,
      lat: 10.782191552,
    },
    {
      lon: 106.866369184,
      lat: 10.791100766,
    },
    {
      lon: 106.878490536,
      lat: 10.801063782,
    },
    {
      lon: 106.881888892,
      lat: 10.811480448,
    },
    {
      lon: 106.877496586,
      lat: 10.819657987,
    },
    {
      lon: 106.856903958,
      lat: 10.842092243,
    },
    {
      lon: 106.849179941,
      lat: 10.857369414,
    },
    {
      lon: 106.846795252,
      lat: 10.865799674,
    },
    {
      lon: 106.85105199,
      lat: 10.876281223,
    },
    {
      lon: 106.843683839,
      lat: 10.883809182983,
    },
    {
      lon: 106.840153698,
      lat: 10.898929487,
    },
    {
      lon: 106.834317564,
      lat: 10.898409297,
    },
    {
      lon: 106.823997781,
      lat: 10.892574668,
    },
    {
      lon: 106.808553855,
      lat: 10.873026332,
    },
    {
      lon: 106.788923333,
      lat: 10.871950949,
    },
    {
      lon: 106.787711193,
      lat: 10.867212421966,
    },
    {
      lon: 106.779950679,
      lat: 10.868387555,
    },
    {
      lon: 106.782086501,
      lat: 10.87708137,
    },
    {
      lon: 106.780327709,
      lat: 10.881495759,
    },
    {
      lon: 106.769659845,
      lat: 10.893844089,
    },
    {
      lon: 106.760511327,
      lat: 10.881252801,
    },
    {
      lon: 106.76219366999,
      lat: 10.867261383,
    },
    {
      lon: 106.748706431,
      lat: 10.865633142,
    },
    {
      lon: 106.745992938,
      lat: 10.871719306,
    },
    {
      lon: 106.747169938,
      lat: 10.877384035,
    },
    {
      lon: 106.717595422,
      lat: 10.896379045,
    },
    {
      lon: 106.713109852,
      lat: 10.889894721,
    },
    {
      lon: 106.723486559,
      lat: 10.871719325,
    },
    {
      lon: 106.71612988,
      lat: 10.872902006,
    },
    {
      lon: 106.713174724,
      lat: 10.865744135,
    },
    {
      lon: 106.701235351,
      lat: 10.86764798,
    },
    {
      lon: 106.689161234,
      lat: 10.881760566,
    },
    {
      lon: 106.694140394,
      lat: 10.894059999,
    },
    {
      lon: 106.691363069,
      lat: 10.908049565,
    },
    {
      lon: 106.68459102,
      lat: 10.921356185,
    },
    {
      lon: 106.677347752,
      lat: 10.923145685,
    },
    {
      lon: 106.66995346,
      lat: 10.920896366,
    },
    {
      lon: 106.656289279,
      lat: 10.926474904,
    },
    {
      lon: 106.650875109,
      lat: 10.932555325,
    },
    {
      lon: 106.65014541,
      lat: 10.938489429,
    },
    {
      lon: 106.65208114,
      lat: 10.953213307,
    },
    {
      lon: 106.64887908,
      lat: 10.963846596,
    },
    {
      lon: 106.65024385,
      lat: 10.974486764,
    },
    {
      lon: 106.648417691,
      lat: 10.980388308,
    },
    {
      lon: 106.637505564,
      lat: 10.977630674,
    },
    {
      lon: 106.625915047,
      lat: 10.980235907,
    },
    {
      lon: 106.616045764,
      lat: 10.990033592,
    },
    {
      lon: 106.621150575,
      lat: 11.002177315,
    },
    {
      lon: 106.61723451,
      lat: 11.01108925,
    },
    {
      lon: 106.607529958,
      lat: 11.011998244,
    },
    {
      lon: 106.597921265,
      lat: 11.016052133,
    },
    {
      lon: 106.595126749,
      lat: 11.025788447,
    },
    {
      lon: 106.596262623,
      lat: 11.031220699,
    },
    {
      lon: 106.603397541,
      lat: 11.03990638,
    },
    {
      lon: 106.597551039,
      lat: 11.045817944,
    },
    {
      lon: 106.593029466,
      lat: 11.043479683,
    },
    {
      lon: 106.591040598,
      lat: 11.037027216,
    },
    {
      lon: 106.582964231,
      lat: 11.038218289,
    },
    {
      lon: 106.579490653,
      lat: 11.047920818,
    },
    {
      lon: 106.573716728,
      lat: 11.053856109,
    },
    {
      lon: 106.567506805,
      lat: 11.045344689,
    },
    {
      lon: 106.551506505,
      lat: 11.045794231,
    },
    {
      lon: 106.546868948,
      lat: 11.051319619,
    },
    {
      lon: 106.533038395,
      lat: 11.058767413,
    },
    {
      lon: 106.523908004,
      lat: 11.073345294,
    },
    {
      lon: 106.526415303,
      lat: 11.080265694,
    },
    {
      lon: 106.533692105,
      lat: 11.088536539,
    },
    {
      lon: 106.528274831,
      lat: 11.092145684,
    },
    {
      lon: 106.522346399,
      lat: 11.090268549,
    },
    {
      lon: 106.517029796,
      lat: 11.101982144,
    },
    {
      lon: 106.526610977,
      lat: 11.106749383,
    },
    {
      lon: 106.526287311,
      lat: 11.121487762,
    },
    {
      lon: 106.516294118,
      lat: 11.125276285,
    },
    {
      lon: 106.506539485,
      lat: 11.142100273,
    },
    {
      lon: 106.501510407,
      lat: 11.140251115,
    },
    {
      lon: 106.491421083,
      lat: 11.144445431,
    },
    {
      lon: 106.483204098,
      lat: 11.142550481,
    },
    {
      lon: 106.472281857,
      lat: 11.143204039,
    },
    {
      lon: 106.467461179,
      lat: 11.148519045,
    },
    {
      lon: 106.471261018,
      lat: 11.153588597,
    },
    {
      lon: 106.463275579,
      lat: 11.159984491,
    },
    {
      lon: 106.459709681,
      lat: 11.158200101,
    },
  ],
  riverBoundaries: [
    [
      {
        lon: 106.65766572600029,
        lat: 10.924613562000161,
      },
      {
        lon: 106.66038251100031,
        lat: 10.92302834100036,
      },
      {
        lon: 106.66434364500032,
        lat: 10.921077233000233,
      },
      {
        lon: 106.66822365000024,
        lat: 10.919844893000231,
      },
      {
        lon: 106.66997856000035,
        lat: 10.919960123000521,
      },
      {
        lon: 106.67301557400026,
        lat: 10.92101925700024,
      },
      {
        lon: 106.6758359610003,
        lat: 10.921857204000219,
      },
      {
        lon: 106.6798026160003,
        lat: 10.922635354000079,
      },
      {
        lon: 106.6810113380003,
        lat: 10.922447797000302,
      },
      {
        lon: 106.6835928510003,
        lat: 10.921029151000141,
      },
      {
        lon: 106.68488713700032,
        lat: 10.919414382000241,
      },
      {
        lon: 106.68749563600022,
        lat: 10.914805813000214,
      },
      {
        lon: 106.68799881900037,
        lat: 10.913497383000106,
      },
      {
        lon: 106.68997597500015,
        lat: 10.909337159000358,
      },
      {
        lon: 106.69134817100036,
        lat: 10.905371087000276,
      },
      {
        lon: 106.6921156170003,
        lat: 10.903473595000321,
      },
      {
        lon: 106.69317126900023,
        lat: 10.898958327000287,
      },
      {
        lon: 106.6935261600002,
        lat: 10.896968265000282,
      },
      {
        lon: 106.6934948770002,
        lat: 10.89515106000033,
      },
      {
        lon: 106.69321697400017,
        lat: 10.893998373000215,
      },
      {
        lon: 106.69092471500034,
        lat: 10.890018329999975,
      },
      {
        lon: 106.68944353900037,
        lat: 10.888120754,
      },
      {
        lon: 106.68857179500031,
        lat: 10.886327129000222,
      },
      {
        lon: 106.6881830610003,
        lat: 10.884869595000225,
      },
      {
        lon: 106.68800829100024,
        lat: 10.883087035000528,
      },
      {
        lon: 106.68822764600037,
        lat: 10.881653736000317,
      },
      {
        lon: 106.68932255200036,
        lat: 10.879401598000129,
      },
      {
        lon: 106.69464541200023,
        lat: 10.872488367000244,
      },
      {
        lon: 106.69755710000027,
        lat: 10.86928242100015,
      },
      {
        lon: 106.70007465600025,
        lat: 10.867172309000182,
      },
      {
        lon: 106.70307804200024,
        lat: 10.865811100000258,
      },
      {
        lon: 106.7048632590002,
        lat: 10.865952346000256,
      },
      {
        lon: 106.70944614000024,
        lat: 10.865681070000164,
      },
      {
        lon: 106.71134311100025,
        lat: 10.865347968000322,
      },
      {
        lon: 106.71287611900027,
        lat: 10.86475117300023,
      },
      {
        lon: 106.71451382300023,
        lat: 10.863540539000269,
      },
      {
        lon: 106.71551562100035,
        lat: 10.862307129000158,
      },
      {
        lon: 106.71631103500027,
        lat: 10.860889800000223,
      },
      {
        lon: 106.71630778300033,
        lat: 10.860307865000323,
      },
      {
        lon: 106.71718837600031,
        lat: 10.858033071000193,
      },
      {
        lon: 106.71715504300028,
        lat: 10.856116468000153,
      },
      {
        lon: 106.71625104900028,
        lat: 10.85428486800032,
      },
      {
        lon: 106.71446619300019,
        lat: 10.852822384000262,
      },
      {
        lon: 106.71332131700035,
        lat: 10.852242165000348,
      },
      {
        lon: 106.71121231700027,
        lat: 10.852010222000217,
      },
      {
        lon: 106.70608812200021,
        lat: 10.852679171000261,
      },
      {
        lon: 106.7040784430003,
        lat: 10.85269564700036,
      },
      {
        lon: 106.7020433270003,
        lat: 10.852277484000297,
      },
      {
        lon: 106.70104469600022,
        lat: 10.852277233000223,
      },
      {
        lon: 106.69951972800033,
        lat: 10.851727227000197,
      },
      {
        lon: 106.69827044700025,
        lat: 10.85082176100002,
      },
      {
        lon: 106.69701364700035,
        lat: 10.848000732000287,
      },
      {
        lon: 106.69664822900035,
        lat: 10.845908461000306,
      },
      {
        lon: 106.69662558400029,
        lat: 10.844722824000144,
      },
      {
        lon: 106.69704149800026,
        lat: 10.842731546000266,
      },
      {
        lon: 106.69783953100028,
        lat: 10.841667527000254,
      },
      {
        lon: 106.70019052800023,
        lat: 10.839419688000305,
      },
      {
        lon: 106.7023444560001,
        lat: 10.838065754000183,
      },
      {
        lon: 106.7034653190002,
        lat: 10.837604975000204,
      },
      {
        lon: 106.70518595100009,
        lat: 10.836284110000236,
      },
      {
        lon: 106.70658628800021,
        lat: 10.83481182900016,
      },
      {
        lon: 106.70711460000024,
        lat: 10.833912480000095,
      },
      {
        lon: 106.70743431000022,
        lat: 10.832580665000137,
      },
      {
        lon: 106.7073703710002,
        lat: 10.831210443000089,
      },
      {
        lon: 106.70761243300012,
        lat: 10.829809799000202,
      },
      {
        lon: 106.70778370400018,
        lat: 10.826923347000104,
      },
      {
        lon: 106.70810765400017,
        lat: 10.825093204000154,
      },
      {
        lon: 106.70883327600018,
        lat: 10.823640914000237,
      },
      {
        lon: 106.70883571200017,
        lat: 10.822995655000227,
      },
      {
        lon: 106.71099226400025,
        lat: 10.821249475000172,
      },
      {
        lon: 106.7125434510001,
        lat: 10.820345985000188,
      },
      {
        lon: 106.7142287530001,
        lat: 10.819787640000243,
      },
      {
        lon: 106.71420310600018,
        lat: 10.820913005000193,
      },
      {
        lon: 106.71879090500028,
        lat: 10.820419635000235,
      },
      {
        lon: 106.72092426800032,
        lat: 10.820497096000338,
      },
      {
        lon: 106.72619354800037,
        lat: 10.822165374000349,
      },
      {
        lon: 106.72861996000023,
        lat: 10.823501338000199,
      },
      {
        lon: 106.73095310700029,
        lat: 10.825034986000276,
      },
      {
        lon: 106.73291634400027,
        lat: 10.827474698000344,
      },
      {
        lon: 106.73434203600027,
        lat: 10.829509135000194,
      },
      {
        lon: 106.73642862800028,
        lat: 10.832906147000244,
      },
      {
        lon: 106.7377698550003,
        lat: 10.83450947200032,
      },
      {
        lon: 106.73974543400027,
        lat: 10.835578016000227,
      },
      {
        lon: 106.73951519700029,
        lat: 10.83674004000028,
      },
      {
        lon: 106.73727178300032,
        lat: 10.835735784000349,
      },
      {
        lon: 106.73544976800025,
        lat: 10.833755011000335,
      },
      {
        lon: 106.73422222400029,
        lat: 10.831369107000057,
      },
      {
        lon: 106.7321736960003,
        lat: 10.82805734700014,
      },
      {
        lon: 106.73045689200035,
        lat: 10.826148646000036,
      },
      {
        lon: 106.72905536000036,
        lat: 10.824946424000245,
      },
      {
        lon: 106.72704546600033,
        lat: 10.82378162300023,
      },
      {
        lon: 106.72169776800035,
        lat: 10.821707820000313,
      },
      {
        lon: 106.72014335300025,
        lat: 10.821433134000358,
      },
      {
        lon: 106.71840791000028,
        lat: 10.821392122000304,
      },
      {
        lon: 106.7142750870002,
        lat: 10.822149650000313,
      },
      {
        lon: 106.71198009900019,
        lat: 10.823419361000276,
      },
      {
        lon: 106.71060277200019,
        lat: 10.824768015000302,
      },
      {
        lon: 106.7102337090003,
        lat: 10.825643964000221,
      },
      {
        lon: 106.70984287100032,
        lat: 10.827682699000263,
      },
      {
        lon: 106.70966665200018,
        lat: 10.831900179000343,
      },
      {
        lon: 106.70934676900026,
        lat: 10.834048081000219,
      },
      {
        lon: 106.70793819200037,
        lat: 10.836742438000329,
      },
      {
        lon: 106.70660258700025,
        lat: 10.838143633000357,
      },
      {
        lon: 106.70070463800033,
        lat: 10.841499396000245,
      },
      {
        lon: 106.69947233600033,
        lat: 10.842654994000327,
      },
      {
        lon: 106.69890059200026,
        lat: 10.844515860000286,
      },
      {
        lon: 106.69907379500029,
        lat: 10.847850372000323,
      },
      {
        lon: 106.69940870000022,
        lat: 10.849009596000315,
      },
      {
        lon: 106.70081815600031,
        lat: 10.850031573000194,
      },
      {
        lon: 106.70393332500021,
        lat: 10.850771986000268,
      },
      {
        lon: 106.7060912030002,
        lat: 10.850896338000268,
      },
      {
        lon: 106.70809706900025,
        lat: 10.850650616000166,
      },
      {
        lon: 106.7106813830004,
        lat: 10.850135411000622,
      },
      {
        lon: 106.71140970500028,
        lat: 10.850141064000297,
      },
      {
        lon: 106.71450322300024,
        lat: 10.85082488400019,
      },
      {
        lon: 106.71603499500031,
        lat: 10.851696204000348,
      },
      {
        lon: 106.71752948800025,
        lat: 10.853011442000309,
      },
      {
        lon: 106.71887630600031,
        lat: 10.855004852000206,
      },
      {
        lon: 106.71924161400034,
        lat: 10.85603468900024,
      },
      {
        lon: 106.71935435500035,
        lat: 10.857667445000345,
      },
      {
        lon: 106.71906919800028,
        lat: 10.858136615000262,
      },
      {
        lon: 106.71819829800035,
        lat: 10.861724335000247,
      },
      {
        lon: 106.7178603020003,
        lat: 10.862611535000326,
      },
      {
        lon: 106.71572706300026,
        lat: 10.865307299000051,
      },
      {
        lon: 106.71379271700027,
        lat: 10.866643349000299,
      },
      {
        lon: 106.71317472500027,
        lat: 10.865744134000238,
      },
      {
        lon: 106.71012008000025,
        lat: 10.866501478000202,
      },
      {
        lon: 106.70344992300033,
        lat: 10.866858495000145,
      },
      {
        lon: 106.70123535100026,
        lat: 10.867647980000186,
      },
      {
        lon: 106.69970343900036,
        lat: 10.868651633000184,
      },
      {
        lon: 106.69813417300037,
        lat: 10.870018271000106,
      },
      {
        lon: 106.69612468900033,
        lat: 10.872109218000219,
      },
      {
        lon: 106.69051197400017,
        lat: 10.879262861000313,
      },
      {
        lon: 106.68916123400015,
        lat: 10.88176056600031,
      },
      {
        lon: 106.68892601900035,
        lat: 10.88391995200027,
      },
      {
        lon: 106.68944045900025,
        lat: 10.886063580000325,
      },
      {
        lon: 106.69042283600027,
        lat: 10.887871073000213,
      },
      {
        lon: 106.69227047000021,
        lat: 10.890544110000349,
      },
      {
        lon: 106.69414039400021,
        lat: 10.894059999000206,
      },
      {
        lon: 106.6944332620003,
        lat: 10.895394826000029,
      },
      {
        lon: 106.69421450400029,
        lat: 10.898232234000147,
      },
      {
        lon: 106.69323748600027,
        lat: 10.90243802300023,
      },
      {
        lon: 106.69223780800036,
        lat: 10.905679623000253,
      },
      {
        lon: 106.6913630690002,
        lat: 10.908049565000226,
      },
      {
        lon: 106.68939732500024,
        lat: 10.912587264000251,
      },
      {
        lon: 106.68802064900024,
        lat: 10.915260492000243,
      },
      {
        lon: 106.68729762100025,
        lat: 10.917158803,
      },
      {
        lon: 106.68532523500028,
        lat: 10.920448653000278,
      },
      {
        lon: 106.68441012200037,
        lat: 10.921488588000273,
      },
      {
        lon: 106.68298249800034,
        lat: 10.922484163000092,
      },
      {
        lon: 106.6813685110003,
        lat: 10.923244907000196,
      },
      {
        lon: 106.67979169900025,
        lat: 10.923488846,
      },
      {
        lon: 106.67734775200023,
        lat: 10.923145685000293,
      },
      {
        lon: 106.67442586600026,
        lat: 10.922295342000211,
      },
      {
        lon: 106.67088139600025,
        lat: 10.921079852000162,
      },
      {
        lon: 106.66845385900024,
        lat: 10.920671973000253,
      },
      {
        lon: 106.66541140500021,
        lat: 10.921631567000247,
      },
      {
        lon: 106.66138035300025,
        lat: 10.92339685900032,
      },
      {
        lon: 106.65803724900024,
        lat: 10.925409510000293,
      },
      {
        lon: 106.65766572600029,
        lat: 10.924613562000161,
      },
    ],
    [
      {
        lon: 106.56863456500031,
        lat: 11.048328888000356,
      },
      {
        lon: 106.56844715100027,
        lat: 11.046618593000346,
      },
      {
        lon: 106.56750680600032,
        lat: 11.045344687000238,
      },
      {
        lon: 106.56595897500029,
        lat: 11.044878183000208,
      },
      {
        lon: 106.5642882000003,
        lat: 11.044910681000033,
      },
      {
        lon: 106.55551147200032,
        lat: 11.045807462000255,
      },
      {
        lon: 106.55319707700022,
        lat: 11.04568476600025,
      },
      {
        lon: 106.55150650400037,
        lat: 11.045794236000233,
      },
      {
        lon: 106.5498799740003,
        lat: 11.046297006000259,
      },
      {
        lon: 106.54850981400023,
        lat: 11.04758264300034,
      },
      {
        lon: 106.54782532700017,
        lat: 11.049563714000215,
      },
      {
        lon: 106.5468689410003,
        lat: 11.051319614000192,
      },
      {
        lon: 106.54514450900024,
        lat: 11.052824873000304,
      },
      {
        lon: 106.54294606400032,
        lat: 11.053894934000255,
      },
      {
        lon: 106.53815241100028,
        lat: 11.055507718000115,
      },
      {
        lon: 106.53517974300036,
        lat: 11.05693194600019,
      },
      {
        lon: 106.53303839500029,
        lat: 11.058767413000282,
      },
      {
        lon: 106.53207861100026,
        lat: 11.06026326000034,
      },
      {
        lon: 106.53057000900031,
        lat: 11.06375910300028,
      },
      {
        lon: 106.52940873200026,
        lat: 11.065935020000097,
      },
      {
        lon: 106.52850266600024,
        lat: 11.066988126000012,
      },
      {
        lon: 106.5260283350002,
        lat: 11.06863692900032,
      },
      {
        lon: 106.52513536400033,
        lat: 11.069761396000331,
      },
      {
        lon: 106.52454256600032,
        lat: 11.071150323000268,
      },
      {
        lon: 106.52390800000023,
        lat: 11.073345297000117,
      },
      {
        lon: 106.5242344200002,
        lat: 11.075721412000348,
      },
      {
        lon: 106.52559542600022,
        lat: 11.07876817100032,
      },
      {
        lon: 106.52641529900029,
        lat: 11.0802657010002,
      },
      {
        lon: 106.52853970700016,
        lat: 11.08295342800028,
      },
      {
        lon: 106.53139664800023,
        lat: 11.08533319500026,
      },
      {
        lon: 106.53264021400025,
        lat: 11.086890802000084,
      },
      {
        lon: 106.53369210200026,
        lat: 11.088536546000228,
      },
      {
        lon: 106.53408703300033,
        lat: 11.089923094000198,
      },
      {
        lon: 106.53366397900032,
        lat: 11.091329342000165,
      },
      {
        lon: 106.53191869700015,
        lat: 11.092304579000313,
      },
      {
        lon: 106.53017861000035,
        lat: 11.092416198000024,
      },
      {
        lon: 106.52827482600037,
        lat: 11.092145687000311,
      },
      {
        lon: 106.52671845300029,
        lat: 11.091420292000237,
      },
      {
        lon: 106.52490692100037,
        lat: 11.089894713000206,
      },
      {
        lon: 106.52357375000025,
        lat: 11.08962271900026,
      },
      {
        lon: 106.52234636300022,
        lat: 11.090268556000353,
      },
      {
        lon: 106.52136857600021,
        lat: 11.091566644000295,
      },
      {
        lon: 106.51931425700036,
        lat: 11.0949630960003,
      },
      {
        lon: 106.51716009000017,
        lat: 11.097927467000318,
      },
      {
        lon: 106.51646395100022,
        lat: 11.099679409000316,
      },
      {
        lon: 106.5163939390003,
        lat: 11.100960756000234,
      },
      {
        lon: 106.51702981900036,
        lat: 11.101982159000272,
      },
      {
        lon: 106.51867109100027,
        lat: 11.102502360000244,
      },
      {
        lon: 106.52359817800033,
        lat: 11.103631561000327,
      },
      {
        lon: 106.52468266900024,
        lat: 11.104077042000313,
      },
      {
        lon: 106.5257725700003,
        lat: 11.104953870000202,
      },
      {
        lon: 106.52661096900027,
        lat: 11.106749383000192,
      },
      {
        lon: 106.52697833000025,
        lat: 11.110007393999979,
      },
      {
        lon: 106.52770195900035,
        lat: 11.113994627000295,
      },
      {
        lon: 106.52775107200023,
        lat: 11.115402366000353,
      },
      {
        lon: 106.52697749200036,
        lat: 11.117596365000225,
      },
      {
        lon: 106.52689743800028,
        lat: 11.120653080000295,
      },
      {
        lon: 106.52628731100015,
        lat: 11.121487762000243,
      },
      {
        lon: 106.52270999900031,
        lat: 11.121638653000218,
      },
      {
        lon: 106.52023733000027,
        lat: 11.12254998800023,
      },
      {
        lon: 106.5187879050003,
        lat: 11.123395256000322,
      },
      {
        lon: 106.51629411900024,
        lat: 11.1252762860002,
      },
      {
        lon: 106.51512867400032,
        lat: 11.12665286100031,
      },
      {
        lon: 106.51365053900031,
        lat: 11.129193154000063,
      },
      {
        lon: 106.51165234400031,
        lat: 11.135206678000259,
      },
      {
        lon: 106.5108024710002,
        lat: 11.136540303000231,
      },
      {
        lon: 106.50912639600017,
        lat: 11.138474353000163,
      },
      {
        lon: 106.5083192750003,
        lat: 11.139841544000262,
      },
      {
        lon: 106.50774634400022,
        lat: 11.141852852000227,
      },
      {
        lon: 106.50653952400023,
        lat: 11.142100272000278,
      },
      {
        lon: 106.50515588500019,
        lat: 11.141648695000299,
      },
      {
        lon: 106.50296205900025,
        lat: 11.140546050000069,
      },
      {
        lon: 106.50151040200029,
        lat: 11.140251122000222,
      },
      {
        lon: 106.49940178900016,
        lat: 11.14116311000015,
      },
      {
        lon: 106.49781717500036,
        lat: 11.142294105000019,
      },
      {
        lon: 106.49574273000019,
        lat: 11.143155134000324,
      },
      {
        lon: 106.49142107800037,
        lat: 11.144445423000207,
      },
      {
        lon: 106.4899712100003,
        lat: 11.144584592000285,
      },
      {
        lon: 106.4883832190003,
        lat: 11.144402073000208,
      },
      {
        lon: 106.48542939800024,
        lat: 11.143655128000319,
      },
      {
        lon: 106.48320409800033,
        lat: 11.142550482000217,
      },
      {
        lon: 106.48221675900027,
        lat: 11.142349516000252,
      },
      {
        lon: 106.47885280600036,
        lat: 11.142298379000238,
      },
      {
        lon: 106.47414354000028,
        lat: 11.143021592000082,
      },
      {
        lon: 106.47228186300032,
        lat: 11.143204038000079,
      },
      {
        lon: 106.47093761300033,
        lat: 11.14442753600025,
      },
      {
        lon: 106.46937471900031,
        lat: 11.146338145000357,
      },
      {
        lon: 106.46767686400028,
        lat: 11.147374084000148,
      },
      {
        lon: 106.46746118600015,
        lat: 11.148519054000019,
      },
      {
        lon: 106.46932782800025,
        lat: 11.150446996000198,
      },
      {
        lon: 106.47126101300023,
        lat: 11.153588598999988,
      },
      {
        lon: 106.47087782900036,
        lat: 11.15496916200027,
      },
      {
        lon: 106.47028255100031,
        lat: 11.155548676000251,
      },
      {
        lon: 106.4671826950003,
        lat: 11.156501053000284,
      },
      {
        lon: 106.46553613200024,
        lat: 11.157851485000094,
      },
      {
        lon: 106.46451555200036,
        lat: 11.159490543000175,
      },
      {
        lon: 106.46327558700034,
        lat: 11.159984482000311,
      },
      {
        lon: 106.46235741700019,
        lat: 11.159599827000193,
      },
      {
        lon: 106.46062159600024,
        lat: 11.158339278000255,
      },
      {
        lon: 106.45895131400022,
        lat: 11.158465993000334,
      },
      {
        lon: 106.45695319700036,
        lat: 11.160107328000322,
      },
      {
        lon: 106.45623025400033,
        lat: 11.160228060000179,
      },
      {
        lon: 106.45411096300018,
        lat: 11.15926595500025,
      },
      {
        lon: 106.4529786950003,
        lat: 11.158346148000078,
      },
      {
        lon: 106.45177410500031,
        lat: 11.156047551000256,
      },
      {
        lon: 106.45149274100027,
        lat: 11.153261984000267,
      },
      {
        lon: 106.45182381700033,
        lat: 11.151874491000285,
      },
      {
        lon: 106.4532418260003,
        lat: 11.151430987000197,
      },
      {
        lon: 106.45411948100036,
        lat: 11.151817791000212,
      },
      {
        lon: 106.45554143400034,
        lat: 11.15298836400035,
      },
      {
        lon: 106.45647097600033,
        lat: 11.153316331000212,
      },
      {
        lon: 106.45768041900033,
        lat: 11.152638486000342,
      },
      {
        lon: 106.45821216100023,
        lat: 11.151309249000235,
      },
      {
        lon: 106.45812559800027,
        lat: 11.149313800000243,
      },
      {
        lon: 106.45826194600022,
        lat: 11.147325162000186,
      },
      {
        lon: 106.45783204300022,
        lat: 11.144891040000232,
      },
      {
        lon: 106.45592481800026,
        lat: 11.140615149000212,
      },
      {
        lon: 106.4526460910003,
        lat: 11.137314065000282,
      },
      {
        lon: 106.45098603000037,
        lat: 11.136267539000187,
      },
      {
        lon: 106.4457325200002,
        lat: 11.135890994000022,
      },
      {
        lon: 106.44602337400028,
        lat: 11.135323288000336,
      },
      {
        lon: 106.44763818800033,
        lat: 11.135629778000293,
      },
      {
        lon: 106.44819055400035,
        lat: 11.135484812000357,
      },
      {
        lon: 106.45154270400025,
        lat: 11.135969077000198,
      },
      {
        lon: 106.45291963000028,
        lat: 11.137019954000209,
      },
      {
        lon: 106.45423134000032,
        lat: 11.138434809000273,
      },
      {
        lon: 106.45633745300023,
        lat: 11.140230207000343,
      },
      {
        lon: 106.4571363520003,
        lat: 11.142476517000205,
      },
      {
        lon: 106.45820920600028,
        lat: 11.144722141000216,
      },
      {
        lon: 106.45865458900028,
        lat: 11.14724905700001,
      },
      {
        lon: 106.45850833300022,
        lat: 11.149268984000287,
      },
      {
        lon: 106.45882998800028,
        lat: 11.151409070000227,
      },
      {
        lon: 106.45813101800036,
        lat: 11.153076106000128,
      },
      {
        lon: 106.45640318800025,
        lat: 11.153760884000121,
      },
      {
        lon: 106.45554276200028,
        lat: 11.153441106000344,
      },
      {
        lon: 106.45423672200019,
        lat: 11.152450695000198,
      },
      {
        lon: 106.45303581900026,
        lat: 11.151954420000266,
      },
      {
        lon: 106.45208253700025,
        lat: 11.152297157000248,
      },
      {
        lon: 106.45194878600029,
        lat: 11.15364074900026,
      },
      {
        lon: 106.45246938100024,
        lat: 11.156406614000218,
      },
      {
        lon: 106.45341433500028,
        lat: 11.158185092999986,
      },
      {
        lon: 106.45580591800018,
        lat: 11.159725110000352,
      },
      {
        lon: 106.45737028500025,
        lat: 11.159366809000062,
      },
      {
        lon: 106.45803590800034,
        lat: 11.15859437200021,
      },
      {
        lon: 106.45934933900037,
        lat: 11.157770257000323,
      },
      {
        lon: 106.46044964400022,
        lat: 11.15759036400027,
      },
      {
        lon: 106.46169210000033,
        lat: 11.158084650000319,
      },
      {
        lon: 106.46243607000025,
        lat: 11.15904647400032,
      },
      {
        lon: 106.46375160600036,
        lat: 11.15949813200018,
      },
      {
        lon: 106.46437489300035,
        lat: 11.158982117000221,
      },
      {
        lon: 106.46563826900024,
        lat: 11.156766726000342,
      },
      {
        lon: 106.46713598100035,
        lat: 11.155837716,
      },
      {
        lon: 106.47005931200022,
        lat: 11.155039136000198,
      },
      {
        lon: 106.47067843700034,
        lat: 11.154354520000254,
      },
      {
        lon: 106.47070778700032,
        lat: 11.15359174100007,
      },
      {
        lon: 106.46867040700033,
        lat: 11.15051704400014,
      },
      {
        lon: 106.46698105600024,
        lat: 11.148955537000347,
      },
      {
        lon: 106.46669575800024,
        lat: 11.148210806000293,
      },
      {
        lon: 106.46732672200022,
        lat: 11.146999122000238,
      },
      {
        lon: 106.46819346800021,
        lat: 11.146579584000335,
      },
      {
        lon: 106.46988657300022,
        lat: 11.145158327000217,
      },
      {
        lon: 106.47060211100029,
        lat: 11.143999043000235,
      },
      {
        lon: 106.4719184190003,
        lat: 11.142887319000295,
      },
      {
        lon: 106.47288173300024,
        lat: 11.142555535000156,
      },
      {
        lon: 106.47454569300027,
        lat: 11.142583651,
      },
      {
        lon: 106.47803509700032,
        lat: 11.14193395500024,
      },
      {
        lon: 106.48021547200034,
        lat: 11.141730395000309,
      },
      {
        lon: 106.48371208400027,
        lat: 11.142205143000297,
      },
      {
        lon: 106.48577545500035,
        lat: 11.143146496000327,
      },
      {
        lon: 106.48814362700017,
        lat: 11.143845696000255,
      },
      {
        lon: 106.49024655800036,
        lat: 11.14401746200025,
      },
      {
        lon: 106.49348232500034,
        lat: 11.143373256000245,
      },
      {
        lon: 106.49687260000024,
        lat: 11.14227798100029,
      },
      {
        lon: 106.49941936700037,
        lat: 11.140356037000288,
      },
      {
        lon: 106.50125507500024,
        lat: 11.139412027000333,
      },
      {
        lon: 106.50238952700028,
        lat: 11.13954194300021,
      },
      {
        lon: 106.50387983700034,
        lat: 11.140415458000291,
      },
      {
        lon: 106.50684321800031,
        lat: 11.141560364000156,
      },
      {
        lon: 106.50743230300026,
        lat: 11.141291014000274,
      },
      {
        lon: 106.5083024830003,
        lat: 11.138758523000263,
      },
      {
        lon: 106.51089077500023,
        lat: 11.135520958000143,
      },
      {
        lon: 106.51268787500027,
        lat: 11.130660905000298,
      },
      {
        lon: 106.51318321873322,
        lat: 11.128622318330144,
      },
      {
        lon: 106.51420659500036,
        lat: 11.126912111000252,
      },
      {
        lon: 106.51639000300031,
        lat: 11.12429816200006,
      },
      {
        lon: 106.51874965400026,
        lat: 11.122589751000191,
      },
      {
        lon: 106.52101496200027,
        lat: 11.12138319200028,
      },
      {
        lon: 106.52307736100033,
        lat: 11.120823244000348,
      },
      {
        lon: 106.52564178900025,
        lat: 11.12098326800026,
      },
      {
        lon: 106.52639705800027,
        lat: 11.119981175000191,
      },
      {
        lon: 106.52639220500032,
        lat: 11.117740915000297,
      },
      {
        lon: 106.52719429500024,
        lat: 11.115424530000212,
      },
      {
        lon: 106.52722772000027,
        lat: 11.114297423000323,
      },
      {
        lon: 106.52642779800033,
        lat: 11.110551070000213,
      },
      {
        lon: 106.52611760400026,
        lat: 11.107008502000383,
      },
      {
        lon: 106.52524698300022,
        lat: 11.10525575000031,
      },
      {
        lon: 106.5232401370003,
        lat: 11.104161349000265,
      },
      {
        lon: 106.52145765800032,
        lat: 11.103678553000293,
      },
      {
        lon: 106.5170493770002,
        lat: 11.102737306000279,
      },
      {
        lon: 106.51620958300032,
        lat: 11.102192033000222,
      },
      {
        lon: 106.51582978700037,
        lat: 11.101213865000261,
      },
      {
        lon: 106.51579642800033,
        lat: 11.1000115570003,
      },
      {
        lon: 106.51646312600025,
        lat: 11.09790567600021,
      },
      {
        lon: 106.5169737730003,
        lat: 11.097018475000425,
      },
      {
        lon: 106.51836448100045,
        lat: 11.095457208000482,
      },
      {
        lon: 106.52109741900028,
        lat: 11.090969784000265,
      },
      {
        lon: 106.52257740700031,
        lat: 11.089454244000308,
      },
      {
        lon: 106.52436053400015,
        lat: 11.089092974000039,
      },
      {
        lon: 106.5252295060003,
        lat: 11.089426159000197,
      },
      {
        lon: 106.52729410300032,
        lat: 11.091020023000056,
      },
      {
        lon: 106.5286826580002,
        lat: 11.091578604000176,
      },
      {
        lon: 106.53136190200036,
        lat: 11.091831532000356,
      },
      {
        lon: 106.53272391500036,
        lat: 11.091388578000306,
      },
      {
        lon: 106.53336266000036,
        lat: 11.090634496000234,
      },
      {
        lon: 106.53342049600036,
        lat: 11.089683132000232,
      },
      {
        lon: 106.53303859700026,
        lat: 11.088751760000264,
      },
      {
        lon: 106.53157310800026,
        lat: 11.086450692000085,
      },
      {
        lon: 106.52979854000034,
        lat: 11.084713337000323,
      },
      {
        lon: 106.52851586000025,
        lat: 11.083678233000285,
      },
      {
        lon: 106.5272686380003,
        lat: 11.082296158000247,
      },
      {
        lon: 106.52509443200034,
        lat: 11.079083667000305,
      },
      {
        lon: 106.52378509600032,
        lat: 11.076028437000275,
      },
      {
        lon: 106.52330756900034,
        lat: 11.074474137000266,
      },
      {
        lon: 106.52325717000036,
        lat: 11.073092653000174,
      },
      {
        lon: 106.52480236700023,
        lat: 11.06916123100035,
      },
      {
        lon: 106.5260938910003,
        lat: 11.067853794000257,
      },
      {
        lon: 106.52807558300032,
        lat: 11.066553982000357,
      },
      {
        lon: 106.5296409830002,
        lat: 11.064229218000207,
      },
      {
        lon: 106.53153131800036,
        lat: 11.06026130000015,
      },
      {
        lon: 106.5329649952356,
        lat: 11.057980973288181,
      },
      {
        lon: 106.53465474300026,
        lat: 11.05635696900032,
      },
      {
        lon: 106.53774243400017,
        lat: 11.05506261300036,
      },
      {
        lon: 106.54409412700022,
        lat: 11.052802465000013,
      },
      {
        lon: 106.54576846300034,
        lat: 11.051662322000311,
      },
      {
        lon: 106.54653680800027,
        lat: 11.050754639000218,
      },
      {
        lon: 106.54786097800019,
        lat: 11.047370392000289,
      },
      {
        lon: 106.54847103200024,
        lat: 11.046660247000204,
      },
      {
        lon: 106.55042125900019,
        lat: 11.045373168000197,
      },
      {
        lon: 106.5526347830002,
        lat: 11.045155997000279,
      },
      {
        lon: 106.55541124600035,
        lat: 11.04526673700019,
      },
      {
        lon: 106.55597996900025,
        lat: 11.045108154000332,
      },
      {
        lon: 106.56015192600017,
        lat: 11.044758225000237,
      },
      {
        lon: 106.56102222100037,
        lat: 11.044422072000202,
      },
      {
        lon: 106.56208273300035,
        lat: 11.04457458900032,
      },
      {
        lon: 106.56363277700035,
        lat: 11.044327826000256,
      },
      {
        lon: 106.56639954500031,
        lat: 11.044185118000314,
      },
      {
        lon: 106.56723763900021,
        lat: 11.044370724000032,
      },
      {
        lon: 106.56840973200025,
        lat: 11.045174671000211,
      },
      {
        lon: 106.56928226500025,
        lat: 11.047392166000293,
      },
      {
        lon: 106.56931337600025,
        lat: 11.048322885000289,
      },
      {
        lon: 106.56863456500031,
        lat: 11.048328888000356,
      },
    ],
    [
      {
        lon: 106.65803724900024,
        lat: 10.925409510000293,
      },
      {
        lon: 106.6562892740003,
        lat: 10.926474900000358,
      },
      {
        lon: 106.65332776500031,
        lat: 10.929064621000293,
      },
      {
        lon: 106.65200866400033,
        lat: 10.930585338000299,
      },
      {
        lon: 106.65087510900025,
        lat: 10.932555325000294,
      },
      {
        lon: 106.65023254300036,
        lat: 10.934470444000226,
      },
      {
        lon: 106.64995967900035,
        lat: 10.936687282000321,
      },
      {
        lon: 106.65014540200036,
        lat: 10.938489438000204,
      },
      {
        lon: 106.6512551860003,
        lat: 10.94216552800026,
      },
      {
        lon: 106.65197108100034,
        lat: 10.945661811000264,
      },
      {
        lon: 106.65234924300034,
        lat: 10.94960144300029,
      },
      {
        lon: 106.65208114200027,
        lat: 10.953213313000333,
      },
      {
        lon: 106.65175890300037,
        lat: 10.954860408000286,
      },
      {
        lon: 106.65072818800024,
        lat: 10.958317395000222,
      },
      {
        lon: 106.64942937000023,
        lat: 10.961901400000215,
      },
      {
        lon: 106.64887908400021,
        lat: 10.963846597000327,
      },
      {
        lon: 106.6486680330002,
        lat: 10.965830992000292,
      },
      {
        lon: 106.64872791600027,
        lat: 10.967732237000291,
      },
      {
        lon: 106.64911932500024,
        lat: 10.970008558000245,
      },
      {
        lon: 106.65014752000036,
        lat: 10.972936629000287,
      },
      {
        lon: 106.6502438420003,
        lat: 10.974486759000115,
      },
      {
        lon: 106.64929374700029,
        lat: 10.978450519000205,
      },
      {
        lon: 106.64841769000037,
        lat: 10.980388315000312,
      },
      {
        lon: 106.64736628500015,
        lat: 10.981160969000257,
      },
      {
        lon: 106.64618717900032,
        lat: 10.98136814100036,
      },
      {
        lon: 106.6440329260003,
        lat: 10.981051950000221,
      },
      {
        lon: 106.64226742500034,
        lat: 10.980502599000317,
      },
      {
        lon: 106.63895046600027,
        lat: 10.978214950000336,
      },
      {
        lon: 106.63750556400026,
        lat: 10.977630674000295,
      },
      {
        lon: 106.63602762800036,
        lat: 10.977487862000203,
      },
      {
        lon: 106.63479115400037,
        lat: 10.977661205000231,
      },
      {
        lon: 106.62835866700027,
        lat: 10.979162375000271,
      },
      {
        lon: 106.6259150470002,
        lat: 10.980235901000299,
      },
      {
        lon: 106.6249788350002,
        lat: 10.981074977000276,
      },
      {
        lon: 106.62335028000035,
        lat: 10.983233517000144,
      },
      {
        lon: 106.62137481400015,
        lat: 10.985363666000296,
      },
      {
        lon: 106.62055417100021,
        lat: 10.985963218000336,
      },
      {
        lon: 106.61837744500025,
        lat: 10.987056163000261,
      },
      {
        lon: 106.61751716900028,
        lat: 10.987665610000306,
      },
      {
        lon: 106.61651990500036,
        lat: 10.98887265000036,
      },
      {
        lon: 106.61604576400032,
        lat: 10.990033592000316,
      },
      {
        lon: 106.61601671500028,
        lat: 10.991943216000209,
      },
      {
        lon: 106.61652337400022,
        lat: 10.993454478000217,
      },
      {
        lon: 106.61749971700027,
        lat: 10.995253100000355,
      },
      {
        lon: 106.62011777100037,
        lat: 10.999521315000266,
      },
      {
        lon: 106.62115057300036,
        lat: 11.002177320000214,
      },
      {
        lon: 106.62127180500028,
        lat: 11.004147350000324,
      },
      {
        lon: 106.62103012600022,
        lat: 11.005701644000112,
      },
      {
        lon: 106.6201599320002,
        lat: 11.007940575000362,
      },
      {
        lon: 106.61904791600017,
        lat: 11.009708229000239,
      },
      {
        lon: 106.61723451000023,
        lat: 11.011089256000218,
      },
      {
        lon: 106.61591960900029,
        lat: 11.011423351000246,
      },
      {
        lon: 106.61381354300028,
        lat: 11.011604246000218,
      },
      {
        lon: 106.60955434100026,
        lat: 11.011675696000225,
      },
      {
        lon: 106.60752995900032,
        lat: 11.011998244000267,
      },
      {
        lon: 106.60273392600021,
        lat: 11.013718757000277,
      },
      {
        lon: 106.59980684400034,
        lat: 11.014396078000289,
      },
      {
        lon: 106.59885480400027,
        lat: 11.014940668,
      },
      {
        lon: 106.59792126200028,
        lat: 11.016052134000347,
      },
      {
        lon: 106.59687328900037,
        lat: 11.018464523000205,
      },
      {
        lon: 106.59587991800032,
        lat: 11.021588760000327,
      },
      {
        lon: 106.59530699200036,
        lat: 11.024027625000201,
      },
      {
        lon: 106.59512674200033,
        lat: 11.025788447000252,
      },
      {
        lon: 106.59529850000035,
        lat: 11.02879639900027,
      },
      {
        lon: 106.59626261700026,
        lat: 11.03122070100028,
      },
      {
        lon: 106.59860039000034,
        lat: 11.033664840000313,
      },
      {
        lon: 106.60240382700016,
        lat: 11.038204039000277,
      },
      {
        lon: 106.60339754100033,
        lat: 11.03990637700025,
      },
      {
        lon: 106.60282863600025,
        lat: 11.041141857000127,
      },
      {
        lon: 106.60034885100032,
        lat: 11.044473410000194,
      },
      {
        lon: 106.59879927400027,
        lat: 11.045531684000196,
      },
      {
        lon: 106.59755103600037,
        lat: 11.045817946000227,
      },
      {
        lon: 106.59553813700035,
        lat: 11.045407903000353,
      },
      {
        lon: 106.59416325200021,
        lat: 11.044585431000314,
      },
      {
        lon: 106.59302946500026,
        lat: 11.043479684000204,
      },
      {
        lon: 106.59233523600028,
        lat: 11.042165287000273,
      },
      {
        lon: 106.59160170800016,
        lat: 11.038385849000178,
      },
      {
        lon: 106.59104060300018,
        lat: 11.037027210000304,
      },
      {
        lon: 106.59021356500034,
        lat: 11.036568798000246,
      },
      {
        lon: 106.58844222000022,
        lat: 11.036304450000216,
      },
      {
        lon: 106.58561697900035,
        lat: 11.036743063000358,
      },
      {
        lon: 106.58439686800017,
        lat: 11.037219111000297,
      },
      {
        lon: 106.5829642300003,
        lat: 11.038218291000192,
      },
      {
        lon: 106.58154503000031,
        lat: 11.040408097000352,
      },
      {
        lon: 106.58086615400026,
        lat: 11.043098470000302,
      },
      {
        lon: 106.58047031200022,
        lat: 11.045923682000307,
      },
      {
        lon: 106.57949064500025,
        lat: 11.04792081900024,
      },
      {
        lon: 106.57858882300025,
        lat: 11.048872648000213,
      },
      {
        lon: 106.57534229300018,
        lat: 11.051777458000231,
      },
      {
        lon: 106.57371671900034,
        lat: 11.053856107000342,
      },
      {
        lon: 106.57283784300029,
        lat: 11.054369073000146,
      },
      {
        lon: 106.57164238900032,
        lat: 11.054300524000269,
      },
      {
        lon: 106.5697863660003,
        lat: 11.0519361530001,
      },
      {
        lon: 106.5689589670003,
        lat: 11.050622064000208,
      },
      {
        lon: 106.56863456500031,
        lat: 11.048328888000356,
      },
      {
        lon: 106.56931337600025,
        lat: 11.048322885000289,
      },
      {
        lon: 106.5693936860003,
        lat: 11.049728468000353,
      },
      {
        lon: 106.56992694400037,
        lat: 11.051013040000214,
      },
      {
        lon: 106.57193432300028,
        lat: 11.05341360600022,
      },
      {
        lon: 106.57275693300033,
        lat: 11.053598554000246,
      },
      {
        lon: 106.57357596800034,
        lat: 11.052873797000245,
      },
      {
        lon: 106.57545450700025,
        lat: 11.050723283000309,
      },
      {
        lon: 106.57843000800034,
        lat: 11.048157684000216,
      },
      {
        lon: 106.57938693100016,
        lat: 11.046856987000353,
      },
      {
        lon: 106.5798249190002,
        lat: 11.045789766000212,
      },
      {
        lon: 106.58022232300021,
        lat: 11.042537739000352,
      },
      {
        lon: 106.58090133200034,
        lat: 11.040029238000216,
      },
      {
        lon: 106.58213107100022,
        lat: 11.037982613000224,
      },
      {
        lon: 106.58381199200028,
        lat: 11.036608455000021,
      },
      {
        lon: 106.5856966640003,
        lat: 11.035955722000324,
      },
      {
        lon: 106.58754790200032,
        lat: 11.035634007000226,
      },
      {
        lon: 106.59077483400034,
        lat: 11.035839069000133,
      },
      {
        lon: 106.59166767100021,
        lat: 11.036525092000147,
      },
      {
        lon: 106.5922525550003,
        lat: 11.037673651000262,
      },
      {
        lon: 106.59270673800029,
        lat: 11.040839151000341,
      },
      {
        lon: 106.59308948700027,
        lat: 11.042299104000222,
      },
      {
        lon: 106.59372095200018,
        lat: 11.043214640000315,
      },
      {
        lon: 106.59593973300036,
        lat: 11.044831161000218,
      },
      {
        lon: 106.59767432500036,
        lat: 11.045112337000205,
      },
      {
        lon: 106.59951176800024,
        lat: 11.044386481,
      },
      {
        lon: 106.60227628700028,
        lat: 11.04074963100021,
      },
      {
        lon: 106.60253157900024,
        lat: 11.039750790000255,
      },
      {
        lon: 106.59850773100021,
        lat: 11.034793518000242,
      },
      {
        lon: 106.59563497500022,
        lat: 11.031839192000234,
      },
      {
        lon: 106.59509033000035,
        lat: 11.03093308800021,
      },
      {
        lon: 106.59436237300025,
        lat: 11.028155997000397,
      },
      {
        lon: 106.59425240800027,
        lat: 11.02584759000024,
      },
      {
        lon: 106.59448534200021,
        lat: 11.023976922000202,
      },
      {
        lon: 106.59512818300021,
        lat: 11.02121262400027,
      },
      {
        lon: 106.59711237500022,
        lat: 11.0156930360003,
      },
      {
        lon: 106.59761291100028,
        lat: 11.014906263000285,
      },
      {
        lon: 106.59927779400027,
        lat: 11.013685881000015,
      },
      {
        lon: 106.6031221910003,
        lat: 11.012762508000264,
      },
      {
        lon: 106.60713164000032,
        lat: 11.011226739000335,
      },
      {
        lon: 106.60773149400035,
        lat: 11.010888305000208,
      },
      {
        lon: 106.61007886200025,
        lat: 11.0107351770003,
      },
      {
        lon: 106.61544830700035,
        lat: 11.010661166000318,
      },
      {
        lon: 106.61665299400028,
        lat: 11.010422678000111,
      },
      {
        lon: 106.61819993000017,
        lat: 11.009381223000219,
      },
      {
        lon: 106.61894267500026,
        lat: 11.008419591000234,
      },
      {
        lon: 106.62016603000035,
        lat: 11.00577019100001,
      },
      {
        lon: 106.62047234100021,
        lat: 11.003176707000193,
      },
      {
        lon: 106.61992660400023,
        lat: 11.001065435000328,
      },
      {
        lon: 106.61813070600033,
        lat: 10.997683411000253,
      },
      {
        lon: 106.6156385830003,
        lat: 10.99392064400024,
      },
      {
        lon: 106.61501397400036,
        lat: 10.991308836000314,
      },
      {
        lon: 106.61545714900035,
        lat: 10.989191664000089,
      },
      {
        lon: 106.61671709400025,
        lat: 10.987160314000278,
      },
      {
        lon: 106.61761493600028,
        lat: 10.98628294300022,
      },
      {
        lon: 106.61962424600028,
        lat: 10.985436304000245,
      },
      {
        lon: 106.62221561800027,
        lat: 10.98337093400005,
      },
      {
        lon: 106.62403156800025,
        lat: 10.98077444400036,
      },
      {
        lon: 106.62500408600027,
        lat: 10.979784112000289,
      },
      {
        lon: 106.62774779500035,
        lat: 10.97841707900028,
      },
      {
        lon: 106.63060891900028,
        lat: 10.977858305000211,
      },
      {
        lon: 106.63534783100033,
        lat: 10.976633868000249,
      },
      {
        lon: 106.63721660500022,
        lat: 10.976612175000298,
      },
      {
        lon: 106.63876116900047,
        lat: 10.977068856000358,
      },
      {
        lon: 106.64268062400032,
        lat: 10.979744734000235,
      },
      {
        lon: 106.64460557700016,
        lat: 10.98033605600034,
      },
      {
        lon: 106.6464361270003,
        lat: 10.980311591000145,
      },
      {
        lon: 106.64730318700043,
        lat: 10.979969416000529,
      },
      {
        lon: 106.64799227200052,
        lat: 10.979153569000289,
      },
      {
        lon: 106.64849028400033,
        lat: 10.977227824000522,
      },
      {
        lon: 106.64943464400034,
        lat: 10.974499956000273,
      },
      {
        lon: 106.64910466100031,
        lat: 10.972642879000148,
      },
      {
        lon: 106.6482720750003,
        lat: 10.970767523000234,
      },
      {
        lon: 106.64786245900035,
        lat: 10.968174277000287,
      },
      {
        lon: 106.6478237830002,
        lat: 10.965025219000324,
      },
      {
        lon: 106.64853202700033,
        lat: 10.961806065000246,
      },
      {
        lon: 106.65021952500035,
        lat: 10.957113229000242,
      },
      {
        lon: 106.65113956300024,
        lat: 10.953662645,
      },
      {
        lon: 106.65151931700024,
        lat: 10.950016157000277,
      },
      {
        lon: 106.6511885010002,
        lat: 10.945792545000245,
      },
      {
        lon: 106.65035839000018,
        lat: 10.94208002400029,
      },
      {
        lon: 106.64941326300027,
        lat: 10.939421445000335,
      },
      {
        lon: 106.64909242500033,
        lat: 10.936319562000278,
      },
      {
        lon: 106.64923301300024,
        lat: 10.934464658000252,
      },
      {
        lon: 106.65067604600017,
        lat: 10.931095299000276,
      },
      {
        lon: 106.65113906000033,
        lat: 10.930351995000251,
      },
      {
        lon: 106.65376813400025,
        lat: 10.927542075000076,
      },
      {
        lon: 106.65624144500032,
        lat: 10.925438428000234,
      },
      {
        lon: 106.65766572600029,
        lat: 10.924613562000161,
      },
      {
        lon: 106.65803724900024,
        lat: 10.925409510000293,
      },
    ],
    [
      {
        lon: 106.56161040727818,
        lat: 10.91689125834995,
      },
      {
        lon: 106.56249069800043,
        lat: 10.917632134000371,
      },
      {
        lon: 106.56428747300049,
        lat: 10.918719527000178,
      },
      {
        lon: 106.56565187500041,
        lat: 10.918923940000127,
      },
      {
        lon: 106.56671717200038,
        lat: 10.918998591000388,
      },
      {
        lon: 106.56755058700048,
        lat: 10.91917619300068,
      },
      {
        lon: 106.56866151500043,
        lat: 10.91912311800047,
      },
      {
        lon: 106.57048471500042,
        lat: 10.919270182000389,
      },
      {
        lon: 106.57287872700044,
        lat: 10.919395714000473,
      },
      {
        lon: 106.5735994580005,
        lat: 10.919488183000396,
      },
      {
        lon: 106.57526558600051,
        lat: 10.919544118000488,
      },
      {
        lon: 106.5766938200004,
        lat: 10.919455905000177,
      },
      {
        lon: 106.57717388900039,
        lat: 10.919653098000424,
      },
      {
        lon: 106.5791850270005,
        lat: 10.919739445000403,
      },
      {
        lon: 106.5801892060004,
        lat: 10.919724319000181,
      },
      {
        lon: 106.58374830900044,
        lat: 10.919429293000462,
      },
      {
        lon: 106.58450696800043,
        lat: 10.919112405000362,
      },
      {
        lon: 106.58790719000041,
        lat: 10.919046104000472,
      },
      {
        lon: 106.58873889700038,
        lat: 10.918899311000132,
      },
      {
        lon: 106.59014336500042,
        lat: 10.918826274000423,
      },
      {
        lon: 106.59191428300039,
        lat: 10.918588516000455,
      },
      {
        lon: 106.59479842400037,
        lat: 10.918451528000503,
      },
      {
        lon: 106.59679723100044,
        lat: 10.918212772000459,
      },
      {
        lon: 106.59914458400044,
        lat: 10.917752321000478,
      },
      {
        lon: 106.60061264200039,
        lat: 10.917586814000515,
      },
      {
        lon: 106.60131113000045,
        lat: 10.917362487000162,
      },
      {
        lon: 106.6028788000005,
        lat: 10.917271529000459,
      },
      {
        lon: 106.6055337020004,
        lat: 10.916849163000506,
      },
      {
        lon: 106.60776159100044,
        lat: 10.916416292000122,
      },
      {
        lon: 106.61031042100052,
        lat: 10.916043113000455,
      },
      {
        lon: 106.61107428000054,
        lat: 10.915880143000436,
      },
      {
        lon: 106.61299374300046,
        lat: 10.915676629000473,
      },
      {
        lon: 106.61703716100038,
        lat: 10.914984436000509,
      },
      {
        lon: 106.6180422600005,
        lat: 10.914859074000447,
      },
      {
        lon: 106.61861663700046,
        lat: 10.914658313000416,
      },
      {
        lon: 106.61865437300042,
        lat: 10.913842220000504,
      },
      {
        lon: 106.61824879800054,
        lat: 10.91330616800036,
      },
      {
        lon: 106.61716390100054,
        lat: 10.91272512300037,
      },
      {
        lon: 106.61605479500042,
        lat: 10.912569753000469,
      },
      {
        lon: 106.6140345050004,
        lat: 10.912179130000368,
      },
      {
        lon: 106.61180313100039,
        lat: 10.911573722000014,
      },
      {
        lon: 106.60987365900041,
        lat: 10.91119742000052,
      },
      {
        lon: 106.6086230570005,
        lat: 10.910734769000499,
      },
      {
        lon: 106.60727680100047,
        lat: 10.910359430000085,
      },
      {
        lon: 106.60711717800052,
        lat: 10.909834736000391,
      },
      {
        lon: 106.60484291200044,
        lat: 10.909413318000531,
      },
      {
        lon: 106.60461486100053,
        lat: 10.909049333000496,
      },
      {
        lon: 106.60368201300042,
        lat: 10.908385275000455,
      },
      {
        lon: 106.60213843889227,
        lat: 10.90771453268843,
      },
      {
        lon: 106.60216653800052,
        lat: 10.906950770000492,
      },
      {
        lon: 106.60261707900042,
        lat: 10.906848585000489,
      },
      {
        lon: 106.60269532200039,
        lat: 10.907275725000488,
      },
      {
        lon: 106.60341374000052,
        lat: 10.907992802000024,
      },
      {
        lon: 106.6052865470005,
        lat: 10.909217270000454,
      },
      {
        lon: 106.60599620900041,
        lat: 10.909351913000478,
      },
      {
        lon: 106.60615796800039,
        lat: 10.909161354000446,
      },
      {
        lon: 106.60728794600044,
        lat: 10.909146284000428,
      },
      {
        lon: 106.60747660800041,
        lat: 10.909959657000513,
      },
      {
        lon: 106.60795710800039,
        lat: 10.910240359000454,
      },
      {
        lon: 106.60923325100038,
        lat: 10.910483403000512,
      },
      {
        lon: 106.60999154700039,
        lat: 10.91081987500047,
      },
      {
        lon: 106.61165765500039,
        lat: 10.911207274000446,
      },
      {
        lon: 106.61190396400048,
        lat: 10.911165009000399,
      },
      {
        lon: 106.61356080800039,
        lat: 10.911440074000325,
      },
      {
        lon: 106.61520944500046,
        lat: 10.911875446000465,
      },
      {
        lon: 106.61696658300053,
        lat: 10.912178504000508,
      },
      {
        lon: 106.61761775600053,
        lat: 10.912402305000455,
      },
      {
        lon: 106.61862964700049,
        lat: 10.913112291000402,
      },
      {
        lon: 106.6198072130004,
        lat: 10.913356402000431,
      },
      {
        lon: 106.62140294900047,
        lat: 10.913912317000154,
      },
      {
        lon: 106.62271989900051,
        lat: 10.91419087600002,
      },
      {
        lon: 106.62460608100042,
        lat: 10.914780613000232,
      },
      {
        lon: 106.62678726000053,
        lat: 10.915150410000365,
      },
      {
        lon: 106.63028365300039,
        lat: 10.915894798000522,
      },
      {
        lon: 106.6316609740004,
        lat: 10.91573888800042,
      },
      {
        lon: 106.63197276400041,
        lat: 10.915828717000466,
      },
      {
        lon: 106.63287276900053,
        lat: 10.915663139000458,
      },
      {
        lon: 106.63411052400039,
        lat: 10.915761863000455,
      },
      {
        lon: 106.63443547000043,
        lat: 10.915667086000497,
      },
      {
        lon: 106.63529793900045,
        lat: 10.915787220000482,
      },
      {
        lon: 106.63720390900045,
        lat: 10.91552127500023,
      },
      {
        lon: 106.6385005930004,
        lat: 10.915535530999989,
      },
      {
        lon: 106.64111816400037,
        lat: 10.916511552000372,
      },
      {
        lon: 106.64261072500051,
        lat: 10.917150832000516,
      },
      {
        lon: 106.64295583000035,
        lat: 10.917214419000345,
      },
      {
        lon: 106.64414607100053,
        lat: 10.91796475800038,
      },
      {
        lon: 106.6452316360004,
        lat: 10.919093177000377,
      },
      {
        lon: 106.64590582200054,
        lat: 10.919390810000209,
      },
      {
        lon: 106.64715677600049,
        lat: 10.919188849000477,
      },
      {
        lon: 106.64753129100052,
        lat: 10.918846308000639,
      },
      {
        lon: 106.64844532100045,
        lat: 10.918853184000511,
      },
      {
        lon: 106.64907752500051,
        lat: 10.918615936000435,
      },
      {
        lon: 106.64955935600051,
        lat: 10.918761118000532,
      },
      {
        lon: 106.65020384700045,
        lat: 10.919290958000488,
      },
      {
        lon: 106.65122746800051,
        lat: 10.919583416000364,
      },
      {
        lon: 106.65191602300035,
        lat: 10.919477081000366,
      },
      {
        lon: 106.65268030300035,
        lat: 10.919053809000502,
      },
      {
        lon: 106.65338957400053,
        lat: 10.918971267000416,
      },
      {
        lon: 106.6541286300004,
        lat: 10.91944703500053,
      },
      {
        lon: 106.65502741300043,
        lat: 10.920565575000523,
      },
      {
        lon: 106.65571016000052,
        lat: 10.921278071000131,
      },
      {
        lon: 106.65629229100045,
        lat: 10.922096195000506,
      },
      {
        lon: 106.65658993200049,
        lat: 10.92295752600046,
      },
      {
        lon: 106.65648494800047,
        lat: 10.924154475000478,
      },
      {
        lon: 106.65674165500053,
        lat: 10.92460371000044,
      },
      {
        lon: 106.65723877900041,
        lat: 10.924725393000472,
      },
      {
        lon: 106.65624144500049,
        lat: 10.925438428000405,
      },
      {
        lon: 106.65591626500043,
        lat: 10.924748279000198,
      },
      {
        lon: 106.6556906710004,
        lat: 10.923850814000104,
      },
      {
        lon: 106.6555972520004,
        lat: 10.922672885000452,
      },
      {
        lon: 106.65515514500049,
        lat: 10.92191006500002,
      },
      {
        lon: 106.65450256900067,
        lat: 10.921262338000702,
      },
      {
        lon: 106.65363438900044,
        lat: 10.920187021000459,
      },
      {
        lon: 106.65305004200053,
        lat: 10.919978009000033,
      },
      {
        lon: 106.65169217300053,
        lat: 10.92034814900046,
      },
      {
        lon: 106.6496256790004,
        lat: 10.920266766000395,
      },
      {
        lon: 106.6483664270005,
        lat: 10.920019675000106,
      },
      {
        lon: 106.64696123300041,
        lat: 10.920327197000518,
      },
      {
        lon: 106.6458761990005,
        lat: 10.920340276000445,
      },
      {
        lon: 106.64485128900043,
        lat: 10.919950438000512,
      },
      {
        lon: 106.6431525480005,
        lat: 10.918661127000519,
      },
      {
        lon: 106.64215693000051,
        lat: 10.918039368000507,
      },
      {
        lon: 106.64148435400045,
        lat: 10.917816025000207,
      },
      {
        lon: 106.63923201200043,
        lat: 10.916898914000456,
      },
      {
        lon: 106.63832420400047,
        lat: 10.916659597000091,
      },
      {
        lon: 106.63720395400054,
        lat: 10.916506538000306,
      },
      {
        lon: 106.63516813000047,
        lat: 10.916470937000497,
      },
      {
        lon: 106.63469361600045,
        lat: 10.916681074000394,
      },
      {
        lon: 106.63303565200044,
        lat: 10.916573287000233,
      },
      {
        lon: 106.63106183900044,
        lat: 10.916665417000218,
      },
      {
        lon: 106.63018137300043,
        lat: 10.916468212000154,
      },
      {
        lon: 106.62970131400039,
        lat: 10.916517574000522,
      },
      {
        lon: 106.62846202600053,
        lat: 10.916316198000402,
      },
      {
        lon: 106.62709727600043,
        lat: 10.915865788000417,
      },
      {
        lon: 106.62490779300038,
        lat: 10.915637610000374,
      },
      {
        lon: 106.62178954100045,
        lat: 10.91476729300041,
      },
      {
        lon: 106.62007145700039,
        lat: 10.914953524000452,
      },
      {
        lon: 106.61915439500041,
        lat: 10.915247961000375,
      },
      {
        lon: 106.61676370400039,
        lat: 10.915618109000434,
      },
      {
        lon: 106.61097233800047,
        lat: 10.91645085800053,
      },
      {
        lon: 106.60894864700043,
        lat: 10.91679457600037,
      },
      {
        lon: 106.60718701700051,
        lat: 10.917020951000325,
      },
      {
        lon: 106.60426721800042,
        lat: 10.91746986100037,
      },
      {
        lon: 106.60300529400047,
        lat: 10.917713022000498,
      },
      {
        lon: 106.60082896900042,
        lat: 10.91790364900038,
      },
      {
        lon: 106.5989041150005,
        lat: 10.918353497000453,
      },
      {
        lon: 106.59553733900049,
        lat: 10.918907342000352,
      },
      {
        lon: 106.59432195400052,
        lat: 10.919028282000056,
      },
      {
        lon: 106.58881288800052,
        lat: 10.919389393000358,
      },
      {
        lon: 106.58593346600048,
        lat: 10.919757633000472,
      },
      {
        lon: 106.58542836400045,
        lat: 10.919703535000451,
      },
      {
        lon: 106.5834171870004,
        lat: 10.920043223000278,
      },
      {
        lon: 106.5812123180005,
        lat: 10.920279344000392,
      },
      {
        lon: 106.57768634900032,
        lat: 10.9201401010003,
      },
      {
        lon: 106.57611383300042,
        lat: 10.919961489000457,
      },
      {
        lon: 106.57384160400045,
        lat: 10.919977212000447,
      },
      {
        lon: 106.56939568500042,
        lat: 10.919610113000147,
      },
      {
        lon: 106.56867748500048,
        lat: 10.919629141000485,
      },
      {
        lon: 106.56686668700041,
        lat: 10.919485142000383,
      },
      {
        lon: 106.5663072370005,
        lat: 10.919337834000089,
      },
      {
        lon: 106.5648751800004,
        lat: 10.919292872000483,
      },
      {
        lon: 106.56428744600044,
        lat: 10.919160466000335,
      },
      {
        lon: 106.56306284200053,
        lat: 10.918482531000109,
      },
      {
        lon: 106.56155310300039,
        lat: 10.91737639100046,
      },
      {
        lon: 106.56060015100041,
        lat: 10.917244871000378,
      },
      {
        lon: 106.55730854800049,
        lat: 10.917044384000405,
      },
      {
        lon: 106.55215020800051,
        lat: 10.916828696000493,
      },
      {
        lon: 106.54995360000049,
        lat: 10.916686932000516,
      },
      {
        lon: 106.5474886330004,
        lat: 10.916586857000482,
      },
      {
        lon: 106.54385366300039,
        lat: 10.916362881000055,
      },
      {
        lon: 106.54104805500043,
        lat: 10.91624140900052,
      },
      {
        lon: 106.53807623500052,
        lat: 10.916153490000227,
      },
      {
        lon: 106.53699081600053,
        lat: 10.916187386000388,
      },
      {
        lon: 106.53303753700038,
        lat: 10.91664218600016,
      },
      {
        lon: 106.5271821060004,
        lat: 10.917376694000382,
      },
      {
        lon: 106.52532699300048,
        lat: 10.9175574730005,
      },
      {
        lon: 106.52241531800053,
        lat: 10.917997588000219,
      },
      {
        lon: 106.51812266100052,
        lat: 10.918488711000464,
      },
      {
        lon: 106.51807428800049,
        lat: 10.91830902600043,
      },
      {
        lon: 106.51505868900051,
        lat: 10.918661347000523,
      },
      {
        lon: 106.5122042700005,
        lat: 10.91906861500047,
      },
      {
        lon: 106.50951367600044,
        lat: 10.919362317000434,
      },
      {
        lon: 106.5017876500005,
        lat: 10.920254305000526,
      },
      {
        lon: 106.49756748100049,
        lat: 10.920652849000476,
      },
      {
        lon: 106.4973693270004,
        lat: 10.920523848000528,
      },
      {
        lon: 106.50178764000049,
        lat: 10.920094950000419,
      },
      {
        lon: 106.50535460400039,
        lat: 10.91964905000026,
      },
      {
        lon: 106.51220428900041,
        lat: 10.918849740000383,
      },
      {
        lon: 106.51329903300041,
        lat: 10.918763108000519,
      },
      {
        lon: 106.5138874220005,
        lat: 10.918602483000086,
      },
      {
        lon: 106.51909876300044,
        lat: 10.917964141000027,
      },
      {
        lon: 106.5205817960005,
        lat: 10.917817335000507,
      },
      {
        lon: 106.5239006540005,
        lat: 10.917288968000207,
      },
      {
        lon: 106.52497974700049,
        lat: 10.917239431000496,
      },
      {
        lon: 106.52940339000048,
        lat: 10.916763888000105,
      },
      {
        lon: 106.53303754500052,
        lat: 10.916220434000081,
      },
      {
        lon: 106.53484831900045,
        lat: 10.91599856800002,
      },
      {
        lon: 106.53735778000049,
        lat: 10.915768825000498,
      },
      {
        lon: 106.5383910520004,
        lat: 10.915754294000237,
      },
      {
        lon: 106.54345418700052,
        lat: 10.915966633000494,
      },
      {
        lon: 106.54491567600041,
        lat: 10.915956923000408,
      },
      {
        lon: 106.54809531200038,
        lat: 10.916228349000505,
      },
      {
        lon: 106.55078373700053,
        lat: 10.916318771000135,
      },
      {
        lon: 106.55613004800051,
        lat: 10.916575333000424,
      },
      {
        lon: 106.5608724540005,
        lat: 10.91687539100036,
      },
      {
        lon: 106.56112365300055,
        lat: 10.916847377000352,
      },
      {
        lon: 106.56161040727818,
        lat: 10.91689125834995,
      },
    ],
    [
      {
        lon: 106.51327648800054,
        lat: 10.787525506,
      },
      {
        lon: 106.51287889300045,
        lat: 10.788503327,
      },
      {
        lon: 106.51193374900038,
        lat: 10.789262798000436,
      },
      {
        lon: 106.51285332400039,
        lat: 10.789774083000339,
      },
      {
        lon: 106.5126830350004,
        lat: 10.789945193000108,
      },
      {
        lon: 106.51169774200048,
        lat: 10.78951027300036,
      },
      {
        lon: 106.51107536600051,
        lat: 10.789361483000505,
      },
      {
        lon: 106.50642927600052,
        lat: 10.789091955000401,
      },
      {
        lon: 106.50466364100049,
        lat: 10.789031895000392,
      },
      {
        lon: 106.50436123400044,
        lat: 10.788765582000508,
      },
      {
        lon: 106.51055580100041,
        lat: 10.789026235000279,
      },
      {
        lon: 106.51156027400049,
        lat: 10.789141389000406,
      },
      {
        lon: 106.51228124700037,
        lat: 10.7887279480002,
      },
      {
        lon: 106.51291549900043,
        lat: 10.78791208000041,
      },
      {
        lon: 106.51306068200046,
        lat: 10.786806940000417,
      },
      {
        lon: 106.51235049300044,
        lat: 10.784173613000405,
      },
      {
        lon: 106.51138302700048,
        lat: 10.780232897000133,
      },
      {
        lon: 106.51093329200046,
        lat: 10.77826154300027,
      },
      {
        lon: 106.51032526800043,
        lat: 10.77637713500053,
      },
      {
        lon: 106.5092662820004,
        lat: 10.774469744000328,
      },
      {
        lon: 106.50864367500049,
        lat: 10.773755773000516,
      },
      {
        lon: 106.5078030680005,
        lat: 10.772968518000482,
      },
      {
        lon: 106.50668209656283,
        lat: 10.772167923669542,
      },
      {
        lon: 106.50362017700053,
        lat: 10.769764232000398,
      },
      {
        lon: 106.49823534200038,
        lat: 10.7657003810005,
      },
      {
        lon: 106.49145637000049,
        lat: 10.760469120000096,
      },
      {
        lon: 106.48872502400042,
        lat: 10.758412394000459,
      },
      {
        lon: 106.48779047100044,
        lat: 10.757633496000494,
      },
      {
        lon: 106.48568880500045,
        lat: 10.756061788000295,
      },
      {
        lon: 106.48326847700042,
        lat: 10.754193930000497,
      },
      {
        lon: 106.4818236540005,
        lat: 10.753194658000211,
      },
      {
        lon: 106.48074151000048,
        lat: 10.752361462000408,
      },
      {
        lon: 106.48038700500041,
        lat: 10.751985901000296,
      },
      {
        lon: 106.4764766350005,
        lat: 10.748982971000373,
      },
      {
        lon: 106.4755538330005,
        lat: 10.748345298000459,
      },
      {
        lon: 106.47378850700045,
        lat: 10.746900811000387,
      },
      {
        lon: 106.47214862800041,
        lat: 10.745745514000475,
      },
      {
        lon: 106.47227940700049,
        lat: 10.745492662000515,
      },
      {
        lon: 106.47349683100049,
        lat: 10.746401242000161,
      },
      {
        lon: 106.4737246190005,
        lat: 10.746281082000166,
      },
      {
        lon: 106.4799459980004,
        lat: 10.75106675900048,
      },
      {
        lon: 106.48092704700048,
        lat: 10.75178423900013,
      },
      {
        lon: 106.48131574900049,
        lat: 10.751784879000184,
      },
      {
        lon: 106.48957484900046,
        lat: 10.746989563000454,
      },
      {
        lon: 106.49170164800051,
        lat: 10.74581905100041,
      },
      {
        lon: 106.49953997800048,
        lat: 10.741246240000521,
      },
      {
        lon: 106.50066212200045,
        lat: 10.740629499000363,
      },
      {
        lon: 106.50178655000042,
        lat: 10.739899409000373,
      },
      {
        lon: 106.5062702170004,
        lat: 10.737398116000065,
      },
      {
        lon: 106.50789107000048,
        lat: 10.736431966000453,
      },
      {
        lon: 106.5133215730005,
        lat: 10.73328006100013,
      },
      {
        lon: 106.51393695500042,
        lat: 10.732966755000518,
      },
      {
        lon: 106.51985440700048,
        lat: 10.729506418000483,
      },
      {
        lon: 106.52070187600049,
        lat: 10.729074370000433,
      },
      {
        lon: 106.52265213300046,
        lat: 10.727887977000421,
      },
      {
        lon: 106.52386630300037,
        lat: 10.727253708000433,
      },
      {
        lon: 106.52629304800053,
        lat: 10.72578742900049,
      },
      {
        lon: 106.52707462900048,
        lat: 10.725396114000375,
      },
      {
        lon: 106.53225816800045,
        lat: 10.722377354000514,
      },
      {
        lon: 106.53358301800046,
        lat: 10.72165060000043,
      },
      {
        lon: 106.53652051800054,
        lat: 10.719944525000415,
      },
      {
        lon: 106.53682799300049,
        lat: 10.719684566000467,
      },
      {
        lon: 106.54061452400049,
        lat: 10.717507897000417,
      },
      {
        lon: 106.54271537300053,
        lat: 10.716459909000378,
      },
      {
        lon: 106.54322228900054,
        lat: 10.716069142000492,
      },
      {
        lon: 106.54399883100047,
        lat: 10.715691229000015,
      },
      {
        lon: 106.54575367400048,
        lat: 10.714605301000404,
      },
      {
        lon: 106.5479440840005,
        lat: 10.713397892000444,
      },
      {
        lon: 106.54912047300041,
        lat: 10.712631212000417,
      },
      {
        lon: 106.55157081900046,
        lat: 10.711351234000404,
      },
      {
        lon: 106.55207209200046,
        lat: 10.710952237000129,
      },
      {
        lon: 106.55297260000037,
        lat: 10.71051348400033,
      },
      {
        lon: 106.55368951800051,
        lat: 10.709977114000367,
      },
      {
        lon: 106.55566037700044,
        lat: 10.708964124000033,
      },
      {
        lon: 106.55599595900048,
        lat: 10.70865446,
      },
      {
        lon: 106.55707820900051,
        lat: 10.708098627000481,
      },
      {
        lon: 106.55870144500051,
        lat: 10.707085154000367,
      },
      {
        lon: 106.56111002800043,
        lat: 10.705810752000446,
      },
      {
        lon: 106.5618160880004,
        lat: 10.705254365000485,
      },
      {
        lon: 106.56257901700053,
        lat: 10.704983068000331,
      },
      {
        lon: 106.56404981800046,
        lat: 10.704062215000079,
      },
      {
        lon: 106.56530005500048,
        lat: 10.703016532000333,
      },
      {
        lon: 106.56638600300045,
        lat: 10.702523889000531,
      },
      {
        lon: 106.56810283700054,
        lat: 10.702455515000395,
      },
      {
        lon: 106.56848233700049,
        lat: 10.702326314000459,
      },
      {
        lon: 106.56915913000046,
        lat: 10.701662832000238,
      },
      {
        lon: 106.57014404200044,
        lat: 10.699984800000493,
      },
      {
        lon: 106.57050304800043,
        lat: 10.699637889000485,
      },
      {
        lon: 106.57134337200051,
        lat: 10.699204518000442,
      },
      {
        lon: 106.57585630800054,
        lat: 10.69857277600039,
      },
      {
        lon: 106.57652926600042,
        lat: 10.697989213000483,
      },
      {
        lon: 106.57656587400044,
        lat: 10.69663673000042,
      },
      {
        lon: 106.57644203000052,
        lat: 10.696053948000497,
      },
      {
        lon: 106.5765097470005,
        lat: 10.695469103000335,
      },
      {
        lon: 106.57686021800049,
        lat: 10.694807762000437,
      },
      {
        lon: 106.57785585600044,
        lat: 10.694180554000411,
      },
      {
        lon: 106.57789243600041,
        lat: 10.694673253000417,
      },
      {
        lon: 106.57718446000047,
        lat: 10.695422065000173,
      },
      {
        lon: 106.5770367120005,
        lat: 10.696038831000408,
      },
      {
        lon: 106.57711987500039,
        lat: 10.697403323000515,
      },
      {
        lon: 106.57705427100046,
        lat: 10.698131161000504,
      },
      {
        lon: 106.57670928200042,
        lat: 10.698750272000495,
      },
      {
        lon: 106.57610760200038,
        lat: 10.699058347000516,
      },
      {
        lon: 106.5746587430005,
        lat: 10.699290979000011,
      },
      {
        lon: 106.57400970200052,
        lat: 10.699303541000518,
      },
      {
        lon: 106.57173555600048,
        lat: 10.699657821000528,
      },
      {
        lon: 106.57054659700043,
        lat: 10.70024398600026,
      },
      {
        lon: 106.56982295400047,
        lat: 10.701554162000491,
      },
      {
        lon: 106.56888205200045,
        lat: 10.702655869000068,
      },
      {
        lon: 106.56820349700052,
        lat: 10.702935798000226,
      },
      {
        lon: 106.56656389200049,
        lat: 10.703000215000316,
      },
      {
        lon: 106.56555948300041,
        lat: 10.703464424000458,
      },
      {
        lon: 106.56449585400043,
        lat: 10.7043029350001,
      },
      {
        lon: 106.5635883440005,
        lat: 10.704762978000417,
      },
      {
        lon: 106.56277488800049,
        lat: 10.705300570000134,
      },
      {
        lon: 106.56189492100043,
        lat: 10.705721962000382,
      },
      {
        lon: 106.55885661600047,
        lat: 10.707458144000498,
      },
      {
        lon: 106.55787988700041,
        lat: 10.708102657000438,
      },
      {
        lon: 106.55616217900041,
        lat: 10.709030002000532,
      },
      {
        lon: 106.55146307400054,
        lat: 10.711770876000458,
      },
      {
        lon: 106.54956682100038,
        lat: 10.712799770000515,
      },
      {
        lon: 106.54263824700053,
        lat: 10.716830540000046,
      },
      {
        lon: 106.54047381900051,
        lat: 10.71813616600025,
      },
      {
        lon: 106.53728793300053,
        lat: 10.719915921000336,
      },
      {
        lon: 106.53654989800049,
        lat: 10.720523582999988,
      },
      {
        lon: 106.53424309900049,
        lat: 10.721717859000421,
      },
      {
        lon: 106.53359217600052,
        lat: 10.722108861000436,
      },
      {
        lon: 106.5338873320004,
        lat: 10.722738533000495,
      },
      {
        lon: 106.53350549100054,
        lat: 10.722868573000426,
      },
      {
        lon: 106.53321208200049,
        lat: 10.722410573000332,
      },
      {
        lon: 106.5308162280005,
        lat: 10.72361942500038,
      },
      {
        lon: 106.52959677100046,
        lat: 10.724393811000482,
      },
      {
        lon: 106.52214387100054,
        lat: 10.728612814000428,
      },
      {
        lon: 106.51706390000038,
        lat: 10.731535690000499,
      },
      {
        lon: 106.51453905200037,
        lat: 10.733030024000527,
      },
      {
        lon: 106.50972944400053,
        lat: 10.735776125000427,
      },
      {
        lon: 106.50782509900046,
        lat: 10.73692656200052,
      },
      {
        lon: 106.50034589800038,
        lat: 10.74114301900039,
      },
      {
        lon: 106.4975548490005,
        lat: 10.742816752000408,
      },
      {
        lon: 106.48903879200043,
        lat: 10.747735357000522,
      },
      {
        lon: 106.48755065600045,
        lat: 10.748544596000045,
      },
      {
        lon: 106.48688561000046,
        lat: 10.74898306900053,
      },
      {
        lon: 106.48303161800044,
        lat: 10.751164864000486,
      },
      {
        lon: 106.48146399200039,
        lat: 10.75209647800051,
      },
      {
        lon: 106.4815201290005,
        lat: 10.752357808000454,
      },
      {
        lon: 106.4837493210004,
        lat: 10.753987925000388,
      },
      {
        lon: 106.48726791300038,
        lat: 10.75671300300047,
      },
      {
        lon: 106.48852482900048,
        lat: 10.75764388700037,
      },
      {
        lon: 106.48979256300038,
        lat: 10.758697181000514,
      },
      {
        lon: 106.49038379000052,
        lat: 10.7590491400004,
      },
      {
        lon: 106.49452933700042,
        lat: 10.762235698000438,
      },
      {
        lon: 106.49856099432276,
        lat: 10.765391935189541,
      },
      {
        lon: 106.50492003800048,
        lat: 10.770229558000324,
      },
      {
        lon: 106.50559784200044,
        lat: 10.77079425900024,
      },
      {
        lon: 106.50805821000046,
        lat: 10.772644038000465,
      },
      {
        lon: 106.50908119100046,
        lat: 10.773569407000366,
      },
      {
        lon: 106.5098853010004,
        lat: 10.77467005900047,
      },
      {
        lon: 106.51043599600052,
        lat: 10.775572494000471,
      },
      {
        lon: 106.51096100300038,
        lat: 10.776862117000121,
      },
      {
        lon: 106.51129451100041,
        lat: 10.778030185000489,
      },
      {
        lon: 106.51186911600047,
        lat: 10.780457657999989,
      },
      {
        lon: 106.5121761510004,
        lat: 10.78146905600039,
      },
      {
        lon: 106.51354405298264,
        lat: 10.78673087970958,
      },
      {
        lon: 106.51327648800054,
        lat: 10.787525506,
      },
    ],
    [
      {
        lon: 106.56112365300055,
        lat: 10.916847377000352,
      },
      {
        lon: 106.55844709900043,
        lat: 10.912489612000513,
      },
      {
        lon: 106.55631090100064,
        lat: 10.908805406000113,
      },
      {
        lon: 106.55521497100057,
        lat: 10.906988877000572,
      },
      {
        lon: 106.55310843200061,
        lat: 10.903214518000084,
      },
      {
        lon: 106.55113279400058,
        lat: 10.8999762480002,
      },
      {
        lon: 106.54920928000058,
        lat: 10.896652180000501,
      },
      {
        lon: 106.5480698290005,
        lat: 10.894815818000438,
      },
      {
        lon: 106.5453013500005,
        lat: 10.890040871000508,
      },
      {
        lon: 106.5426819450006,
        lat: 10.885587044000147,
      },
      {
        lon: 106.5406036610006,
        lat: 10.882116892000454,
      },
      {
        lon: 106.53551026800062,
        lat: 10.873440681000488,
      },
      {
        lon: 106.53473292600057,
        lat: 10.872199411000452,
      },
      {
        lon: 106.53442631000064,
        lat: 10.870479731000273,
      },
      {
        lon: 106.53286591800065,
        lat: 10.864513216000546,
      },
      {
        lon: 106.5320183190006,
        lat: 10.861003739000694,
      },
      {
        lon: 106.53161254100058,
        lat: 10.859535445000688,
      },
      {
        lon: 106.53060937500055,
        lat: 10.855493997000622,
      },
      {
        lon: 106.53027856100056,
        lat: 10.85432402600054,
      },
      {
        lon: 106.52734263300061,
        lat: 10.842732763000242,
      },
      {
        lon: 106.52698123800064,
        lat: 10.841506991000074,
      },
      {
        lon: 106.52524780300064,
        lat: 10.834591885000634,
      },
      {
        lon: 106.52458428600056,
        lat: 10.832104526000421,
      },
      {
        lon: 106.5235617790006,
        lat: 10.828070177000484,
      },
      {
        lon: 106.52246940100065,
        lat: 10.823947278000503,
      },
      {
        lon: 106.52213069100065,
        lat: 10.822466846000225,
      },
      {
        lon: 106.52117718200077,
        lat: 10.81894877900055,
      },
      {
        lon: 106.52056682100061,
        lat: 10.816453456000602,
      },
      {
        lon: 106.51993500700075,
        lat: 10.814101260000655,
      },
      {
        lon: 106.51805465400065,
        lat: 10.806481159000327,
      },
      {
        lon: 106.51749273800056,
        lat: 10.804426426000589,
      },
      {
        lon: 106.5166270110006,
        lat: 10.800829371000589,
      },
      {
        lon: 106.51610043000062,
        lat: 10.798902146000557,
      },
      {
        lon: 106.51509925600055,
        lat: 10.794843494000474,
      },
      {
        lon: 106.51410239000069,
        lat: 10.791566433000696,
      },
      {
        lon: 106.51428120500066,
        lat: 10.791277563000392,
      },
      {
        lon: 106.5138289490007,
        lat: 10.789599621000662,
      },
      {
        lon: 106.51364156300065,
        lat: 10.788514343000202,
      },
      {
        lon: 106.51327648800071,
        lat: 10.787525506,
      },
      {
        lon: 106.51354405298281,
        lat: 10.78673087970975,
      },
      {
        lon: 106.51400805200069,
        lat: 10.788254976000701,
      },
      {
        lon: 106.51452934400066,
        lat: 10.79056278300061,
      },
      {
        lon: 106.51489066000056,
        lat: 10.793096827000284,
      },
      {
        lon: 106.51512963000062,
        lat: 10.793595979000372,
      },
      {
        lon: 106.5155831750007,
        lat: 10.795624587000532,
      },
      {
        lon: 106.51750280400069,
        lat: 10.80323900800039,
      },
      {
        lon: 106.51847562700061,
        lat: 10.80691028800004,
      },
      {
        lon: 106.51958993200071,
        lat: 10.811482908000016,
      },
      {
        lon: 106.51981100000069,
        lat: 10.81208394300063,
      },
      {
        lon: 106.52054394700065,
        lat: 10.814947652000342,
      },
      {
        lon: 106.52115277300061,
        lat: 10.81748971000053,
      },
      {
        lon: 106.52149155900071,
        lat: 10.818644734000543,
      },
      {
        lon: 106.52309826000055,
        lat: 10.825054387000534,
      },
      {
        lon: 106.52501156700066,
        lat: 10.83234234100047,
      },
      {
        lon: 106.52885167000068,
        lat: 10.84745073600061,
      },
      {
        lon: 106.52870132900057,
        lat: 10.84749278500027,
      },
      {
        lon: 106.52982620500057,
        lat: 10.851924812000618,
      },
      {
        lon: 106.53184300700065,
        lat: 10.859684731000243,
      },
      {
        lon: 106.53311219100067,
        lat: 10.864760146000549,
      },
      {
        lon: 106.5346644880006,
        lat: 10.870765223000092,
      },
      {
        lon: 106.53485030400071,
        lat: 10.871885354000312,
      },
      {
        lon: 106.53542690100056,
        lat: 10.87267177300049,
      },
      {
        lon: 106.53817171600053,
        lat: 10.877344445000574,
      },
      {
        lon: 106.53972164200053,
        lat: 10.879920245000505,
      },
      {
        lon: 106.54139045000056,
        lat: 10.882861493000519,
      },
      {
        lon: 106.54380251600048,
        lat: 10.886871627000119,
      },
      {
        lon: 106.54615575900061,
        lat: 10.890888262000544,
      },
      {
        lon: 106.54849106000046,
        lat: 10.89481582100035,
      },
      {
        lon: 106.55118282500052,
        lat: 10.899397487000556,
      },
      {
        lon: 106.5520685690006,
        lat: 10.9009659510005,
      },
      {
        lon: 106.55308524200063,
        lat: 10.902610346000188,
      },
      {
        lon: 106.55695108500049,
        lat: 10.909154463000505,
      },
      {
        lon: 106.55810719800047,
        lat: 10.911178532000179,
      },
      {
        lon: 106.5601081580005,
        lat: 10.914568816000383,
      },
      {
        lon: 106.56161040727818,
        lat: 10.91689125834995,
      },
      {
        lon: 106.56112365300055,
        lat: 10.916847377000352,
      },
    ],
    [
      {
        lon: 106.62346728360023,
        lat: 10.819162993593864,
      },
      {
        lon: 106.62482954500098,
        lat: 10.82069339000094,
      },
      {
        lon: 106.62569546400096,
        lat: 10.821898590000103,
      },
      {
        lon: 106.62749297100095,
        lat: 10.824034066000934,
      },
      {
        lon: 106.62921745577563,
        lat: 10.826153283376073,
      },
      {
        lon: 106.63018074800084,
        lat: 10.827520144000914,
      },
      {
        lon: 106.63050848294364,
        lat: 10.828399386312412,
      },
      {
        lon: 106.631478703001,
        lat: 10.8301843950008,
      },
      {
        lon: 106.63250944200092,
        lat: 10.8329533790004,
      },
      {
        lon: 106.63287291300111,
        lat: 10.834590599000876,
      },
      {
        lon: 106.63272339200108,
        lat: 10.835717850000606,
      },
      {
        lon: 106.63219313700097,
        lat: 10.836361675000319,
      },
      {
        lon: 106.6321311480009,
        lat: 10.836825583000689,
      },
      {
        lon: 106.63235334900111,
        lat: 10.83733768500025,
      },
      {
        lon: 106.63315960400092,
        lat: 10.837835634000328,
      },
      {
        lon: 106.63289833300104,
        lat: 10.83879942300058,
      },
      {
        lon: 106.63256313600095,
        lat: 10.839139478000561,
      },
      {
        lon: 106.63229036000092,
        lat: 10.839699763000112,
      },
      {
        lon: 106.63234402900105,
        lat: 10.840455781001026,
      },
      {
        lon: 106.63360738700095,
        lat: 10.840808210000432,
      },
      {
        lon: 106.63387275500105,
        lat: 10.841227901000252,
      },
      {
        lon: 106.63394694100094,
        lat: 10.84193898800038,
      },
      {
        lon: 106.63371273200102,
        lat: 10.842932666000706,
      },
      {
        lon: 106.63394159100102,
        lat: 10.844912710000756,
      },
      {
        lon: 106.63414240300096,
        lat: 10.845037265000315,
      },
      {
        lon: 106.63476081300098,
        lat: 10.844618638000952,
      },
      {
        lon: 106.63730597256477,
        lat: 10.846890869003232,
      },
      {
        lon: 106.6398892250009,
        lat: 10.848864564,
      },
      {
        lon: 106.64080027800105,
        lat: 10.84928534,
      },
      {
        lon: 106.64135040300101,
        lat: 10.849402923000753,
      },
      {
        lon: 106.64264445000089,
        lat: 10.850591194000572,
      },
      {
        lon: 106.64295568300099,
        lat: 10.851058460000331,
      },
      {
        lon: 106.64305597700093,
        lat: 10.851960671000995,
      },
      {
        lon: 106.64320990600095,
        lat: 10.852265370000907,
      },
      {
        lon: 106.6438780730009,
        lat: 10.852372402000867,
      },
      {
        lon: 106.64461828400096,
        lat: 10.85293832600098,
      },
      {
        lon: 106.64476608600091,
        lat: 10.853401778000773,
      },
      {
        lon: 106.64469901700204,
        lat: 10.853886293319874,
      },
      {
        lon: 106.64498574300092,
        lat: 10.854310848001035,
      },
      {
        lon: 106.64537361800092,
        lat: 10.854303727000172,
      },
      {
        lon: 106.64584725100093,
        lat: 10.853781117000096,
      },
      {
        lon: 106.64624242200108,
        lat: 10.853776363000236,
      },
      {
        lon: 106.64664689300105,
        lat: 10.854458148999981,
      },
      {
        lon: 106.64667564500093,
        lat: 10.855967859000373,
      },
      {
        lon: 106.64705792000103,
        lat: 10.856925345000548,
      },
      {
        lon: 106.64726509300101,
        lat: 10.857710797000891,
      },
      {
        lon: 106.64891562308094,
        lat: 10.858767697691576,
      },
      {
        lon: 106.650145971001,
        lat: 10.858943062000606,
      },
      {
        lon: 106.65074997000096,
        lat: 10.859527612000477,
      },
      {
        lon: 106.65237200500098,
        lat: 10.860542370000417,
      },
      {
        lon: 106.65299653800105,
        lat: 10.86028367700095,
      },
      {
        lon: 106.653565783001,
        lat: 10.859363577001034,
      },
      {
        lon: 106.654067002001,
        lat: 10.858995248000838,
      },
      {
        lon: 106.65526568200102,
        lat: 10.85878618099997,
      },
      {
        lon: 106.65590459300108,
        lat: 10.858411035000017,
      },
      {
        lon: 106.65671642000098,
        lat: 10.85760821300056,
      },
      {
        lon: 106.65722422900097,
        lat: 10.857417219000922,
      },
      {
        lon: 106.65855654371308,
        lat: 10.857731912051065,
      },
      {
        lon: 106.659063126001,
        lat: 10.857511167000894,
      },
      {
        lon: 106.66120077600095,
        lat: 10.85557676600098,
      },
      {
        lon: 106.66265822791638,
        lat: 10.855266608218358,
      },
      {
        lon: 106.66351209800104,
        lat: 10.85531995600104,
      },
      {
        lon: 106.665151087222,
        lat: 10.855816716887375,
      },
      {
        lon: 106.66638143814646,
        lat: 10.855809209054769,
      },
      {
        lon: 106.66724849500105,
        lat: 10.85553315700082,
      },
      {
        lon: 106.668074523001,
        lat: 10.855410571000448,
      },
      {
        lon: 106.66969606200092,
        lat: 10.85478641500032,
      },
      {
        lon: 106.67054853890511,
        lat: 10.853780163767965,
      },
      {
        lon: 106.67202576800099,
        lat: 10.852997930000754,
      },
      {
        lon: 106.67382534300094,
        lat: 10.852354485000717,
      },
      {
        lon: 106.67520622846945,
        lat: 10.851933794936826,
      },
      {
        lon: 106.67617964579983,
        lat: 10.851463675937623,
      },
      {
        lon: 106.67930172000098,
        lat: 10.850276759000224,
      },
      {
        lon: 106.67978118300096,
        lat: 10.850296969001022,
      },
      {
        lon: 106.68062944900095,
        lat: 10.850771330000555,
      },
      {
        lon: 106.68231607800101,
        lat: 10.851247097000396,
      },
      {
        lon: 106.68309105500089,
        lat: 10.851335931000108,
      },
      {
        lon: 106.68373858500098,
        lat: 10.851232809000635,
      },
      {
        lon: 106.68383036200106,
        lat: 10.850390877000962,
      },
      {
        lon: 106.68320691800096,
        lat: 10.849672944000986,
      },
      {
        lon: 106.68271840700095,
        lat: 10.848838193000745,
      },
      {
        lon: 106.68261184700123,
        lat: 10.848155673000406,
      },
      {
        lon: 106.6827754170011,
        lat: 10.847387144000322,
      },
      {
        lon: 106.683137195001,
        lat: 10.846887959001037,
      },
      {
        lon: 106.68383883100097,
        lat: 10.84648112400032,
      },
      {
        lon: 106.68491875500098,
        lat: 10.846556020000833,
      },
      {
        lon: 106.68620413700091,
        lat: 10.846946922000313,
      },
      {
        lon: 106.68696319200092,
        lat: 10.846756369000445,
      },
      {
        lon: 106.68789739700105,
        lat: 10.845763189000877,
      },
      {
        lon: 106.68831474500092,
        lat: 10.844889656000987,
      },
      {
        lon: 106.68880852200101,
        lat: 10.84328950400021,
      },
      {
        lon: 106.68861028000094,
        lat: 10.842530913000417,
      },
      {
        lon: 106.68835278056781,
        lat: 10.840641600795266,
      },
      {
        lon: 106.68832059200093,
        lat: 10.839662399000929,
      },
      {
        lon: 106.68861125700096,
        lat: 10.838603016000945,
      },
      {
        lon: 106.6894427600011,
        lat: 10.837150329000735,
      },
      {
        lon: 106.69017339300103,
        lat: 10.836258277000468,
      },
      {
        lon: 106.69047631500109,
        lat: 10.835054651000178,
      },
      {
        lon: 106.69069201200108,
        lat: 10.833871733000535,
      },
      {
        lon: 106.69069554800097,
        lat: 10.832853774000966,
      },
      {
        lon: 106.69143504300098,
        lat: 10.831142721000333,
      },
      {
        lon: 106.69211603900101,
        lat: 10.830162443000404,
      },
      {
        lon: 106.69289266527153,
        lat: 10.829604776026656,
      },
      {
        lon: 106.69366845300107,
        lat: 10.829505805000963,
      },
      {
        lon: 106.69468561100098,
        lat: 10.82987071900078,
      },
      {
        lon: 106.69547851000095,
        lat: 10.830577530000198,
      },
      {
        lon: 106.69591513600102,
        lat: 10.831922456000996,
      },
      {
        lon: 106.696454805001,
        lat: 10.832711664000385,
      },
      {
        lon: 106.69699083400104,
        lat: 10.833037538000895,
      },
      {
        lon: 106.69961737100101,
        lat: 10.833991535000678,
      },
      {
        lon: 106.70056766500096,
        lat: 10.834827376000646,
      },
      {
        lon: 106.70095476400093,
        lat: 10.835520667000367,
      },
      {
        lon: 106.70127657600091,
        lat: 10.83720240600087,
      },
      {
        lon: 106.70146921700098,
        lat: 10.83762525400033,
      },
      {
        lon: 106.70192441300094,
        lat: 10.837973897000666,
      },
      {
        lon: 106.70192857500092,
        lat: 10.838464501000942,
      },
      {
        lon: 106.70153316500091,
        lat: 10.838653357000396,
      },
      {
        lon: 106.70120663700106,
        lat: 10.838346644000865,
      },
      {
        lon: 106.70068872800096,
        lat: 10.837566293000691,
      },
      {
        lon: 106.70038082600104,
        lat: 10.83591435900098,
      },
      {
        lon: 106.70007742100091,
        lat: 10.835285706000377,
      },
      {
        lon: 106.69929946100103,
        lat: 10.834687911000742,
      },
      {
        lon: 106.6983287320009,
        lat: 10.834221219000142,
      },
      {
        lon: 106.69732496600096,
        lat: 10.83393551200004,
      },
      {
        lon: 106.69628481700096,
        lat: 10.833463490000952,
      },
      {
        lon: 106.69562877500107,
        lat: 10.832600410000964,
      },
      {
        lon: 106.69489053400099,
        lat: 10.831021513000962,
      },
      {
        lon: 106.69418291500097,
        lat: 10.830396707000602,
      },
      {
        lon: 106.6932064520011,
        lat: 10.830420102001028,
      },
      {
        lon: 106.69282354600102,
        lat: 10.830617823000068,
      },
      {
        lon: 106.69235102100095,
        lat: 10.831186008000998,
      },
      {
        lon: 106.69201292100104,
        lat: 10.83190970900091,
      },
      {
        lon: 106.6918128720011,
        lat: 10.83262210400079,
      },
      {
        lon: 106.69150191500103,
        lat: 10.834297371000048,
      },
      {
        lon: 106.69110903000092,
        lat: 10.83574868600067,
      },
      {
        lon: 106.69081486900103,
        lat: 10.836599391000448,
      },
      {
        lon: 106.69053804945344,
        lat: 10.837082226916607,
      },
      {
        lon: 106.68939735700098,
        lat: 10.838646745000915,
      },
      {
        lon: 106.68910405692338,
        lat: 10.839656865133179,
      },
      {
        lon: 106.68905151800107,
        lat: 10.840977725000869,
      },
      {
        lon: 106.68958910800092,
        lat: 10.843399470000975,
      },
      {
        lon: 106.68951917700093,
        lat: 10.843822445000342,
      },
      {
        lon: 106.68901379000096,
        lat: 10.845242279000047,
      },
      {
        lon: 106.68811053100109,
        lat: 10.846781399000918,
      },
      {
        lon: 106.68736932000093,
        lat: 10.84731736200041,
      },
      {
        lon: 106.68649626800101,
        lat: 10.847594475000335,
      },
      {
        lon: 106.68580263300089,
        lat: 10.84755141000096,
      },
      {
        lon: 106.684506142001,
        lat: 10.847302927000726,
      },
      {
        lon: 106.68384564700096,
        lat: 10.84737919600002,
      },
      {
        lon: 106.6833958730009,
        lat: 10.847737856000208,
      },
      {
        lon: 106.68337602000094,
        lat: 10.848301774000504,
      },
      {
        lon: 106.68358740000104,
        lat: 10.84880809800012,
      },
      {
        lon: 106.684321406001,
        lat: 10.849642823000352,
      },
      {
        lon: 106.68461635000108,
        lat: 10.850393536000922,
      },
      {
        lon: 106.68436156300106,
        lat: 10.851342317000103,
      },
      {
        lon: 106.68390097700092,
        lat: 10.851803951000818,
      },
      {
        lon: 106.682889168001,
        lat: 10.85203429200078,
      },
      {
        lon: 106.68147054300096,
        lat: 10.851717137000207,
      },
      {
        lon: 106.68079598600099,
        lat: 10.851716523000334,
      },
      {
        lon: 106.67998483500094,
        lat: 10.851408066000658,
      },
      {
        lon: 106.67891201300097,
        lat: 10.851252798000246,
      },
      {
        lon: 106.67832768300093,
        lat: 10.851381967001089,
      },
      {
        lon: 106.676183651001,
        lat: 10.852355325000076,
      },
      {
        lon: 106.67522247308563,
        lat: 10.852692728975967,
      },
      {
        lon: 106.67379697500104,
        lat: 10.853025656000739,
      },
      {
        lon: 106.6715618996343,
        lat: 10.853968122776337,
      },
      {
        lon: 106.67008082600097,
        lat: 10.855393541000637,
      },
      {
        lon: 106.66932358200103,
        lat: 10.855773885000133,
      },
      {
        lon: 106.66626949300104,
        lat: 10.856515568000304,
      },
      {
        lon: 106.66586967854573,
        lat: 10.856463200734936,
      },
      {
        lon: 106.66449975200089,
        lat: 10.856006355000716,
      },
      {
        lon: 106.6632784963798,
        lat: 10.855721321624799,
      },
      {
        lon: 106.66216383100095,
        lat: 10.855706894000718,
      },
      {
        lon: 106.66167756100089,
        lat: 10.855811428000663,
      },
      {
        lon: 106.66082931000096,
        lat: 10.856288136000671,
      },
      {
        lon: 106.65923109400103,
        lat: 10.857896833001007,
      },
      {
        lon: 106.65820046000101,
        lat: 10.858122144000902,
      },
      {
        lon: 106.65721281100093,
        lat: 10.857816918000879,
      },
      {
        lon: 106.65674278400104,
        lat: 10.857952310000883,
      },
      {
        lon: 106.6564126120009,
        lat: 10.858466964000115,
      },
      {
        lon: 106.65531483400105,
        lat: 10.859116215000995,
      },
      {
        lon: 106.65404699000095,
        lat: 10.859371456000702,
      },
      {
        lon: 106.65346130100107,
        lat: 10.860169207000183,
      },
      {
        lon: 106.65333292000099,
        lat: 10.860645602000432,
      },
      {
        lon: 106.652468927001,
        lat: 10.861000581000951,
      },
      {
        lon: 106.6510689180011,
        lat: 10.860219238001037,
      },
      {
        lon: 106.6498786250009,
        lat: 10.859183409000877,
      },
      {
        lon: 106.64858804000096,
        lat: 10.859008835000452,
      },
      {
        lon: 106.64789580400105,
        lat: 10.858569659000352,
      },
      {
        lon: 106.64699275300103,
        lat: 10.858335612000985,
      },
      {
        lon: 106.64678941887712,
        lat: 10.858091620400899,
      },
      {
        lon: 106.64661086200101,
        lat: 10.85692839300009,
      },
      {
        lon: 106.64637381912678,
        lat: 10.856374050906993,
      },
      {
        lon: 106.6461933840011,
        lat: 10.854883601000722,
      },
      {
        lon: 106.64639027700093,
        lat: 10.85444869300062,
      },
      {
        lon: 106.64593335600108,
        lat: 10.85419312500079,
      },
      {
        lon: 106.64518693100104,
        lat: 10.85471281100098,
      },
      {
        lon: 106.64472284300098,
        lat: 10.854575528000908,
      },
      {
        lon: 106.64438073900095,
        lat: 10.853961111000956,
      },
      {
        lon: 106.64439136000101,
        lat: 10.853106901000444,
      },
      {
        lon: 106.64411365400093,
        lat: 10.852831542000843,
      },
      {
        lon: 106.64324846800093,
        lat: 10.852691550000998,
      },
      {
        lon: 106.64281844900097,
        lat: 10.85215081900003,
      },
      {
        lon: 106.64274483400101,
        lat: 10.851110314000891,
      },
      {
        lon: 106.64185011900099,
        lat: 10.850275114,
      },
      {
        lon: 106.641544170001,
        lat: 10.849867837000318,
      },
      {
        lon: 106.64108613300118,
        lat: 10.849606803000938,
      },
      {
        lon: 106.64054603900092,
        lat: 10.849637003000225,
      },
      {
        lon: 106.64009483700107,
        lat: 10.849429920999967,
      },
      {
        lon: 106.63974172400106,
        lat: 10.849057712,
      },
      {
        lon: 106.63771985233433,
        lat: 10.84749566156006,
      },
      {
        lon: 106.63708260000095,
        lat: 10.84705513800003,
      },
      {
        lon: 106.63628285700094,
        lat: 10.846342956000967,
      },
      {
        lon: 106.63535660487776,
        lat: 10.845642546008946,
      },
      {
        lon: 106.63488165000092,
        lat: 10.844947941000385,
      },
      {
        lon: 106.63407092700089,
        lat: 10.845340492000446,
      },
      {
        lon: 106.63375307300089,
        lat: 10.845224239000686,
      },
      {
        lon: 106.63356149679731,
        lat: 10.844775271621359,
      },
      {
        lon: 106.63345376600091,
        lat: 10.84307393900076,
      },
      {
        lon: 106.63355315100108,
        lat: 10.84260586400103,
      },
      {
        lon: 106.63354123900098,
        lat: 10.841723686000876,
      },
      {
        lon: 106.63342239200097,
        lat: 10.841169339000203,
      },
      {
        lon: 106.63202988200106,
        lat: 10.840542933000961,
      },
      {
        lon: 106.63196909100111,
        lat: 10.840249758000766,
      },
      {
        lon: 106.63229268800097,
        lat: 10.8388182270009,
      },
      {
        lon: 106.63282614800102,
        lat: 10.838337491000914,
      },
      {
        lon: 106.63284956000103,
        lat: 10.83790718500012,
      },
      {
        lon: 106.63236918700096,
        lat: 10.837688681000884,
      },
      {
        lon: 106.63179881100103,
        lat: 10.836831276000908,
      },
      {
        lon: 106.63182925400099,
        lat: 10.836456933000932,
      },
      {
        lon: 106.63238337600097,
        lat: 10.835669059000566,
      },
      {
        lon: 106.6326158050926,
        lat: 10.834430547064017,
      },
      {
        lon: 106.63196525800106,
        lat: 10.831995676000474,
      },
      {
        lon: 106.63129822500099,
        lat: 10.830252463000932,
      },
      {
        lon: 106.63044276000102,
        lat: 10.828661999000246,
      },
      {
        lon: 106.63020451900093,
        lat: 10.827921792000552,
      },
      {
        lon: 106.62883505200102,
        lat: 10.825936609000166,
      },
      {
        lon: 106.62738578800105,
        lat: 10.824144974000887,
      },
      {
        lon: 106.62557780500089,
        lat: 10.822062440000877,
      },
      {
        lon: 106.6248705960009,
        lat: 10.821076102000973,
      },
      {
        lon: 106.62390061000099,
        lat: 10.820101696000108,
      },
      {
        lon: 106.62318709595525,
        lat: 10.819652873295638,
      },
      {
        lon: 106.62311404699403,
        lat: 10.819263887577506,
      },
      {
        lon: 106.62320343349438,
        lat: 10.819175426321408,
      },
      {
        lon: 106.62346728360023,
        lat: 10.819162993593864,
      },
    ],
    [
      {
        lon: 106.57564672405033,
        lat: 10.768853707009896,
      },
      {
        lon: 106.5754745420009,
        lat: 10.768515816000104,
      },
      {
        lon: 106.57628045900105,
        lat: 10.767864676000954,
      },
      {
        lon: 106.57658633200089,
        lat: 10.767379790000973,
      },
      {
        lon: 106.57691615900092,
        lat: 10.767309497000213,
      },
      {
        lon: 106.57764540200094,
        lat: 10.76652318400079,
      },
      {
        lon: 106.57738812600098,
        lat: 10.76495213900074,
      },
      {
        lon: 106.57750182800095,
        lat: 10.764151613000763,
      },
      {
        lon: 106.57771025500091,
        lat: 10.763791437000805,
      },
      {
        lon: 106.57871513400102,
        lat: 10.762762199000917,
      },
      {
        lon: 106.5790682220009,
        lat: 10.761955686000704,
      },
      {
        lon: 106.580339129001,
        lat: 10.760468288000027,
      },
      {
        lon: 106.58045095425439,
        lat: 10.759904393506304,
      },
      {
        lon: 106.58084311900075,
        lat: 10.759773871000391,
      },
      {
        lon: 106.58220934500082,
        lat: 10.758836890000225,
      },
      {
        lon: 106.5828799820008,
        lat: 10.758624538000788,
      },
      {
        lon: 106.58348780100073,
        lat: 10.758697515000506,
      },
      {
        lon: 106.58439054600075,
        lat: 10.758050839000646,
      },
      {
        lon: 106.58612755700085,
        lat: 10.757692423000833,
      },
      {
        lon: 106.58697985800075,
        lat: 10.757729877000202,
      },
      {
        lon: 106.58756773600072,
        lat: 10.757471767000709,
      },
      {
        lon: 106.58772096900088,
        lat: 10.757231138000236,
      },
      {
        lon: 106.58761880900096,
        lat: 10.755212522000193,
      },
      {
        lon: 106.58716089900105,
        lat: 10.75377448200095,
      },
      {
        lon: 106.58688621700111,
        lat: 10.752107453000406,
      },
      {
        lon: 106.58622002100105,
        lat: 10.750707902000158,
      },
      {
        lon: 106.58629278600108,
        lat: 10.750373310000896,
      },
      {
        lon: 106.58719681100104,
        lat: 10.74893577000097,
      },
      {
        lon: 106.58764431300091,
        lat: 10.748091443000476,
      },
      {
        lon: 106.58855289400105,
        lat: 10.747211012000548,
      },
      {
        lon: 106.58950366200105,
        lat: 10.74674664000014,
      },
      {
        lon: 106.58989042100097,
        lat: 10.746203112001012,
      },
      {
        lon: 106.59125171500096,
        lat: 10.742033970000477,
      },
      {
        lon: 106.59117634400104,
        lat: 10.74113389200079,
      },
      {
        lon: 106.590999750001,
        lat: 10.740719334000062,
      },
      {
        lon: 106.59104235600097,
        lat: 10.739717624000491,
      },
      {
        lon: 106.59156702200102,
        lat: 10.738282048000372,
      },
      {
        lon: 106.59163692800098,
        lat: 10.737221813000133,
      },
      {
        lon: 106.59231889100101,
        lat: 10.736119753000253,
      },
      {
        lon: 106.59262879200094,
        lat: 10.735779586000262,
      },
      {
        lon: 106.59277796300097,
        lat: 10.735212309000133,
      },
      {
        lon: 106.59306727600102,
        lat: 10.734737811001025,
      },
      {
        lon: 106.5932388490009,
        lat: 10.734130722000259,
      },
      {
        lon: 106.59381652000098,
        lat: 10.733557693000364,
      },
      {
        lon: 106.59407180400098,
        lat: 10.73291555600008,
      },
      {
        lon: 106.59463368700102,
        lat: 10.732086918000618,
      },
      {
        lon: 106.59499830300098,
        lat: 10.731369268000037,
      },
      {
        lon: 106.59550448400103,
        lat: 10.73087047100097,
      },
      {
        lon: 106.59579747300103,
        lat: 10.73081042700096,
      },
      {
        lon: 106.59703269700111,
        lat: 10.73101845400032,
      },
      {
        lon: 106.59771333400101,
        lat: 10.730869581000889,
      },
      {
        lon: 106.59802720100078,
        lat: 10.730250268000532,
      },
      {
        lon: 106.59840037000083,
        lat: 10.729921076000835,
      },
      {
        lon: 106.59949979400085,
        lat: 10.729230016000315,
      },
      {
        lon: 106.59998322400088,
        lat: 10.728718986000843,
      },
      {
        lon: 106.60065667000083,
        lat: 10.72868957000054,
      },
      {
        lon: 106.60111460900077,
        lat: 10.729515923000804,
      },
      {
        lon: 106.60153290000085,
        lat: 10.728741679000677,
      },
      {
        lon: 106.60232115800073,
        lat: 10.727594285000066,
      },
      {
        lon: 106.60387336100081,
        lat: 10.726756281,
      },
      {
        lon: 106.60378190400075,
        lat: 10.726093772000524,
      },
      {
        lon: 106.60395612300073,
        lat: 10.725385701000846,
      },
      {
        lon: 106.60362368800082,
        lat: 10.724096672000485,
      },
      {
        lon: 106.60333084900083,
        lat: 10.723755911000808,
      },
      {
        lon: 106.6031442850009,
        lat: 10.722699944000738,
      },
      {
        lon: 106.60363541100082,
        lat: 10.721725225000114,
      },
      {
        lon: 106.60370629700083,
        lat: 10.720249649000777,
      },
      {
        lon: 106.60396348700078,
        lat: 10.719108297000844,
      },
      {
        lon: 106.60398000900074,
        lat: 10.717654981000862,
      },
      {
        lon: 106.60383804100087,
        lat: 10.716799772000343,
      },
      {
        lon: 106.60353782600083,
        lat: 10.716041341000562,
      },
      {
        lon: 106.60289984000082,
        lat: 10.715245827000842,
      },
      {
        lon: 106.60281960100082,
        lat: 10.714573424000719,
      },
      {
        lon: 106.60348234700075,
        lat: 10.7135012100008,
      },
      {
        lon: 106.6035268320008,
        lat: 10.712937934000024,
      },
      {
        lon: 106.6030900810008,
        lat: 10.711918380000043,
      },
      {
        lon: 106.60317192300084,
        lat: 10.711245269000756,
      },
      {
        lon: 106.60370407100083,
        lat: 10.71063976900075,
      },
      {
        lon: 106.60472747200083,
        lat: 10.710139012000809,
      },
      {
        lon: 106.60507690700092,
        lat: 10.70970018300045,
      },
      {
        lon: 106.60585490700078,
        lat: 10.709236320000173,
      },
      {
        lon: 106.60727632200087,
        lat: 10.708036921000144,
      },
      {
        lon: 106.60782354500077,
        lat: 10.70726026700072,
      },
      {
        lon: 106.60814726200084,
        lat: 10.706517001000861,
      },
      {
        lon: 106.60855386100081,
        lat: 10.706850921000068,
      },
      {
        lon: 106.60811828000072,
        lat: 10.707652492000761,
      },
      {
        lon: 106.60737444100073,
        lat: 10.70858690900053,
      },
      {
        lon: 106.60650565800074,
        lat: 10.709335769000859,
      },
      {
        lon: 106.60458651200094,
        lat: 10.710777921000272,
      },
      {
        lon: 106.60411864800074,
        lat: 10.710986645000844,
      },
      {
        lon: 106.60367545700075,
        lat: 10.711666247000778,
      },
      {
        lon: 106.6036921040008,
        lat: 10.712063637000426,
      },
      {
        lon: 106.60399928400085,
        lat: 10.712955263000481,
      },
      {
        lon: 106.60403155200083,
        lat: 10.713370134000797,
      },
      {
        lon: 106.60335844900084,
        lat: 10.714605290000861,
      },
      {
        lon: 106.60335971500075,
        lat: 10.715057569000521,
      },
      {
        lon: 106.60403950100084,
        lat: 10.716015121000169,
      },
      {
        lon: 106.60433896000086,
        lat: 10.717556706000266,
      },
      {
        lon: 106.60436005000082,
        lat: 10.718717360000198,
      },
      {
        lon: 106.60384428500072,
        lat: 10.722051083000792,
      },
      {
        lon: 106.60355081700078,
        lat: 10.722812166000608,
      },
      {
        lon: 106.60365814800082,
        lat: 10.723446107000768,
      },
      {
        lon: 106.60413880000084,
        lat: 10.72450729800073,
      },
      {
        lon: 106.60434016800076,
        lat: 10.726640487000736,
      },
      {
        lon: 106.6036971698424,
        lat: 10.727349355086403,
      },
      {
        lon: 106.60304500700067,
        lat: 10.727553537000631,
      },
      {
        lon: 106.60250922300071,
        lat: 10.727870663000374,
      },
      {
        lon: 106.60173301900079,
        lat: 10.728953125999965,
      },
      {
        lon: 106.6013709290009,
        lat: 10.729840210000532,
      },
      {
        lon: 106.60080235200081,
        lat: 10.729817300999969,
      },
      {
        lon: 106.60045907900076,
        lat: 10.729149107000637,
      },
      {
        lon: 106.60019318900078,
        lat: 10.729091800000788,
      },
      {
        lon: 106.59832453700085,
        lat: 10.730429059000869,
      },
      {
        lon: 106.59801265600072,
        lat: 10.731045597,
      },
      {
        lon: 106.59744318200103,
        lat: 10.731251861000715,
      },
      {
        lon: 106.59663683500105,
        lat: 10.731284590000314,
      },
      {
        lon: 106.5956368350009,
        lat: 10.731085291000966,
      },
      {
        lon: 106.59511553900091,
        lat: 10.731528372000412,
      },
      {
        lon: 106.59410417888472,
        lat: 10.733338418438677,
      },
      {
        lon: 106.59335063300091,
        lat: 10.7343428300004,
      },
      {
        lon: 106.59272801600099,
        lat: 10.73586086800077,
      },
      {
        lon: 106.59219522400099,
        lat: 10.736547261000453,
      },
      {
        lon: 106.59174742900097,
        lat: 10.737442195000426,
      },
      {
        lon: 106.59169990200104,
        lat: 10.738369649999981,
      },
      {
        lon: 106.59129561500089,
        lat: 10.739379811000163,
      },
      {
        lon: 106.59111168900091,
        lat: 10.740570911000304,
      },
      {
        lon: 106.59133905400103,
        lat: 10.741548526000997,
      },
      {
        lon: 106.59131396800109,
        lat: 10.742448875000434,
      },
      {
        lon: 106.590658246001,
        lat: 10.744058299000017,
      },
      {
        lon: 106.59001408000097,
        lat: 10.746269800001016,
      },
      {
        lon: 106.58965095900106,
        lat: 10.746789103000367,
      },
      {
        lon: 106.58882638600096,
        lat: 10.747196656000426,
      },
      {
        lon: 106.58789864300098,
        lat: 10.748054609000803,
      },
      {
        lon: 106.58673708287557,
        lat: 10.750173206527563,
      },
      {
        lon: 106.58652982900111,
        lat: 10.751078446000975,
      },
      {
        lon: 106.5869720870009,
        lat: 10.751934751000874,
      },
      {
        lon: 106.58728609200102,
        lat: 10.753742359000853,
      },
      {
        lon: 106.587817940001,
        lat: 10.755521082000143,
      },
      {
        lon: 106.58787337700085,
        lat: 10.757038685000566,
      },
      {
        lon: 106.58757618600072,
        lat: 10.757643076000761,
      },
      {
        lon: 106.58680660500073,
        lat: 10.757903308000607,
      },
      {
        lon: 106.58611203700082,
        lat: 10.757851091000816,
      },
      {
        lon: 106.58453050500088,
        lat: 10.758156820000806,
      },
      {
        lon: 106.58356089800083,
        lat: 10.758858903000714,
      },
      {
        lon: 106.5828914440009,
        lat: 10.758789620000755,
      },
      {
        lon: 106.58243519100085,
        lat: 10.758914648000086,
      },
      {
        lon: 106.58168945200072,
        lat: 10.759339308000051,
      },
      {
        lon: 106.58069380500103,
        lat: 10.760117656000148,
      },
      {
        lon: 106.58042773000096,
        lat: 10.760758714000076,
      },
      {
        lon: 106.57970276600099,
        lat: 10.761376647000134,
      },
      {
        lon: 106.57915929600097,
        lat: 10.76226670000068,
      },
      {
        lon: 106.57849622400101,
        lat: 10.76369364000044,
      },
      {
        lon: 106.57787620000101,
        lat: 10.763973251000039,
      },
      {
        lon: 106.57766076500104,
        lat: 10.764619026000446,
      },
      {
        lon: 106.57795340300095,
        lat: 10.766538293,
      },
      {
        lon: 106.57688423300095,
        lat: 10.767474695000203,
      },
      {
        lon: 106.5765371280009,
        lat: 10.767595632,
      },
      {
        lon: 106.5762181850011,
        lat: 10.768070388000922,
      },
      {
        lon: 106.575648217001,
        lat: 10.768605894000984,
      },
      {
        lon: 106.57564672405033,
        lat: 10.768853707009896,
      },
    ],
    [
      {
        lon: 106.62318709595525,
        lat: 10.819652873295638,
      },
      {
        lon: 106.62168986000171,
        lat: 10.819041390001018,
      },
      {
        lon: 106.62060100300158,
        lat: 10.818841310000096,
      },
      {
        lon: 106.61960636700172,
        lat: 10.818758845001355,
      },
      {
        lon: 106.61785339400171,
        lat: 10.819671410000922,
      },
      {
        lon: 106.61686697872318,
        lat: 10.819805921911154,
      },
      {
        lon: 106.61525255251803,
        lat: 10.819146480537484,
      },
      {
        lon: 106.61443231744812,
        lat: 10.818622051481043,
      },
      {
        lon: 106.61316084100167,
        lat: 10.817999128000224,
      },
      {
        lon: 106.61197342200177,
        lat: 10.817139489001306,
      },
      {
        lon: 106.61030224862571,
        lat: 10.815599748901661,
      },
      {
        lon: 106.60897654600177,
        lat: 10.814569223000206,
      },
      {
        lon: 106.6077466425674,
        lat: 10.813356147916124,
      },
      {
        lon: 106.60749264700183,
        lat: 10.813019484000677,
      },
      {
        lon: 106.60726857600184,
        lat: 10.811358102001236,
      },
      {
        lon: 106.60696972776003,
        lat: 10.808388626640522,
      },
      {
        lon: 106.60721988500183,
        lat: 10.806837799001414,
      },
      {
        lon: 106.60673775110087,
        lat: 10.805879881434862,
      },
      {
        lon: 106.60637554200184,
        lat: 10.805474081001023,
      },
      {
        lon: 106.60544263979284,
        lat: 10.801896520393086,
      },
      {
        lon: 106.60504762000176,
        lat: 10.800926198000695,
      },
      {
        lon: 106.6044734860019,
        lat: 10.80031467900028,
      },
      {
        lon: 106.60348046600177,
        lat: 10.798730646000934,
      },
      {
        lon: 106.60322563146627,
        lat: 10.798132947181784,
      },
      {
        lon: 106.60274809234,
        lat: 10.794983962076401,
      },
      {
        lon: 106.6024472630018,
        lat: 10.794456167000135,
      },
      {
        lon: 106.5997013710018,
        lat: 10.79126789800057,
      },
      {
        lon: 106.59737404200172,
        lat: 10.78947611400082,
      },
      {
        lon: 106.59660107900163,
        lat: 10.788757930000372,
      },
      {
        lon: 106.59619824500152,
        lat: 10.787379958001111,
      },
      {
        lon: 106.59544556800165,
        lat: 10.786394761000508,
      },
      {
        lon: 106.59497354500161,
        lat: 10.785926320000385,
      },
      {
        lon: 106.59453687500155,
        lat: 10.785960283000975,
      },
      {
        lon: 106.59256342000157,
        lat: 10.784754882000982,
      },
      {
        lon: 106.59242456100156,
        lat: 10.783919425001045,
      },
      {
        lon: 106.5918960980016,
        lat: 10.783251769000515,
      },
      {
        lon: 106.5907256530017,
        lat: 10.782686863000492,
      },
      {
        lon: 106.59043691300161,
        lat: 10.781946751000362,
      },
      {
        lon: 106.58990812500173,
        lat: 10.78160150100075,
      },
      {
        lon: 106.58905705600154,
        lat: 10.78128507700086,
      },
      {
        lon: 106.5888238100016,
        lat: 10.78102171300003,
      },
      {
        lon: 106.58878516400159,
        lat: 10.77914642100012,
      },
      {
        lon: 106.58816145900164,
        lat: 10.777978142001245,
      },
      {
        lon: 106.58765369000179,
        lat: 10.777642541000034,
      },
      {
        lon: 106.58708803300163,
        lat: 10.77661235900024,
      },
      {
        lon: 106.58629339000169,
        lat: 10.775565536000045,
      },
      {
        lon: 106.58511987600173,
        lat: 10.774986316000081,
      },
      {
        lon: 106.58394636800165,
        lat: 10.774909443001093,
      },
      {
        lon: 106.58204584700167,
        lat: 10.775439776001008,
      },
      {
        lon: 106.5814747810017,
        lat: 10.77542162200075,
      },
      {
        lon: 106.58083664200171,
        lat: 10.774880358000019,
      },
      {
        lon: 106.58056800300164,
        lat: 10.774855551000542,
      },
      {
        lon: 106.5798111500016,
        lat: 10.773828749001382,
      },
      {
        lon: 106.57886387900169,
        lat: 10.772999199000424,
      },
      {
        lon: 106.57834200700165,
        lat: 10.772774339000648,
      },
      {
        lon: 106.57673495200163,
        lat: 10.771293722000621,
      },
      {
        lon: 106.57668490800177,
        lat: 10.770357916000819,
      },
      {
        lon: 106.57568006697096,
        lat: 10.769276050667543,
      },
      {
        lon: 106.5756467240505,
        lat: 10.768853707010067,
      },
      {
        lon: 106.57653274148754,
        lat: 10.769783137273464,
      },
      {
        lon: 106.5769080464874,
        lat: 10.770352423273494,
      },
      {
        lon: 106.5767876164872,
        lat: 10.770923638273416,
      },
      {
        lon: 106.57697938548716,
        lat: 10.771317453273582,
      },
      {
        lon: 106.57758242948734,
        lat: 10.771908699273297,
      },
      {
        lon: 106.57857815248741,
        lat: 10.772600435272977,
      },
      {
        lon: 106.5799842974871,
        lat: 10.773847700272881,
      },
      {
        lon: 106.58056428548713,
        lat: 10.774714568273737,
      },
      {
        lon: 106.58079347048738,
        lat: 10.774704926272932,
      },
      {
        lon: 106.58153418648737,
        lat: 10.775342942273141,
      },
      {
        lon: 106.58239758148733,
        lat: 10.775253573273119,
      },
      {
        lon: 106.5839678464871,
        lat: 10.774821495273613,
      },
      {
        lon: 106.58491819948733,
        lat: 10.774855159273727,
      },
      {
        lon: 106.58628610548737,
        lat: 10.775453732273604,
      },
      {
        lon: 106.58685579148744,
        lat: 10.776055327273184,
      },
      {
        lon: 106.5875425534872,
        lat: 10.777278703273169,
      },
      {
        lon: 106.58825385248736,
        lat: 10.77788503427314,
      },
      {
        lon: 106.58885987848743,
        lat: 10.778985996273661,
      },
      {
        lon: 106.58896212048734,
        lat: 10.7810104112732,
      },
      {
        lon: 106.58919201948726,
        lat: 10.781228647273679,
      },
      {
        lon: 106.59004167148743,
        lat: 10.781545946273013,
      },
      {
        lon: 106.59054589248728,
        lat: 10.78189945527322,
      },
      {
        lon: 106.59082204448742,
        lat: 10.782596741273721,
      },
      {
        lon: 106.5920230314872,
        lat: 10.783204765273751,
      },
      {
        lon: 106.5924705494873,
        lat: 10.78373465027363,
      },
      {
        lon: 106.59265134648723,
        lat: 10.784683254273602,
      },
      {
        lon: 106.59457881848738,
        lat: 10.785852025273584,
      },
      {
        lon: 106.59513386448731,
        lat: 10.785841939272927,
      },
      {
        lon: 106.59611245448707,
        lat: 10.787030395273746,
      },
      {
        lon: 106.59652708148718,
        lat: 10.787838658273188,
      },
      {
        lon: 106.59670458148713,
        lat: 10.788672527273718,
      },
      {
        lon: 106.59743824748762,
        lat: 10.789358464273903,
      },
      {
        lon: 106.59961637348755,
        lat: 10.79095687127301,
      },
      {
        lon: 106.60098236948761,
        lat: 10.792449774273166,
      },
      {
        lon: 106.60361607948721,
        lat: 10.79565484027313,
      },
      {
        lon: 106.60810868048702,
        lat: 10.801032947273598,
      },
      {
        lon: 106.61273812548717,
        lat: 10.806499350273555,
      },
      {
        lon: 106.61505286448693,
        lat: 10.809296338273327,
      },
      {
        lon: 106.61876769648717,
        lat: 10.813689719273498,
      },
      {
        lon: 106.6193102354872,
        lat: 10.814289119272985,
      },
      {
        lon: 106.62211448948705,
        lat: 10.81761223627359,
      },
      {
        lon: 106.62346728360023,
        lat: 10.819162993593864,
      },
      {
        lon: 106.62320343349438,
        lat: 10.819175426321408,
      },
      {
        lon: 106.6219503000014,
        lat: 10.817754749000699,
      },
      {
        lon: 106.6191590330015,
        lat: 10.814411302000906,
      },
      {
        lon: 106.61865932300151,
        lat: 10.813776203000316,
      },
      {
        lon: 106.61555299700137,
        lat: 10.810033553000267,
      },
      {
        lon: 106.61479200900146,
        lat: 10.809163100001342,
      },
      {
        lon: 106.61210396300146,
        lat: 10.805944192001107,
      },
      {
        lon: 106.60798837700145,
        lat: 10.801065373001109,
      },
      {
        lon: 106.60510363500134,
        lat: 10.79760448700057,
      },
      {
        lon: 106.6029036240015,
        lat: 10.795007213000076,
      },
      {
        lon: 106.60293656214401,
        lat: 10.795744567279257,
      },
      {
        lon: 106.60334879756027,
        lat: 10.798074523814307,
      },
      {
        lon: 106.6036015974874,
        lat: 10.798637873273492,
      },
      {
        lon: 106.6045973041658,
        lat: 10.800240159332532,
      },
      {
        lon: 106.60515551348755,
        lat: 10.800805734273183,
      },
      {
        lon: 106.60553850275397,
        lat: 10.801501673183623,
      },
      {
        lon: 106.60646370711726,
        lat: 10.80525641361345,
      },
      {
        lon: 106.6068691415478,
        lat: 10.805488808955374,
      },
      {
        lon: 106.60696288248761,
        lat: 10.805986557272945,
      },
      {
        lon: 106.60748893948755,
        lat: 10.806874563273766,
      },
      {
        lon: 106.60754226306312,
        lat: 10.807288636074702,
      },
      {
        lon: 106.6073477904875,
        lat: 10.808212111273235,
      },
      {
        lon: 106.60753032126661,
        lat: 10.810323547114706,
      },
      {
        lon: 106.60758817348739,
        lat: 10.811880550272917,
      },
      {
        lon: 106.60784778248728,
        lat: 10.813103687273667,
      },
      {
        lon: 106.60923717620096,
        lat: 10.814321236332603,
      },
      {
        lon: 106.61144065648739,
        lat: 10.816496064273792,
      },
      {
        lon: 106.61263581348743,
        lat: 10.81735222727383,
      },
      {
        lon: 106.61329747048734,
        lat: 10.817680673273287,
      },
      {
        lon: 106.61426513448748,
        lat: 10.817979435273855,
      },
      {
        lon: 106.61518496894035,
        lat: 10.818603775782947,
      },
      {
        lon: 106.6168662624874,
        lat: 10.81932810727318,
      },
      {
        lon: 106.61753971176421,
        lat: 10.819474327980004,
      },
      {
        lon: 106.61855197048726,
        lat: 10.819091691273513,
      },
      {
        lon: 106.61930533848744,
        lat: 10.818502754273368,
      },
      {
        lon: 106.62077749548746,
        lat: 10.818561530273257,
      },
      {
        lon: 106.6218998144875,
        lat: 10.818750213273859,
      },
      {
        lon: 106.62311404699403,
        lat: 10.819263887577506,
      },
      {
        lon: 106.62318709595525,
        lat: 10.819652873295638,
      },
    ],
    [
      {
        lon: 106.72707288149655,
        lat: 10.680866842990838,
      },
      {
        lon: 106.72688683700028,
        lat: 10.68155673800021,
      },
      {
        lon: 106.72752619400035,
        lat: 10.682734644000334,
      },
      {
        lon: 106.72899510600035,
        lat: 10.684291217000066,
      },
      {
        lon: 106.72932670800026,
        lat: 10.68536812900021,
      },
      {
        lon: 106.72878317500033,
        lat: 10.686896830000308,
      },
      {
        lon: 106.72813436900032,
        lat: 10.687910742000327,
      },
      {
        lon: 106.72824127800033,
        lat: 10.68969232800032,
      },
      {
        lon: 106.72769174000035,
        lat: 10.691528919000291,
      },
      {
        lon: 106.72770349300015,
        lat: 10.693151386000352,
      },
      {
        lon: 106.72753302900026,
        lat: 10.693942632000244,
      },
      {
        lon: 106.72640400200021,
        lat: 10.694914313000083,
      },
      {
        lon: 106.7241582930003,
        lat: 10.696010586000284,
      },
      {
        lon: 106.72360792000029,
        lat: 10.696718359000329,
      },
      {
        lon: 106.72431442900023,
        lat: 10.697564293000141,
      },
      {
        lon: 106.72549471700017,
        lat: 10.698526400000276,
      },
      {
        lon: 106.72625708500021,
        lat: 10.698680208000212,
      },
      {
        lon: 106.72685757500034,
        lat: 10.69828291600021,
      },
      {
        lon: 106.72870105200036,
        lat: 10.69768437900035,
      },
      {
        lon: 106.73188426800033,
        lat: 10.697112077000213,
      },
      {
        lon: 106.73318476000026,
        lat: 10.697180010000238,
      },
      {
        lon: 106.73462792900023,
        lat: 10.698261980000268,
      },
      {
        lon: 106.73519955290737,
        lat: 10.700132790703378,
      },
      {
        lon: 106.73547500900031,
        lat: 10.701987978000147,
      },
      {
        lon: 106.73610536600034,
        lat: 10.702669811000192,
      },
      {
        lon: 106.73717249200035,
        lat: 10.703133420000256,
      },
      {
        lon: 106.7400747660003,
        lat: 10.702663874000109,
      },
      {
        lon: 106.74316882500028,
        lat: 10.702371539000353,
      },
      {
        lon: 106.74542921200032,
        lat: 10.702517036000245,
      },
      {
        lon: 106.74701397700028,
        lat: 10.703005128000314,
      },
      {
        lon: 106.74638163100036,
        lat: 10.70424580000008,
      },
      {
        lon: 106.74520827300023,
        lat: 10.703845829000329,
      },
      {
        lon: 106.74377209800036,
        lat: 10.703764007000302,
      },
      {
        lon: 106.74199915600019,
        lat: 10.704293415000222,
      },
      {
        lon: 106.74094119400024,
        lat: 10.704056839000202,
      },
      {
        lon: 106.73717800592868,
        lat: 10.704143036326968,
      },
      {
        lon: 106.73545991600025,
        lat: 10.703797895000264,
      },
      {
        lon: 106.73462914300035,
        lat: 10.703070798000226,
      },
      {
        lon: 106.73391565922589,
        lat: 10.701966678432541,
      },
      {
        lon: 106.73357619500024,
        lat: 10.699223921000339,
      },
      {
        lon: 106.73312892900037,
        lat: 10.698646721000216,
      },
      {
        lon: 106.73211788400027,
        lat: 10.69852542000018,
      },
      {
        lon: 106.72913261000036,
        lat: 10.698807098000032,
      },
      {
        lon: 106.72794794200024,
        lat: 10.699281896000059,
      },
      {
        lon: 106.72709893900026,
        lat: 10.700374304000206,
      },
      {
        lon: 106.72706163500015,
        lat: 10.7016352670002,
      },
      {
        lon: 106.72541521300036,
        lat: 10.70550686100006,
      },
      {
        lon: 106.72543306500023,
        lat: 10.706088073000217,
      },
      {
        lon: 106.7261547570003,
        lat: 10.708025633000318,
      },
      {
        lon: 106.72657182913053,
        lat: 10.709878553821511,
      },
      {
        lon: 106.72605140700023,
        lat: 10.712100665000321,
      },
      {
        lon: 106.72447562500025,
        lat: 10.713913992000187,
      },
      {
        lon: 106.72376048600017,
        lat: 10.715397596000173,
      },
      {
        lon: 106.72414574000021,
        lat: 10.716632773000129,
      },
      {
        lon: 106.72550295900015,
        lat: 10.718818639000176,
      },
      {
        lon: 106.72555854600023,
        lat: 10.72043922200021,
      },
      {
        lon: 106.7249866480002,
        lat: 10.721718055000053,
      },
      {
        lon: 106.7220639440003,
        lat: 10.722824185000093,
      },
      {
        lon: 106.72053587200014,
        lat: 10.72308342100024,
      },
      {
        lon: 106.71949169500016,
        lat: 10.721905555000319,
      },
      {
        lon: 106.71834041600022,
        lat: 10.719669863000263,
      },
      {
        lon: 106.71730507600034,
        lat: 10.719577461000256,
      },
      {
        lon: 106.71508218900021,
        lat: 10.720377955000231,
      },
      {
        lon: 106.71254293400034,
        lat: 10.721064865000187,
      },
      {
        lon: 106.71094119900022,
        lat: 10.721038748000069,
      },
      {
        lon: 106.70853223100022,
        lat: 10.720280984000283,
      },
      {
        lon: 106.7080660590002,
        lat: 10.721200729000259,
      },
      {
        lon: 106.70815491100024,
        lat: 10.72337844200024,
      },
      {
        lon: 106.7077207720003,
        lat: 10.72472870200022,
      },
      {
        lon: 106.7062694460003,
        lat: 10.725656329000344,
      },
      {
        lon: 106.7046171690003,
        lat: 10.72591025700018,
      },
      {
        lon: 106.70352021600019,
        lat: 10.725880583000219,
      },
      {
        lon: 106.70025721700034,
        lat: 10.72507170199998,
      },
      {
        lon: 106.69832292800027,
        lat: 10.724763733000202,
      },
      {
        lon: 106.69669193500022,
        lat: 10.72512955999997,
      },
      {
        lon: 106.69407272500018,
        lat: 10.725925655000026,
      },
      {
        lon: 106.69132569700031,
        lat: 10.72588883900022,
      },
      {
        lon: 106.6906733040002,
        lat: 10.72621742400031,
      },
      {
        lon: 106.69086558800012,
        lat: 10.730179049000242,
      },
      {
        lon: 106.69116388300023,
        lat: 10.730677149000087,
      },
      {
        lon: 106.6933619400001,
        lat: 10.73221092600022,
      },
      {
        lon: 106.6944427082679,
        lat: 10.733487334144911,
      },
      {
        lon: 106.6955709790002,
        lat: 10.735925111000142,
      },
      {
        lon: 106.69551412700025,
        lat: 10.736921238000264,
      },
      {
        lon: 106.69510184900025,
        lat: 10.737985810000225,
      },
      {
        lon: 106.69234739000035,
        lat: 10.742218807000427,
      },
      {
        lon: 106.69323059200042,
        lat: 10.743974239000352,
      },
      {
        lon: 106.69335126000027,
        lat: 10.74574010100008,
      },
      {
        lon: 106.69284823400022,
        lat: 10.747442011000032,
      },
      {
        lon: 106.69209772400029,
        lat: 10.748501613000315,
      },
      {
        lon: 106.69095428124825,
        lat: 10.749739643479018,
      },
      {
        lon: 106.69067133300035,
        lat: 10.750447780000343,
      },
      {
        lon: 106.69087977172688,
        lat: 10.751544487863498,
      },
      {
        lon: 106.6896610214381,
        lat: 10.751397222203593,
      },
      {
        lon: 106.68989790400036,
        lat: 10.749820793000254,
      },
      {
        lon: 106.69165457600025,
        lat: 10.747522591000347,
      },
      {
        lon: 106.69246706200033,
        lat: 10.745248221000452,
      },
      {
        lon: 106.69233762800025,
        lat: 10.744389509000257,
      },
      {
        lon: 106.69166897300045,
        lat: 10.743282171000487,
      },
      {
        lon: 106.6914102350002,
        lat: 10.742148214000167,
      },
      {
        lon: 106.69147198000024,
        lat: 10.741382187000283,
      },
      {
        lon: 106.6921078960001,
        lat: 10.740537203000201,
      },
      {
        lon: 106.6941391120003,
        lat: 10.737108389000184,
      },
      {
        lon: 106.69427918100024,
        lat: 10.735846653000237,
      },
      {
        lon: 106.69330609200011,
        lat: 10.733941368000046,
      },
      {
        lon: 106.69218597300022,
        lat: 10.732881975000055,
      },
      {
        lon: 106.69052057400017,
        lat: 10.731677175000243,
      },
      {
        lon: 106.6898786280002,
        lat: 10.730760459000237,
      },
      {
        lon: 106.68969332000012,
        lat: 10.729836977000218,
      },
      {
        lon: 106.68978495300018,
        lat: 10.727962292000086,
      },
      {
        lon: 106.68956653800012,
        lat: 10.727209668000171,
      },
      {
        lon: 106.68959599400029,
        lat: 10.724662718000275,
      },
      {
        lon: 106.68858611200017,
        lat: 10.722121137000215,
      },
      {
        lon: 106.68855023900034,
        lat: 10.72008924000022,
      },
      {
        lon: 106.68900625500021,
        lat: 10.718754294000234,
      },
      {
        lon: 106.6879286380003,
        lat: 10.715393216000336,
      },
      {
        lon: 106.68829421000021,
        lat: 10.712051154000221,
      },
      {
        lon: 106.68888447152165,
        lat: 10.711328356362628,
      },
      {
        lon: 106.69023532400024,
        lat: 10.710783003000529,
      },
      {
        lon: 106.69050348100029,
        lat: 10.709538063000196,
      },
      {
        lon: 106.69121396800017,
        lat: 10.707266774000288,
      },
      {
        lon: 106.69215864000017,
        lat: 10.705063498000243,
      },
      {
        lon: 106.69313430700021,
        lat: 10.703573031000474,
      },
      {
        lon: 106.69375287100019,
        lat: 10.700395878000222,
      },
      {
        lon: 106.69540761800027,
        lat: 10.697549214000048,
      },
      {
        lon: 106.69840081100023,
        lat: 10.694333294000273,
      },
      {
        lon: 106.69857365700028,
        lat: 10.693802475000325,
      },
      {
        lon: 106.69822378300029,
        lat: 10.693060610000316,
      },
      {
        lon: 106.69814478100034,
        lat: 10.691701982000325,
      },
      {
        lon: 106.70009411000024,
        lat: 10.689726256000256,
      },
      {
        lon: 106.70056618200022,
        lat: 10.68887087100029,
      },
      {
        lon: 106.7019043120003,
        lat: 10.688338815000122,
      },
      {
        lon: 106.70288551200025,
        lat: 10.688210616000276,
      },
      {
        lon: 106.70357245100035,
        lat: 10.687454820000255,
      },
      {
        lon: 106.70534079200019,
        lat: 10.686192392000294,
      },
      {
        lon: 106.70636466200017,
        lat: 10.685891701000344,
      },
      {
        lon: 106.70737140400036,
        lat: 10.685314742000287,
      },
      {
        lon: 106.70815030900025,
        lat: 10.684333923000224,
      },
      {
        lon: 106.7083079330003,
        lat: 10.683040193000295,
      },
      {
        lon: 106.70877228500035,
        lat: 10.6820940570002,
      },
      {
        lon: 106.70971880900032,
        lat: 10.681702146000191,
      },
      {
        lon: 106.71052336000037,
        lat: 10.681871039000214,
      },
      {
        lon: 106.7123041560003,
        lat: 10.68348852600036,
      },
      {
        lon: 106.71278473600029,
        lat: 10.681841777000272,
      },
      {
        lon: 106.71343134800031,
        lat: 10.681286202000251,
      },
      {
        lon: 106.71508731300025,
        lat: 10.680736577000118,
      },
      {
        lon: 106.7167030900003,
        lat: 10.681232403000308,
      },
      {
        lon: 106.71755848400016,
        lat: 10.68099857500033,
      },
      {
        lon: 106.71815169200022,
        lat: 10.680439175000231,
      },
      {
        lon: 106.71956648800023,
        lat: 10.679884053000308,
      },
      {
        lon: 106.72016389987095,
        lat: 10.681237911058474,
      },
      {
        lon: 106.71926780200022,
        lat: 10.68095189700017,
      },
      {
        lon: 106.71771666000029,
        lat: 10.682157590000202,
      },
      {
        lon: 106.7166637930003,
        lat: 10.682266520000326,
      },
      {
        lon: 106.71486191700029,
        lat: 10.681896398999982,
      },
      {
        lon: 106.7137662960003,
        lat: 10.682396239000127,
      },
      {
        lon: 106.71382598500037,
        lat: 10.683642343000201,
      },
      {
        lon: 106.71341825500036,
        lat: 10.684371202000023,
      },
      {
        lon: 106.71277694300022,
        lat: 10.684858107000252,
      },
      {
        lon: 106.71212639400034,
        lat: 10.68487236300001,
      },
      {
        lon: 106.71055059200035,
        lat: 10.683104728000217,
      },
      {
        lon: 106.70998069000024,
        lat: 10.682665852000355,
      },
      {
        lon: 106.70920071400036,
        lat: 10.68314335200023,
      },
      {
        lon: 106.70905223225526,
        lat: 10.684824482618751,
      },
      {
        lon: 106.7071261010002,
        lat: 10.686606047000282,
      },
      {
        lon: 106.70541836900031,
        lat: 10.687270417000207,
      },
      {
        lon: 106.70438288000037,
        lat: 10.688027548000036,
      },
      {
        lon: 106.70325241000023,
        lat: 10.68914673800009,
      },
      {
        lon: 106.7019166080002,
        lat: 10.689232589000198,
      },
      {
        lon: 106.70138737800033,
        lat: 10.6894656850003,
      },
      {
        lon: 106.70050225300037,
        lat: 10.69063312500026,
      },
      {
        lon: 106.69939209400025,
        lat: 10.691582316,
      },
      {
        lon: 106.69896295000024,
        lat: 10.69234555200029,
      },
      {
        lon: 106.69941854400031,
        lat: 10.693194321000249,
      },
      {
        lon: 106.69940630400015,
        lat: 10.694425478000255,
      },
      {
        lon: 106.69881666000026,
        lat: 10.695201567000254,
      },
      {
        lon: 106.69717531300023,
        lat: 10.696716380000453,
      },
      {
        lon: 106.6961427140003,
        lat: 10.697828728000275,
      },
      {
        lon: 106.69449100200029,
        lat: 10.700613521000207,
      },
      {
        lon: 106.6937154710003,
        lat: 10.704124818000196,
      },
      {
        lon: 106.69251437400033,
        lat: 10.705851444000245,
      },
      {
        lon: 106.69123766707986,
        lat: 10.70933124679891,
      },
      {
        lon: 106.69074165665211,
        lat: 10.711095898762096,
      },
      {
        lon: 106.69021350300028,
        lat: 10.711852534000286,
      },
      {
        lon: 106.6894771010003,
        lat: 10.712129745000425,
      },
      {
        lon: 106.6889237390003,
        lat: 10.714007037000329,
      },
      {
        lon: 106.68906558399476,
        lat: 10.716223840815474,
      },
      {
        lon: 106.68978964400031,
        lat: 10.717888842000036,
      },
      {
        lon: 106.68987084800028,
        lat: 10.718488570000488,
      },
      {
        lon: 106.68944689100027,
        lat: 10.72093953600023,
      },
      {
        lon: 106.68950620700036,
        lat: 10.721791485000267,
      },
      {
        lon: 106.69054944900017,
        lat: 10.724650697000243,
      },
      {
        lon: 106.69160751800024,
        lat: 10.724584687000288,
      },
      {
        lon: 106.69243217700034,
        lat: 10.724993651000204,
      },
      {
        lon: 106.6937514180002,
        lat: 10.724573642000166,
      },
      {
        lon: 106.69474493300021,
        lat: 10.724914005000358,
      },
      {
        lon: 106.69796645600024,
        lat: 10.723689591000323,
      },
      {
        lon: 106.69909183000027,
        lat: 10.723709868000299,
      },
      {
        lon: 106.7033310510003,
        lat: 10.724778210000238,
      },
      {
        lon: 106.70446788000027,
        lat: 10.724915331000318,
      },
      {
        lon: 106.70562354500026,
        lat: 10.72472354100023,
      },
      {
        lon: 106.70640334100028,
        lat: 10.724362294000287,
      },
      {
        lon: 106.70690879500034,
        lat: 10.72371013700024,
      },
      {
        lon: 106.70709339200027,
        lat: 10.722746009000218,
      },
      {
        lon: 106.70711972906986,
        lat: 10.720588599911878,
      },
      {
        lon: 106.70781417100034,
        lat: 10.71948773600036,
      },
      {
        lon: 106.70944106500025,
        lat: 10.719077907000099,
      },
      {
        lon: 106.71111051900021,
        lat: 10.720087182000213,
      },
      {
        lon: 106.71293095700037,
        lat: 10.719681593000132,
      },
      {
        lon: 106.71716434700033,
        lat: 10.71852315700022,
      },
      {
        lon: 106.71791278100028,
        lat: 10.718501109000158,
      },
      {
        lon: 106.7188438730002,
        lat: 10.718907624000337,
      },
      {
        lon: 106.71959720208429,
        lat: 10.719623933869684,
      },
      {
        lon: 106.72018755200031,
        lat: 10.721148593,
      },
      {
        lon: 106.72115275600021,
        lat: 10.721736675000216,
      },
      {
        lon: 106.72329368100031,
        lat: 10.72110726700015,
      },
      {
        lon: 106.7242445180002,
        lat: 10.720319725000195,
      },
      {
        lon: 106.72418963200033,
        lat: 10.718820323000102,
      },
      {
        lon: 106.72340090332682,
        lat: 10.718043893849654,
      },
      {
        lon: 106.72280170500034,
        lat: 10.717104187000245,
      },
      {
        lon: 106.72232908900031,
        lat: 10.715629149000222,
      },
      {
        lon: 106.72275077000029,
        lat: 10.713992243000348,
      },
      {
        lon: 106.72347930000035,
        lat: 10.712725821000333,
      },
      {
        lon: 106.72442915400023,
        lat: 10.711686623000332,
      },
      {
        lon: 106.72484434700021,
        lat: 10.710939976000304,
      },
      {
        lon: 106.72509938000024,
        lat: 10.70940194000002,
      },
      {
        lon: 106.72395250600022,
        lat: 10.705766584000344,
      },
      {
        lon: 106.72431900800035,
        lat: 10.704354089000276,
      },
      {
        lon: 106.72533380900023,
        lat: 10.702225697000188,
      },
      {
        lon: 106.72545572800027,
        lat: 10.70145785300025,
      },
      {
        lon: 106.72529085900021,
        lat: 10.70011540500019,
      },
      {
        lon: 106.72390497600037,
        lat: 10.698627025000349,
      },
      {
        lon: 106.72279137400028,
        lat: 10.697793890000241,
      },
      {
        lon: 106.72246601100022,
        lat: 10.696747712,
      },
      {
        lon: 106.7228200090002,
        lat: 10.695756120000226,
      },
      {
        lon: 106.72429139200023,
        lat: 10.694648089000282,
      },
      {
        lon: 106.72633995300032,
        lat: 10.69369019200036,
      },
      {
        lon: 106.72658281900033,
        lat: 10.69325665900027,
      },
      {
        lon: 106.7266529070003,
        lat: 10.691045652000184,
      },
      {
        lon: 106.72713360400036,
        lat: 10.690188725999974,
      },
      {
        lon: 106.7270371750003,
        lat: 10.687869079000166,
      },
      {
        lon: 106.72733557100025,
        lat: 10.686945969000192,
      },
      {
        lon: 106.72830744500021,
        lat: 10.685385319000204,
      },
      {
        lon: 106.7282022140003,
        lat: 10.685073529000135,
      },
      {
        lon: 106.7266864040003,
        lat: 10.68340972000027,
      },
      {
        lon: 106.72563987100028,
        lat: 10.681453710000255,
      },
      {
        lon: 106.72707288149655,
        lat: 10.680866842990838,
      },
    ],
    [
      {
        lon: 106.69164183200041,
        lat: 10.751812161000316,
      },
      {
        lon: 106.69449178245861,
        lat: 10.75191071597925,
      },
      {
        lon: 106.69650316857212,
        lat: 10.751740041413598,
      },
      {
        lon: 106.7059656750003,
        lat: 10.751916396000524,
      },
      {
        lon: 106.71126740900041,
        lat: 10.751884478000534,
      },
      {
        lon: 106.71644050900034,
        lat: 10.75194488700049,
      },
      {
        lon: 106.71796418800034,
        lat: 10.75223413100025,
      },
      {
        lon: 106.71935638800045,
        lat: 10.753090213000405,
      },
      {
        lon: 106.72053604000035,
        lat: 10.754505463000044,
      },
      {
        lon: 106.72192484700042,
        lat: 10.75763911800027,
      },
      {
        lon: 106.72093116200034,
        lat: 10.757706670000516,
      },
      {
        lon: 106.72002592100034,
        lat: 10.755670613000348,
      },
      {
        lon: 106.71826836000048,
        lat: 10.753639980000287,
      },
      {
        lon: 106.71593542600039,
        lat: 10.753045119000376,
      },
      {
        lon: 106.71339010400061,
        lat: 10.752810431000512,
      },
      {
        lon: 106.71116807900034,
        lat: 10.752980110000408,
      },
      {
        lon: 106.70002371000027,
        lat: 10.752845585000387,
      },
      {
        lon: 106.6945409700005,
        lat: 10.75275451600021,
      },
      {
        lon: 106.68977849000044,
        lat: 10.752892124000596,
      },
      {
        lon: 106.68916040899506,
        lat: 10.754834589602865,
      },
      {
        lon: 106.68928614200036,
        lat: 10.755215134000423,
      },
      {
        lon: 106.69114779700067,
        lat: 10.756851921000589,
      },
      {
        lon: 106.69339780900032,
        lat: 10.758363637000059,
      },
      {
        lon: 106.69474442200044,
        lat: 10.759125793000237,
      },
      {
        lon: 106.69675093400036,
        lat: 10.76094723600022,
      },
      {
        lon: 106.69826407400035,
        lat: 10.763105468000276,
      },
      {
        lon: 106.69930946800031,
        lat: 10.76385012600025,
      },
      {
        lon: 106.70080040500034,
        lat: 10.764592846000255,
      },
      {
        lon: 106.7020851190004,
        lat: 10.765956147000338,
      },
      {
        lon: 106.70259862100045,
        lat: 10.76678109000028,
      },
      {
        lon: 106.70378148700041,
        lat: 10.767807922000372,
      },
      {
        lon: 106.70524582600035,
        lat: 10.76840285000037,
      },
      {
        lon: 106.70698177000043,
        lat: 10.768746447000296,
      },
      {
        lon: 106.70647387500043,
        lat: 10.769691394000404,
      },
      {
        lon: 106.7035387540003,
        lat: 10.768383374000337,
      },
      {
        lon: 106.70175714600038,
        lat: 10.766750447000334,
      },
      {
        lon: 106.70109940600048,
        lat: 10.765776087000347,
      },
      {
        lon: 106.69993340009444,
        lat: 10.764792240242514,
      },
      {
        lon: 106.69909894341998,
        lat: 10.764380558167773,
      },
      {
        lon: 106.69775954300042,
        lat: 10.763291688000436,
      },
      {
        lon: 106.69709473700033,
        lat: 10.762283922000336,
      },
      {
        lon: 106.69582195200041,
        lat: 10.760797744000286,
      },
      {
        lon: 106.69463212307596,
        lat: 10.759634399714685,
      },
      {
        lon: 106.6917414710004,
        lat: 10.757939723000334,
      },
      {
        lon: 106.68864754700041,
        lat: 10.755386952000435,
      },
      {
        lon: 106.68678412933133,
        lat: 10.753579388668527,
      },
      {
        lon: 106.68592547700041,
        lat: 10.752036881000265,
      },
      {
        lon: 106.68427841500039,
        lat: 10.75146018900034,
      },
      {
        lon: 106.68132150000042,
        lat: 10.749564471000326,
      },
      {
        lon: 106.67955519500038,
        lat: 10.748683610000398,
      },
      {
        lon: 106.67514433500048,
        lat: 10.746326018000332,
      },
      {
        lon: 106.6712750820003,
        lat: 10.745062809000387,
      },
      {
        lon: 106.66998539900027,
        lat: 10.744872695000254,
      },
      {
        lon: 106.66495364100035,
        lat: 10.74387194200051,
      },
      {
        lon: 106.65850673300037,
        lat: 10.742706306000343,
      },
      {
        lon: 106.65699990000064,
        lat: 10.742367025000249,
      },
      {
        lon: 106.65474870600053,
        lat: 10.741535220000287,
      },
      {
        lon: 106.65254929600047,
        lat: 10.740429014000199,
      },
      {
        lon: 106.65193125731287,
        lat: 10.739994374588093,
      },
      {
        lon: 106.64550047300031,
        lat: 10.736069958000314,
      },
      {
        lon: 106.64123375900044,
        lat: 10.733532818000356,
      },
      {
        lon: 106.63863670400042,
        lat: 10.731469339000057,
      },
      {
        lon: 106.63660411600046,
        lat: 10.728613269000391,
      },
      {
        lon: 106.63560351700033,
        lat: 10.726616401000342,
      },
      {
        lon: 106.63331606500043,
        lat: 10.721637810000288,
      },
      {
        lon: 106.63136493800039,
        lat: 10.717731858000377,
      },
      {
        lon: 106.62952224300045,
        lat: 10.715058090000298,
      },
      {
        lon: 106.62825783500045,
        lat: 10.71386165600029,
      },
      {
        lon: 106.62699280700042,
        lat: 10.713032346000375,
      },
      {
        lon: 106.62533855600037,
        lat: 10.712169910000284,
      },
      {
        lon: 106.62473640600047,
        lat: 10.712129801000401,
      },
      {
        lon: 106.62561856865398,
        lat: 10.714204553152172,
      },
      {
        lon: 106.63466659200071,
        lat: 10.732993501000408,
      },
      {
        lon: 106.63439600166782,
        lat: 10.734355876468669,
      },
      {
        lon: 106.63499771500051,
        lat: 10.736063196000487,
      },
      {
        lon: 106.63471108900058,
        lat: 10.737068896000551,
      },
      {
        lon: 106.63403295800032,
        lat: 10.738484861000188,
      },
      {
        lon: 106.63400446000048,
        lat: 10.739236870000354,
      },
      {
        lon: 106.63463299600045,
        lat: 10.741102384000328,
      },
      {
        lon: 106.63518098000048,
        lat: 10.741713851000384,
      },
      {
        lon: 106.63664257230243,
        lat: 10.742678494783547,
      },
      {
        lon: 106.63852303900052,
        lat: 10.744102600000588,
      },
      {
        lon: 106.63971074700055,
        lat: 10.745180474000051,
      },
      {
        lon: 106.64010590500055,
        lat: 10.745900393000568,
      },
      {
        lon: 106.64001226000056,
        lat: 10.748814793000463,
      },
      {
        lon: 106.63979854800061,
        lat: 10.749580721000427,
      },
      {
        lon: 106.63837989500058,
        lat: 10.751921239000467,
      },
      {
        lon: 106.63851139400055,
        lat: 10.752382169000043,
      },
      {
        lon: 106.63676404500063,
        lat: 10.754192296000156,
      },
      {
        lon: 106.63683956200055,
        lat: 10.755068341000253,
      },
      {
        lon: 106.63539206600052,
        lat: 10.755348414000423,
      },
      {
        lon: 106.63516467900064,
        lat: 10.755704923000167,
      },
      {
        lon: 106.63443306000056,
        lat: 10.755810445000577,
      },
      {
        lon: 106.63356218100057,
        lat: 10.756344856000453,
      },
      {
        lon: 106.63329350200064,
        lat: 10.757078432000583,
      },
      {
        lon: 106.6336926100006,
        lat: 10.75825771900054,
      },
      {
        lon: 106.63510874400055,
        lat: 10.759910889000537,
      },
      {
        lon: 106.63570994000071,
        lat: 10.76252115400024,
      },
      {
        lon: 106.63571138200047,
        lat: 10.76568171300056,
      },
      {
        lon: 106.63562419300064,
        lat: 10.769648388000576,
      },
      {
        lon: 106.63549738104359,
        lat: 10.769620739464472,
      },
      {
        lon: 106.63560943236524,
        lat: 10.765739172315023,
      },
      {
        lon: 106.63548883063504,
        lat: 10.764929527418303,
      },
      {
        lon: 106.6355869336349,
        lat: 10.762596849418173,
      },
      {
        lon: 106.63495943663497,
        lat: 10.759925060418198,
      },
      {
        lon: 106.63357185063506,
        lat: 10.758287815418157,
      },
      {
        lon: 106.63315375063496,
        lat: 10.757094093418175,
      },
      {
        lon: 106.63346417763495,
        lat: 10.7562595054182,
      },
      {
        lon: 106.63436409363499,
        lat: 10.755711393418323,
      },
      {
        lon: 106.63488459163506,
        lat: 10.755700465418329,
      },
      {
        lon: 106.63556916663498,
        lat: 10.755129636418246,
      },
      {
        lon: 106.63664501863497,
        lat: 10.755038605418292,
      },
      {
        lon: 106.63680210963508,
        lat: 10.753795036418126,
      },
      {
        lon: 106.6384024406351,
        lat: 10.752369524418327,
      },
      {
        lon: 106.6382940416351,
        lat: 10.751871963418296,
      },
      {
        lon: 106.639371552635,
        lat: 10.750165173418281,
      },
      {
        lon: 106.639901215635,
        lat: 10.74841564641838,
      },
      {
        lon: 106.639976095635,
        lat: 10.74640111741843,
      },
      {
        lon: 106.63984251063505,
        lat: 10.745712574418178,
      },
      {
        lon: 106.63925754225693,
        lat: 10.744964010469573,
      },
      {
        lon: 106.63813660015018,
        lat: 10.743981069903327,
      },
      {
        lon: 106.63616542700049,
        lat: 10.742717523000465,
      },
      {
        lon: 106.63427687100034,
        lat: 10.741281024000386,
      },
      {
        lon: 106.6338158800005,
        lat: 10.740167720000443,
      },
      {
        lon: 106.63367574500052,
        lat: 10.738710120000462,
      },
      {
        lon: 106.6338018610005,
        lat: 10.73787875000022,
      },
      {
        lon: 106.63456951600051,
        lat: 10.736159619000546,
      },
      {
        lon: 106.63400371300048,
        lat: 10.734639735000371,
      },
      {
        lon: 106.63397441400048,
        lat: 10.732740691000458,
      },
      {
        lon: 106.6327682690004,
        lat: 10.730308315000457,
      },
      {
        lon: 106.63126835600042,
        lat: 10.726979411000173,
      },
      {
        lon: 106.62996597100039,
        lat: 10.724514368000428,
      },
      {
        lon: 106.62918479900037,
        lat: 10.722550005000528,
      },
      {
        lon: 106.62792319900046,
        lat: 10.720143096000413,
      },
      {
        lon: 106.62525117700051,
        lat: 10.71451690500038,
      },
      {
        lon: 106.62419064700038,
        lat: 10.711852811000426,
      },
      {
        lon: 106.62355300000047,
        lat: 10.711417187000052,
      },
      {
        lon: 106.6206865520004,
        lat: 10.71096450600038,
      },
      {
        lon: 106.6199178000004,
        lat: 10.710696854000048,
      },
      {
        lon: 106.6171512200005,
        lat: 10.710198420000381,
      },
      {
        lon: 106.61603450800044,
        lat: 10.709680172000503,
      },
      {
        lon: 106.61431314200047,
        lat: 10.708585953000465,
      },
      {
        lon: 106.61290563500063,
        lat: 10.707873498000595,
      },
      {
        lon: 106.60953813600048,
        lat: 10.70692653700047,
      },
      {
        lon: 106.60795347900051,
        lat: 10.70638546100065,
      },
      {
        lon: 106.60664345000038,
        lat: 10.705604584000241,
      },
      {
        lon: 106.6048250760004,
        lat: 10.704814815000532,
      },
      {
        lon: 106.60124653200046,
        lat: 10.703866972000355,
      },
      {
        lon: 106.59994902100044,
        lat: 10.70331570100052,
      },
      {
        lon: 106.59807649400068,
        lat: 10.702254417000631,
      },
      {
        lon: 106.59651028091542,
        lat: 10.701661673475257,
      },
      {
        lon: 106.59553605000048,
        lat: 10.700907343000381,
      },
      {
        lon: 106.59197709809285,
        lat: 10.699421258695509,
      },
      {
        lon: 106.58453459200041,
        lat: 10.696084719000112,
      },
      {
        lon: 106.58348194600052,
        lat: 10.695676742000387,
      },
      {
        lon: 106.58206702600052,
        lat: 10.695449140000392,
      },
      {
        lon: 106.58004658100043,
        lat: 10.694900731000416,
      },
      {
        lon: 106.57783197300051,
        lat: 10.69415630400016,
      },
      {
        lon: 106.57586513100046,
        lat: 10.69332843900014,
      },
      {
        lon: 106.57368272800045,
        lat: 10.692942835000395,
      },
      {
        lon: 106.57066674600048,
        lat: 10.692180555000391,
      },
      {
        lon: 106.56895459000043,
        lat: 10.691325537000523,
      },
      {
        lon: 106.56730375649175,
        lat: 10.690714581362442,
      },
      {
        lon: 106.56596913700037,
        lat: 10.690052390000062,
      },
      {
        lon: 106.56297025100042,
        lat: 10.689747168000395,
      },
      {
        lon: 106.55997319800053,
        lat: 10.688786275000382,
      },
      {
        lon: 106.55766013400046,
        lat: 10.688731729000494,
      },
      {
        lon: 106.55491927000048,
        lat: 10.688301916,
      },
      {
        lon: 106.55188193500044,
        lat: 10.687936639999975,
      },
      {
        lon: 106.55041195100068,
        lat: 10.687576500000375,
      },
      {
        lon: 106.54787405700051,
        lat: 10.687455015000182,
      },
      {
        lon: 106.54761466600041,
        lat: 10.687079135000545,
      },
      {
        lon: 106.55038650578854,
        lat: 10.68732679549015,
      },
      {
        lon: 106.55249163800039,
        lat: 10.687789381000698,
      },
      {
        lon: 106.5543450920004,
        lat: 10.687943354000367,
      },
      {
        lon: 106.55813676500054,
        lat: 10.688471957000502,
      },
      {
        lon: 106.55944445500035,
        lat: 10.68842601200032,
      },
      {
        lon: 106.56238313200043,
        lat: 10.689198924000323,
      },
      {
        lon: 106.56428606900039,
        lat: 10.689468853000562,
      },
      {
        lon: 106.56491343600032,
        lat: 10.689403645000482,
      },
      {
        lon: 106.56659215300044,
        lat: 10.689866113000392,
      },
      {
        lon: 106.56781417500048,
        lat: 10.690567671000508,
      },
      {
        lon: 106.56876358500051,
        lat: 10.690848147000452,
      },
      {
        lon: 106.57120107000048,
        lat: 10.691928275000464,
      },
      {
        lon: 106.57602999800781,
        lat: 10.69298667369111,
      },
      {
        lon: 106.57781067092037,
        lat: 10.693665170253723,
      },
      {
        lon: 106.5813551114041,
        lat: 10.694692100292116,
      },
      {
        lon: 106.58339604349817,
        lat: 10.695003058983843,
      },
      {
        lon: 106.58450155000048,
        lat: 10.695361923000348,
      },
      {
        lon: 106.58633228800045,
        lat: 10.696221994000382,
      },
      {
        lon: 106.58893807772972,
        lat: 10.697303436717618,
      },
      {
        lon: 106.59196732785864,
        lat: 10.698663053667303,
      },
      {
        lon: 106.5951921570005,
        lat: 10.699956481000186,
      },
      {
        lon: 106.5972143820004,
        lat: 10.701140197000427,
      },
      {
        lon: 106.59809474800045,
        lat: 10.70135670200068,
      },
      {
        lon: 106.60007892375228,
        lat: 10.702356351778235,
      },
      {
        lon: 106.60105525500052,
        lat: 10.7029825730001,
      },
      {
        lon: 106.6050036310005,
        lat: 10.704015813000524,
      },
      {
        lon: 106.60680552700057,
        lat: 10.704880044000333,
      },
      {
        lon: 106.60943611700043,
        lat: 10.705973851000522,
      },
      {
        lon: 106.61073427000048,
        lat: 10.706376658000352,
      },
      {
        lon: 106.61246391200041,
        lat: 10.706689659000403,
      },
      {
        lon: 106.6137570230004,
        lat: 10.707164800000385,
      },
      {
        lon: 106.61599603100052,
        lat: 10.708636888000683,
      },
      {
        lon: 106.61769235800045,
        lat: 10.70933972500029,
      },
      {
        lon: 106.61921834743964,
        lat: 10.709602347208545,
      },
      {
        lon: 106.61969715200047,
        lat: 10.709943489000523,
      },
      {
        lon: 106.62171712500049,
        lat: 10.710527853000372,
      },
      {
        lon: 106.62383464300041,
        lat: 10.71079358100053,
      },
      {
        lon: 106.62621281000042,
        lat: 10.711765050000338,
      },
      {
        lon: 106.62834843600041,
        lat: 10.713026441000352,
      },
      {
        lon: 106.63068026000042,
        lat: 10.71524386200042,
      },
      {
        lon: 106.63181978300048,
        lat: 10.716765777000319,
      },
      {
        lon: 106.63322387100027,
        lat: 10.719644724000375,
      },
      {
        lon: 106.63572790500035,
        lat: 10.724969346000421,
      },
      {
        lon: 106.63793587700059,
        lat: 10.729225458000315,
      },
      {
        lon: 106.63905046100047,
        lat: 10.730646969000418,
      },
      {
        lon: 106.64272477500037,
        lat: 10.73325897400025,
      },
      {
        lon: 106.64698807700029,
        lat: 10.73575559200026,
      },
      {
        lon: 106.65157141300028,
        lat: 10.738833254000326,
      },
      {
        lon: 106.65395022700028,
        lat: 10.74036042500012,
      },
      {
        lon: 106.65621606500054,
        lat: 10.741424627000242,
      },
      {
        lon: 106.65823751000039,
        lat: 10.742015137000294,
      },
      {
        lon: 106.66139250235682,
        lat: 10.742442033434202,
      },
      {
        lon: 106.67191299200033,
        lat: 10.74433922600008,
      },
      {
        lon: 106.67385205700032,
        lat: 10.744881629000474,
      },
      {
        lon: 106.67660715500051,
        lat: 10.746057895000263,
      },
      {
        lon: 106.68004973000052,
        lat: 10.747941980000292,
      },
      {
        lon: 106.68230316300037,
        lat: 10.749281908000512,
      },
      {
        lon: 106.6844132260004,
        lat: 10.750382278000416,
      },
      {
        lon: 106.6871706920005,
        lat: 10.751341933000504,
      },
      {
        lon: 106.68966102143827,
        lat: 10.751397222203764,
      },
      {
        lon: 106.69164183200041,
        lat: 10.751812161000316,
      },
    ],
    [
      {
        lon: 106.71956648800023,
        lat: 10.679884053000308,
      },
      {
        lon: 106.71950062700023,
        lat: 10.679237901000306,
      },
      {
        lon: 106.71702093300019,
        lat: 10.679235267000251,
      },
      {
        lon: 106.71523644800027,
        lat: 10.678610015000231,
      },
      {
        lon: 106.7117103950003,
        lat: 10.676392107000197,
      },
      {
        lon: 106.71024285000027,
        lat: 10.676355579000187,
      },
      {
        lon: 106.70927479900035,
        lat: 10.675698387000352,
      },
      {
        lon: 106.70562420900029,
        lat: 10.675021296000011,
      },
      {
        lon: 106.70429199300037,
        lat: 10.674503263000247,
      },
      {
        lon: 106.70329960800024,
        lat: 10.673329030000332,
      },
      {
        lon: 106.7015258500004,
        lat: 10.674038862000259,
      },
      {
        lon: 106.70005713593304,
        lat: 10.673765929441231,
      },
      {
        lon: 106.69794049000024,
        lat: 10.672758474000204,
      },
      {
        lon: 106.69481648700031,
        lat: 10.671728768000264,
      },
      {
        lon: 106.69240149100028,
        lat: 10.67375486500032,
      },
      {
        lon: 106.69104216239668,
        lat: 10.67432896528959,
      },
      {
        lon: 106.68651714600026,
        lat: 10.674533340000323,
      },
      {
        lon: 106.68335279100035,
        lat: 10.675533075000203,
      },
      {
        lon: 106.68203020100032,
        lat: 10.675018747000252,
      },
      {
        lon: 106.68217209600027,
        lat: 10.678418090000264,
      },
      {
        lon: 106.6801865780003,
        lat: 10.68237083500037,
      },
      {
        lon: 106.67709491200031,
        lat: 10.685104490000299,
      },
      {
        lon: 106.67642260600024,
        lat: 10.686748924000312,
      },
      {
        lon: 106.67599572889895,
        lat: 10.689595259372311,
      },
      {
        lon: 106.67415985300016,
        lat: 10.691983960000186,
      },
      {
        lon: 106.67176278500028,
        lat: 10.693264127000361,
      },
      {
        lon: 106.6694424120002,
        lat: 10.695924017000323,
      },
      {
        lon: 106.66917764300024,
        lat: 10.695467515000246,
      },
      {
        lon: 106.67148149705446,
        lat: 10.692816017338771,
      },
      {
        lon: 106.67438053200021,
        lat: 10.69023906300015,
      },
      {
        lon: 106.67506069000018,
        lat: 10.689117169000042,
      },
      {
        lon: 106.6754539380002,
        lat: 10.685752154000284,
      },
      {
        lon: 106.67688017900036,
        lat: 10.683633371000212,
      },
      {
        lon: 106.67897648300027,
        lat: 10.68160559700027,
      },
      {
        lon: 106.68008827300025,
        lat: 10.679945362000296,
      },
      {
        lon: 106.68060613500029,
        lat: 10.676858151000204,
      },
      {
        lon: 106.67860039400034,
        lat: 10.67332172900035,
      },
      {
        lon: 106.67772110200025,
        lat: 10.6710171000002,
      },
      {
        lon: 106.67742138000028,
        lat: 10.669120578000104,
      },
      {
        lon: 106.67801731600025,
        lat: 10.663939182000206,
      },
      {
        lon: 106.68010719100022,
        lat: 10.661820245000342,
      },
      {
        lon: 106.68339227600035,
        lat: 10.660828880000281,
      },
      {
        lon: 106.68419791700029,
        lat: 10.65989302200029,
      },
      {
        lon: 106.68430702800015,
        lat: 10.658427011000242,
      },
      {
        lon: 106.68516616100027,
        lat: 10.655231510000249,
      },
      {
        lon: 106.6853261531391,
        lat: 10.653387377305364,
      },
      {
        lon: 106.68427038000033,
        lat: 10.65103439700033,
      },
      {
        lon: 106.68070434700024,
        lat: 10.648355816000333,
      },
      {
        lon: 106.67886885200033,
        lat: 10.646419096000272,
      },
      {
        lon: 106.67776479900022,
        lat: 10.64586346800013,
      },
      {
        lon: 106.67463361600022,
        lat: 10.645585249000305,
      },
      {
        lon: 106.6729071480002,
        lat: 10.644298023000204,
      },
      {
        lon: 106.67239789600035,
        lat: 10.642819226000142,
      },
      {
        lon: 106.67099864200037,
        lat: 10.642093338000336,
      },
      {
        lon: 106.66964756600032,
        lat: 10.640104762000249,
      },
      {
        lon: 106.66820781200022,
        lat: 10.639895699000476,
      },
      {
        lon: 106.66660820800035,
        lat: 10.64167797100049,
      },
      {
        lon: 106.66424081000025,
        lat: 10.645156815000405,
      },
      {
        lon: 106.65952951443717,
        lat: 10.649926742265848,
      },
      {
        lon: 106.65638967500036,
        lat: 10.650783530000467,
      },
      {
        lon: 106.65362590300026,
        lat: 10.650737803000311,
      },
      {
        lon: 106.65047346900019,
        lat: 10.65109985800052,
      },
      {
        lon: 106.64729521600026,
        lat: 10.65202434700035,
      },
      {
        lon: 106.64668668300027,
        lat: 10.653274033000173,
      },
      {
        lon: 106.64645866000046,
        lat: 10.655640888000164,
      },
      {
        lon: 106.64515727200029,
        lat: 10.657585085000221,
      },
      {
        lon: 106.64236414100037,
        lat: 10.659173649000195,
      },
      {
        lon: 106.63989262800021,
        lat: 10.660021143000336,
      },
      {
        lon: 106.63878536036782,
        lat: 10.662590350863127,
      },
      {
        lon: 106.6399254020003,
        lat: 10.665017875000387,
      },
      {
        lon: 106.63947645700034,
        lat: 10.667851633000396,
      },
      {
        lon: 106.63788558710888,
        lat: 10.669797738884483,
      },
      {
        lon: 106.63643594600023,
        lat: 10.677053622000301,
      },
      {
        lon: 106.63569099600022,
        lat: 10.678621723000106,
      },
      {
        lon: 106.63389412400022,
        lat: 10.680909532000328,
      },
      {
        lon: 106.6308065620002,
        lat: 10.68238448600033,
      },
      {
        lon: 106.62913657900032,
        lat: 10.683715272000313,
      },
      {
        lon: 106.62894545100033,
        lat: 10.68493155400023,
      },
      {
        lon: 106.62922727507885,
        lat: 10.68837949331953,
      },
      {
        lon: 106.62887694600022,
        lat: 10.689553483000282,
      },
      {
        lon: 106.62777349667152,
        lat: 10.69064905199565,
      },
      {
        lon: 106.62591421100035,
        lat: 10.691306491000205,
      },
      {
        lon: 106.62483289900018,
        lat: 10.692132992000268,
      },
      {
        lon: 106.62232946100022,
        lat: 10.696670024000355,
      },
      {
        lon: 106.62210084000037,
        lat: 10.698747617000208,
      },
      {
        lon: 106.62070290200052,
        lat: 10.700288499000123,
      },
      {
        lon: 106.61894756600026,
        lat: 10.701473446000023,
      },
      {
        lon: 106.61840875100035,
        lat: 10.703587178000305,
      },
      {
        lon: 106.61909631100036,
        lat: 10.709311918000253,
      },
      {
        lon: 106.61821833500039,
        lat: 10.708707554000512,
      },
      {
        lon: 106.61753241500031,
        lat: 10.702999912000223,
      },
      {
        lon: 106.61838745300037,
        lat: 10.700820442000179,
      },
      {
        lon: 106.62053575200036,
        lat: 10.699356826000042,
      },
      {
        lon: 106.6213855407114,
        lat: 10.698096921502156,
      },
      {
        lon: 106.62144890900015,
        lat: 10.696898588000238,
      },
      {
        lon: 106.62329415000033,
        lat: 10.692779613000027,
      },
      {
        lon: 106.62460820600035,
        lat: 10.691090650000206,
      },
      {
        lon: 106.62794930800021,
        lat: 10.68916154200025,
      },
      {
        lon: 106.62819545000036,
        lat: 10.687977157000262,
      },
      {
        lon: 106.62780235300022,
        lat: 10.685199067000042,
      },
      {
        lon: 106.62799964800047,
        lat: 10.684005338000304,
      },
      {
        lon: 106.62924458400028,
        lat: 10.681959970000207,
      },
      {
        lon: 106.63174986600029,
        lat: 10.680449579000197,
      },
      {
        lon: 106.63439728600031,
        lat: 10.677998702000195,
      },
      {
        lon: 106.63564539600037,
        lat: 10.675972155000295,
      },
      {
        lon: 106.63705317800031,
        lat: 10.6691861500002,
      },
      {
        lon: 106.63831552261013,
        lat: 10.667469586427899,
      },
      {
        lon: 106.63772277900017,
        lat: 10.663158414000293,
      },
      {
        lon: 106.63879255100022,
        lat: 10.65976254900022,
      },
      {
        lon: 106.6398866890002,
        lat: 10.658966774000191,
      },
      {
        lon: 106.64320213400032,
        lat: 10.657583461000229,
      },
      {
        lon: 106.64489377700022,
        lat: 10.656201421000276,
      },
      {
        lon: 106.64502927400031,
        lat: 10.653891180000358,
      },
      {
        lon: 106.64550138700025,
        lat: 10.65199299100027,
      },
      {
        lon: 106.64768582500034,
        lat: 10.650197607000223,
      },
      {
        lon: 106.65335248000031,
        lat: 10.649442707000276,
      },
      {
        lon: 106.6566565770002,
        lat: 10.649544452000441,
      },
      {
        lon: 106.65878217800031,
        lat: 10.649003177000509,
      },
      {
        lon: 106.66299828100017,
        lat: 10.64462603800024,
      },
      {
        lon: 106.66634162600036,
        lat: 10.63983731999997,
      },
      {
        lon: 106.66672140300022,
        lat: 10.6404207140003,
      },
      {
        lon: 106.66845216400037,
        lat: 10.639134638000314,
      },
      {
        lon: 106.66974452500023,
        lat: 10.639332941000248,
      },
      {
        lon: 106.67132950100029,
        lat: 10.641143964000264,
      },
      {
        lon: 106.67319730900027,
        lat: 10.6421728600003,
      },
      {
        lon: 106.67324396100025,
        lat: 10.643098632000203,
      },
      {
        lon: 106.67428597300034,
        lat: 10.644296351000264,
      },
      {
        lon: 106.67623878300026,
        lat: 10.644995857000197,
      },
      {
        lon: 106.67886885400031,
        lat: 10.645419566000214,
      },
      {
        lon: 106.68190777500035,
        lat: 10.648359446000256,
      },
      {
        lon: 106.68403879600015,
        lat: 10.64978252800023,
      },
      {
        lon: 106.6852606120002,
        lat: 10.65107346900021,
      },
      {
        lon: 106.68600452500021,
        lat: 10.653615432000265,
      },
      {
        lon: 106.68570632700022,
        lat: 10.656523201000255,
      },
      {
        lon: 106.68755473400029,
        lat: 10.655725574000257,
      },
      {
        lon: 106.68900353900034,
        lat: 10.652817857000286,
      },
      {
        lon: 106.69025414500027,
        lat: 10.651170948000356,
      },
      {
        lon: 106.69086440100034,
        lat: 10.648843752000346,
      },
      {
        lon: 106.69168640300029,
        lat: 10.648457585000301,
      },
      {
        lon: 106.69647997000033,
        lat: 10.65002474000022,
      },
      {
        lon: 106.69742726800018,
        lat: 10.651767865000295,
      },
      {
        lon: 106.69970215900025,
        lat: 10.653559790000315,
      },
      {
        lon: 106.70074473100033,
        lat: 10.653955117000237,
      },
      {
        lon: 106.7019694300003,
        lat: 10.653283607000276,
      },
      {
        lon: 106.70424878900036,
        lat: 10.652853154000297,
      },
      {
        lon: 106.70525573100036,
        lat: 10.651001346000157,
      },
      {
        lon: 106.70884996800032,
        lat: 10.649793161000332,
      },
      {
        lon: 106.71115254100033,
        lat: 10.650430607000317,
      },
      {
        lon: 106.7127302750003,
        lat: 10.650063080000336,
      },
      {
        lon: 106.7163072740002,
        lat: 10.647764956000287,
      },
      {
        lon: 106.7178515360003,
        lat: 10.646287233000294,
      },
      {
        lon: 106.71956259200027,
        lat: 10.64550476900024,
      },
      {
        lon: 106.72117905100015,
        lat: 10.645923364000112,
      },
      {
        lon: 106.72274422900034,
        lat: 10.645538759000203,
      },
      {
        lon: 106.72324827000023,
        lat: 10.644671306000077,
      },
      {
        lon: 106.72339316200032,
        lat: 10.641936553000221,
      },
      {
        lon: 106.72579290900035,
        lat: 10.638404838000326,
      },
      {
        lon: 106.73010117300032,
        lat: 10.63624952500021,
      },
      {
        lon: 106.73142857000028,
        lat: 10.634322164000196,
      },
      {
        lon: 106.73177338800036,
        lat: 10.633158402000163,
      },
      {
        lon: 106.7329314720003,
        lat: 10.633609779000324,
      },
      {
        lon: 106.73357420000036,
        lat: 10.636085010000215,
      },
      {
        lon: 106.73483562500036,
        lat: 10.636892628000226,
      },
      {
        lon: 106.73633432200029,
        lat: 10.639167665000286,
      },
      {
        lon: 106.73778919500029,
        lat: 10.639768819000267,
      },
      {
        lon: 106.74156299200035,
        lat: 10.640545385000223,
      },
      {
        lon: 106.74685504100029,
        lat: 10.644824703000268,
      },
      {
        lon: 106.74501423600026,
        lat: 10.645677132000174,
      },
      {
        lon: 106.7418166570003,
        lat: 10.64248645000032,
      },
      {
        lon: 106.74014191200035,
        lat: 10.641424790000258,
      },
      {
        lon: 106.73661211400037,
        lat: 10.64065734000036,
      },
      {
        lon: 106.73563119800036,
        lat: 10.640127251000251,
      },
      {
        lon: 106.73406479000028,
        lat: 10.638020261000179,
      },
      {
        lon: 106.73280245100034,
        lat: 10.636963901000172,
      },
      {
        lon: 106.73180731900027,
        lat: 10.635016914000289,
      },
      {
        lon: 106.7296857410002,
        lat: 10.637144219000163,
      },
      {
        lon: 106.72614202500029,
        lat: 10.63881574200036,
      },
      {
        lon: 106.72539066300027,
        lat: 10.64071383300029,
      },
      {
        lon: 106.72414191000024,
        lat: 10.641649984000026,
      },
      {
        lon: 106.72376914800031,
        lat: 10.64481999900022,
      },
      {
        lon: 106.72286897700036,
        lat: 10.646268553000198,
      },
      {
        lon: 106.72178232500033,
        lat: 10.646796223000308,
      },
      {
        lon: 106.71925048000037,
        lat: 10.646066030000213,
      },
      {
        lon: 106.71658265200028,
        lat: 10.648393660000238,
      },
      {
        lon: 106.7132330630003,
        lat: 10.650432941000304,
      },
      {
        lon: 106.71122936600034,
        lat: 10.650987633,
      },
      {
        lon: 106.70888465400031,
        lat: 10.65032767200023,
      },
      {
        lon: 106.70784550600035,
        lat: 10.6504005660002,
      },
      {
        lon: 106.70560296300033,
        lat: 10.651379436000184,
      },
      {
        lon: 106.70471258100036,
        lat: 10.653284286000257,
      },
      {
        lon: 106.70232915200023,
        lat: 10.653704443000265,
      },
      {
        lon: 106.70101238800021,
        lat: 10.654587351000316,
      },
      {
        lon: 106.69970216800033,
        lat: 10.654280075000315,
      },
      {
        lon: 106.6971748820003,
        lat: 10.652229393000255,
      },
      {
        lon: 106.69594107600034,
        lat: 10.650188517000345,
      },
      {
        lon: 106.69177426400017,
        lat: 10.648961408000332,
      },
      {
        lon: 106.69118357900027,
        lat: 10.649417051000285,
      },
      {
        lon: 106.69067618700018,
        lat: 10.651612740000303,
      },
      {
        lon: 106.68928552600033,
        lat: 10.653191455000297,
      },
      {
        lon: 106.68776182000022,
        lat: 10.656245883000224,
      },
      {
        lon: 106.68658004500037,
        lat: 10.657025501000248,
      },
      {
        lon: 106.68578554000027,
        lat: 10.660472704000313,
      },
      {
        lon: 106.6850383980003,
        lat: 10.661728518000473,
      },
      {
        lon: 106.68386954200037,
        lat: 10.662591764000297,
      },
      {
        lon: 106.68039637000027,
        lat: 10.663552214000163,
      },
      {
        lon: 106.67945995600024,
        lat: 10.665894247000267,
      },
      {
        lon: 106.67931564900027,
        lat: 10.669807106000064,
      },
      {
        lon: 106.67950710600019,
        lat: 10.67073707100019,
      },
      {
        lon: 106.68087396600032,
        lat: 10.671756279000135,
      },
      {
        lon: 106.68249222400027,
        lat: 10.67463029500027,
      },
      {
        lon: 106.68417315400029,
        lat: 10.674810299000228,
      },
      {
        lon: 106.68585291600016,
        lat: 10.674006626000278,
      },
      {
        lon: 106.69062464700022,
        lat: 10.673820752000154,
      },
      {
        lon: 106.69292514300031,
        lat: 10.672576175000302,
      },
      {
        lon: 106.69467656500024,
        lat: 10.671060603000342,
      },
      {
        lon: 106.69843412800037,
        lat: 10.672271553000144,
      },
      {
        lon: 106.70108441100018,
        lat: 10.673355386000196,
      },
      {
        lon: 106.70401765356922,
        lat: 10.67272290675703,
      },
      {
        lon: 106.70535968300044,
        lat: 10.674222889000305,
      },
      {
        lon: 106.70929844800031,
        lat: 10.67487565900035,
      },
      {
        lon: 106.71093789500031,
        lat: 10.675642719000223,
      },
      {
        lon: 106.71248852000025,
        lat: 10.675783939000326,
      },
      {
        lon: 106.71331936200028,
        lat: 10.676621823000232,
      },
      {
        lon: 106.71630010800033,
        lat: 10.678182602000277,
      },
      {
        lon: 106.71809443500018,
        lat: 10.67861009000012,
      },
      {
        lon: 106.71987154100032,
        lat: 10.678309527000238,
      },
      {
        lon: 106.72079050600024,
        lat: 10.680129184000236,
      },
      {
        lon: 106.72213026000026,
        lat: 10.68038597400016,
      },
      {
        lon: 106.72531414000025,
        lat: 10.680024144000242,
      },
      {
        lon: 106.72621887000037,
        lat: 10.679031627000313,
      },
      {
        lon: 106.72684908100052,
        lat: 10.67653295700012,
      },
      {
        lon: 106.72629287930624,
        lat: 10.674119729013626,
      },
      {
        lon: 106.72648211380596,
        lat: 10.672410686640603,
      },
      {
        lon: 106.72773815000028,
        lat: 10.668436539000083,
      },
      {
        lon: 106.72862294329332,
        lat: 10.666755204829883,
      },
      {
        lon: 106.73073701800024,
        lat: 10.66549911900023,
      },
      {
        lon: 106.7342896740002,
        lat: 10.665046732,
      },
      {
        lon: 106.73552376200035,
        lat: 10.66466080400005,
      },
      {
        lon: 106.73605398000035,
        lat: 10.666875743000276,
      },
      {
        lon: 106.73160076500022,
        lat: 10.667015139000284,
      },
      {
        lon: 106.73009908600022,
        lat: 10.667524005000075,
      },
      {
        lon: 106.72898173300024,
        lat: 10.669344890000218,
      },
      {
        lon: 106.72778405700024,
        lat: 10.674018237000269,
      },
      {
        lon: 106.72833861400039,
        lat: 10.676467801000058,
      },
      {
        lon: 106.72782098500028,
        lat: 10.679415092000113,
      },
      {
        lon: 106.72707288149655,
        lat: 10.680866842990838,
      },
      {
        lon: 106.72563987100028,
        lat: 10.681453710000255,
      },
      {
        lon: 106.72358310147564,
        lat: 10.681801284776668,
      },
      {
        lon: 106.72016389987095,
        lat: 10.681237911058474,
      },
      {
        lon: 106.71956648800023,
        lat: 10.679884053000308,
      },
    ],
    [
      {
        lon: 106.85334040802832,
        lat: 10.839496730857547,
      },
      {
        lon: 106.85965320500043,
        lat: 10.835437991000276,
      },
      {
        lon: 106.86241861133419,
        lat: 10.8310784046094,
      },
      {
        lon: 106.86681792400054,
        lat: 10.825334262000524,
      },
      {
        lon: 106.87418942400046,
        lat: 10.818001046000404,
      },
      {
        lon: 106.87781770600049,
        lat: 10.81148042800055,
      },
      {
        lon: 106.8786110150005,
        lat: 10.808410081000602,
      },
      {
        lon: 106.87674209000045,
        lat: 10.80376181000048,
      },
      {
        lon: 106.87166525100042,
        lat: 10.799853949000351,
      },
      {
        lon: 106.86933989000067,
        lat: 10.79706291000008,
      },
      {
        lon: 106.86520888200045,
        lat: 10.793807076000519,
      },
      {
        lon: 106.86323960075254,
        lat: 10.791603244128908,
      },
      {
        lon: 106.8619691490004,
        lat: 10.788272472000529,
      },
      {
        lon: 106.86243222500048,
        lat: 10.782466407000015,
      },
      {
        lon: 106.86472363200045,
        lat: 10.777700456000503,
      },
      {
        lon: 106.86946430200067,
        lat: 10.772785437000323,
      },
      {
        lon: 106.87069326600056,
        lat: 10.768572822000579,
      },
      {
        lon: 106.86734759400048,
        lat: 10.767559298000378,
      },
      {
        lon: 106.86478939700066,
        lat: 10.768280292000156,
      },
      {
        lon: 106.8554743190005,
        lat: 10.77562383100053,
      },
      {
        lon: 106.84962466300038,
        lat: 10.778788858000667,
      },
      {
        lon: 106.84307162300053,
        lat: 10.77873399400039,
      },
      {
        lon: 106.83847420988167,
        lat: 10.77756056856731,
      },
      {
        lon: 106.83793612389081,
        lat: 10.770904122139655,
      },
      {
        lon: 106.84553581600039,
        lat: 10.77485748200047,
      },
      {
        lon: 106.84801576900048,
        lat: 10.77504532200038,
      },
      {
        lon: 106.85448904500038,
        lat: 10.773771667000403,
      },
      {
        lon: 106.85795069400052,
        lat: 10.77115529400038,
      },
      {
        lon: 106.86218978700049,
        lat: 10.765778237000347,
      },
      {
        lon: 106.86491106400052,
        lat: 10.764481633,
      },
      {
        lon: 106.86821634300054,
        lat: 10.764393630000484,
      },
      {
        lon: 106.87327021200042,
        lat: 10.766869458000485,
      },
      {
        lon: 106.87464117800039,
        lat: 10.770294288000173,
      },
      {
        lon: 106.87378722400041,
        lat: 10.774346444000457,
      },
      {
        lon: 106.87066709100043,
        lat: 10.778607784000428,
      },
      {
        lon: 106.86636912400053,
        lat: 10.782191553000189,
      },
      {
        lon: 106.86466526400045,
        lat: 10.786149152000348,
      },
      {
        lon: 106.86458875000051,
        lat: 10.787981252000236,
      },
      {
        lon: 106.86636918100044,
        lat: 10.791100774000427,
      },
      {
        lon: 106.87849053600053,
        lat: 10.80106378200037,
      },
      {
        lon: 106.88100835000051,
        lat: 10.80452715000041,
      },
      {
        lon: 106.88222446700047,
        lat: 10.809068249000518,
      },
      {
        lon: 106.88188889100041,
        lat: 10.811480448000168,
      },
      {
        lon: 106.87749658400054,
        lat: 10.819657983000468,
      },
      {
        lon: 106.87153077200043,
        lat: 10.82591195100008,
      },
      {
        lon: 106.8638824610004,
        lat: 10.834878863000199,
      },
      {
        lon: 106.8569039590003,
        lat: 10.842092244000298,
      },
      {
        lon: 106.85277229500025,
        lat: 10.850088027000254,
      },
      {
        lon: 106.85123257300023,
        lat: 10.85397495899997,
      },
      {
        lon: 106.84917994100022,
        lat: 10.857369414000289,
      },
      {
        lon: 106.84679022600028,
        lat: 10.864423314000192,
      },
      {
        lon: 106.84679525300021,
        lat: 10.865799674000243,
      },
      {
        lon: 106.85044974000039,
        lat: 10.872403000000531,
      },
      {
        lon: 106.85105199500038,
        lat: 10.87628122100051,
      },
      {
        lon: 106.84593251600052,
        lat: 10.882293669000433,
      },
      {
        lon: 106.84368383800029,
        lat: 10.883809177000217,
      },
      {
        lon: 106.84300330300022,
        lat: 10.891285467000102,
      },
      {
        lon: 106.84015370200024,
        lat: 10.898929493000196,
      },
      {
        lon: 106.83866844800025,
        lat: 10.898498503000269,
      },
      {
        lon: 106.83990371200031,
        lat: 10.890867098000228,
      },
      {
        lon: 106.83965633400027,
        lat: 10.878244104000487,
      },
      {
        lon: 106.84123176200023,
        lat: 10.875473585000293,
      },
      {
        lon: 106.84453329050649,
        lat: 10.860970398770576,
      },
      {
        lon: 106.84866330200015,
        lat: 10.851086170000144,
      },
      {
        lon: 106.85150363000037,
        lat: 10.84316516000024,
      },
      {
        lon: 106.85334040802832,
        lat: 10.839496730857547,
      },
    ],
    [
      {
        lon: 106.77797286980143,
        lat: 10.586643052047236,
      },
      {
        lon: 106.77669775600043,
        lat: 10.583814771000505,
      },
      {
        lon: 106.77449000800033,
        lat: 10.582387628000447,
      },
      {
        lon: 106.76834180500066,
        lat: 10.585374631999969,
      },
      {
        lon: 106.76132814700048,
        lat: 10.586469398000133,
      },
      {
        lon: 106.7579514865314,
        lat: 10.586398500671805,
      },
      {
        lon: 106.75120206300039,
        lat: 10.585105364000071,
      },
      {
        lon: 106.74718613863257,
        lat: 10.582815310154672,
      },
      {
        lon: 106.74267563847701,
        lat: 10.578365504251906,
      },
      {
        lon: 106.74569042000047,
        lat: 10.575601139000526,
      },
      {
        lon: 106.74254359900038,
        lat: 10.571139286000403,
      },
      {
        lon: 106.74180005600044,
        lat: 10.568451248000201,
      },
      {
        lon: 106.74214638600051,
        lat: 10.561481413000422,
      },
      {
        lon: 106.74346029800051,
        lat: 10.553350929000374,
      },
      {
        lon: 106.74529002500043,
        lat: 10.545840975000374,
      },
      {
        lon: 106.74777600400051,
        lat: 10.53282587300032,
      },
      {
        lon: 106.74836125900038,
        lat: 10.525202741000385,
      },
      {
        lon: 106.74738357100046,
        lat: 10.51563608500038,
      },
      {
        lon: 106.74390257700043,
        lat: 10.503629933000127,
      },
      {
        lon: 106.74202626200048,
        lat: 10.49386630200047,
      },
      {
        lon: 106.74136946200053,
        lat: 10.48755173400042,
      },
      {
        lon: 106.74667985700052,
        lat: 10.477782289000402,
      },
      {
        lon: 106.76135008800048,
        lat: 10.45319239600019,
      },
      {
        lon: 106.76727522900052,
        lat: 10.445611214000508,
      },
      {
        lon: 106.77675954200038,
        lat: 10.434183027000415,
      },
      {
        lon: 106.7932972590005,
        lat: 10.416105369000377,
      },
      {
        lon: 106.80131515100038,
        lat: 10.4057524210005,
      },
      {
        lon: 106.80986687500047,
        lat: 10.391049431000397,
      },
      {
        lon: 106.81428355300051,
        lat: 10.382326897999974,
      },
      {
        lon: 106.83054515100042,
        lat: 10.394269829000393,
      },
      {
        lon: 106.83262661100048,
        lat: 10.396053162999976,
      },
      {
        lon: 106.8291419880004,
        lat: 10.40418855600069,
      },
      {
        lon: 106.82617295600039,
        lat: 10.409946137000361,
      },
      {
        lon: 106.81342155700042,
        lat: 10.41991878200048,
      },
      {
        lon: 106.7869605830004,
        lat: 10.441633265000462,
      },
      {
        lon: 106.78073385200042,
        lat: 10.448394303000498,
      },
      {
        lon: 106.7776625240005,
        lat: 10.45284315700053,
      },
      {
        lon: 106.7616716650005,
        lat: 10.479696889000365,
      },
      {
        lon: 106.7596170040004,
        lat: 10.48429716800041,
      },
      {
        lon: 106.75780491900048,
        lat: 10.490602927000339,
      },
      {
        lon: 106.75723070500044,
        lat: 10.494784880000054,
      },
      {
        lon: 106.7568831030004,
        lat: 10.504745731000469,
      },
      {
        lon: 106.75708115100048,
        lat: 10.51689625600045,
      },
      {
        lon: 106.75643729300066,
        lat: 10.527857968999967,
      },
      {
        lon: 106.75461988500041,
        lat: 10.537136305000274,
      },
      {
        lon: 106.75068635500054,
        lat: 10.554078872000503,
      },
      {
        lon: 106.7482068630004,
        lat: 10.561481208000487,
      },
      {
        lon: 106.74715140100045,
        lat: 10.566476016000195,
      },
      {
        lon: 106.7480555880004,
        lat: 10.571248244000344,
      },
      {
        lon: 106.75075850500048,
        lat: 10.57483944600051,
      },
      {
        lon: 106.75323904000044,
        lat: 10.57621769600047,
      },
      {
        lon: 106.75620690800054,
        lat: 10.5763348520004,
      },
      {
        lon: 106.7601944970005,
        lat: 10.574590559000399,
      },
      {
        lon: 106.76389097700047,
        lat: 10.571578120000481,
      },
      {
        lon: 106.7670363300004,
        lat: 10.570463158000223,
      },
      {
        lon: 106.77347253100044,
        lat: 10.570090180000477,
      },
      {
        lon: 106.77987729900047,
        lat: 10.571703745000491,
      },
      {
        lon: 106.7839159230005,
        lat: 10.57402643400053,
      },
      {
        lon: 106.7862479350004,
        lat: 10.57670153800018,
      },
      {
        lon: 106.78854070424899,
        lat: 10.581040826556944,
      },
      {
        lon: 106.77797286980143,
        lat: 10.586643052047236,
      },
    ],
    [
      {
        lon: 106.7617173393997,
        lat: 10.747142593295052,
      },
      {
        lon: 106.76055156600063,
        lat: 10.747860207000542,
      },
      {
        lon: 106.7556281870007,
        lat: 10.747837193000578,
      },
      {
        lon: 106.75084488800059,
        lat: 10.744486788000586,
      },
      {
        lon: 106.74726683900059,
        lat: 10.744807906000688,
      },
      {
        lon: 106.74665613100069,
        lat: 10.749391197000193,
      },
      {
        lon: 106.74848371400066,
        lat: 10.752619342000571,
      },
      {
        lon: 106.75301673000087,
        lat: 10.757827544000577,
      },
      {
        lon: 106.75410648700057,
        lat: 10.760600037000358,
      },
      {
        lon: 106.75473567800083,
        lat: 10.764606703000766,
      },
      {
        lon: 106.75352677700056,
        lat: 10.770046472000274,
      },
      {
        lon: 106.74977934600071,
        lat: 10.774634665000747,
      },
      {
        lon: 106.74232323400054,
        lat: 10.779162242000098,
      },
      {
        lon: 106.73933933200055,
        lat: 10.779265130000624,
      },
      {
        lon: 106.73616152500045,
        lat: 10.777934888000516,
      },
      {
        lon: 106.73379769206451,
        lat: 10.775997607726538,
      },
      {
        lon: 106.73249869600073,
        lat: 10.772151415000621,
      },
      {
        lon: 106.73258775500068,
        lat: 10.766278000000398,
      },
      {
        lon: 106.7316580610007,
        lat: 10.762562866000678,
      },
      {
        lon: 106.72981905100062,
        lat: 10.761169472000859,
      },
      {
        lon: 106.7261140210008,
        lat: 10.760630534000484,
      },
      {
        lon: 106.7210556783782,
        lat: 10.761173565660329,
      },
      {
        lon: 106.7138063590005,
        lat: 10.764366206000489,
      },
      {
        lon: 106.71048202700058,
        lat: 10.767923784000516,
      },
      {
        lon: 106.70932384731509,
        lat: 10.772851930696959,
      },
      {
        lon: 106.70999149600061,
        lat: 10.776864187000115,
      },
      {
        lon: 106.71253918100064,
        lat: 10.782171769000684,
      },
      {
        lon: 106.71522267500063,
        lat: 10.783843266000702,
      },
      {
        lon: 106.72065502100071,
        lat: 10.785109961000614,
      },
      {
        lon: 106.72491150100063,
        lat: 10.788211367000542,
      },
      {
        lon: 106.72660280400083,
        lat: 10.790752900000598,
      },
      {
        lon: 106.72848041900085,
        lat: 10.795782550000013,
      },
      {
        lon: 106.72813401400055,
        lat: 10.801382585,
      },
      {
        lon: 106.72449550500068,
        lat: 10.806337831000576,
      },
      {
        lon: 106.7234612860006,
        lat: 10.810506314000577,
      },
      {
        lon: 106.72528573600056,
        lat: 10.81454600000069,
      },
      {
        lon: 106.72837984000063,
        lat: 10.817471883000565,
      },
      {
        lon: 106.73154198200058,
        lat: 10.818615190000571,
      },
      {
        lon: 106.7341598295157,
        lat: 10.816008836090475,
      },
      {
        lon: 106.73536003300075,
        lat: 10.811974901000667,
      },
      {
        lon: 106.73759738800067,
        lat: 10.809495394000237,
      },
      {
        lon: 106.74045699700076,
        lat: 10.807865044000721,
      },
      {
        lon: 106.7437003630007,
        lat: 10.807177789999969,
      },
      {
        lon: 106.74736981500058,
        lat: 10.807904935000181,
      },
      {
        lon: 106.75022819500066,
        lat: 10.809829838000553,
      },
      {
        lon: 106.7519855610006,
        lat: 10.812049616000365,
      },
      {
        lon: 106.75266867300058,
        lat: 10.815545533000716,
      },
      {
        lon: 106.75206848600061,
        lat: 10.829655696000486,
      },
      {
        lon: 106.7496346870006,
        lat: 10.833465954000815,
      },
      {
        lon: 106.74745533400062,
        lat: 10.834880474000613,
      },
      {
        lon: 106.73964447900067,
        lat: 10.836956048000559,
      },
      {
        lon: 106.73095310700063,
        lat: 10.825034986000617,
      },
      {
        lon: 106.72619354800071,
        lat: 10.82216537400069,
      },
      {
        lon: 106.72092426800066,
        lat: 10.820497096000679,
      },
      {
        lon: 106.71420310600058,
        lat: 10.820913005000534,
      },
      {
        lon: 106.71422308247332,
        lat: 10.819825674784454,
      },
      {
        lon: 106.72019274800056,
        lat: 10.819339254000624,
      },
      {
        lon: 106.72734535100057,
        lat: 10.821618566000382,
      },
      {
        lon: 106.73242704300071,
        lat: 10.825222790000169,
      },
      {
        lon: 106.7374234800007,
        lat: 10.832314643000132,
      },
      {
        lon: 106.73949384400055,
        lat: 10.834257701000695,
      },
      {
        lon: 106.74136981000055,
        lat: 10.834412682000618,
      },
      {
        lon: 106.74706887700057,
        lat: 10.8323482330008,
      },
      {
        lon: 106.75013200140904,
        lat: 10.827913127153408,
      },
      {
        lon: 106.74961276400063,
        lat: 10.812846526000044,
      },
      {
        lon: 106.74850975800064,
        lat: 10.811299905000794,
      },
      {
        lon: 106.7455453670006,
        lat: 10.809583027000656,
      },
      {
        lon: 106.74227276700071,
        lat: 10.809529266000197,
      },
      {
        lon: 106.7384862790006,
        lat: 10.811447069000337,
      },
      {
        lon: 106.73611374600068,
        lat: 10.817609309000034,
      },
      {
        lon: 106.73411825000062,
        lat: 10.820002926000654,
      },
      {
        lon: 106.73107547100079,
        lat: 10.820897686000421,
      },
      {
        lon: 106.72822449800071,
        lat: 10.819909809000308,
      },
      {
        lon: 106.72338383800059,
        lat: 10.81606586500078,
      },
      {
        lon: 106.72067182700067,
        lat: 10.810798574000614,
      },
      {
        lon: 106.71573424868376,
        lat: 10.817189762865326,
      },
      {
        lon: 106.71502237005677,
        lat: 10.819467988635154,
      },
      {
        lon: 106.71313799400053,
        lat: 10.819750728000201,
      },
      {
        lon: 106.72101792300072,
        lat: 10.809249257000033,
      },
      {
        lon: 106.72185546100087,
        lat: 10.80600485800062,
      },
      {
        lon: 106.72596172500067,
        lat: 10.798683753000716,
      },
      {
        lon: 106.72724521500061,
        lat: 10.798922953000613,
      },
      {
        lon: 106.72650135200064,
        lat: 10.793478050000658,
      },
      {
        lon: 106.72464525800069,
        lat: 10.789972526000327,
      },
      {
        lon: 106.72173966600064,
        lat: 10.787575212000036,
      },
      {
        lon: 106.71845975800068,
        lat: 10.785945971000501,
      },
      {
        lon: 106.71431952600062,
        lat: 10.78512945800054,
      },
      {
        lon: 106.71333773700064,
        lat: 10.786361913000633,
      },
      {
        lon: 106.70948190800055,
        lat: 10.782929185000114,
      },
      {
        lon: 106.70814105000062,
        lat: 10.78051492400067,
      },
      {
        lon: 106.70702137600068,
        lat: 10.776404644000195,
      },
      {
        lon: 106.70651011700062,
        lat: 10.77040590900026,
      },
      {
        lon: 106.70820624854815,
        lat: 10.766452269620117,
      },
      {
        lon: 106.7102123730005,
        lat: 10.764076421000425,
      },
      {
        lon: 106.71376162200062,
        lat: 10.761389873000553,
      },
      {
        lon: 106.72016339300052,
        lat: 10.758141465000506,
      },
      {
        lon: 106.7262930720006,
        lat: 10.757480822000673,
      },
      {
        lon: 106.7309527240007,
        lat: 10.75848316200063,
      },
      {
        lon: 106.7348921750006,
        lat: 10.762046262000581,
      },
      {
        lon: 106.73661413400072,
        lat: 10.766079250000473,
      },
      {
        lon: 106.7369327300006,
        lat: 10.77297817900046,
      },
      {
        lon: 106.7393189130006,
        lat: 10.775726948999989,
      },
      {
        lon: 106.74283540400063,
        lat: 10.774661276000188,
      },
      {
        lon: 106.74679108400056,
        lat: 10.771284886999979,
      },
      {
        lon: 106.7498582420007,
        lat: 10.767201328000056,
      },
      {
        lon: 106.75075047800061,
        lat: 10.764935612000727,
      },
      {
        lon: 106.74911282400063,
        lat: 10.760999997000056,
      },
      {
        lon: 106.74415270500066,
        lat: 10.754574029000594,
      },
      {
        lon: 106.74179959200058,
        lat: 10.748342948000413,
      },
      {
        lon: 106.74217566500067,
        lat: 10.74389850800003,
      },
      {
        lon: 106.74527638100056,
        lat: 10.739926450000553,
      },
      {
        lon: 106.7512078000006,
        lat: 10.739243076000662,
      },
      {
        lon: 106.75836565300051,
        lat: 10.739929249000511,
      },
      {
        lon: 106.75545662500053,
        lat: 10.735874852000507,
      },
      {
        lon: 106.7499968440004,
        lat: 10.730275474000507,
      },
      {
        lon: 106.74829011000043,
        lat: 10.72805471099997,
      },
      {
        lon: 106.74559684000053,
        lat: 10.721781983000369,
      },
      {
        lon: 106.74482456900046,
        lat: 10.715809594000518,
      },
      {
        lon: 106.74573415600054,
        lat: 10.707314513000426,
      },
      {
        lon: 106.74834082900043,
        lat: 10.698592688000417,
      },
      {
        lon: 106.75032392200046,
        lat: 10.694083811000496,
      },
      {
        lon: 106.75574837900052,
        lat: 10.685540461000414,
      },
      {
        lon: 106.76808689400042,
        lat: 10.673264918000541,
      },
      {
        lon: 106.7692412700004,
        lat: 10.66959684200043,
      },
      {
        lon: 106.76777368600045,
        lat: 10.668991572000266,
      },
      {
        lon: 106.76066337010138,
        lat: 10.672646799179518,
      },
      {
        lon: 106.75598840200041,
        lat: 10.674352207000538,
      },
      {
        lon: 106.74847432700051,
        lat: 10.675499981000485,
      },
      {
        lon: 106.74240256700045,
        lat: 10.673684488000447,
      },
      {
        lon: 106.73772599825628,
        lat: 10.669614328465514,
      },
      {
        lon: 106.73552376200035,
        lat: 10.66466080400005,
      },
      {
        lon: 106.73628918800046,
        lat: 10.659705601000326,
      },
      {
        lon: 106.73883174100041,
        lat: 10.653609891000258,
      },
      {
        lon: 106.74444664300029,
        lat: 10.646697827000366,
      },
      {
        lon: 106.75253152700043,
        lat: 10.64238368399998,
      },
      {
        lon: 106.76359443700045,
        lat: 10.638647266000476,
      },
      {
        lon: 106.7661434470005,
        lat: 10.637146396000446,
      },
      {
        lon: 106.76700879900051,
        lat: 10.634576213000514,
      },
      {
        lon: 106.76602383400046,
        lat: 10.62832015900051,
      },
      {
        lon: 106.76664504900043,
        lat: 10.621263150000402,
      },
      {
        lon: 106.76761477900038,
        lat: 10.615608728000439,
      },
      {
        lon: 106.76985022200051,
        lat: 10.608313829000394,
      },
      {
        lon: 106.7724836430005,
        lat: 10.603232078000474,
      },
      {
        lon: 106.77671889200036,
        lat: 10.597082965000368,
      },
      {
        lon: 106.77823331200045,
        lat: 10.591477917000418,
      },
      {
        lon: 106.77797286980143,
        lat: 10.586643052047236,
      },
      {
        lon: 106.78854070424899,
        lat: 10.581040826556944,
      },
      {
        lon: 106.78920257100043,
        lat: 10.585108777000471,
      },
      {
        lon: 106.78807851200042,
        lat: 10.59469442600033,
      },
      {
        lon: 106.78719620300052,
        lat: 10.598766310000087,
      },
      {
        lon: 106.78500185800038,
        lat: 10.604615155000431,
      },
      {
        lon: 106.78198602600042,
        lat: 10.610029937000434,
      },
      {
        lon: 106.77517739000041,
        lat: 10.615831261000494,
      },
      {
        lon: 106.7734826520005,
        lat: 10.6189069870004,
      },
      {
        lon: 106.7751687740004,
        lat: 10.625194364000436,
      },
      {
        lon: 106.77590217300042,
        lat: 10.633051572000397,
      },
      {
        lon: 106.77403737600054,
        lat: 10.638996088,
      },
      {
        lon: 106.7716725070004,
        lat: 10.643406368000399,
      },
      {
        lon: 106.76793059400046,
        lat: 10.647258928000497,
      },
      {
        lon: 106.76225904600051,
        lat: 10.650979515000415,
      },
      {
        lon: 106.75201959400044,
        lat: 10.653680973000462,
      },
      {
        lon: 106.74574044100041,
        lat: 10.656446656000412,
      },
      {
        lon: 106.74188380100054,
        lat: 10.660619735000466,
      },
      {
        lon: 106.74092314500047,
        lat: 10.664560708000295,
      },
      {
        lon: 106.74268528500039,
        lat: 10.666184412000348,
      },
      {
        lon: 106.74788570000038,
        lat: 10.665907600000367,
      },
      {
        lon: 106.75490993200049,
        lat: 10.66296886400039,
      },
      {
        lon: 106.76565332600052,
        lat: 10.660823968000386,
      },
      {
        lon: 106.7687170340007,
        lat: 10.66109267700051,
      },
      {
        lon: 106.77376652000038,
        lat: 10.664231889000177,
      },
      {
        lon: 106.77682466300053,
        lat: 10.667598677000399,
      },
      {
        lon: 106.77827501100046,
        lat: 10.670233185000427,
      },
      {
        lon: 106.77869496700038,
        lat: 10.673548473000096,
      },
      {
        lon: 106.77646767600038,
        lat: 10.674493079000456,
      },
      {
        lon: 106.77167448300054,
        lat: 10.678255128000444,
      },
      {
        lon: 106.76665316500038,
        lat: 10.683942865000404,
      },
      {
        lon: 106.75950755400044,
        lat: 10.69059978500053,
      },
      {
        lon: 106.75542661500037,
        lat: 10.696018941000375,
      },
      {
        lon: 106.75270991100047,
        lat: 10.703879413000436,
      },
      {
        lon: 106.75145112500041,
        lat: 10.708506412000531,
      },
      {
        lon: 106.75115724200049,
        lat: 10.71172224000037,
      },
      {
        lon: 106.75178572800047,
        lat: 10.71602946100029,
      },
      {
        lon: 106.75306269200041,
        lat: 10.71975915200045,
      },
      {
        lon: 106.75707637100044,
        lat: 10.728147804000514,
      },
      {
        lon: 106.76481934595819,
        lat: 10.737584084713603,
      },
      {
        lon: 106.7617173393997,
        lat: 10.747142593295052,
      },
    ],
    [
      {
        lon: 106.76481934595819,
        lat: 10.737584084713603,
      },
      {
        lon: 106.76957801100048,
        lat: 10.742819676000295,
      },
      {
        lon: 106.77538959800034,
        lat: 10.747940149000215,
      },
      {
        lon: 106.77796217300033,
        lat: 10.749668536000343,
      },
      {
        lon: 106.7934525170005,
        lat: 10.756370792000496,
      },
      {
        lon: 106.80059093500051,
        lat: 10.760062217000268,
      },
      {
        lon: 106.80418655600039,
        lat: 10.764380449000157,
      },
      {
        lon: 106.8058255190004,
        lat: 10.77026776300039,
      },
      {
        lon: 106.81286285200048,
        lat: 10.777249689000428,
      },
      {
        lon: 106.81750535900045,
        lat: 10.7802308090005,
      },
      {
        lon: 106.82156332300048,
        lat: 10.780700972000432,
      },
      {
        lon: 106.82339262200054,
        lat: 10.77992865500039,
      },
      {
        lon: 106.82519343800033,
        lat: 10.777694344000508,
      },
      {
        lon: 106.82621018200047,
        lat: 10.773299653000436,
      },
      {
        lon: 106.8253053720004,
        lat: 10.769814055000495,
      },
      {
        lon: 106.82341655300053,
        lat: 10.766376618000209,
      },
      {
        lon: 106.82299357300047,
        lat: 10.761674602000369,
      },
      {
        lon: 106.82451514500048,
        lat: 10.759767351000391,
      },
      {
        lon: 106.82792841000054,
        lat: 10.760006078000515,
      },
      {
        lon: 106.83447264200038,
        lat: 10.76802784300014,
      },
      {
        lon: 106.83793612389098,
        lat: 10.770904122139825,
      },
      {
        lon: 106.8384742098819,
        lat: 10.77756056856748,
      },
      {
        lon: 106.83449590300046,
        lat: 10.780606183000486,
      },
      {
        lon: 106.83392913200032,
        lat: 10.78241573600053,
      },
      {
        lon: 106.83586640700048,
        lat: 10.783862726,
      },
      {
        lon: 106.8376421760006,
        lat: 10.783231669000656,
      },
      {
        lon: 106.84191436300068,
        lat: 10.780119445000537,
      },
      {
        lon: 106.84466646100066,
        lat: 10.779721085000062,
      },
      {
        lon: 106.84623260900042,
        lat: 10.781077573000402,
      },
      {
        lon: 106.84673079000049,
        lat: 10.785673604000635,
      },
      {
        lon: 106.84795697200053,
        lat: 10.78776446500052,
      },
      {
        lon: 106.85085751300045,
        lat: 10.790320580000525,
      },
      {
        lon: 106.85194005000056,
        lat: 10.793877835000615,
      },
      {
        lon: 106.84979290300043,
        lat: 10.79771018400038,
      },
      {
        lon: 106.84928845600075,
        lat: 10.805189864024328,
      },
      {
        lon: 106.84434954400047,
        lat: 10.808875063000528,
      },
      {
        lon: 106.83986016200043,
        lat: 10.810389119000376,
      },
      {
        lon: 106.84320890300052,
        lat: 10.813785361000555,
      },
      {
        lon: 106.84530956600048,
        lat: 10.817211136000424,
      },
      {
        lon: 106.84509742100045,
        lat: 10.819888145000505,
      },
      {
        lon: 106.84342914600052,
        lat: 10.820474771000363,
      },
      {
        lon: 106.8382107100004,
        lat: 10.818048123000494,
      },
      {
        lon: 106.83596764500032,
        lat: 10.820232700000474,
      },
      {
        lon: 106.83575030400038,
        lat: 10.822416704000375,
      },
      {
        lon: 106.83770075500041,
        lat: 10.831339137999976,
      },
      {
        lon: 106.8398535870005,
        lat: 10.831769565000513,
      },
      {
        lon: 106.84418473100044,
        lat: 10.82988135400052,
      },
      {
        lon: 106.8471539450004,
        lat: 10.831511822000607,
      },
      {
        lon: 106.8484591710004,
        lat: 10.833187294000481,
      },
      {
        lon: 106.85380187900068,
        lat: 10.835762838000619,
      },
      {
        lon: 106.85527472599011,
        lat: 10.83803030211044,
      },
      {
        lon: 106.85334040802832,
        lat: 10.839496730857547,
      },
      {
        lon: 106.85232293600046,
        lat: 10.836239039000475,
      },
      {
        lon: 106.8481994850004,
        lat: 10.834539165000365,
      },
      {
        lon: 106.84489419700049,
        lat: 10.831697773000428,
      },
      {
        lon: 106.8433288170005,
        lat: 10.831402182000602,
      },
      {
        lon: 106.83876240200038,
        lat: 10.833349470000599,
      },
      {
        lon: 106.83646739100033,
        lat: 10.832359837000354,
      },
      {
        lon: 106.83511949300032,
        lat: 10.827808013000322,
      },
      {
        lon: 106.83414545100038,
        lat: 10.822246210000458,
      },
      {
        lon: 106.83520030600039,
        lat: 10.817939171000148,
      },
      {
        lon: 106.83896906600046,
        lat: 10.816645647000428,
      },
      {
        lon: 106.84303963200045,
        lat: 10.818601836000369,
      },
      {
        lon: 106.84266744900049,
        lat: 10.815855857000258,
      },
      {
        lon: 106.83953925400039,
        lat: 10.813557715,
      },
      {
        lon: 106.83804866100041,
        lat: 10.810854962000462,
      },
      {
        lon: 106.83953391100039,
        lat: 10.80870722300051,
      },
      {
        lon: 106.84440762000065,
        lat: 10.806925407000506,
      },
      {
        lon: 106.8475153710005,
        lat: 10.8047280800007,
      },
      {
        lon: 106.84820839500043,
        lat: 10.803345428000682,
      },
      {
        lon: 106.84790638100048,
        lat: 10.79821764600041,
      },
      {
        lon: 106.8502053630005,
        lat: 10.792131218000236,
      },
      {
        lon: 106.84547294800052,
        lat: 10.786933165000505,
      },
      {
        lon: 106.84455172582483,
        lat: 10.782858179622053,
      },
      {
        lon: 106.8431639910005,
        lat: 10.781358009000144,
      },
      {
        lon: 106.83791581200069,
        lat: 10.785060463000434,
      },
      {
        lon: 106.8354899360005,
        lat: 10.785552294000524,
      },
      {
        lon: 106.83251144500053,
        lat: 10.78277748300053,
      },
      {
        lon: 106.83277856900042,
        lat: 10.780230711000513,
      },
      {
        lon: 106.83718037300048,
        lat: 10.776365987000474,
      },
      {
        lon: 106.83149485600035,
        lat: 10.769313110000553,
      },
      {
        lon: 106.8275743900005,
        lat: 10.763140885000269,
      },
      {
        lon: 106.82488580400047,
        lat: 10.762747720000334,
      },
      {
        lon: 106.8256362890005,
        lat: 10.765920759999972,
      },
      {
        lon: 106.82746147200044,
        lat: 10.769317254000157,
      },
      {
        lon: 106.82826228800042,
        lat: 10.774956834000307,
      },
      {
        lon: 106.82678793800045,
        lat: 10.778924455000606,
      },
      {
        lon: 106.82364981158167,
        lat: 10.78223197639818,
      },
      {
        lon: 106.81717048500042,
        lat: 10.782344037000371,
      },
      {
        lon: 106.8143769460005,
        lat: 10.781049453000378,
      },
      {
        lon: 106.80732911600052,
        lat: 10.775791362000632,
      },
      {
        lon: 106.8038692610005,
        lat: 10.77182981700048,
      },
      {
        lon: 106.80132920300048,
        lat: 10.767220324000391,
      },
      {
        lon: 106.79969693100043,
        lat: 10.762987889000044,
      },
      {
        lon: 106.78901318800047,
        lat: 10.758001780000427,
      },
      {
        lon: 106.78392950300042,
        lat: 10.756132797000419,
      },
      {
        lon: 106.77320998200048,
        lat: 10.753198046000136,
      },
      {
        lon: 106.76565170300029,
        lat: 10.750205474000154,
      },
      {
        lon: 106.7617173393997,
        lat: 10.747142593295052,
      },
      {
        lon: 106.76481934595819,
        lat: 10.737584084713603,
      },
    ],
    [
      {
        lon: 106.93132274915672,
        lat: 10.412212257153442,
      },
      {
        lon: 106.93254778900086,
        lat: 10.411907785000665,
      },
      {
        lon: 106.93831961000103,
        lat: 10.412051095000265,
      },
      {
        lon: 106.94121074100104,
        lat: 10.40948172500066,
      },
      {
        lon: 106.94277482400105,
        lat: 10.409608006000894,
      },
      {
        lon: 106.9435610460011,
        lat: 10.410669017000487,
      },
      {
        lon: 106.94409447269857,
        lat: 10.414888656736252,
      },
      {
        lon: 106.94549655000117,
        lat: 10.415745283000035,
      },
      {
        lon: 106.94940156300106,
        lat: 10.414671325000882,
      },
      {
        lon: 106.95103413978347,
        lat: 10.415500851701722,
      },
      {
        lon: 106.95108169333565,
        lat: 10.418253170259163,
      },
      {
        lon: 106.95345689500107,
        lat: 10.419162056000744,
      },
      {
        lon: 106.95597084707794,
        lat: 10.418759156151793,
      },
      {
        lon: 106.95667199600103,
        lat: 10.419907582000974,
      },
      {
        lon: 106.9586616390011,
        lat: 10.419983928000534,
      },
      {
        lon: 106.96063363500105,
        lat: 10.42110658900043,
      },
      {
        lon: 106.9643677880008,
        lat: 10.417536120000705,
      },
      {
        lon: 106.96666205200074,
        lat: 10.414860408000095,
      },
      {
        lon: 106.97195595900098,
        lat: 10.417588626000054,
      },
      {
        lon: 106.97532104700088,
        lat: 10.415820041000359,
      },
      {
        lon: 106.97321558600083,
        lat: 10.408905801000799,
      },
      {
        lon: 106.97227548200084,
        lat: 10.407093594000685,
      },
      {
        lon: 106.96123044695145,
        lat: 10.402860618765146,
      },
      {
        lon: 106.94194402900075,
        lat: 10.395063747000904,
      },
      {
        lon: 106.93578852600086,
        lat: 10.393627495000715,
      },
      {
        lon: 106.93650663300082,
        lat: 10.392480864000788,
      },
      {
        lon: 106.94263304600088,
        lat: 10.385448764000728,
      },
      {
        lon: 106.96186796300083,
        lat: 10.391496160000315,
      },
      {
        lon: 106.9705331980008,
        lat: 10.394813029000773,
      },
      {
        lon: 106.97620436400086,
        lat: 10.39969187100013,
      },
      {
        lon: 106.98094993000075,
        lat: 10.405229641000744,
      },
      {
        lon: 106.98559971600093,
        lat: 10.413195010000322,
      },
      {
        lon: 106.98528232500087,
        lat: 10.418201939000085,
      },
      {
        lon: 106.98227404900081,
        lat: 10.424744780000822,
      },
      {
        lon: 106.98084954400082,
        lat: 10.42614825300086,
      },
      {
        lon: 106.97258428100076,
        lat: 10.433160051000868,
      },
      {
        lon: 106.97053344000079,
        lat: 10.434408332000658,
      },
      {
        lon: 106.96599559200087,
        lat: 10.436479928000836,
      },
      {
        lon: 106.96267160800079,
        lat: 10.4384932840008,
      },
      {
        lon: 106.96608645600088,
        lat: 10.446488984000837,
      },
      {
        lon: 106.96821511700074,
        lat: 10.452620152000804,
      },
      {
        lon: 106.9661698690008,
        lat: 10.462849332000758,
      },
      {
        lon: 106.96479063700087,
        lat: 10.464111837000303,
      },
      {
        lon: 106.96253702600086,
        lat: 10.46431718600087,
      },
      {
        lon: 106.96054697100078,
        lat: 10.466222587000686,
      },
      {
        lon: 106.95802843164824,
        lat: 10.466916993566201,
      },
      {
        lon: 106.93446492581671,
        lat: 10.463758997939237,
      },
      {
        lon: 106.93542971700072,
        lat: 10.461128527000199,
      },
      {
        lon: 106.93295135300082,
        lat: 10.455913113000861,
      },
      {
        lon: 106.93310559400072,
        lat: 10.451553989000786,
      },
      {
        lon: 106.93362228800083,
        lat: 10.450375335000786,
      },
      {
        lon: 106.93334422900085,
        lat: 10.447411992000468,
      },
      {
        lon: 106.93529550800088,
        lat: 10.442442396000331,
      },
      {
        lon: 106.93253721000076,
        lat: 10.442926907000185,
      },
      {
        lon: 106.92719556600076,
        lat: 10.442163102000848,
      },
      {
        lon: 106.92452834100095,
        lat: 10.442109812000524,
      },
      {
        lon: 106.9225201740008,
        lat: 10.440731053000775,
      },
      {
        lon: 106.92026336800075,
        lat: 10.443136322000726,
      },
      {
        lon: 106.91759352900073,
        lat: 10.445332782000207,
      },
      {
        lon: 106.91535418600085,
        lat: 10.446068419000767,
      },
      {
        lon: 106.91364091300073,
        lat: 10.445585667000614,
      },
      {
        lon: 106.91086715900076,
        lat: 10.447429424000859,
      },
      {
        lon: 106.90863241800082,
        lat: 10.447380274000864,
      },
      {
        lon: 106.9063099530008,
        lat: 10.44641478600056,
      },
      {
        lon: 106.90534943500074,
        lat: 10.44662785400078,
      },
      {
        lon: 106.90344642000076,
        lat: 10.452604091000808,
      },
      {
        lon: 106.90642027800078,
        lat: 10.453874866000717,
      },
      {
        lon: 106.90678453100082,
        lat: 10.454972049000446,
      },
      {
        lon: 106.90541519500084,
        lat: 10.457181189000266,
      },
      {
        lon: 106.90559930100079,
        lat: 10.460232611000322,
      },
      {
        lon: 106.90650834100086,
        lat: 10.461877088000051,
      },
      {
        lon: 106.90606321570533,
        lat: 10.463193195093936,
      },
      {
        lon: 106.90374897600088,
        lat: 10.46401415800085,
      },
      {
        lon: 106.90182675600084,
        lat: 10.46546889300032,
      },
      {
        lon: 106.89918695300082,
        lat: 10.465054468000403,
      },
      {
        lon: 106.89726210200081,
        lat: 10.466458555000315,
      },
      {
        lon: 106.89686093800083,
        lat: 10.469192174000455,
      },
      {
        lon: 106.89771905000077,
        lat: 10.470110542000782,
      },
      {
        lon: 106.90166093200082,
        lat: 10.471518226,
      },
      {
        lon: 106.90266523400078,
        lat: 10.47289679500011,
      },
      {
        lon: 106.90232776500085,
        lat: 10.474427155000797,
      },
      {
        lon: 106.90018973600075,
        lat: 10.475737594000236,
      },
      {
        lon: 106.89766536900072,
        lat: 10.473653314000444,
      },
      {
        lon: 106.89608508100088,
        lat: 10.472877805000849,
      },
      {
        lon: 106.89195077100072,
        lat: 10.472647457000619,
      },
      {
        lon: 106.88957528643328,
        lat: 10.471651009706875,
      },
      {
        lon: 106.88853403610909,
        lat: 10.470106889341082,
      },
      {
        lon: 106.88870548900081,
        lat: 10.467751877000751,
      },
      {
        lon: 106.88943945900081,
        lat: 10.466425528000514,
      },
      {
        lon: 106.89335928600082,
        lat: 10.462815686000795,
      },
      {
        lon: 106.89336963100072,
        lat: 10.460638736000817,
      },
      {
        lon: 106.89190139900074,
        lat: 10.458838930000752,
      },
      {
        lon: 106.89050171500077,
        lat: 10.458249874000671,
      },
      {
        lon: 106.88789318400046,
        lat: 10.458302053000466,
      },
      {
        lon: 106.88524768500054,
        lat: 10.460001977000445,
      },
      {
        lon: 106.88275985700056,
        lat: 10.463787214000547,
      },
      {
        lon: 106.88144277000055,
        lat: 10.467899354000451,
      },
      {
        lon: 106.87773930400056,
        lat: 10.46897072200045,
      },
      {
        lon: 106.87586487000056,
        lat: 10.468666379000581,
      },
      {
        lon: 106.87828731100052,
        lat: 10.464727162000145,
      },
      {
        lon: 106.87984569500048,
        lat: 10.465856753000082,
      },
      {
        lon: 106.88150352600064,
        lat: 10.465283313000441,
      },
      {
        lon: 106.88280537600053,
        lat: 10.462254188000543,
      },
      {
        lon: 106.88519835618075,
        lat: 10.45951017335642,
      },
      {
        lon: 106.88708338966774,
        lat: 10.458169754633218,
      },
      {
        lon: 106.89129789100144,
        lat: 10.457712898120064,
      },
      {
        lon: 106.89395392400081,
        lat: 10.460032332000537,
      },
      {
        lon: 106.8943152370008,
        lat: 10.462343996000868,
      },
      {
        lon: 106.89307590500073,
        lat: 10.46425777600075,
      },
      {
        lon: 106.89076320700087,
        lat: 10.465844323000852,
      },
      {
        lon: 106.88905730700077,
        lat: 10.468497309000782,
      },
      {
        lon: 106.88966334900084,
        lat: 10.4707233350004,
      },
      {
        lon: 106.8921037660009,
        lat: 10.471961739000733,
      },
      {
        lon: 106.8973668750009,
        lat: 10.472566390000395,
      },
      {
        lon: 106.90019364800088,
        lat: 10.475177575000714,
      },
      {
        lon: 106.9012756390008,
        lat: 10.474772539000412,
      },
      {
        lon: 106.90203163400076,
        lat: 10.47303013000004,
      },
      {
        lon: 106.90007154000074,
        lat: 10.471452476000422,
      },
      {
        lon: 106.8973401810008,
        lat: 10.470747427000617,
      },
      {
        lon: 106.89635760100083,
        lat: 10.469817559000774,
      },
      {
        lon: 106.89611260100082,
        lat: 10.467298397000718,
      },
      {
        lon: 106.89708446600088,
        lat: 10.465638890000264,
      },
      {
        lon: 106.89986752000073,
        lat: 10.46414144000073,
      },
      {
        lon: 106.90166843200092,
        lat: 10.464776903000086,
      },
      {
        lon: 106.90294669100086,
        lat: 10.463591890000714,
      },
      {
        lon: 106.9054940350008,
        lat: 10.462696177000055,
      },
      {
        lon: 106.90472064900075,
        lat: 10.460057307000739,
      },
      {
        lon: 106.90461655400077,
        lat: 10.457076898000253,
      },
      {
        lon: 106.90565060300077,
        lat: 10.455020725000566,
      },
      {
        lon: 106.90313812200083,
        lat: 10.453780135000784,
      },
      {
        lon: 106.90272471000088,
        lat: 10.451901127000554,
      },
      {
        lon: 106.90329455200077,
        lat: 10.448384178000083,
      },
      {
        lon: 106.90444469500085,
        lat: 10.446132453000757,
      },
      {
        lon: 106.90677870400077,
        lat: 10.44550729500071,
      },
      {
        lon: 106.91037950100088,
        lat: 10.446512965000068,
      },
      {
        lon: 106.91085535800073,
        lat: 10.444539445000203,
      },
      {
        lon: 106.91315413500081,
        lat: 10.443257577000736,
      },
      {
        lon: 106.91495119500081,
        lat: 10.44461547500083,
      },
      {
        lon: 106.91699080800072,
        lat: 10.444471561000626,
      },
      {
        lon: 106.91960940900094,
        lat: 10.441884716000743,
      },
      {
        lon: 106.91975106600086,
        lat: 10.440449923000585,
      },
      {
        lon: 106.91701962400077,
        lat: 10.4390590950008,
      },
      {
        lon: 106.91513324500096,
        lat: 10.437559038000757,
      },
      {
        lon: 106.91794278500089,
        lat: 10.436637525000094,
      },
      {
        lon: 106.91797124700093,
        lat: 10.43417098200075,
      },
      {
        lon: 106.91704289100085,
        lat: 10.432113761000608,
      },
      {
        lon: 106.91699607100088,
        lat: 10.429399481000758,
      },
      {
        lon: 106.91793931200084,
        lat: 10.427600801999972,
      },
      {
        lon: 106.92144767300078,
        lat: 10.426203113000781,
      },
      {
        lon: 106.92147266200084,
        lat: 10.42485237800065,
      },
      {
        lon: 106.91963807300078,
        lat: 10.420434066000837,
      },
      {
        lon: 106.92039181300083,
        lat: 10.419045506000714,
      },
      {
        lon: 106.92269368800078,
        lat: 10.417421964000653,
      },
      {
        lon: 106.92450542500086,
        lat: 10.41523322700084,
      },
      {
        lon: 106.92631717300083,
        lat: 10.415271252000707,
      },
      {
        lon: 106.92173784300081,
        lat: 10.419052640000757,
      },
      {
        lon: 106.92099673700079,
        lat: 10.420693262000782,
      },
      {
        lon: 106.92283810300074,
        lat: 10.423354768000195,
      },
      {
        lon: 106.92334768800072,
        lat: 10.42492147300078,
      },
      {
        lon: 106.9222025560008,
        lat: 10.428191701000458,
      },
      {
        lon: 106.91911817200088,
        lat: 10.429507292000153,
      },
      {
        lon: 106.91852823500079,
        lat: 10.43143796000038,
      },
      {
        lon: 106.9204733770008,
        lat: 10.435964870000305,
      },
      {
        lon: 106.92356038900078,
        lat: 10.43767621800015,
      },
      {
        lon: 106.92860030600082,
        lat: 10.437966317000814,
      },
      {
        lon: 106.93152274600084,
        lat: 10.437550452000778,
      },
      {
        lon: 106.94266534600104,
        lat: 10.43488681800028,
      },
      {
        lon: 106.94333350900081,
        lat: 10.43343386500078,
      },
      {
        lon: 106.94709324700102,
        lat: 10.428305754000746,
      },
      {
        lon: 106.9524676940008,
        lat: 10.425496866000685,
      },
      {
        lon: 106.9587069400012,
        lat: 10.421696893000956,
      },
      {
        lon: 106.956009878001,
        lat: 10.420827767000276,
      },
      {
        lon: 106.95514383400109,
        lat: 10.419630632000974,
      },
      {
        lon: 106.95383293300091,
        lat: 10.419848263000233,
      },
      {
        lon: 106.94916313200099,
        lat: 10.418737196000052,
      },
      {
        lon: 106.95048064400089,
        lat: 10.416052689000423,
      },
      {
        lon: 106.94991549100098,
        lat: 10.415532435000898,
      },
      {
        lon: 106.94721229100094,
        lat: 10.416138170000124,
      },
      {
        lon: 106.94630043133992,
        lat: 10.418721770128457,
      },
      {
        lon: 106.94689053766939,
        lat: 10.422414693609596,
      },
      {
        lon: 106.94487275473631,
        lat: 10.42266215755427,
      },
      {
        lon: 106.94194125877709,
        lat: 10.420739553061367,
      },
      {
        lon: 106.9432705610011,
        lat: 10.41715550200081,
      },
      {
        lon: 106.94267186900095,
        lat: 10.410825026000339,
      },
      {
        lon: 106.94073326400104,
        lat: 10.410898007000071,
      },
      {
        lon: 106.93943489318394,
        lat: 10.413229815495924,
      },
      {
        lon: 106.9383938789939,
        lat: 10.41340827507139,
      },
      {
        lon: 106.93229789000088,
        lat: 10.412999885000431,
      },
      {
        lon: 106.93132274915672,
        lat: 10.412212257153442,
      },
    ],
    [
      {
        lon: 106.77921905200066,
        lat: 10.669254216000695,
      },
      {
        lon: 106.78319101300059,
        lat: 10.669672980000541,
      },
      {
        lon: 106.78566315000057,
        lat: 10.667921257000614,
      },
      {
        lon: 106.78872342056553,
        lat: 10.6665724693637,
      },
      {
        lon: 106.79303492200069,
        lat: 10.66201755500066,
      },
      {
        lon: 106.79565610200069,
        lat: 10.66000822300066,
      },
      {
        lon: 106.79844928683752,
        lat: 10.658398616777959,
      },
      {
        lon: 106.80293287700067,
        lat: 10.656467132000671,
      },
      {
        lon: 106.8054825940007,
        lat: 10.654906246000678,
      },
      {
        lon: 106.80669083600083,
        lat: 10.653218980000702,
      },
      {
        lon: 106.80789135300063,
        lat: 10.650074291000635,
      },
      {
        lon: 106.80914156938593,
        lat: 10.644375224582063,
      },
      {
        lon: 106.81026994100057,
        lat: 10.641647254000702,
      },
      {
        lon: 106.81456121900055,
        lat: 10.635126762000482,
      },
      {
        lon: 106.81972887300071,
        lat: 10.628891954000665,
      },
      {
        lon: 106.82185680688764,
        lat: 10.62686439185103,
      },
      {
        lon: 106.82617898000069,
        lat: 10.624844493000694,
      },
      {
        lon: 106.83092094300059,
        lat: 10.623903667000661,
      },
      {
        lon: 106.83323302700057,
        lat: 10.6242453990007,
      },
      {
        lon: 106.83646437403547,
        lat: 10.624044124246495,
      },
      {
        lon: 106.83909075000076,
        lat: 10.623150368000779,
      },
      {
        lon: 106.83962961600076,
        lat: 10.622294583000382,
      },
      {
        lon: 106.84411518100069,
        lat: 10.619899945000668,
      },
      {
        lon: 106.8436361390007,
        lat: 10.617639119000671,
      },
      {
        lon: 106.84084370000068,
        lat: 10.610189854000623,
      },
      {
        lon: 106.83863379700068,
        lat: 10.609431512000583,
      },
      {
        lon: 106.83718867900063,
        lat: 10.611267274000227,
      },
      {
        lon: 106.83548491500056,
        lat: 10.611898446000225,
      },
      {
        lon: 106.83262712300069,
        lat: 10.610964717000684,
      },
      {
        lon: 106.83191055400067,
        lat: 10.60902893100058,
      },
      {
        lon: 106.82905681400064,
        lat: 10.606462827000541,
      },
      {
        lon: 106.82860672900057,
        lat: 10.604198223000196,
      },
      {
        lon: 106.82396412700086,
        lat: 10.605438207000475,
      },
      {
        lon: 106.82156764500064,
        lat: 10.604157304000637,
      },
      {
        lon: 106.82038207900058,
        lat: 10.602357686000232,
      },
      {
        lon: 106.81836218900065,
        lat: 10.602149913,
      },
      {
        lon: 106.81702692900063,
        lat: 10.603633391000358,
      },
      {
        lon: 106.81828085600057,
        lat: 10.606200388000389,
      },
      {
        lon: 106.81839440700065,
        lat: 10.608816275000379,
      },
      {
        lon: 106.81711031700064,
        lat: 10.60990577000058,
      },
      {
        lon: 106.81442367100055,
        lat: 10.608978077000643,
      },
      {
        lon: 106.81158120600065,
        lat: 10.605007570000282,
      },
      {
        lon: 106.81379224000062,
        lat: 10.603642523000588,
      },
      {
        lon: 106.8144671360007,
        lat: 10.600357408000662,
      },
      {
        lon: 106.81312060700071,
        lat: 10.5977459460006,
      },
      {
        lon: 106.80560316700064,
        lat: 10.595997090000651,
      },
      {
        lon: 106.80614157300062,
        lat: 10.595348600000648,
      },
      {
        lon: 106.81309389700067,
        lat: 10.59700475900064,
      },
      {
        lon: 106.81477267100064,
        lat: 10.598545056000432,
      },
      {
        lon: 106.81520096900056,
        lat: 10.601326807000646,
      },
      {
        lon: 106.81490044600065,
        lat: 10.603157764000173,
      },
      {
        lon: 106.81274991500061,
        lat: 10.60479945400067,
      },
      {
        lon: 106.81469198200062,
        lat: 10.608506702999989,
      },
      {
        lon: 106.8172816130006,
        lat: 10.609186746000034,
      },
      {
        lon: 106.8181217200007,
        lat: 10.607771094000498,
      },
      {
        lon: 106.81645871500069,
        lat: 10.603243705000637,
      },
      {
        lon: 106.81824424700062,
        lat: 10.60160349700027,
      },
      {
        lon: 106.81997371900059,
        lat: 10.601643857000056,
      },
      {
        lon: 106.82271362000068,
        lat: 10.604558589000533,
      },
      {
        lon: 106.82392459300064,
        lat: 10.605029511000055,
      },
      {
        lon: 106.8259502270007,
        lat: 10.604648578000422,
      },
      {
        lon: 106.82755990000948,
        lat: 10.603529087459265,
      },
      {
        lon: 106.82901422500055,
        lat: 10.603870869000843,
      },
      {
        lon: 106.83004636200059,
        lat: 10.607108348000565,
      },
      {
        lon: 106.83265910400058,
        lat: 10.60896153400023,
      },
      {
        lon: 106.83277931900068,
        lat: 10.609938344000227,
      },
      {
        lon: 106.83507552900062,
        lat: 10.61120121900018,
      },
      {
        lon: 106.83636934800063,
        lat: 10.6110619170002,
      },
      {
        lon: 106.83726454400062,
        lat: 10.609648458000606,
      },
      {
        lon: 106.8390371660006,
        lat: 10.60870845500034,
      },
      {
        lon: 106.84169457800056,
        lat: 10.610394142000644,
      },
      {
        lon: 106.84480324800069,
        lat: 10.618927041000461,
      },
      {
        lon: 106.84553485300057,
        lat: 10.61952220200061,
      },
      {
        lon: 106.84894186417347,
        lat: 10.619432077557406,
      },
      {
        lon: 106.85324219100056,
        lat: 10.620696189000114,
      },
      {
        lon: 106.85690849600059,
        lat: 10.620645934000663,
      },
      {
        lon: 106.85794814600064,
        lat: 10.619176881000726,
      },
      {
        lon: 106.85954952200063,
        lat: 10.61455944900058,
      },
      {
        lon: 106.86211204800077,
        lat: 10.609596502000556,
      },
      {
        lon: 106.86231013500071,
        lat: 10.60778060500013,
      },
      {
        lon: 106.86359868400064,
        lat: 10.607498678000695,
      },
      {
        lon: 106.86475907700066,
        lat: 10.605736909000143,
      },
      {
        lon: 106.86550319600065,
        lat: 10.603161350000676,
      },
      {
        lon: 106.86424645323427,
        lat: 10.601576550596235,
      },
      {
        lon: 106.8599299768274,
        lat: 10.59732402477482,
      },
      {
        lon: 106.85815023500061,
        lat: 10.593645764000428,
      },
      {
        lon: 106.85753983400065,
        lat: 10.590387384000678,
      },
      {
        lon: 106.8576708670007,
        lat: 10.587901000000102,
      },
      {
        lon: 106.85654020700059,
        lat: 10.585925124000596,
      },
      {
        lon: 106.85212188711307,
        lat: 10.585096869152949,
      },
      {
        lon: 106.84531465016539,
        lat: 10.584928428524222,
      },
      {
        lon: 106.83762818200069,
        lat: 10.585926709000262,
      },
      {
        lon: 106.8358816950007,
        lat: 10.585446831000525,
      },
      {
        lon: 106.8330154040006,
        lat: 10.582653253000672,
      },
      {
        lon: 106.83162690657565,
        lat: 10.582823791131887,
      },
      {
        lon: 106.82632260700063,
        lat: 10.584547453000539,
      },
      {
        lon: 106.82395712500062,
        lat: 10.58397574600059,
      },
      {
        lon: 106.82269191700061,
        lat: 10.582578519000549,
      },
      {
        lon: 106.82017919500072,
        lat: 10.581483186000582,
      },
      {
        lon: 106.8179181120006,
        lat: 10.583609279000541,
      },
      {
        lon: 106.81211259000071,
        lat: 10.583004816000539,
      },
      {
        lon: 106.81077532700067,
        lat: 10.581540569000083,
      },
      {
        lon: 106.80954835400064,
        lat: 10.581614028000388,
      },
      {
        lon: 106.80878958687936,
        lat: 10.583819986211381,
      },
      {
        lon: 106.80533337200063,
        lat: 10.587771065000652,
      },
      {
        lon: 106.80483720000058,
        lat: 10.590363563000551,
      },
      {
        lon: 106.8054921390007,
        lat: 10.593342565000682,
      },
      {
        lon: 106.80510377064775,
        lat: 10.595221080717522,
      },
      {
        lon: 106.79916697700065,
        lat: 10.596085166000478,
      },
      {
        lon: 106.79794551200058,
        lat: 10.595801734000474,
      },
      {
        lon: 106.79685153500054,
        lat: 10.593490653000117,
      },
      {
        lon: 106.78859834800062,
        lat: 10.594200585000237,
      },
      {
        lon: 106.78887721100051,
        lat: 10.593506552000463,
      },
      {
        lon: 106.79635231600054,
        lat: 10.592757366000285,
      },
      {
        lon: 106.7975369080005,
        lat: 10.593374171000448,
      },
      {
        lon: 106.79878530500054,
        lat: 10.595243660000506,
      },
      {
        lon: 106.8036316240005,
        lat: 10.594880585000567,
      },
      {
        lon: 106.80463341100068,
        lat: 10.594355635000454,
      },
      {
        lon: 106.80407786600063,
        lat: 10.589419972000371,
      },
      {
        lon: 106.80506926200059,
        lat: 10.586668944000678,
      },
      {
        lon: 106.80794015300057,
        lat: 10.583648804000347,
      },
      {
        lon: 106.80945284000057,
        lat: 10.580636092000475,
      },
      {
        lon: 106.81050045200055,
        lat: 10.580421384000658,
      },
      {
        lon: 106.81226712200055,
        lat: 10.582248188000278,
      },
      {
        lon: 106.81481208600064,
        lat: 10.582304232000297,
      },
      {
        lon: 106.8175247560007,
        lat: 10.582956078000052,
      },
      {
        lon: 106.82003922700068,
        lat: 10.580663000000584,
      },
      {
        lon: 106.82369951500067,
        lat: 10.582279740000047,
      },
      {
        lon: 106.82473753300076,
        lat: 10.583540247000542,
      },
      {
        lon: 106.82647168600062,
        lat: 10.58365562500029,
      },
      {
        lon: 106.8314867490007,
        lat: 10.581787512000176,
      },
      {
        lon: 106.83246990700064,
        lat: 10.580601605000197,
      },
      {
        lon: 106.83314106700061,
        lat: 10.577005696000299,
      },
      {
        lon: 106.83573025900063,
        lat: 10.570542896000632,
      },
      {
        lon: 106.83530675427778,
        lat: 10.566443631244908,
      },
      {
        lon: 106.83554867200075,
        lat: 10.56294649500046,
      },
      {
        lon: 106.8361973450007,
        lat: 10.559675024000228,
      },
      {
        lon: 106.8375671300006,
        lat: 10.556541899000251,
      },
      {
        lon: 106.83906513800059,
        lat: 10.55485480400057,
      },
      {
        lon: 106.84358067700055,
        lat: 10.55182060600032,
      },
      {
        lon: 106.84760794600061,
        lat: 10.549629790999973,
      },
      {
        lon: 106.84728848205214,
        lat: 10.54760645595951,
      },
      {
        lon: 106.84546683800056,
        lat: 10.54393415800007,
      },
      {
        lon: 106.84517283500065,
        lat: 10.542033750000599,
      },
      {
        lon: 106.84778431000063,
        lat: 10.538783686000613,
      },
      {
        lon: 106.85087832100055,
        lat: 10.53607043300019,
      },
      {
        lon: 106.85377916900057,
        lat: 10.534851065000566,
      },
      {
        lon: 106.85878495400061,
        lat: 10.533575418000623,
      },
      {
        lon: 106.86143351656739,
        lat: 10.53190431352806,
      },
      {
        lon: 106.86418642100068,
        lat: 10.529511609000565,
      },
      {
        lon: 106.86808520749008,
        lat: 10.52473377315647,
      },
      {
        lon: 106.87244749401907,
        lat: 10.527311487923441,
      },
      {
        lon: 106.8701717750007,
        lat: 10.530288051000127,
      },
      {
        lon: 106.86663227300056,
        lat: 10.533903495000686,
      },
      {
        lon: 106.86376371721104,
        lat: 10.536061281471234,
      },
      {
        lon: 106.86069503100066,
        lat: 10.53780283800063,
      },
      {
        lon: 106.85781132400069,
        lat: 10.538818194000612,
      },
      {
        lon: 106.8515658360007,
        lat: 10.540504590000523,
      },
      {
        lon: 106.8513652010007,
        lat: 10.541167668000696,
      },
      {
        lon: 106.85310238100055,
        lat: 10.545914978000553,
      },
      {
        lon: 106.85214209200058,
        lat: 10.550017538000702,
      },
      {
        lon: 106.8500522770006,
        lat: 10.553948421000428,
      },
      {
        lon: 106.84784906900057,
        lat: 10.555428231000121,
      },
      {
        lon: 106.84111589700069,
        lat: 10.558382580000227,
      },
      {
        lon: 106.84004348100063,
        lat: 10.559436899000332,
      },
      {
        lon: 106.83960914500057,
        lat: 10.562397418000614,
      },
      {
        lon: 106.84013673481593,
        lat: 10.567370460281325,
      },
      {
        lon: 106.83979729779503,
        lat: 10.571531537230669,
      },
      {
        lon: 106.8369520510006,
        lat: 10.578066035000575,
      },
      {
        lon: 106.83630074700056,
        lat: 10.580603450000638,
      },
      {
        lon: 106.83752666200064,
        lat: 10.58172626600026,
      },
      {
        lon: 106.8430554210006,
        lat: 10.580922448000365,
      },
      {
        lon: 106.84512729400069,
        lat: 10.580889315000377,
      },
      {
        lon: 106.85404392400073,
        lat: 10.58178673800029,
      },
      {
        lon: 106.85843213400057,
        lat: 10.582972106000682,
      },
      {
        lon: 106.85974887300068,
        lat: 10.584351395000681,
      },
      {
        lon: 106.86155955400068,
        lat: 10.587747276000528,
      },
      {
        lon: 106.86162120400064,
        lat: 10.59174801300054,
      },
      {
        lon: 106.86345915500056,
        lat: 10.593418510000276,
      },
      {
        lon: 106.86616427700073,
        lat: 10.592195277000599,
      },
      {
        lon: 106.86792750700062,
        lat: 10.590591003000554,
      },
      {
        lon: 106.86936038410857,
        lat: 10.58712302566272,
      },
      {
        lon: 106.87262188800065,
        lat: 10.58516578600063,
      },
      {
        lon: 106.87671512100064,
        lat: 10.583871492000299,
      },
      {
        lon: 106.87771877600062,
        lat: 10.581830341000625,
      },
      {
        lon: 106.8811413590006,
        lat: 10.578956841000547,
      },
      {
        lon: 106.88255558400061,
        lat: 10.576262454000641,
      },
      {
        lon: 106.8843568128076,
        lat: 10.575220147524874,
      },
      {
        lon: 106.88434151931028,
        lat: 10.577468291643697,
      },
      {
        lon: 106.8812276780007,
        lat: 10.581014724000568,
      },
      {
        lon: 106.87918832700063,
        lat: 10.58232326400082,
      },
      {
        lon: 106.87719809000055,
        lat: 10.585579213000642,
      },
      {
        lon: 106.87430435700065,
        lat: 10.585898852000355,
      },
      {
        lon: 106.87009746000064,
        lat: 10.588439038000388,
      },
      {
        lon: 106.87008962600055,
        lat: 10.590177152000138,
      },
      {
        lon: 106.8718508530007,
        lat: 10.591403505000642,
      },
      {
        lon: 106.87385504900061,
        lat: 10.593793026000355,
      },
      {
        lon: 106.87701057600066,
        lat: 10.594175554000572,
      },
      {
        lon: 106.8795733690007,
        lat: 10.596162096000626,
      },
      {
        lon: 106.88122401000061,
        lat: 10.596350286000586,
      },
      {
        lon: 106.88614627900063,
        lat: 10.595285338000565,
      },
      {
        lon: 106.89097352786865,
        lat: 10.59510949958866,
      },
      {
        lon: 106.8953516508247,
        lat: 10.596242470188656,
      },
      {
        lon: 106.89693388082225,
        lat: 10.597752780640803,
      },
      {
        lon: 106.8942656500007,
        lat: 10.598592281000549,
      },
      {
        lon: 106.89099431100067,
        lat: 10.596784007000679,
      },
      {
        lon: 106.88477285100066,
        lat: 10.597231472000658,
      },
      {
        lon: 106.88050666000055,
        lat: 10.598021610000671,
      },
      {
        lon: 106.8787640990007,
        lat: 10.597629203000622,
      },
      {
        lon: 106.87635837900064,
        lat: 10.595710628000575,
      },
      {
        lon: 106.87373796600059,
        lat: 10.595505282000659,
      },
      {
        lon: 106.87176748600064,
        lat: 10.594378035000545,
      },
      {
        lon: 106.87052903100056,
        lat: 10.592730606000202,
      },
      {
        lon: 106.86875138300059,
        lat: 10.592917007000437,
      },
      {
        lon: 106.8671292320007,
        lat: 10.596617634000665,
      },
      {
        lon: 106.86999018900059,
        lat: 10.60210941000065,
      },
      {
        lon: 106.86981605984965,
        lat: 10.606182575023013,
      },
      {
        lon: 106.86877037100055,
        lat: 10.608469183000636,
      },
      {
        lon: 106.86635630500064,
        lat: 10.611542909000207,
      },
      {
        lon: 106.86459072700075,
        lat: 10.615345953000656,
      },
      {
        lon: 106.86351135320047,
        lat: 10.61900520167768,
      },
      {
        lon: 106.86083844100062,
        lat: 10.624358071000586,
      },
      {
        lon: 106.85849474400061,
        lat: 10.625512424000647,
      },
      {
        lon: 106.85591830200059,
        lat: 10.625803957000699,
      },
      {
        lon: 106.85174886800058,
        lat: 10.625350315000617,
      },
      {
        lon: 106.84801890100056,
        lat: 10.624039952000658,
      },
      {
        lon: 106.84522026300067,
        lat: 10.624888277000593,
      },
      {
        lon: 106.83879032000056,
        lat: 10.629423822000604,
      },
      {
        lon: 106.83732888500055,
        lat: 10.627659473000563,
      },
      {
        lon: 106.83481004200058,
        lat: 10.626918422000244,
      },
      {
        lon: 106.82741542300062,
        lat: 10.627633670000648,
      },
      {
        lon: 106.8244784600007,
        lat: 10.628693385000645,
      },
      {
        lon: 106.82278525700065,
        lat: 10.629988766000622,
      },
      {
        lon: 106.81843696000067,
        lat: 10.63495197500032,
      },
      {
        lon: 106.81345542300056,
        lat: 10.641489064000211,
      },
      {
        lon: 106.81223520500066,
        lat: 10.64383475300059,
      },
      {
        lon: 106.81096194700064,
        lat: 10.64945103100058,
      },
      {
        lon: 106.80936142200062,
        lat: 10.653946195000572,
      },
      {
        lon: 106.80820304700063,
        lat: 10.65574781200013,
      },
      {
        lon: 106.80469201600067,
        lat: 10.659030268000663,
      },
      {
        lon: 106.79981654000068,
        lat: 10.661132299000599,
      },
      {
        lon: 106.7959130190007,
        lat: 10.663486928000623,
      },
      {
        lon: 106.79223923300066,
        lat: 10.666899161000629,
      },
      {
        lon: 106.78856176600061,
        lat: 10.669695400000592,
      },
      {
        lon: 106.78407979300056,
        lat: 10.672527225000692,
      },
      {
        lon: 106.77869496700055,
        lat: 10.673548473000096,
      },
      {
        lon: 106.77809250500064,
        lat: 10.668921948000559,
      },
      {
        lon: 106.77921905200066,
        lat: 10.669254216000695,
      },
    ],
    [
      {
        lon: 106.9452412661314,
        lat: 10.504913932716818,
      },
      {
        lon: 106.94525941394619,
        lat: 10.502127861165263,
      },
      {
        lon: 106.94613091000087,
        lat: 10.491685381000195,
      },
      {
        lon: 106.9473553630009,
        lat: 10.487084749000758,
      },
      {
        lon: 106.94643319200088,
        lat: 10.485858886000301,
      },
      {
        lon: 106.94465312700078,
        lat: 10.485453498000709,
      },
      {
        lon: 106.94403074000087,
        lat: 10.488780616000838,
      },
      {
        lon: 106.94229383000084,
        lat: 10.49053235800045,
      },
      {
        lon: 106.93947773500082,
        lat: 10.490397192000103,
      },
      {
        lon: 106.9364503690008,
        lat: 10.49308799700043,
      },
      {
        lon: 106.93508950900082,
        lat: 10.495125488000724,
      },
      {
        lon: 106.93509833200073,
        lat: 10.498639690000857,
      },
      {
        lon: 106.93431095000079,
        lat: 10.498491448000493,
      },
      {
        lon: 106.93465550000087,
        lat: 10.494450368000798,
      },
      {
        lon: 106.9361046450008,
        lat: 10.492098132000706,
      },
      {
        lon: 106.93925059400078,
        lat: 10.489502880000714,
      },
      {
        lon: 106.9419922660008,
        lat: 10.489729847000774,
      },
      {
        lon: 106.94341889800086,
        lat: 10.487812809000786,
      },
      {
        lon: 106.94327185600076,
        lat: 10.483670982000831,
      },
      {
        lon: 106.94281715000079,
        lat: 10.48305485100002,
      },
      {
        lon: 106.93515024200076,
        lat: 10.479160640000487,
      },
      {
        lon: 106.93242904600089,
        lat: 10.47832638600056,
      },
      {
        lon: 106.93059508800081,
        lat: 10.478672115000847,
      },
      {
        lon: 106.92940285900085,
        lat: 10.480042873000741,
      },
      {
        lon: 106.92765253500079,
        lat: 10.480660594000426,
      },
      {
        lon: 106.92596933700082,
        lat: 10.480250945000193,
      },
      {
        lon: 106.92503557700081,
        lat: 10.478892531999975,
      },
      {
        lon: 106.92455195100075,
        lat: 10.476386965000586,
      },
      {
        lon: 106.92300694700083,
        lat: 10.47505621,
      },
      {
        lon: 106.92153965000085,
        lat: 10.474872924000636,
      },
      {
        lon: 106.91921053100077,
        lat: 10.479535242000395,
      },
      {
        lon: 106.91739294100086,
        lat: 10.48138349200002,
      },
      {
        lon: 106.91658644500075,
        lat: 10.48638280700004,
      },
      {
        lon: 106.91328362900083,
        lat: 10.490357739000842,
      },
      {
        lon: 106.91063016000078,
        lat: 10.491219028999978,
      },
      {
        lon: 106.90921802600081,
        lat: 10.489804966000861,
      },
      {
        lon: 106.90930501800074,
        lat: 10.485264626000514,
      },
      {
        lon: 106.90846626800078,
        lat: 10.480070977000764,
      },
      {
        lon: 106.90754502300086,
        lat: 10.47745071600076,
      },
      {
        lon: 106.90652053200074,
        lat: 10.476279475000752,
      },
      {
        lon: 106.9037308640008,
        lat: 10.476897733000214,
      },
      {
        lon: 106.90270209300087,
        lat: 10.479404963000718,
      },
      {
        lon: 106.90185560300085,
        lat: 10.484016523000719,
      },
      {
        lon: 106.9002168810008,
        lat: 10.485740660000829,
      },
      {
        lon: 106.89497732100085,
        lat: 10.488570099000185,
      },
      {
        lon: 106.89243600500072,
        lat: 10.488626461000763,
      },
      {
        lon: 106.89155867300082,
        lat: 10.490170700000192,
      },
      {
        lon: 106.89335009100085,
        lat: 10.490613024000197,
      },
      {
        lon: 106.8981042030008,
        lat: 10.49039513800011,
      },
      {
        lon: 106.8991735620009,
        lat: 10.492398793000802,
      },
      {
        lon: 106.89686098700076,
        lat: 10.494633132000843,
      },
      {
        lon: 106.89381860000083,
        lat: 10.494714948000308,
      },
      {
        lon: 106.89156697800087,
        lat: 10.495685503000232,
      },
      {
        lon: 106.89226007600087,
        lat: 10.497590141000614,
      },
      {
        lon: 106.89420356800088,
        lat: 10.497187560000611,
      },
      {
        lon: 106.89704887100083,
        lat: 10.495429584000817,
      },
      {
        lon: 106.89762365200073,
        lat: 10.496501586000704,
      },
      {
        lon: 106.89383835700079,
        lat: 10.498630095000863,
      },
      {
        lon: 106.89238130800072,
        lat: 10.498679145000835,
      },
      {
        lon: 106.8905788060008,
        lat: 10.497442460000741,
      },
      {
        lon: 106.89074970700085,
        lat: 10.494780710000782,
      },
      {
        lon: 106.89311140400076,
        lat: 10.492039630000818,
      },
      {
        lon: 106.89128516723292,
        lat: 10.491552200028877,
      },
      {
        lon: 106.89003362700072,
        lat: 10.489389547000485,
      },
      {
        lon: 106.89035941400078,
        lat: 10.487946624000756,
      },
      {
        lon: 106.89489155200079,
        lat: 10.487130704000549,
      },
      {
        lon: 106.89855375400077,
        lat: 10.485372323000831,
      },
      {
        lon: 106.9006412580008,
        lat: 10.483602233000738,
      },
      {
        lon: 106.90200909000083,
        lat: 10.477331341000763,
      },
      {
        lon: 106.90458539800085,
        lat: 10.475110512000754,
      },
      {
        lon: 106.90605857100076,
        lat: 10.474966539000832,
      },
      {
        lon: 106.90849570000074,
        lat: 10.476555119000466,
      },
      {
        lon: 106.90960693900075,
        lat: 10.478709919000778,
      },
      {
        lon: 106.91067991200077,
        lat: 10.48416729300078,
      },
      {
        lon: 106.91079645600087,
        lat: 10.488591095000857,
      },
      {
        lon: 106.91182260400086,
        lat: 10.489471063000849,
      },
      {
        lon: 106.91513510000084,
        lat: 10.485652901000776,
      },
      {
        lon: 106.91600270200087,
        lat: 10.481134815000871,
      },
      {
        lon: 106.91865122500087,
        lat: 10.477560358000062,
      },
      {
        lon: 106.92033611300081,
        lat: 10.47355572600054,
      },
      {
        lon: 106.92187941900079,
        lat: 10.47330708500084,
      },
      {
        lon: 106.92494918700078,
        lat: 10.474404431000153,
      },
      {
        lon: 106.92745478900082,
        lat: 10.479126592000512,
      },
      {
        lon: 106.93002277100084,
        lat: 10.47710476200075,
      },
      {
        lon: 106.93236962900085,
        lat: 10.476472297000726,
      },
      {
        lon: 106.93357326900076,
        lat: 10.475378622000733,
      },
      {
        lon: 106.9319720290008,
        lat: 10.474009551000165,
      },
      {
        lon: 106.9308353670009,
        lat: 10.468583276000345,
      },
      {
        lon: 106.93149327700098,
        lat: 10.466518112000358,
      },
      {
        lon: 106.93446492581688,
        lat: 10.463758997939408,
      },
      {
        lon: 106.95802843164842,
        lat: 10.466916993566372,
      },
      {
        lon: 106.95704414700106,
        lat: 10.468154346000915,
      },
      {
        lon: 106.95211941300107,
        lat: 10.469675457000562,
      },
      {
        lon: 106.9504894100009,
        lat: 10.470591251000485,
      },
      {
        lon: 106.94522422000102,
        lat: 10.472167819000902,
      },
      {
        lon: 106.94558964800086,
        lat: 10.473239335000756,
      },
      {
        lon: 106.94928833000085,
        lat: 10.476075958000706,
      },
      {
        lon: 106.95110897300077,
        lat: 10.476931634000266,
      },
      {
        lon: 106.95378175600086,
        lat: 10.480128428000626,
      },
      {
        lon: 106.95531988483157,
        lat: 10.485268789820225,
      },
      {
        lon: 106.95541468400074,
        lat: 10.489141050000796,
      },
      {
        lon: 106.95478651600087,
        lat: 10.491826168000387,
      },
      {
        lon: 106.95266870938065,
        lat: 10.497656260399822,
      },
      {
        lon: 106.95257619300082,
        lat: 10.502038265000238,
      },
      {
        lon: 106.95301800305873,
        lat: 10.509810173752385,
      },
      {
        lon: 106.95195817900088,
        lat: 10.5159096400007,
      },
      {
        lon: 106.95005074100078,
        lat: 10.521019994000767,
      },
      {
        lon: 106.94775151200088,
        lat: 10.524303584000847,
      },
      {
        lon: 106.94592413500078,
        lat: 10.525990963000766,
      },
      {
        lon: 106.94183730000094,
        lat: 10.52842843000036,
      },
      {
        lon: 106.93773951100087,
        lat: 10.530095401000722,
      },
      {
        lon: 106.93247729400076,
        lat: 10.530870375000347,
      },
      {
        lon: 106.92826194200086,
        lat: 10.532125020000876,
      },
      {
        lon: 106.92361345600085,
        lat: 10.534860767000112,
      },
      {
        lon: 106.92128681400072,
        lat: 10.535646711000481,
      },
      {
        lon: 106.91885083800076,
        lat: 10.535456856000849,
      },
      {
        lon: 106.91345736700083,
        lat: 10.532605418000287,
      },
      {
        lon: 106.91131155600084,
        lat: 10.532076846000791,
      },
      {
        lon: 106.90829392900082,
        lat: 10.533431787000723,
      },
      {
        lon: 106.90711083100081,
        lat: 10.536412711000821,
      },
      {
        lon: 106.90767305900079,
        lat: 10.538400755000623,
      },
      {
        lon: 106.90901435700073,
        lat: 10.540057266000815,
      },
      {
        lon: 106.90989690800086,
        lat: 10.542645168000718,
      },
      {
        lon: 106.90980396200081,
        lat: 10.548285566000573,
      },
      {
        lon: 106.9092932080008,
        lat: 10.550186851999968,
      },
      {
        lon: 106.90764971800087,
        lat: 10.552479944000709,
      },
      {
        lon: 106.90441293700087,
        lat: 10.554574160000243,
      },
      {
        lon: 106.90167336200079,
        lat: 10.557981979000033,
      },
      {
        lon: 106.90067454500081,
        lat: 10.562797651000778,
      },
      {
        lon: 106.89800394200074,
        lat: 10.566856491000863,
      },
      {
        lon: 106.89482354900076,
        lat: 10.569050743000332,
      },
      {
        lon: 106.89046030100087,
        lat: 10.570744461000857,
      },
      {
        lon: 106.88851962600097,
        lat: 10.572233080000842,
      },
      {
        lon: 106.88732772800074,
        lat: 10.57439672100071,
      },
      {
        lon: 106.88726120700085,
        lat: 10.576860029000784,
      },
      {
        lon: 106.89104534900076,
        lat: 10.584024146000672,
      },
      {
        lon: 106.89453651100081,
        lat: 10.589582759000791,
      },
      {
        lon: 106.89577492000086,
        lat: 10.591943198000422,
      },
      {
        lon: 106.89827550900094,
        lat: 10.594692794000196,
      },
      {
        lon: 106.89822092400073,
        lat: 10.596906258000729,
      },
      {
        lon: 106.89693388082242,
        lat: 10.597752780640803,
      },
      {
        lon: 106.89535165082492,
        lat: 10.596242470188827,
      },
      {
        lon: 106.89437285239131,
        lat: 10.593877455450126,
      },
      {
        lon: 106.88831408000084,
        lat: 10.58465543000068,
      },
      {
        lon: 106.88434151931051,
        lat: 10.577468291643868,
      },
      {
        lon: 106.88435681280782,
        lat: 10.575220147525044,
      },
      {
        lon: 106.88558848400072,
        lat: 10.570939950000593,
      },
      {
        lon: 106.8870523910008,
        lat: 10.5693694360005,
      },
      {
        lon: 106.88966259900076,
        lat: 10.567617696000866,
      },
      {
        lon: 106.89379815700084,
        lat: 10.565675859000692,
      },
      {
        lon: 106.89583393000078,
        lat: 10.563997344000825,
      },
      {
        lon: 106.89642687500088,
        lat: 10.560137869000243,
      },
      {
        lon: 106.89975604000074,
        lat: 10.55439311600054,
      },
      {
        lon: 106.90269775800078,
        lat: 10.550335482000719,
      },
      {
        lon: 106.90669948900086,
        lat: 10.546063191000826,
      },
      {
        lon: 106.90387355800073,
        lat: 10.54061951700072,
      },
      {
        lon: 106.9035651080008,
        lat: 10.538751225000851,
      },
      {
        lon: 106.90401796018693,
        lat: 10.534038173588442,
      },
      {
        lon: 106.90541293400094,
        lat: 10.531232914000782,
      },
      {
        lon: 106.9087823210009,
        lat: 10.528142739000259,
      },
      {
        lon: 106.91093149600084,
        lat: 10.52756053300078,
      },
      {
        lon: 106.9108889890008,
        lat: 10.524179443000605,
      },
      {
        lon: 106.90962474400072,
        lat: 10.52078526300079,
      },
      {
        lon: 106.90689183000092,
        lat: 10.518932090000135,
      },
      {
        lon: 106.90390141700084,
        lat: 10.518517932000861,
      },
      {
        lon: 106.90216611643143,
        lat: 10.519405183853166,
      },
      {
        lon: 106.89775047300083,
        lat: 10.52082051300033,
      },
      {
        lon: 106.89342913000075,
        lat: 10.521524368000769,
      },
      {
        lon: 106.88755846900074,
        lat: 10.520898137000358,
      },
      {
        lon: 106.88467606700084,
        lat: 10.521192016000782,
      },
      {
        lon: 106.88052897900087,
        lat: 10.522210294000843,
      },
      {
        lon: 106.87581209800078,
        lat: 10.523961266000583,
      },
      {
        lon: 106.87353650500091,
        lat: 10.525774793000267,
      },
      {
        lon: 106.87244749401924,
        lat: 10.527311487923612,
      },
      {
        lon: 106.86808520749025,
        lat: 10.52473377315664,
      },
      {
        lon: 106.87023800300085,
        lat: 10.521911206000425,
      },
      {
        lon: 106.87048831200082,
        lat: 10.520168093,
      },
      {
        lon: 106.86905003200087,
        lat: 10.51878910000056,
      },
      {
        lon: 106.87030050900086,
        lat: 10.517692809000721,
      },
      {
        lon: 106.86980593500078,
        lat: 10.513587090000556,
      },
      {
        lon: 106.86673313700072,
        lat: 10.51104774600077,
      },
      {
        lon: 106.86574605394316,
        lat: 10.508588403042154,
      },
      {
        lon: 106.86633602900093,
        lat: 10.507351865000771,
      },
      {
        lon: 106.86831684100088,
        lat: 10.50588336900006,
      },
      {
        lon: 106.87162470100083,
        lat: 10.504284558000734,
      },
      {
        lon: 106.87241632500076,
        lat: 10.502900075000866,
      },
      {
        lon: 106.87179158300074,
        lat: 10.501016572000708,
      },
      {
        lon: 106.86973351800071,
        lat: 10.499185078000153,
      },
      {
        lon: 106.86666820677812,
        lat: 10.499167963631407,
      },
      {
        lon: 106.86443092800079,
        lat: 10.49797652000052,
      },
      {
        lon: 106.86700181600082,
        lat: 10.496371093000846,
      },
      {
        lon: 106.87052321300081,
        lat: 10.496520229000737,
      },
      {
        lon: 106.87403779800087,
        lat: 10.49366752400033,
      },
      {
        lon: 106.87439412200087,
        lat: 10.49045033699997,
      },
      {
        lon: 106.87557314300085,
        lat: 10.489954578000606,
      },
      {
        lon: 106.87989680400074,
        lat: 10.490507896000736,
      },
      {
        lon: 106.88314720700083,
        lat: 10.492622609000648,
      },
      {
        lon: 106.88404065100082,
        lat: 10.495150700000124,
      },
      {
        lon: 106.88257286500072,
        lat: 10.497448533000806,
      },
      {
        lon: 106.88356078400085,
        lat: 10.499206650000872,
      },
      {
        lon: 106.88815722600083,
        lat: 10.501208170000211,
      },
      {
        lon: 106.89543774100076,
        lat: 10.502388516,
      },
      {
        lon: 106.89212808100086,
        lat: 10.502971746000469,
      },
      {
        lon: 106.88710065000083,
        lat: 10.501959590000752,
      },
      {
        lon: 106.88271336500082,
        lat: 10.499681308000731,
      },
      {
        lon: 106.88170210600072,
        lat: 10.498259464000853,
      },
      {
        lon: 106.88325988400084,
        lat: 10.495087339000122,
      },
      {
        lon: 106.88182859800088,
        lat: 10.492364353000596,
      },
      {
        lon: 106.87984582900089,
        lat: 10.491309004000584,
      },
      {
        lon: 106.87700893500079,
        lat: 10.490700695000612,
      },
      {
        lon: 106.8752151490009,
        lat: 10.49079792700013,
      },
      {
        lon: 106.8748665470008,
        lat: 10.493594045000748,
      },
      {
        lon: 106.87409518800087,
        lat: 10.494914551000761,
      },
      {
        lon: 106.87141111300087,
        lat: 10.497026146000223,
      },
      {
        lon: 106.87368013791871,
        lat: 10.50188761604187,
      },
      {
        lon: 106.87391520900081,
        lat: 10.503633272000855,
      },
      {
        lon: 106.87208442600075,
        lat: 10.506678928000781,
      },
      {
        lon: 106.86786382900084,
        lat: 10.50872669500086,
      },
      {
        lon: 106.86817848300075,
        lat: 10.51006934400084,
      },
      {
        lon: 106.87024089900086,
        lat: 10.511557120000816,
      },
      {
        lon: 106.8717695190009,
        lat: 10.5146979700001,
      },
      {
        lon: 106.87215492000087,
        lat: 10.51795250700053,
      },
      {
        lon: 106.87402785300088,
        lat: 10.519940320999979,
      },
      {
        lon: 106.88418873400076,
        lat: 10.516615287000377,
      },
      {
        lon: 106.88749735700088,
        lat: 10.516572033000728,
      },
      {
        lon: 106.89314423100092,
        lat: 10.517355836000831,
      },
      {
        lon: 106.89582383600083,
        lat: 10.516792001999988,
      },
      {
        lon: 106.90068500299225,
        lat: 10.514801074765728,
      },
      {
        lon: 106.90358671600097,
        lat: 10.514399786000297,
      },
      {
        lon: 106.90742476000077,
        lat: 10.514994354000464,
      },
      {
        lon: 106.91040385400089,
        lat: 10.516193131000819,
      },
      {
        lon: 106.91278883653928,
        lat: 10.518048952204026,
      },
      {
        lon: 106.91534898700087,
        lat: 10.521928952000849,
      },
      {
        lon: 106.91644684500073,
        lat: 10.526373586000716,
      },
      {
        lon: 106.91810428600081,
        lat: 10.528274058000022,
      },
      {
        lon: 106.92008068500081,
        lat: 10.528532257000336,
      },
      {
        lon: 106.92298292728998,
        lat: 10.527255305996391,
      },
      {
        lon: 106.9265051850009,
        lat: 10.525048155000718,
      },
      {
        lon: 106.93213678200084,
        lat: 10.522991030000242,
      },
      {
        lon: 106.93453772375665,
        lat: 10.522526163772113,
      },
      {
        lon: 106.94048455900082,
        lat: 10.520128705000843,
      },
      {
        lon: 106.94292874100091,
        lat: 10.517590380000854,
      },
      {
        lon: 106.9442530860008,
        lat: 10.514311899000575,
      },
      {
        lon: 106.94533392700077,
        lat: 10.508761354000796,
      },
      {
        lon: 106.9452412661314,
        lat: 10.504913932716818,
      },
    ],
    [
      {
        lon: 106.85612752529369,
        lat: 10.447376024033588,
      },
      {
        lon: 106.85694505900034,
        lat: 10.445874979000223,
      },
      {
        lon: 106.8544955620003,
        lat: 10.435273500000335,
      },
      {
        lon: 106.85465104100024,
        lat: 10.42199301400035,
      },
      {
        lon: 106.85454900400043,
        lat: 10.415759508000235,
      },
      {
        lon: 106.85396199400037,
        lat: 10.413136498000313,
      },
      {
        lon: 106.84999537600027,
        lat: 10.406803520000324,
      },
      {
        lon: 106.83890127600034,
        lat: 10.396526963000099,
      },
      {
        lon: 106.8347474490003,
        lat: 10.395441828000287,
      },
      {
        lon: 106.83262661100031,
        lat: 10.396053162999976,
      },
      {
        lon: 106.8305451510002,
        lat: 10.394269829000223,
      },
      {
        lon: 106.81428355300034,
        lat: 10.382326897999974,
      },
      {
        lon: 106.8182410580003,
        lat: 10.374541065000358,
      },
      {
        lon: 106.82824945200025,
        lat: 10.352539738000246,
      },
      {
        lon: 106.84090694800022,
        lat: 10.35591194400024,
      },
      {
        lon: 106.85275154800019,
        lat: 10.358330608000301,
      },
      {
        lon: 106.87585918600024,
        lat: 10.360718622000206,
      },
      {
        lon: 106.8945400790002,
        lat: 10.365085022000358,
      },
      {
        lon: 106.91844992000028,
        lat: 10.373980005000305,
      },
      {
        lon: 106.94263304600031,
        lat: 10.385448764000216,
      },
      {
        lon: 106.9353453250003,
        lat: 10.393634096000312,
      },
      {
        lon: 106.93420749700033,
        lat: 10.393385631000285,
      },
      {
        lon: 106.93059355400015,
        lat: 10.390530145000241,
      },
      {
        lon: 106.92422912100034,
        lat: 10.387238885000272,
      },
      {
        lon: 106.91844998000028,
        lat: 10.383450017000087,
      },
      {
        lon: 106.90467137700023,
        lat: 10.375056682000263,
      },
      {
        lon: 106.90414278100036,
        lat: 10.37946331400019,
      },
      {
        lon: 106.90155419100023,
        lat: 10.378154342000244,
      },
      {
        lon: 106.89968921800022,
        lat: 10.376420770000152,
      },
      {
        lon: 106.8858828830003,
        lat: 10.375641117000214,
      },
      {
        lon: 106.88081692900022,
        lat: 10.375701660000345,
      },
      {
        lon: 106.87496055200035,
        lat: 10.377815451000174,
      },
      {
        lon: 106.87977723700033,
        lat: 10.382558283000208,
      },
      {
        lon: 106.88268431600028,
        lat: 10.383179431000258,
      },
      {
        lon: 106.88428181074318,
        lat: 10.382287223058256,
      },
      {
        lon: 106.88605630700027,
        lat: 10.383134859000336,
      },
      {
        lon: 106.88750908000031,
        lat: 10.385048959000244,
      },
      {
        lon: 106.88936525500031,
        lat: 10.384396837000281,
      },
      {
        lon: 106.89156213900037,
        lat: 10.3851464950003,
      },
      {
        lon: 106.89437434600029,
        lat: 10.38483646900005,
      },
      {
        lon: 106.8968308180003,
        lat: 10.387021917000254,
      },
      {
        lon: 106.89845085900032,
        lat: 10.387446720000298,
      },
      {
        lon: 106.89943541769497,
        lat: 10.388825385827658,
      },
      {
        lon: 106.89871781400029,
        lat: 10.391381940000315,
      },
      {
        lon: 106.90105864300028,
        lat: 10.391258286000209,
      },
      {
        lon: 106.90406200800032,
        lat: 10.393794851,
      },
      {
        lon: 106.90417699200026,
        lat: 10.395738741000287,
      },
      {
        lon: 106.90547847300019,
        lat: 10.39634670300029,
      },
      {
        lon: 106.90838187900044,
        lat: 10.39934728700041,
      },
      {
        lon: 106.90694883000026,
        lat: 10.401925759000221,
      },
      {
        lon: 106.90803343300024,
        lat: 10.40322246600033,
      },
      {
        lon: 106.90978242800026,
        lat: 10.402333824000152,
      },
      {
        lon: 106.91090204700026,
        lat: 10.402269616000353,
      },
      {
        lon: 106.91208216700022,
        lat: 10.40288003500035,
      },
      {
        lon: 106.91272937400021,
        lat: 10.405653969000355,
      },
      {
        lon: 106.9143787510003,
        lat: 10.404846980000286,
      },
      {
        lon: 106.91525047900035,
        lat: 10.406181486000207,
      },
      {
        lon: 106.91390650600022,
        lat: 10.40821316000006,
      },
      {
        lon: 106.91507584100026,
        lat: 10.40971946000036,
      },
      {
        lon: 106.91666055000019,
        lat: 10.409878714000229,
      },
      {
        lon: 106.91915852800031,
        lat: 10.408991159000038,
      },
      {
        lon: 106.92031558800028,
        lat: 10.406263189000299,
      },
      {
        lon: 106.92280707600037,
        lat: 10.403977238000266,
      },
      {
        lon: 106.92425335300021,
        lat: 10.404479640999966,
      },
      {
        lon: 106.9263646916707,
        lat: 10.407128028058253,
      },
      {
        lon: 106.92765337300034,
        lat: 10.409623427000497,
      },
      {
        lon: 106.9269240180003,
        lat: 10.410359326000219,
      },
      {
        lon: 106.9238311070003,
        lat: 10.40509182500017,
      },
      {
        lon: 106.9218112930003,
        lat: 10.405409733000226,
      },
      {
        lon: 106.91952971800026,
        lat: 10.409520374000238,
      },
      {
        lon: 106.91532954842603,
        lat: 10.410540782393241,
      },
      {
        lon: 106.91344466800024,
        lat: 10.409324796000021,
      },
      {
        lon: 106.91346849900032,
        lat: 10.407536564000225,
      },
      {
        lon: 106.91219066900027,
        lat: 10.405872302000205,
      },
      {
        lon: 106.91205498000033,
        lat: 10.403349984000215,
      },
      {
        lon: 106.91080190300033,
        lat: 10.402529518,
      },
      {
        lon: 106.9072750210002,
        lat: 10.403704272000027,
      },
      {
        lon: 106.90648707498985,
        lat: 10.401812369378263,
      },
      {
        lon: 106.90767928000031,
        lat: 10.39930480500027,
      },
      {
        lon: 106.90550053200036,
        lat: 10.39695893300052,
      },
      {
        lon: 106.9042840270003,
        lat: 10.396550811000338,
      },
      {
        lon: 106.9012643110002,
        lat: 10.391670811000324,
      },
      {
        lon: 106.89867879600024,
        lat: 10.391807063000215,
      },
      {
        lon: 106.89774847200022,
        lat: 10.39076183400033,
      },
      {
        lon: 106.89885191700046,
        lat: 10.38828235799997,
      },
      {
        lon: 106.89640698500034,
        lat: 10.387282784000263,
      },
      {
        lon: 106.89425570400022,
        lat: 10.385269386000289,
      },
      {
        lon: 106.89130672700037,
        lat: 10.385600834000343,
      },
      {
        lon: 106.88989867400034,
        lat: 10.384817691000308,
      },
      {
        lon: 106.88735401100035,
        lat: 10.385528656000247,
      },
      {
        lon: 106.8843722650002,
        lat: 10.382886051000355,
      },
      {
        lon: 106.88211967600029,
        lat: 10.383873804000245,
      },
      {
        lon: 106.87982116600034,
        lat: 10.383632941000315,
      },
      {
        lon: 106.88040394300049,
        lat: 10.386193135000212,
      },
      {
        lon: 106.87326252100024,
        lat: 10.408991004000313,
      },
      {
        lon: 106.86943402300022,
        lat: 10.415902669000275,
      },
      {
        lon: 106.86554466600035,
        lat: 10.422068881000314,
      },
      {
        lon: 106.86264401400035,
        lat: 10.425969037000243,
      },
      {
        lon: 106.86204772300033,
        lat: 10.428498093000258,
      },
      {
        lon: 106.86520345400021,
        lat: 10.433397725000304,
      },
      {
        lon: 106.86869995700022,
        lat: 10.439699698000254,
      },
      {
        lon: 106.87304452800026,
        lat: 10.445762642000261,
      },
      {
        lon: 106.87824453900015,
        lat: 10.456118154000364,
      },
      {
        lon: 106.87763245900032,
        lat: 10.463071604000334,
      },
      {
        lon: 106.87828731100035,
        lat: 10.464727163000248,
      },
      {
        lon: 106.87586487100026,
        lat: 10.46866637899825,
      },
      {
        lon: 106.87222032900036,
        lat: 10.469999294000274,
      },
      {
        lon: 106.86762913000035,
        lat: 10.472182167000142,
      },
      {
        lon: 106.8649846810003,
        lat: 10.472837812000307,
      },
      {
        lon: 106.86588955700023,
        lat: 10.475729669000202,
      },
      {
        lon: 106.8686261560004,
        lat: 10.482165626000437,
      },
      {
        lon: 106.86950995800021,
        lat: 10.486222845000327,
      },
      {
        lon: 106.86925164555532,
        lat: 10.48946083339223,
      },
      {
        lon: 106.86807387500028,
        lat: 10.494553368000084,
      },
      {
        lon: 106.86682352947895,
        lat: 10.49647771182714,
      },
      {
        lon: 106.86412630300049,
        lat: 10.497798492000356,
      },
      {
        lon: 106.8605226692033,
        lat: 10.498072144301545,
      },
      {
        lon: 106.85806672100045,
        lat: 10.497447192000436,
      },
      {
        lon: 106.85545844400053,
        lat: 10.495708427000352,
      },
      {
        lon: 106.85250306000046,
        lat: 10.495073988000172,
      },
      {
        lon: 106.8513114970005,
        lat: 10.49584461600034,
      },
      {
        lon: 106.85041506100043,
        lat: 10.498197272000311,
      },
      {
        lon: 106.85060470100046,
        lat: 10.500637763000384,
      },
      {
        lon: 106.84920168500037,
        lat: 10.503601835000495,
      },
      {
        lon: 106.84728604400044,
        lat: 10.50506794700027,
      },
      {
        lon: 106.84276664300052,
        lat: 10.506473576000133,
      },
      {
        lon: 106.83951572800038,
        lat: 10.505240734000267,
      },
      {
        lon: 106.83687731900045,
        lat: 10.502617157000373,
      },
      {
        lon: 106.83394260400041,
        lat: 10.498787639000398,
      },
      {
        lon: 106.83113619900047,
        lat: 10.498058998000431,
      },
      {
        lon: 106.82853107700038,
        lat: 10.499561105000453,
      },
      {
        lon: 106.82719612000051,
        lat: 10.501290859000392,
      },
      {
        lon: 106.82608679457468,
        lat: 10.505059319504426,
      },
      {
        lon: 106.82429231888608,
        lat: 10.505335392687243,
      },
      {
        lon: 106.8251377390003,
        lat: 10.501529768000182,
      },
      {
        lon: 106.82683277400025,
        lat: 10.498980983000251,
      },
      {
        lon: 106.83012103404019,
        lat: 10.496904741197568,
      },
      {
        lon: 106.83220017700017,
        lat: 10.496507925000287,
      },
      {
        lon: 106.83217182400023,
        lat: 10.492453755000213,
      },
      {
        lon: 106.82937034300016,
        lat: 10.490916967000032,
      },
      {
        lon: 106.82794571200037,
        lat: 10.48942737300024,
      },
      {
        lon: 106.82763812800027,
        lat: 10.485883296000225,
      },
      {
        lon: 106.82875239900022,
        lat: 10.486134058000346,
      },
      {
        lon: 106.82874262700028,
        lat: 10.489154770000198,
      },
      {
        lon: 106.83231813500021,
        lat: 10.491381925000326,
      },
      {
        lon: 106.83324103300015,
        lat: 10.493778335000172,
      },
      {
        lon: 106.83329285100035,
        lat: 10.496345053000312,
      },
      {
        lon: 106.83703414700028,
        lat: 10.498274597000261,
      },
      {
        lon: 106.84143090600037,
        lat: 10.502549690000194,
      },
      {
        lon: 106.84390825400021,
        lat: 10.504025534000277,
      },
      {
        lon: 106.84496760700034,
        lat: 10.50368974300028,
      },
      {
        lon: 106.84731190300033,
        lat: 10.496710895000206,
      },
      {
        lon: 106.84863037900021,
        lat: 10.4949871190002,
      },
      {
        lon: 106.85323813100024,
        lat: 10.4916079690002,
      },
      {
        lon: 106.85531034000036,
        lat: 10.491105160000245,
      },
      {
        lon: 106.86169315600017,
        lat: 10.492159685000217,
      },
      {
        lon: 106.86354737900024,
        lat: 10.491982478000182,
      },
      {
        lon: 106.86483717000027,
        lat: 10.490242285000193,
      },
      {
        lon: 106.86508671200033,
        lat: 10.488535131000333,
      },
      {
        lon: 106.86353448835894,
        lat: 10.484189107416114,
      },
      {
        lon: 106.86233123098879,
        lat: 10.481983169343039,
      },
      {
        lon: 106.8599114290003,
        lat: 10.478900898000347,
      },
      {
        lon: 106.85877578800023,
        lat: 10.475083347000236,
      },
      {
        lon: 106.85981804900018,
        lat: 10.471873063000146,
      },
      {
        lon: 106.86196196400022,
        lat: 10.46835884400025,
      },
      {
        lon: 106.86558601675358,
        lat: 10.464585338942868,
      },
      {
        lon: 106.86940491400037,
        lat: 10.463334248000194,
      },
      {
        lon: 106.87015784000033,
        lat: 10.462601825000263,
      },
      {
        lon: 106.86991712100019,
        lat: 10.460039479000159,
      },
      {
        lon: 106.86793230100028,
        lat: 10.45877994500023,
      },
      {
        lon: 106.86157088200036,
        lat: 10.457041136000328,
      },
      {
        lon: 106.85825839841857,
        lat: 10.455234912883498,
      },
      {
        lon: 106.85612752529369,
        lat: 10.447376024033588,
      },
    ],
    [
      {
        lon: 106.82608679457468,
        lat: 10.505059319504426,
      },
      {
        lon: 106.82689199000038,
        lat: 10.505877620000206,
      },
      {
        lon: 106.83368321200044,
        lat: 10.508605581000438,
      },
      {
        lon: 106.8366315650004,
        lat: 10.511340579000432,
      },
      {
        lon: 106.8367690650004,
        lat: 10.51398835400039,
      },
      {
        lon: 106.83350316400043,
        lat: 10.518289210000432,
      },
      {
        lon: 106.83323523500042,
        lat: 10.519919460000494,
      },
      {
        lon: 106.83410968500044,
        lat: 10.522228217000475,
      },
      {
        lon: 106.83761210800026,
        lat: 10.527218796000284,
      },
      {
        lon: 106.8384531930003,
        lat: 10.529931588000238,
      },
      {
        lon: 106.83812573300037,
        lat: 10.532580651000103,
      },
      {
        lon: 106.83644272400034,
        lat: 10.534497305000059,
      },
      {
        lon: 106.83404370200026,
        lat: 10.534660087000248,
      },
      {
        lon: 106.82938399500023,
        lat: 10.533945389000223,
      },
      {
        lon: 106.82572552400035,
        lat: 10.535348833000171,
      },
      {
        lon: 106.82432388100028,
        lat: 10.53651629400025,
      },
      {
        lon: 106.82248686300034,
        lat: 10.543083078,
      },
      {
        lon: 106.8235020270003,
        lat: 10.545457039000041,
      },
      {
        lon: 106.82548769500028,
        lat: 10.547507654000356,
      },
      {
        lon: 106.82813221100025,
        lat: 10.54765032600028,
      },
      {
        lon: 106.82939660100021,
        lat: 10.545531155000333,
      },
      {
        lon: 106.83436706400033,
        lat: 10.540899053000032,
      },
      {
        lon: 106.83851805500035,
        lat: 10.539846889000216,
      },
      {
        lon: 106.84056308500027,
        lat: 10.540355410000302,
      },
      {
        lon: 106.84426296800024,
        lat: 10.542242091000332,
      },
      {
        lon: 106.84517896000034,
        lat: 10.543744564000235,
      },
      {
        lon: 106.8389587120003,
        lat: 10.540910253000277,
      },
      {
        lon: 106.83657042400023,
        lat: 10.540846056000191,
      },
      {
        lon: 106.83444575600026,
        lat: 10.541972312000325,
      },
      {
        lon: 106.83082085400036,
        lat: 10.545115943000269,
      },
      {
        lon: 106.82897277700033,
        lat: 10.547950357000104,
      },
      {
        lon: 106.82682496900026,
        lat: 10.548672670000315,
      },
      {
        lon: 106.82367245400025,
        lat: 10.546885800000041,
      },
      {
        lon: 106.82193741700036,
        lat: 10.5436974120002,
      },
      {
        lon: 106.82345251000021,
        lat: 10.537670347000244,
      },
      {
        lon: 106.82463985500027,
        lat: 10.53552816700028,
      },
      {
        lon: 106.83019869500032,
        lat: 10.53332293300025,
      },
      {
        lon: 106.83442461000033,
        lat: 10.534208913000214,
      },
      {
        lon: 106.83732925100037,
        lat: 10.533135322000078,
      },
      {
        lon: 106.83801130800026,
        lat: 10.530413546000318,
      },
      {
        lon: 106.83692254500033,
        lat: 10.527426660000287,
      },
      {
        lon: 106.83486190500054,
        lat: 10.525715826000464,
      },
      {
        lon: 106.83250296400047,
        lat: 10.522065988000463,
      },
      {
        lon: 106.83209839200038,
        lat: 10.520057609000446,
      },
      {
        lon: 106.83294939600046,
        lat: 10.517261795000422,
      },
      {
        lon: 106.83543485900049,
        lat: 10.513313091000384,
      },
      {
        lon: 106.83483636100038,
        lat: 10.510944300000403,
      },
      {
        lon: 106.82845671600052,
        lat: 10.509767731000466,
      },
      {
        lon: 106.82498495600049,
        lat: 10.50766589600039,
      },
      {
        lon: 106.82256809300031,
        lat: 10.507422833000248,
      },
      {
        lon: 106.82049323700022,
        lat: 10.504303978000223,
      },
      {
        lon: 106.81867460900037,
        lat: 10.49766540100029,
      },
      {
        lon: 106.81656395400029,
        lat: 10.49546333200027,
      },
      {
        lon: 106.81130297200036,
        lat: 10.493904926000255,
      },
      {
        lon: 106.80755889600016,
        lat: 10.493561863000025,
      },
      {
        lon: 106.80470450400026,
        lat: 10.494015763000277,
      },
      {
        lon: 106.80372621500021,
        lat: 10.494836111000325,
      },
      {
        lon: 106.80477041700033,
        lat: 10.50150977100003,
      },
      {
        lon: 106.8066265130002,
        lat: 10.50920100600024,
      },
      {
        lon: 106.80984782000019,
        lat: 10.510230692000334,
      },
      {
        lon: 106.81133445800032,
        lat: 10.51220507600027,
      },
      {
        lon: 106.8112388050003,
        lat: 10.514429429000359,
      },
      {
        lon: 106.8097192750003,
        lat: 10.520038836000253,
      },
      {
        lon: 106.81184799400035,
        lat: 10.522359898000275,
      },
      {
        lon: 106.81207047700036,
        lat: 10.524373096000204,
      },
      {
        lon: 106.81147034800034,
        lat: 10.527525397000204,
      },
      {
        lon: 106.8135339670003,
        lat: 10.53032418100031,
      },
      {
        lon: 106.81353245200023,
        lat: 10.531748981000248,
      },
      {
        lon: 106.8109392900003,
        lat: 10.53448942300031,
      },
      {
        lon: 106.80860688400037,
        lat: 10.533842010000285,
      },
      {
        lon: 106.80511798800018,
        lat: 10.535202633000154,
      },
      {
        lon: 106.80485745400028,
        lat: 10.53452404400025,
      },
      {
        lon: 106.80913981400028,
        lat: 10.533123677000276,
      },
      {
        lon: 106.81071493300027,
        lat: 10.533655856000166,
      },
      {
        lon: 106.81282539300031,
        lat: 10.530842012000221,
      },
      {
        lon: 106.81126463800018,
        lat: 10.528891637000243,
      },
      {
        lon: 106.81055426500029,
        lat: 10.526652348000141,
      },
      {
        lon: 106.81145532300025,
        lat: 10.523637062000262,
      },
      {
        lon: 106.80904934600022,
        lat: 10.52125195300033,
      },
      {
        lon: 106.80899414300035,
        lat: 10.519417216000136,
      },
      {
        lon: 106.8103633240002,
        lat: 10.513963328999978,
      },
      {
        lon: 106.80980504000024,
        lat: 10.511397070000214,
      },
      {
        lon: 106.80676491200023,
        lat: 10.510678483000333,
      },
      {
        lon: 106.80565843200031,
        lat: 10.509574224000175,
      },
      {
        lon: 106.80492470000036,
        lat: 10.505697030000192,
      },
      {
        lon: 106.80371069100022,
        lat: 10.501444486000253,
      },
      {
        lon: 106.80326975200023,
        lat: 10.496597718000316,
      },
      {
        lon: 106.80256472400032,
        lat: 10.494386367000232,
      },
      {
        lon: 106.79846153300036,
        lat: 10.491104734000203,
      },
      {
        lon: 106.79745990900028,
        lat: 10.49196405700036,
      },
      {
        lon: 106.79203033351592,
        lat: 10.499432685088266,
      },
      {
        lon: 106.78758568000029,
        lat: 10.504017137000233,
      },
      {
        lon: 106.78752596900028,
        lat: 10.507692511000187,
      },
      {
        lon: 106.78915523500024,
        lat: 10.511204497000335,
      },
      {
        lon: 106.78902992500036,
        lat: 10.514682625000205,
      },
      {
        lon: 106.78600278300036,
        lat: 10.518777379000255,
      },
      {
        lon: 106.78303446900026,
        lat: 10.520889400000272,
      },
      {
        lon: 106.78189001500022,
        lat: 10.52348975700022,
      },
      {
        lon: 106.78186950800023,
        lat: 10.529183214000284,
      },
      {
        lon: 106.78115450300032,
        lat: 10.531334607000247,
      },
      {
        lon: 106.77751361500032,
        lat: 10.53675161200016,
      },
      {
        lon: 106.77659241400022,
        lat: 10.538737576000358,
      },
      {
        lon: 106.77711407800024,
        lat: 10.541035225000314,
      },
      {
        lon: 106.77949940600024,
        lat: 10.543244127000264,
      },
      {
        lon: 106.78059904600025,
        lat: 10.54589554700027,
      },
      {
        lon: 106.77824297800021,
        lat: 10.548744561000092,
      },
      {
        lon: 106.77433469200031,
        lat: 10.549832234000291,
      },
      {
        lon: 106.77358475500034,
        lat: 10.550545986000202,
      },
      {
        lon: 106.77299535300023,
        lat: 10.55536622700015,
      },
      {
        lon: 106.77119444500028,
        lat: 10.557928630000333,
      },
      {
        lon: 106.76782255000023,
        lat: 10.559805439000286,
      },
      {
        lon: 106.76534479900033,
        lat: 10.560114267000245,
      },
      {
        lon: 106.76146449300023,
        lat: 10.558788418000233,
      },
      {
        lon: 106.75939046800033,
        lat: 10.555386723000254,
      },
      {
        lon: 106.75886593100034,
        lat: 10.551860818000307,
      },
      {
        lon: 106.7593287870003,
        lat: 10.549825494000288,
      },
      {
        lon: 106.76058057300031,
        lat: 10.548352531000205,
      },
      {
        lon: 106.76578846200033,
        lat: 10.545900028000233,
      },
      {
        lon: 106.76712714900015,
        lat: 10.544384207000292,
      },
      {
        lon: 106.76783859900024,
        lat: 10.542140452000353,
      },
      {
        lon: 106.76749947100024,
        lat: 10.540046900000334,
      },
      {
        lon: 106.76613121800028,
        lat: 10.537273924000203,
      },
      {
        lon: 106.76260756700032,
        lat: 10.534659748000308,
      },
      {
        lon: 106.75840269400032,
        lat: 10.532808787000192,
      },
      {
        lon: 106.75813133300034,
        lat: 10.531245102000184,
      },
      {
        lon: 106.76257143300035,
        lat: 10.533137378999982,
      },
      {
        lon: 106.76528320600045,
        lat: 10.534817916000236,
      },
      {
        lon: 106.76811492400026,
        lat: 10.538488493000273,
      },
      {
        lon: 106.7688688480003,
        lat: 10.54196387500025,
      },
      {
        lon: 106.76765338500036,
        lat: 10.545489101000214,
      },
      {
        lon: 106.76534057900034,
        lat: 10.547565310000323,
      },
      {
        lon: 106.76064539400022,
        lat: 10.549574635000226,
      },
      {
        lon: 106.75988662400033,
        lat: 10.551081261000206,
      },
      {
        lon: 106.76051584500027,
        lat: 10.55561294000023,
      },
      {
        lon: 106.76266869100021,
        lat: 10.558310975000268,
      },
      {
        lon: 106.76625312400023,
        lat: 10.559071478000249,
      },
      {
        lon: 106.76937440400019,
        lat: 10.5580203800003,
      },
      {
        lon: 106.77147412400024,
        lat: 10.555886340000313,
      },
      {
        lon: 106.77219360500033,
        lat: 10.55123886399997,
      },
      {
        lon: 106.77282400600035,
        lat: 10.549520189000361,
      },
      {
        lon: 106.77491172500032,
        lat: 10.548022357000207,
      },
      {
        lon: 106.77883791500028,
        lat: 10.54695495000027,
      },
      {
        lon: 106.77935096000022,
        lat: 10.544965606000346,
      },
      {
        lon: 106.7766342550002,
        lat: 10.542261211000323,
      },
      {
        lon: 106.7754981620003,
        lat: 10.540023053000027,
      },
      {
        lon: 106.77564957400017,
        lat: 10.537964033000208,
      },
      {
        lon: 106.77728035100023,
        lat: 10.53476609400019,
      },
      {
        lon: 106.77925030500029,
        lat: 10.531982069000321,
      },
      {
        lon: 106.7804750530002,
        lat: 10.52853514400033,
      },
      {
        lon: 106.78040024600034,
        lat: 10.524764131000325,
      },
      {
        lon: 106.78106753600031,
        lat: 10.521600148000289,
      },
      {
        lon: 106.78303442400033,
        lat: 10.518698992000225,
      },
      {
        lon: 106.78738161500024,
        lat: 10.515218459000266,
      },
      {
        lon: 106.78775970100025,
        lat: 10.51231204300035,
      },
      {
        lon: 106.7860234160002,
        lat: 10.507506218000344,
      },
      {
        lon: 106.78584791600025,
        lat: 10.505009081000196,
      },
      {
        lon: 106.78686209800031,
        lat: 10.502813316000356,
      },
      {
        lon: 106.79511491200032,
        lat: 10.492131610000285,
      },
      {
        lon: 106.7998306700004,
        lat: 10.486889526000141,
      },
      {
        lon: 106.80340268200018,
        lat: 10.48376859800021,
      },
      {
        lon: 106.80481770200021,
        lat: 10.48094218200032,
      },
      {
        lon: 106.8038815130003,
        lat: 10.479060441000286,
      },
      {
        lon: 106.79794784200021,
        lat: 10.47918980400027,
      },
      {
        lon: 106.79547061200026,
        lat: 10.477643429000295,
      },
      {
        lon: 106.79471855900027,
        lat: 10.475455379000266,
      },
      {
        lon: 106.79529616600024,
        lat: 10.474259322000137,
      },
      {
        lon: 106.79909087900023,
        lat: 10.469548529,
      },
      {
        lon: 106.80198282800029,
        lat: 10.464722609000034,
      },
      {
        lon: 106.80372161900021,
        lat: 10.460584684000253,
      },
      {
        lon: 106.80562126800027,
        lat: 10.458225396999978,
      },
      {
        lon: 106.80785751300027,
        lat: 10.457140270000195,
      },
      {
        lon: 106.81185033700024,
        lat: 10.457606344000226,
      },
      {
        lon: 106.81591631700024,
        lat: 10.46010321600022,
      },
      {
        lon: 106.81738709800032,
        lat: 10.46152070300036,
      },
      {
        lon: 106.81820813600024,
        lat: 10.464082132999977,
      },
      {
        lon: 106.81713769600032,
        lat: 10.469060356000227,
      },
      {
        lon: 106.81982062300034,
        lat: 10.46998296500027,
      },
      {
        lon: 106.82329917500022,
        lat: 10.46855048600031,
      },
      {
        lon: 106.82418596000048,
        lat: 10.466935334000254,
      },
      {
        lon: 106.8238684960005,
        lat: 10.462353088000214,
      },
      {
        lon: 106.82561067632531,
        lat: 10.459870850426682,
      },
      {
        lon: 106.82760739400027,
        lat: 10.459340150000287,
      },
      {
        lon: 106.83427472600033,
        lat: 10.459225263000292,
      },
      {
        lon: 106.8392465030002,
        lat: 10.457593010000153,
      },
      {
        lon: 106.84734861900017,
        lat: 10.452550998000277,
      },
      {
        lon: 106.85304180100036,
        lat: 10.450837287000297,
      },
      {
        lon: 106.85612752529369,
        lat: 10.447376024033588,
      },
      {
        lon: 106.85825839841857,
        lat: 10.455234912883498,
      },
      {
        lon: 106.85570178500035,
        lat: 10.454938575000199,
      },
      {
        lon: 106.85144411900029,
        lat: 10.453623458000152,
      },
      {
        lon: 106.84622761900033,
        lat: 10.457754630000238,
      },
      {
        lon: 106.84148073700032,
        lat: 10.460458916000334,
      },
      {
        lon: 106.83627570700025,
        lat: 10.462177114000212,
      },
      {
        lon: 106.8320432940003,
        lat: 10.462556007000217,
      },
      {
        lon: 106.82654695900032,
        lat: 10.461471994000306,
      },
      {
        lon: 106.82584178900026,
        lat: 10.462883997000347,
      },
      {
        lon: 106.82621756300028,
        lat: 10.466542058000414,
      },
      {
        lon: 106.82522582900026,
        lat: 10.469513826000252,
      },
      {
        lon: 106.82350912700036,
        lat: 10.470853232000252,
      },
      {
        lon: 106.81929808100028,
        lat: 10.471762860000354,
      },
      {
        lon: 106.81615269600036,
        lat: 10.470722664000277,
      },
      {
        lon: 106.81501517400017,
        lat: 10.468581440000207,
      },
      {
        lon: 106.8165291640002,
        lat: 10.463377083000296,
      },
      {
        lon: 106.81550560100015,
        lat: 10.46169775400034,
      },
      {
        lon: 106.8110174960002,
        lat: 10.459536211000284,
      },
      {
        lon: 106.80761753700034,
        lat: 10.458640817000287,
      },
      {
        lon: 106.80641665500025,
        lat: 10.459238500000254,
      },
      {
        lon: 106.80300799300034,
        lat: 10.46687127800027,
      },
      {
        lon: 106.79977048000023,
        lat: 10.471577694000302,
      },
      {
        lon: 106.79697231800037,
        lat: 10.474401776000207,
      },
      {
        lon: 106.79624090800024,
        lat: 10.476653868000199,
      },
      {
        lon: 106.79713709500021,
        lat: 10.477617848000023,
      },
      {
        lon: 106.8036369050003,
        lat: 10.477588090000268,
      },
      {
        lon: 106.80513233600033,
        lat: 10.478599897000208,
      },
      {
        lon: 106.80597812300022,
        lat: 10.481611421000139,
      },
      {
        lon: 106.80500648600037,
        lat: 10.484000074000164,
      },
      {
        lon: 106.80056406800037,
        lat: 10.488184427000249,
      },
      {
        lon: 106.79930030200023,
        lat: 10.490310842999975,
      },
      {
        lon: 106.80117647500032,
        lat: 10.492133202000218,
      },
      {
        lon: 106.80330204300031,
        lat: 10.493206648000239,
      },
      {
        lon: 106.80934003700037,
        lat: 10.492294491000337,
      },
      {
        lon: 106.8121537210003,
        lat: 10.492654148000156,
      },
      {
        lon: 106.81693312200025,
        lat: 10.494409112000255,
      },
      {
        lon: 106.8187422610003,
        lat: 10.495804160000205,
      },
      {
        lon: 106.8211426610003,
        lat: 10.500108271999977,
      },
      {
        lon: 106.82211370800024,
        lat: 10.505102069000259,
      },
      {
        lon: 106.82429231888608,
        lat: 10.505335392687243,
      },
      {
        lon: 106.82608679457468,
        lat: 10.505059319504426,
      },
    ],
    [
      {
        lon: 107.01113424400057,
        lat: 10.5980164030006,
      },
      {
        lon: 107.00807882057467,
        lat: 10.599033338676008,
      },
      {
        lon: 107.00534548200051,
        lat: 10.60256284700057,
      },
      {
        lon: 107.00528966900043,
        lat: 10.60108686800072,
      },
      {
        lon: 107.00663397500045,
        lat: 10.60022429600059,
      },
      {
        lon: 107.00714552000079,
        lat: 10.598890703000109,
      },
      {
        lon: 107.00972917572057,
        lat: 10.597812267544157,
      },
      {
        lon: 107.0104673780005,
        lat: 10.597080062000487,
      },
      {
        lon: 107.01234723300047,
        lat: 10.597857941000484,
      },
      {
        lon: 107.01270562296867,
        lat: 10.59643026622598,
      },
      {
        lon: 107.01799613800063,
        lat: 10.597688540000206,
      },
      {
        lon: 107.02230207125058,
        lat: 10.590176997076014,
      },
      {
        lon: 107.02471603200064,
        lat: 10.5869178920006,
      },
      {
        lon: 107.0246168810005,
        lat: 10.584983083000111,
      },
      {
        lon: 107.02285075600065,
        lat: 10.581606767000551,
      },
      {
        lon: 107.01945516700044,
        lat: 10.5762143270004,
      },
      {
        lon: 107.01633305500059,
        lat: 10.572321058000057,
      },
      {
        lon: 107.01348158900072,
        lat: 10.570299003000173,
      },
      {
        lon: 107.01263684300068,
        lat: 10.570926800000336,
      },
      {
        lon: 107.01262914400081,
        lat: 10.573900583000807,
      },
      {
        lon: 107.01101724900076,
        lat: 10.576385581000181,
      },
      {
        lon: 107.01079888000072,
        lat: 10.578753199000687,
      },
      {
        lon: 107.00999810900066,
        lat: 10.580525541000782,
      },
      {
        lon: 107.0102703810008,
        lat: 10.58254288500018,
      },
      {
        lon: 107.00939464600071,
        lat: 10.582781527000748,
      },
      {
        lon: 107.0094103430007,
        lat: 10.58024086500086,
      },
      {
        lon: 107.01014739200082,
        lat: 10.579187175000527,
      },
      {
        lon: 107.01036530600072,
        lat: 10.5764721940007,
      },
      {
        lon: 107.01202360100069,
        lat: 10.573868190000724,
      },
      {
        lon: 107.0120233220008,
        lat: 10.571182488000716,
      },
      {
        lon: 107.0125442350008,
        lat: 10.56999805600077,
      },
      {
        lon: 107.01399217500051,
        lat: 10.56909375100048,
      },
      {
        lon: 107.01144666400052,
        lat: 10.56586765600025,
      },
      {
        lon: 107.00938773900077,
        lat: 10.562049575000628,
      },
      {
        lon: 107.00816114100053,
        lat: 10.559140363000608,
      },
      {
        lon: 107.00768727700074,
        lat: 10.556521887000315,
      },
      {
        lon: 107.00460209200082,
        lat: 10.557439301000727,
      },
      {
        lon: 107.00111849300066,
        lat: 10.557657536999955,
      },
      {
        lon: 107.00072472200083,
        lat: 10.558460974000809,
      },
      {
        lon: 107.00184985202429,
        lat: 10.560109967885182,
      },
      {
        lon: 107.0014207080007,
        lat: 10.562380408000195,
      },
      {
        lon: 106.99915824500061,
        lat: 10.563629974000378,
      },
      {
        lon: 106.99700704400072,
        lat: 10.566838477000374,
      },
      {
        lon: 106.99692246300071,
        lat: 10.567726024000535,
      },
      {
        lon: 106.9990059590358,
        lat: 10.570005447716653,
      },
      {
        lon: 106.99922283200073,
        lat: 10.572836567000707,
      },
      {
        lon: 107.00254947400066,
        lat: 10.575820200000749,
      },
      {
        lon: 107.00496492482841,
        lat: 10.57565296964782,
      },
      {
        lon: 107.00500061674364,
        lat: 10.576533370219977,
      },
      {
        lon: 107.00211196100076,
        lat: 10.576427468000361,
      },
      {
        lon: 106.99891972000069,
        lat: 10.573314501000823,
      },
      {
        lon: 106.99820145100068,
        lat: 10.57077032300009,
      },
      {
        lon: 106.99544490000079,
        lat: 10.56787465000076,
      },
      {
        lon: 106.99522782000076,
        lat: 10.567025689000786,
      },
      {
        lon: 106.99802206700076,
        lat: 10.56323559500072,
      },
      {
        lon: 107.00102810700082,
        lat: 10.561115185000403,
      },
      {
        lon: 106.99961673300072,
        lat: 10.558616495000251,
      },
      {
        lon: 106.99995901800071,
        lat: 10.5570507930002,
      },
      {
        lon: 107.00244654500074,
        lat: 10.556067297000538,
      },
      {
        lon: 107.00560591100066,
        lat: 10.555919438000672,
      },
      {
        lon: 107.00731609900078,
        lat: 10.555193290000432,
      },
      {
        lon: 107.0084389550006,
        lat: 10.553995735999933,
      },
      {
        lon: 107.00964389800055,
        lat: 10.549970983000435,
      },
      {
        lon: 107.01128009900049,
        lat: 10.54750240200002,
      },
      {
        lon: 107.01464446900047,
        lat: 10.545189046000644,
      },
      {
        lon: 107.02043999800048,
        lat: 10.543410183000447,
      },
      {
        lon: 107.02385655300066,
        lat: 10.542734558000234,
      },
      {
        lon: 107.02491882600054,
        lat: 10.540923898000528,
      },
      {
        lon: 107.02458908300054,
        lat: 10.53911334700058,
      },
      {
        lon: 107.02232780700064,
        lat: 10.535834102000592,
      },
      {
        lon: 107.01941923800058,
        lat: 10.533188975000485,
      },
      {
        lon: 107.01633685900055,
        lat: 10.53092570700062,
      },
      {
        lon: 107.01543885600057,
        lat: 10.530649060000542,
      },
      {
        lon: 107.01395698500045,
        lat: 10.528912381000112,
      },
      {
        lon: 107.0124858800005,
        lat: 10.52809422400054,
      },
      {
        lon: 107.00837074200052,
        lat: 10.531552296000314,
      },
      {
        lon: 107.00480669000052,
        lat: 10.533012266000526,
      },
      {
        lon: 107.00418257200056,
        lat: 10.534574460000613,
      },
      {
        lon: 107.00589163500052,
        lat: 10.537277218999975,
      },
      {
        lon: 107.0067281290008,
        lat: 10.539291274000618,
      },
      {
        lon: 107.00553661300063,
        lat: 10.541175291000627,
      },
      {
        lon: 107.00425611100053,
        lat: 10.541920616000596,
      },
      {
        lon: 107.0028845990006,
        lat: 10.54351554400057,
      },
      {
        lon: 107.00079118600064,
        lat: 10.542800534000548,
      },
      {
        lon: 106.99977151700057,
        lat: 10.545576263000612,
      },
      {
        lon: 106.9959106550005,
        lat: 10.551651078000646,
      },
      {
        lon: 106.99442910169466,
        lat: 10.551493677714404,
      },
      {
        lon: 106.99627456000053,
        lat: 10.549653840999952,
      },
      {
        lon: 106.99878250000052,
        lat: 10.5450841290006,
      },
      {
        lon: 107.00006019162788,
        lat: 10.54182406533073,
      },
      {
        lon: 107.00106177900057,
        lat: 10.540825888999962,
      },
      {
        lon: 107.00291008600044,
        lat: 10.540757248000602,
      },
      {
        lon: 107.00518686700065,
        lat: 10.539811559000611,
      },
      {
        lon: 107.00530678400071,
        lat: 10.538853556000106,
      },
      {
        lon: 107.00255200500078,
        lat: 10.535052075000635,
      },
      {
        lon: 107.00224832900051,
        lat: 10.534166519000166,
      },
      {
        lon: 106.99709011965678,
        lat: 10.532603641076037,
      },
      {
        lon: 106.99871540238985,
        lat: 10.526102510143232,
      },
      {
        lon: 107.00329852400063,
        lat: 10.527044189000435,
      },
      {
        lon: 107.00583839300054,
        lat: 10.526155985000514,
      },
      {
        lon: 107.00717673900061,
        lat: 10.52442654500021,
      },
      {
        lon: 107.00777406600054,
        lat: 10.522607087000665,
      },
      {
        lon: 107.00778114400043,
        lat: 10.51867830700013,
      },
      {
        lon: 107.00395577400064,
        lat: 10.51424397100061,
      },
      {
        lon: 107.0023689260006,
        lat: 10.513638450000485,
      },
      {
        lon: 106.99916939100046,
        lat: 10.51330817300061,
      },
      {
        lon: 106.9965196350006,
        lat: 10.51492000500059,
      },
      {
        lon: 106.99401994700054,
        lat: 10.515234710000584,
      },
      {
        lon: 106.99200282900046,
        lat: 10.514617890000522,
      },
      {
        lon: 106.98842392600056,
        lat: 10.516627696000569,
      },
      {
        lon: 106.98606234700065,
        lat: 10.516252827000528,
      },
      {
        lon: 106.98186943200056,
        lat: 10.51453839400034,
      },
      {
        lon: 106.98008087400052,
        lat: 10.51473682700015,
      },
      {
        lon: 106.97659745100054,
        lat: 10.515745100000174,
      },
      {
        lon: 106.97475746900051,
        lat: 10.517115526000055,
      },
      {
        lon: 106.97447538600056,
        lat: 10.520970775000478,
      },
      {
        lon: 106.97622519100065,
        lat: 10.520827929000177,
      },
      {
        lon: 106.9777491480005,
        lat: 10.521743082000512,
      },
      {
        lon: 106.97678316400055,
        lat: 10.524190670000507,
      },
      {
        lon: 106.97835875300058,
        lat: 10.525451015999977,
      },
      {
        lon: 106.9785754080005,
        lat: 10.526912176000621,
      },
      {
        lon: 106.97715125800062,
        lat: 10.530229987999974,
      },
      {
        lon: 106.97409446600045,
        lat: 10.532190798000386,
      },
      {
        lon: 106.97386825500047,
        lat: 10.53150956000053,
      },
      {
        lon: 106.97676025700048,
        lat: 10.529292171000634,
      },
      {
        lon: 106.97750526000061,
        lat: 10.525914109000553,
      },
      {
        lon: 106.9761395750005,
        lat: 10.524796842000569,
      },
      {
        lon: 106.97593121400058,
        lat: 10.52324906000058,
      },
      {
        lon: 106.97376677500063,
        lat: 10.52228137900056,
      },
      {
        lon: 106.97321657300057,
        lat: 10.520356290000507,
      },
      {
        lon: 106.97325494000046,
        lat: 10.51756491800029,
      },
      {
        lon: 106.97409504000052,
        lat: 10.51577877500074,
      },
      {
        lon: 106.97640158200056,
        lat: 10.514184904000047,
      },
      {
        lon: 106.98061507320256,
        lat: 10.513291218109089,
      },
      {
        lon: 106.98229788200047,
        lat: 10.513248473000658,
      },
      {
        lon: 106.98596498000052,
        lat: 10.514234219000514,
      },
      {
        lon: 106.98725088300057,
        lat: 10.514002632000597,
      },
      {
        lon: 106.9882012110005,
        lat: 10.512760619000062,
      },
      {
        lon: 106.98418925100054,
        lat: 10.511408996000227,
      },
      {
        lon: 106.98240507970684,
        lat: 10.510161986528885,
      },
      {
        lon: 106.99105459180572,
        lat: 10.50963242456362,
      },
      {
        lon: 106.98958570660722,
        lat: 10.504880148921472,
      },
      {
        lon: 106.99045206900058,
        lat: 10.50466314300047,
      },
      {
        lon: 106.99211107200063,
        lat: 10.502049014000477,
      },
      {
        lon: 106.99273170800046,
        lat: 10.498469577000492,
      },
      {
        lon: 106.99065529400059,
        lat: 10.497638795000512,
      },
      {
        lon: 106.98935827200057,
        lat: 10.496398878000491,
      },
      {
        lon: 106.98922230800054,
        lat: 10.495360521000464,
      },
      {
        lon: 106.9879184940005,
        lat: 10.494605439000338,
      },
      {
        lon: 106.9855129710005,
        lat: 10.491979427000501,
      },
      {
        lon: 106.98521025382223,
        lat: 10.48887142629178,
      },
      {
        lon: 106.98556794266113,
        lat: 10.485682885784229,
      },
      {
        lon: 106.98699928700051,
        lat: 10.48194468900067,
      },
      {
        lon: 106.98806709100063,
        lat: 10.481230926000308,
      },
      {
        lon: 106.9873714580005,
        lat: 10.479947311000615,
      },
      {
        lon: 106.98584759300064,
        lat: 10.479875521000338,
      },
      {
        lon: 106.98469466600056,
        lat: 10.479009958000574,
      },
      {
        lon: 106.9831188438817,
        lat: 10.479240012732134,
      },
      {
        lon: 106.97798735908196,
        lat: 10.473513573172966,
      },
      {
        lon: 106.9779763780005,
        lat: 10.472286266000253,
      },
      {
        lon: 106.9761861120005,
        lat: 10.469368017000477,
      },
      {
        lon: 106.97522330600043,
        lat: 10.467144162000409,
      },
      {
        lon: 106.97563783500055,
        lat: 10.46637155100052,
      },
      {
        lon: 106.9760219600006,
        lat: 10.462961393000626,
      },
      {
        lon: 106.9766623860005,
        lat: 10.460701103000304,
      },
      {
        lon: 106.9750352430006,
        lat: 10.460036926000328,
      },
      {
        lon: 106.9730804826612,
        lat: 10.458166549761017,
      },
      {
        lon: 106.97241269900047,
        lat: 10.455441675000543,
      },
      {
        lon: 106.97145653400071,
        lat: 10.453317826000387,
      },
      {
        lon: 106.97002493200057,
        lat: 10.45343833800041,
      },
      {
        lon: 106.96821511700045,
        lat: 10.452620152000577,
      },
      {
        lon: 106.96608645600065,
        lat: 10.44648898400061,
      },
      {
        lon: 106.97023697300062,
        lat: 10.446928608000576,
      },
      {
        lon: 106.98084116400054,
        lat: 10.447343312000612,
      },
      {
        lon: 106.9914579650005,
        lat: 10.450945694000609,
      },
      {
        lon: 106.99656688500062,
        lat: 10.460489999000629,
      },
      {
        lon: 106.99705881000057,
        lat: 10.468230527000514,
      },
      {
        lon: 106.99819388000049,
        lat: 10.478635182000119,
      },
      {
        lon: 106.99789111100057,
        lat: 10.482836044000067,
      },
      {
        lon: 106.99798684100051,
        lat: 10.485994926000501,
      },
      {
        lon: 106.99773339300049,
        lat: 10.498979766000161,
      },
      {
        lon: 106.9971976990006,
        lat: 10.502157030000149,
      },
      {
        lon: 106.99767259200053,
        lat: 10.504352903000381,
      },
      {
        lon: 106.99924345100055,
        lat: 10.506381672000487,
      },
      {
        lon: 107.00178375900043,
        lat: 10.507856447000563,
      },
      {
        lon: 107.00572999100063,
        lat: 10.50936739499997,
      },
      {
        lon: 107.0069521700006,
        lat: 10.51051392000062,
      },
      {
        lon: 107.00948305700047,
        lat: 10.514118647000544,
      },
      {
        lon: 107.01293601600065,
        lat: 10.521548709000513,
      },
      {
        lon: 107.01494108300051,
        lat: 10.524372058000608,
      },
      {
        lon: 107.01970683700051,
        lat: 10.530229848000317,
      },
      {
        lon: 107.02402533700058,
        lat: 10.533818937000547,
      },
      {
        lon: 107.0270848880005,
        lat: 10.537796658000616,
      },
      {
        lon: 107.02754241700063,
        lat: 10.540692700999955,
      },
      {
        lon: 107.02666431200055,
        lat: 10.543625234000388,
      },
      {
        lon: 107.02536437300051,
        lat: 10.5456135550005,
      },
      {
        lon: 107.02431593300065,
        lat: 10.546233359000269,
      },
      {
        lon: 107.01673857300057,
        lat: 10.547309726000606,
      },
      {
        lon: 107.01470318300056,
        lat: 10.547946878999937,
      },
      {
        lon: 107.01318909800062,
        lat: 10.549178628000334,
      },
      {
        lon: 107.01152644400054,
        lat: 10.55181237900058,
      },
      {
        lon: 107.01049182800057,
        lat: 10.555763944000603,
      },
      {
        lon: 107.01071990700052,
        lat: 10.557828193000603,
      },
      {
        lon: 107.0119305840006,
        lat: 10.560701540000196,
      },
      {
        lon: 107.01417797800053,
        lat: 10.564420846000644,
      },
      {
        lon: 107.01721070400049,
        lat: 10.568406642000184,
      },
      {
        lon: 107.02308519300061,
        lat: 10.576633077000054,
      },
      {
        lon: 107.02611582700052,
        lat: 10.582002561000593,
      },
      {
        lon: 107.02685647800064,
        lat: 10.58439033800056,
      },
      {
        lon: 107.02695230800055,
        lat: 10.587214368000616,
      },
      {
        lon: 107.02658467200052,
        lat: 10.588459410999974,
      },
      {
        lon: 107.02398672900057,
        lat: 10.592936202000487,
      },
      {
        lon: 107.02225174200049,
        lat: 10.595082885000352,
      },
      {
        lon: 107.02104603600054,
        lat: 10.597903342000393,
      },
      {
        lon: 107.01966949400054,
        lat: 10.603059285000597,
      },
      {
        lon: 107.01743618000046,
        lat: 10.606519101000515,
      },
      {
        lon: 107.0114211880005,
        lat: 10.610829726000588,
      },
      {
        lon: 107.00803315100057,
        lat: 10.613073050000366,
      },
      {
        lon: 107.00725528695915,
        lat: 10.610504373527249,
      },
      {
        lon: 107.01176078300045,
        lat: 10.607718955000507,
      },
      {
        lon: 107.01241030752851,
        lat: 10.605169348430877,
      },
      {
        lon: 107.0147036150006,
        lat: 10.60590356100056,
      },
      {
        lon: 107.01641815000062,
        lat: 10.604070487000229,
      },
      {
        lon: 107.01556832100056,
        lat: 10.603452375000245,
      },
      {
        lon: 107.01602775100054,
        lat: 10.601871855999946,
      },
      {
        lon: 107.01754453600074,
        lat: 10.60093546200045,
      },
      {
        lon: 107.01835237500052,
        lat: 10.598786391000544,
      },
      {
        lon: 107.01556289904397,
        lat: 10.598633637362184,
      },
      {
        lon: 107.0144087180006,
        lat: 10.597709152000505,
      },
      {
        lon: 107.01241057700076,
        lat: 10.59861981200055,
      },
      {
        lon: 107.01113424400057,
        lat: 10.5980164030006,
      },
    ],
    [
      {
        lon: 106.95407800358464,
        lat: 10.581552212483814,
      },
      {
        lon: 106.95088978000047,
        lat: 10.584556572000508,
      },
      {
        lon: 106.94854910200041,
        lat: 10.58600352200051,
      },
      {
        lon: 106.94429677700037,
        lat: 10.587289653000425,
      },
      {
        lon: 106.94244485900049,
        lat: 10.588144866000391,
      },
      {
        lon: 106.94054735400039,
        lat: 10.588256379000484,
      },
      {
        lon: 106.9388375040005,
        lat: 10.587406290000388,
      },
      {
        lon: 106.93654196000045,
        lat: 10.585590723,
      },
      {
        lon: 106.93418082900041,
        lat: 10.585856180000405,
      },
      {
        lon: 106.93382847100042,
        lat: 10.586608007000507,
      },
      {
        lon: 106.93617936700042,
        lat: 10.589929018000419,
      },
      {
        lon: 106.93803539900051,
        lat: 10.594451052000466,
      },
      {
        lon: 106.93816215200042,
        lat: 10.596931233000419,
      },
      {
        lon: 106.93739887200047,
        lat: 10.59967564900046,
      },
      {
        lon: 106.93395323300041,
        lat: 10.600958344000446,
      },
      {
        lon: 106.92645216700043,
        lat: 10.604169198000193,
      },
      {
        lon: 106.92491971800041,
        lat: 10.605568638000364,
      },
      {
        lon: 106.92182334000051,
        lat: 10.609361622000506,
      },
      {
        lon: 106.91951012100054,
        lat: 10.610018870000317,
      },
      {
        lon: 106.91724751300052,
        lat: 10.609309644000462,
      },
      {
        lon: 106.91471674300038,
        lat: 10.607773330000327,
      },
      {
        lon: 106.91292974600043,
        lat: 10.60740731500033,
      },
      {
        lon: 106.91158061400051,
        lat: 10.60793524900049,
      },
      {
        lon: 106.90940042900053,
        lat: 10.611118984000427,
      },
      {
        lon: 106.9073382300005,
        lat: 10.615805885000498,
      },
      {
        lon: 106.90511324000045,
        lat: 10.621903586000485,
      },
      {
        lon: 106.90193753500051,
        lat: 10.626035999000521,
      },
      {
        lon: 106.89641805700052,
        lat: 10.630399799000259,
      },
      {
        lon: 106.89250447300054,
        lat: 10.63260159500038,
      },
      {
        lon: 106.89143353000048,
        lat: 10.63406174900041,
      },
      {
        lon: 106.89010045000049,
        lat: 10.637082566000515,
      },
      {
        lon: 106.8890851800005,
        lat: 10.63794852800038,
      },
      {
        lon: 106.88564033900053,
        lat: 10.639718151000523,
      },
      {
        lon: 106.8850574530004,
        lat: 10.640531825000323,
      },
      {
        lon: 106.88412943200046,
        lat: 10.645238973000062,
      },
      {
        lon: 106.88273033500042,
        lat: 10.647684876000199,
      },
      {
        lon: 106.87678494200048,
        lat: 10.648543641000288,
      },
      {
        lon: 106.87241720300045,
        lat: 10.64775951400037,
      },
      {
        lon: 106.87182708200044,
        lat: 10.647192065000013,
      },
      {
        lon: 106.8701104480005,
        lat: 10.643417305000526,
      },
      {
        lon: 106.86861323900052,
        lat: 10.64269556000039,
      },
      {
        lon: 106.86492465600043,
        lat: 10.642381504000525,
      },
      {
        lon: 106.86322758900042,
        lat: 10.640594030000443,
      },
      {
        lon: 106.86140235700054,
        lat: 10.637243730000137,
      },
      {
        lon: 106.85871801900049,
        lat: 10.636829086000489,
      },
      {
        lon: 106.85394834000044,
        lat: 10.639051444000529,
      },
      {
        lon: 106.85034929200037,
        lat: 10.639779070000486,
      },
      {
        lon: 106.84553496700039,
        lat: 10.638907851000454,
      },
      {
        lon: 106.84257937000035,
        lat: 10.637807855,
      },
      {
        lon: 106.8413933850004,
        lat: 10.63679232300018,
      },
      {
        lon: 106.84044686500039,
        lat: 10.634906241000465,
      },
      {
        lon: 106.83958524100052,
        lat: 10.631310433000465,
      },
      {
        lon: 106.83879032000038,
        lat: 10.629423822000433,
      },
      {
        lon: 106.84038984500052,
        lat: 10.628752678000524,
      },
      {
        lon: 106.84169876500044,
        lat: 10.63476270800038,
      },
      {
        lon: 106.8429347810004,
        lat: 10.636670137000408,
      },
      {
        lon: 106.84774705000041,
        lat: 10.638334195000425,
      },
      {
        lon: 106.8524201390004,
        lat: 10.638374432000433,
      },
      {
        lon: 106.85389976100043,
        lat: 10.63790908600049,
      },
      {
        lon: 106.85770681600042,
        lat: 10.63592064000045,
      },
      {
        lon: 106.85939400500047,
        lat: 10.635468722000383,
      },
      {
        lon: 106.86208482300054,
        lat: 10.636311437000359,
      },
      {
        lon: 106.8637667640005,
        lat: 10.638366739000446,
      },
      {
        lon: 106.8653960350004,
        lat: 10.64136844300009,
      },
      {
        lon: 106.86800513900045,
        lat: 10.641118575000405,
      },
      {
        lon: 106.86955908600049,
        lat: 10.641409134000526,
      },
      {
        lon: 106.87170101000044,
        lat: 10.643881503000387,
      },
      {
        lon: 106.87242929300044,
        lat: 10.6460535260004,
      },
      {
        lon: 106.87357407100046,
        lat: 10.647002550000423,
      },
      {
        lon: 106.87731555400052,
        lat: 10.647444627000482,
      },
      {
        lon: 106.88173343300053,
        lat: 10.646781735,
      },
      {
        lon: 106.88265176000039,
        lat: 10.645937258000401,
      },
      {
        lon: 106.88386519300053,
        lat: 10.63989247200027,
      },
      {
        lon: 106.88593341900042,
        lat: 10.638029074000485,
      },
      {
        lon: 106.88813890600039,
        lat: 10.63715500200044,
      },
      {
        lon: 106.8893059170004,
        lat: 10.63603444300037,
      },
      {
        lon: 106.89147837500053,
        lat: 10.631944891000387,
      },
      {
        lon: 106.89231909200038,
        lat: 10.631178426000474,
      },
      {
        lon: 106.89734284600041,
        lat: 10.628329229000371,
      },
      {
        lon: 106.8984796420005,
        lat: 10.6274619940001,
      },
      {
        lon: 106.90249073300043,
        lat: 10.623213414000475,
      },
      {
        lon: 106.90397684000038,
        lat: 10.620660331000522,
      },
      {
        lon: 106.90567735700051,
        lat: 10.614731215000404,
      },
      {
        lon: 106.90784190000062,
        lat: 10.61009773200027,
      },
      {
        lon: 106.90981052900054,
        lat: 10.607187171000419,
      },
      {
        lon: 106.91209088700037,
        lat: 10.605741420000072,
      },
      {
        lon: 106.91592372400032,
        lat: 10.606092137000417,
      },
      {
        lon: 106.91977947500044,
        lat: 10.608063355000638,
      },
      {
        lon: 106.92079968500047,
        lat: 10.608209827000508,
      },
      {
        lon: 106.9231521730004,
        lat: 10.60440822100037,
      },
      {
        lon: 106.92615121400047,
        lat: 10.602210632000322,
      },
      {
        lon: 106.92900688500043,
        lat: 10.600908524999966,
      },
      {
        lon: 106.93181775300047,
        lat: 10.600145546000363,
      },
      {
        lon: 106.93332731100048,
        lat: 10.59926758700044,
      },
      {
        lon: 106.9348591570004,
        lat: 10.597408895000513,
      },
      {
        lon: 106.93401788200038,
        lat: 10.592978452000466,
      },
      {
        lon: 106.93145052100044,
        lat: 10.58904093400048,
      },
      {
        lon: 106.9311568960004,
        lat: 10.58616301900048,
      },
      {
        lon: 106.92852506600042,
        lat: 10.584720715000515,
      },
      {
        lon: 106.92321936100046,
        lat: 10.579994741000462,
      },
      {
        lon: 106.92063185700044,
        lat: 10.57874589000022,
      },
      {
        lon: 106.91854795800043,
        lat: 10.580464067000378,
      },
      {
        lon: 106.91565892300054,
        lat: 10.58195279200038,
      },
      {
        lon: 106.9125559940004,
        lat: 10.58212961400045,
      },
      {
        lon: 106.91073661900043,
        lat: 10.581486659000063,
      },
      {
        lon: 106.90857192100037,
        lat: 10.578775863000487,
      },
      {
        lon: 106.90803442400039,
        lat: 10.574978334000434,
      },
      {
        lon: 106.90680166600038,
        lat: 10.5744358660005,
      },
      {
        lon: 106.90552175800053,
        lat: 10.575172749000444,
      },
      {
        lon: 106.9046319950005,
        lat: 10.577343263000444,
      },
      {
        lon: 106.90613127900042,
        lat: 10.579846716000532,
      },
      {
        lon: 106.91026798583437,
        lat: 10.581798260760308,
      },
      {
        lon: 106.91102541700053,
        lat: 10.583338428000445,
      },
      {
        lon: 106.91003870700041,
        lat: 10.585064764000265,
      },
      {
        lon: 106.90620037600053,
        lat: 10.588332444000457,
      },
      {
        lon: 106.90146858900044,
        lat: 10.590859325000395,
      },
      {
        lon: 106.89957507300045,
        lat: 10.590575715000341,
      },
      {
        lon: 106.8995426840005,
        lat: 10.592098437000402,
      },
      {
        lon: 106.89827550900054,
        lat: 10.594692794000196,
      },
      {
        lon: 106.89761798400042,
        lat: 10.593974718000482,
      },
      {
        lon: 106.8985248560005,
        lat: 10.592730535000499,
      },
      {
        lon: 106.89915368200047,
        lat: 10.589955392000263,
      },
      {
        lon: 106.90166185400051,
        lat: 10.590104201000429,
      },
      {
        lon: 106.90618289200052,
        lat: 10.587513539000497,
      },
      {
        lon: 106.9093048200005,
        lat: 10.58456113300025,
      },
      {
        lon: 106.91026140600047,
        lat: 10.583131463000086,
      },
      {
        lon: 106.90945148400039,
        lat: 10.582295905000421,
      },
      {
        lon: 106.9065243233564,
        lat: 10.581065327552153,
      },
      {
        lon: 106.90428686813584,
        lat: 10.579151689290256,
      },
      {
        lon: 106.90378659620472,
        lat: 10.57738812113189,
      },
      {
        lon: 106.90431181500048,
        lat: 10.575410509000392,
      },
      {
        lon: 106.90651329600047,
        lat: 10.5736295600002,
      },
      {
        lon: 106.90807774100068,
        lat: 10.573895003000587,
      },
      {
        lon: 106.90940165300043,
        lat: 10.57650998300005,
      },
      {
        lon: 106.90971475000049,
        lat: 10.57918052100041,
      },
      {
        lon: 106.9108652370004,
        lat: 10.58056139900043,
      },
      {
        lon: 106.91244516600045,
        lat: 10.581131241000321,
      },
      {
        lon: 106.91559443800048,
        lat: 10.58076347500048,
      },
      {
        lon: 106.91711500500043,
        lat: 10.57997734300045,
      },
      {
        lon: 106.91866094400052,
        lat: 10.578078812000456,
      },
      {
        lon: 106.91879449500044,
        lat: 10.57688315000047,
      },
      {
        lon: 106.91695524700032,
        lat: 10.574231287000373,
      },
      {
        lon: 106.91634811700038,
        lat: 10.57152771400041,
      },
      {
        lon: 106.91663156400045,
        lat: 10.569217341000467,
      },
      {
        lon: 106.91806187200052,
        lat: 10.56689851599998,
      },
      {
        lon: 106.92031968400045,
        lat: 10.565110752000408,
      },
      {
        lon: 106.9228602050004,
        lat: 10.56400090200043,
      },
      {
        lon: 106.9245870470005,
        lat: 10.563688675000435,
      },
      {
        lon: 106.92947942200038,
        lat: 10.563509172000408,
      },
      {
        lon: 106.93143776000039,
        lat: 10.561980058000529,
      },
      {
        lon: 106.93271376400043,
        lat: 10.559412842000029,
      },
      {
        lon: 106.93292397400046,
        lat: 10.554321573000038,
      },
      {
        lon: 106.93270585100043,
        lat: 10.551918105000482,
      },
      {
        lon: 106.93167370400039,
        lat: 10.548635641000317,
      },
      {
        lon: 106.92916920900052,
        lat: 10.54457971200037,
      },
      {
        lon: 106.92498667000041,
        lat: 10.540794573000483,
      },
      {
        lon: 106.9238401380004,
        lat: 10.53936691700045,
      },
      {
        lon: 106.92150288900041,
        lat: 10.53813717100013,
      },
      {
        lon: 106.91904621200041,
        lat: 10.535603141000252,
      },
      {
        lon: 106.92128681400038,
        lat: 10.535646711000481,
      },
      {
        lon: 106.92361345600051,
        lat: 10.534860767000112,
      },
      {
        lon: 106.9266306600004,
        lat: 10.533129941000254,
      },
      {
        lon: 106.9264449250004,
        lat: 10.534362513000474,
      },
      {
        lon: 106.92734564800054,
        lat: 10.536728488000392,
      },
      {
        lon: 106.92962961300043,
        lat: 10.539857030000235,
      },
      {
        lon: 106.93490277200038,
        lat: 10.548783877000517,
      },
      {
        lon: 106.93588612600041,
        lat: 10.552327484000159,
      },
      {
        lon: 106.9387342590004,
        lat: 10.552433300000416,
      },
      {
        lon: 106.93906959000054,
        lat: 10.553276525000456,
      },
      {
        lon: 106.94037841200048,
        lat: 10.553217477000373,
      },
      {
        lon: 106.94115180000051,
        lat: 10.554084461000514,
      },
      {
        lon: 106.94148458516048,
        lat: 10.556994656506959,
      },
      {
        lon: 106.94140855773986,
        lat: 10.557271613539399,
      },
      {
        lon: 106.9408526160006,
        lat: 10.556500068000446,
      },
      {
        lon: 106.94105235500052,
        lat: 10.554757283000413,
      },
      {
        lon: 106.94043717900047,
        lat: 10.55342843300042,
      },
      {
        lon: 106.93895389300047,
        lat: 10.553511540000443,
      },
      {
        lon: 106.93856792600042,
        lat: 10.552617909000503,
      },
      {
        lon: 106.9360175270005,
        lat: 10.552589183000407,
      },
      {
        lon: 106.93550589400053,
        lat: 10.556550060000518,
      },
      {
        lon: 106.93339602400039,
        lat: 10.563110809000023,
      },
      {
        lon: 106.9313906420004,
        lat: 10.565438365000432,
      },
      {
        lon: 106.92855590100044,
        lat: 10.567067961000419,
      },
      {
        lon: 106.92563425400033,
        lat: 10.567717021000476,
      },
      {
        lon: 106.92170151900046,
        lat: 10.567854376000412,
      },
      {
        lon: 106.92029842700038,
        lat: 10.568284026000413,
      },
      {
        lon: 106.91959259600048,
        lat: 10.569858430000465,
      },
      {
        lon: 106.92078358800046,
        lat: 10.575027610000234,
      },
      {
        lon: 106.92156900800046,
        lat: 10.576567200000454,
      },
      {
        lon: 106.92609654900048,
        lat: 10.57985992300047,
      },
      {
        lon: 106.92856216400048,
        lat: 10.5812455680001,
      },
      {
        lon: 106.93176152700045,
        lat: 10.584828029000505,
      },
      {
        lon: 106.93489061000042,
        lat: 10.583676343000434,
      },
      {
        lon: 106.93635637600039,
        lat: 10.583614506000174,
      },
      {
        lon: 106.93815225000048,
        lat: 10.584283642000116,
      },
      {
        lon: 106.94066737500049,
        lat: 10.586616869000522,
      },
      {
        lon: 106.94776822900042,
        lat: 10.584480802000087,
      },
      {
        lon: 106.95032860600043,
        lat: 10.5827322990001,
      },
      {
        lon: 106.95244153358192,
        lat: 10.580513683443598,
      },
      {
        lon: 106.95407800358464,
        lat: 10.581552212483814,
      },
    ],
    [
      {
        lon: 106.94148458516048,
        lat: 10.556994656506959,
      },
      {
        lon: 106.94222238200047,
        lat: 10.55742214500043,
      },
      {
        lon: 106.94422460600038,
        lat: 10.556932606000373,
      },
      {
        lon: 106.94492742255608,
        lat: 10.555335641116983,
      },
      {
        lon: 106.94696262152257,
        lat: 10.554658167640582,
      },
      {
        lon: 106.9482946220005,
        lat: 10.556203522999965,
      },
      {
        lon: 106.94870470000052,
        lat: 10.558968177000452,
      },
      {
        lon: 106.95116574100047,
        lat: 10.562033268000505,
      },
      {
        lon: 106.95272410000052,
        lat: 10.562578227000188,
      },
      {
        lon: 106.9563493760005,
        lat: 10.561841929000195,
      },
      {
        lon: 106.96075013100051,
        lat: 10.560378937000507,
      },
      {
        lon: 106.96479197700046,
        lat: 10.560826203000374,
      },
      {
        lon: 106.96732863100038,
        lat: 10.55998052900037,
      },
      {
        lon: 106.9694081510005,
        lat: 10.557202868000502,
      },
      {
        lon: 106.97113197600038,
        lat: 10.555436257999986,
      },
      {
        lon: 106.97407383200061,
        lat: 10.553894797999988,
      },
      {
        lon: 106.97737028900042,
        lat: 10.553273595000405,
      },
      {
        lon: 106.98419703300038,
        lat: 10.555133897000474,
      },
      {
        lon: 106.98516468900033,
        lat: 10.551999315000273,
      },
      {
        lon: 106.9849863780006,
        lat: 10.55030795600004,
      },
      {
        lon: 106.98396862100049,
        lat: 10.546902252000336,
      },
      {
        lon: 106.98221531600046,
        lat: 10.542732001,
      },
      {
        lon: 106.98165397100047,
        lat: 10.540382015000432,
      },
      {
        lon: 106.98170391500054,
        lat: 10.536149206000403,
      },
      {
        lon: 106.98253380800054,
        lat: 10.533042361000469,
      },
      {
        lon: 106.98474709600038,
        lat: 10.529408257000341,
      },
      {
        lon: 106.98631831900042,
        lat: 10.527876605000472,
      },
      {
        lon: 106.98829943400045,
        lat: 10.526606482000432,
      },
      {
        lon: 106.99221811900043,
        lat: 10.52534127200056,
      },
      {
        lon: 106.99464253500048,
        lat: 10.52517770200052,
      },
      {
        lon: 106.99871540238979,
        lat: 10.526102510143119,
      },
      {
        lon: 106.99709011965666,
        lat: 10.532603641075923,
      },
      {
        lon: 106.99465710600032,
        lat: 10.53145516300043,
      },
      {
        lon: 106.99205966800054,
        lat: 10.531072063000522,
      },
      {
        lon: 106.98913704800049,
        lat: 10.53248664300014,
      },
      {
        lon: 106.98748576100053,
        lat: 10.536045911000485,
      },
      {
        lon: 106.98763420900042,
        lat: 10.53871562600051,
      },
      {
        lon: 106.98819354800042,
        lat: 10.54111953800043,
      },
      {
        lon: 106.98977165000042,
        lat: 10.544640791,
      },
      {
        lon: 106.9907289500004,
        lat: 10.54972313500042,
      },
      {
        lon: 106.9901511740004,
        lat: 10.555987030000438,
      },
      {
        lon: 106.99211677800048,
        lat: 10.556964716000152,
      },
      {
        lon: 106.99261030900038,
        lat: 10.55327044400002,
      },
      {
        lon: 106.99347078800042,
        lat: 10.553380991000381,
      },
      {
        lon: 106.99278220000048,
        lat: 10.55734071900065,
      },
      {
        lon: 106.9908610220005,
        lat: 10.557396285000436,
      },
      {
        lon: 106.98997871800049,
        lat: 10.5568258830005,
      },
      {
        lon: 106.98823550500043,
        lat: 10.558987438000372,
      },
      {
        lon: 106.98753679200058,
        lat: 10.560665884000684,
      },
      {
        lon: 106.98553399400043,
        lat: 10.56204769000044,
      },
      {
        lon: 106.98243624400044,
        lat: 10.563002952000431,
      },
      {
        lon: 106.98159237600044,
        lat: 10.563893651000512,
      },
      {
        lon: 106.9824645760005,
        lat: 10.566676714000494,
      },
      {
        lon: 106.98383969100053,
        lat: 10.569764025000381,
      },
      {
        lon: 106.98460605500048,
        lat: 10.572526784000445,
      },
      {
        lon: 106.98515289200054,
        lat: 10.577710261000448,
      },
      {
        lon: 106.98768937900053,
        lat: 10.583863028000508,
      },
      {
        lon: 106.98867411073809,
        lat: 10.586654582176266,
      },
      {
        lon: 106.98955300400064,
        lat: 10.590344046000268,
      },
      {
        lon: 106.9888347020007,
        lat: 10.590649675000066,
      },
      {
        lon: 106.9877986350005,
        lat: 10.593806877000532,
      },
      {
        lon: 106.98815846100041,
        lat: 10.594660970000405,
      },
      {
        lon: 106.9875388530005,
        lat: 10.597036690000152,
      },
      {
        lon: 106.9859886750005,
        lat: 10.595500008000386,
      },
      {
        lon: 106.9867745400004,
        lat: 10.592730072000336,
      },
      {
        lon: 106.98712486900052,
        lat: 10.588982780000435,
      },
      {
        lon: 106.98652268300043,
        lat: 10.585502087000123,
      },
      {
        lon: 106.98385057000041,
        lat: 10.57943343000045,
      },
      {
        lon: 106.98231454400053,
        lat: 10.580318006000482,
      },
      {
        lon: 106.98117150700051,
        lat: 10.576947310000264,
      },
      {
        lon: 106.97855383800038,
        lat: 10.572905021000395,
      },
      {
        lon: 106.97741642800042,
        lat: 10.57066115700053,
      },
      {
        lon: 106.9762313110005,
        lat: 10.567006713000524,
      },
      {
        lon: 106.97580669600046,
        lat: 10.562806733000457,
      },
      {
        lon: 106.9775813260004,
        lat: 10.559888665000415,
      },
      {
        lon: 106.9785383380007,
        lat: 10.557133063000492,
      },
      {
        lon: 106.97795985000045,
        lat: 10.556280444000379,
      },
      {
        lon: 106.97308425200043,
        lat: 10.557651138000665,
      },
      {
        lon: 106.97071028700043,
        lat: 10.559053901000084,
      },
      {
        lon: 106.9703226840004,
        lat: 10.560528852000175,
      },
      {
        lon: 106.97048848200046,
        lat: 10.564291455000387,
      },
      {
        lon: 106.96981914800051,
        lat: 10.568351824000501,
      },
      {
        lon: 106.96785840000041,
        lat: 10.571412197000143,
      },
      {
        lon: 106.96483560600046,
        lat: 10.57338777400048,
      },
      {
        lon: 106.96050074000044,
        lat: 10.575172130000112,
      },
      {
        lon: 106.95933023400039,
        lat: 10.5760278890005,
      },
      {
        lon: 106.95604736830592,
        lat: 10.579797347916724,
      },
      {
        lon: 106.95407800358464,
        lat: 10.581552212483814,
      },
      {
        lon: 106.95244153358192,
        lat: 10.580513683443598,
      },
      {
        lon: 106.95543242400043,
        lat: 10.577689822000423,
      },
      {
        lon: 106.95649891000039,
        lat: 10.57604866800034,
      },
      {
        lon: 106.95861050100041,
        lat: 10.57380483200052,
      },
      {
        lon: 106.96199096600043,
        lat: 10.571990491000406,
      },
      {
        lon: 106.9651011880004,
        lat: 10.570809702000474,
      },
      {
        lon: 106.9672598680005,
        lat: 10.569189608000386,
      },
      {
        lon: 106.96795520400048,
        lat: 10.567108273000372,
      },
      {
        lon: 106.96738441600047,
        lat: 10.561941205999972,
      },
      {
        lon: 106.96004616700054,
        lat: 10.56190182100056,
      },
      {
        lon: 106.95559686800038,
        lat: 10.563317354000503,
      },
      {
        lon: 106.95148482000053,
        lat: 10.5640974450003,
      },
      {
        lon: 106.95142359600044,
        lat: 10.56287199100052,
      },
      {
        lon: 106.94901699700051,
        lat: 10.560386753000444,
      },
      {
        lon: 106.94829679200052,
        lat: 10.559079220000513,
      },
      {
        lon: 106.94803271300043,
        lat: 10.55677820100044,
      },
      {
        lon: 106.94681471500053,
        lat: 10.554912254000328,
      },
      {
        lon: 106.94526237600047,
        lat: 10.555367944000409,
      },
      {
        lon: 106.94492222700046,
        lat: 10.556785390000414,
      },
      {
        lon: 106.94227445300038,
        lat: 10.557695099000455,
      },
      {
        lon: 106.94140855773986,
        lat: 10.557271613539399,
      },
      {
        lon: 106.94148458516048,
        lat: 10.556994656506959,
      },
    ],
    [
      {
        lon: 106.96372608090786,
        lat: 10.536582487366218,
      },
      {
        lon: 106.96238573700049,
        lat: 10.537416658000495,
      },
      {
        lon: 106.95961472527358,
        lat: 10.537900416915022,
      },
      {
        lon: 106.95731204600054,
        lat: 10.53901957800042,
      },
      {
        lon: 106.9544838850004,
        lat: 10.541502323000032,
      },
      {
        lon: 106.95231607600039,
        lat: 10.545010475000424,
      },
      {
        lon: 106.95129878300065,
        lat: 10.544811771000298,
      },
      {
        lon: 106.95003204746126,
        lat: 10.542705336659935,
      },
      {
        lon: 106.95472018000049,
        lat: 10.540211336000425,
      },
      {
        lon: 106.95743149000049,
        lat: 10.538029228000369,
      },
      {
        lon: 106.95762993400041,
        lat: 10.536686881000037,
      },
      {
        lon: 106.95731375500043,
        lat: 10.533436113000391,
      },
      {
        lon: 106.96061814200038,
        lat: 10.531735558000435,
      },
      {
        lon: 106.9607388640004,
        lat: 10.53077122100052,
      },
      {
        lon: 106.95988722300052,
        lat: 10.528311158000463,
      },
      {
        lon: 106.95957287255783,
        lat: 10.524497339114703,
      },
      {
        lon: 106.95813673700047,
        lat: 10.522358445000407,
      },
      {
        lon: 106.9577085620005,
        lat: 10.520773211000517,
      },
      {
        lon: 106.95861164400043,
        lat: 10.51936844200037,
      },
      {
        lon: 106.96056024200038,
        lat: 10.518800257000521,
      },
      {
        lon: 106.96033107900053,
        lat: 10.517478656000492,
      },
      {
        lon: 106.96105352400048,
        lat: 10.515850348000413,
      },
      {
        lon: 106.96254507400045,
        lat: 10.515322925000419,
      },
      {
        lon: 106.96247310600052,
        lat: 10.514068008000379,
      },
      {
        lon: 106.95797874200048,
        lat: 10.512245695000502,
      },
      {
        lon: 106.95322875800053,
        lat: 10.511659870000472,
      },
      {
        lon: 106.9537021790004,
        lat: 10.509214471000519,
      },
      {
        lon: 106.95499328900047,
        lat: 10.510242022000398,
      },
      {
        lon: 106.95969379700051,
        lat: 10.51141462600043,
      },
      {
        lon: 106.96027533300048,
        lat: 10.510233486000402,
      },
      {
        lon: 106.9589074253364,
        lat: 10.50870719107013,
      },
      {
        lon: 106.95938419060349,
        lat: 10.507158717719165,
      },
      {
        lon: 106.96125191900046,
        lat: 10.50705731900041,
      },
      {
        lon: 106.96353237700032,
        lat: 10.506140296000467,
      },
      {
        lon: 106.96599234100051,
        lat: 10.508537497000475,
      },
      {
        lon: 106.96955708300038,
        lat: 10.505299009000396,
      },
      {
        lon: 106.97003532200046,
        lat: 10.501987063000456,
      },
      {
        lon: 106.97186915200047,
        lat: 10.500987694000457,
      },
      {
        lon: 106.97260504700051,
        lat: 10.499850128000503,
      },
      {
        lon: 106.97470004600041,
        lat: 10.49836878900038,
      },
      {
        lon: 106.97446381300045,
        lat: 10.497557686000505,
      },
      {
        lon: 106.9752962500005,
        lat: 10.492250446000526,
      },
      {
        lon: 106.97372277100044,
        lat: 10.491018651000275,
      },
      {
        lon: 106.97472039900038,
        lat: 10.488795556000468,
      },
      {
        lon: 106.97714623900038,
        lat: 10.487289269000257,
      },
      {
        lon: 106.97744979100042,
        lat: 10.485414284000285,
      },
      {
        lon: 106.97573124200045,
        lat: 10.48328214100053,
      },
      {
        lon: 106.97157024500052,
        lat: 10.479413787000396,
      },
      {
        lon: 106.97032931500053,
        lat: 10.477231833000701,
      },
      {
        lon: 106.9692512469195,
        lat: 10.474188469741364,
      },
      {
        lon: 106.96722858997299,
        lat: 10.471099909563236,
      },
      {
        lon: 106.96690014200038,
        lat: 10.465502575000471,
      },
      {
        lon: 106.9662842307813,
        lat: 10.462285568540494,
      },
      {
        lon: 106.96818860900049,
        lat: 10.452897652000502,
      },
      {
        lon: 106.9692645990005,
        lat: 10.454351151000424,
      },
      {
        lon: 106.97108991700054,
        lat: 10.45525035700041,
      },
      {
        lon: 106.9725727392343,
        lat: 10.457178579503648,
      },
      {
        lon: 106.97273310300045,
        lat: 10.458621116000302,
      },
      {
        lon: 106.97385615700045,
        lat: 10.460965924000334,
      },
      {
        lon: 106.9737338040004,
        lat: 10.464171827000484,
      },
      {
        lon: 106.9720154580005,
        lat: 10.46767960900047,
      },
      {
        lon: 106.97293420100038,
        lat: 10.47024214400011,
      },
      {
        lon: 106.97430976300052,
        lat: 10.470430513000508,
      },
      {
        lon: 106.97631569500038,
        lat: 10.472786170000461,
      },
      {
        lon: 106.97798735908185,
        lat: 10.473513573172852,
      },
      {
        lon: 106.98311884388164,
        lat: 10.47924001273202,
      },
      {
        lon: 106.98288177900048,
        lat: 10.479942361999974,
      },
      {
        lon: 106.98460298500044,
        lat: 10.485108365000485,
      },
      {
        lon: 106.98556794266102,
        lat: 10.485682885784172,
      },
      {
        lon: 106.98465288800054,
        lat: 10.491132642000025,
      },
      {
        lon: 106.98327138100035,
        lat: 10.493878212000368,
      },
      {
        lon: 106.98304438600053,
        lat: 10.495645340000408,
      },
      {
        lon: 106.98378193500054,
        lat: 10.498263517000396,
      },
      {
        lon: 106.98691945400049,
        lat: 10.503622453000219,
      },
      {
        lon: 106.98819603100054,
        lat: 10.504658980000272,
      },
      {
        lon: 106.9895857066071,
        lat: 10.504880148921359,
      },
      {
        lon: 106.99105459180566,
        lat: 10.509632424563506,
      },
      {
        lon: 106.98240507970672,
        lat: 10.510161986528828,
      },
      {
        lon: 106.97981827100045,
        lat: 10.50809384000047,
      },
      {
        lon: 106.97571939100044,
        lat: 10.502870450000444,
      },
      {
        lon: 106.97480946400054,
        lat: 10.501173587000494,
      },
      {
        lon: 106.97356150400049,
        lat: 10.5001983300005,
      },
      {
        lon: 106.9711644830005,
        lat: 10.502231523000432,
      },
      {
        lon: 106.97056470900043,
        lat: 10.504717851000464,
      },
      {
        lon: 106.96968234521165,
        lat: 10.506408267052564,
      },
      {
        lon: 106.96649567000065,
        lat: 10.509080729000289,
      },
      {
        lon: 106.96440839800044,
        lat: 10.508674334000148,
      },
      {
        lon: 106.96354040200038,
        lat: 10.506880257000489,
      },
      {
        lon: 106.95993552200054,
        lat: 10.507844357000522,
      },
      {
        lon: 106.95983764300053,
        lat: 10.508730591000472,
      },
      {
        lon: 106.96097267900046,
        lat: 10.510516681000468,
      },
      {
        lon: 106.96070445200041,
        lat: 10.511875794000389,
      },
      {
        lon: 106.96356646800041,
        lat: 10.513761094000415,
      },
      {
        lon: 106.96395913442689,
        lat: 10.515195336605814,
      },
      {
        lon: 106.96332590212313,
        lat: 10.516280512414198,
      },
      {
        lon: 106.96162782800042,
        lat: 10.51706879600033,
      },
      {
        lon: 106.96208979100038,
        lat: 10.5188235800004,
      },
      {
        lon: 106.96062187400048,
        lat: 10.520541136000418,
      },
      {
        lon: 106.95902383900051,
        lat: 10.520933597000464,
      },
      {
        lon: 106.96074924900063,
        lat: 10.525369891000537,
      },
      {
        lon: 106.96096435900051,
        lat: 10.528831875000435,
      },
      {
        lon: 106.96161465300048,
        lat: 10.53076571000048,
      },
      {
        lon: 106.96127432900039,
        lat: 10.532537024000135,
      },
      {
        lon: 106.95855491100042,
        lat: 10.533927387000517,
      },
      {
        lon: 106.95803391800041,
        lat: 10.535076249000383,
      },
      {
        lon: 106.95840430100043,
        lat: 10.537785856000482,
      },
      {
        lon: 106.96007874900039,
        lat: 10.536887075000436,
      },
      {
        lon: 106.96372608090786,
        lat: 10.536582487366218,
      },
    ],
  ],
};

export default data;
